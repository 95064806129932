<template>

  <div v-if="controller.account.accountGroupID != 3">
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.stationPagination.total"
      :items="controller.stationPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >

      <template v-slot:[`item.barometer`]="{ item }">
        <v-icon v-if="item.barometer" color="green">mdi-check-bold</v-icon> 
        <v-icon v-else color="red">mdi-close-thick</v-icon> 
      </template>

      <template v-slot:[`item.humidity`]="{ item }">
        <v-icon v-if="item.humidity" color="green">mdi-check-bold</v-icon> 
        <v-icon v-else color="red">mdi-close-thick</v-icon> 
      </template>

      <template v-slot:[`item.pluviometer`]="{ item }">
        <v-icon v-if="item.pluviometer" color="green">mdi-check-bold</v-icon> 
        <v-icon v-else color="red">mdi-close-thick</v-icon> 
      </template>

      <template v-slot:[`item.temperature`]="{ item }">
        <v-icon v-if="item.temperature" color="green">mdi-check-bold</v-icon> 
        <v-icon v-else color="red">mdi-close-thick</v-icon> 
      </template>

      <template v-slot:[`item.wind_direction`]="{ item }">
        <v-icon v-if="item.wind_direction" color="green">mdi-check-bold</v-icon> 
        <v-icon v-else color="red">mdi-close-thick</v-icon> 
      </template>

      <template v-slot:[`item.wind_velocity`]="{ item }">
        <v-icon v-if="item.wind_velocity" color="green">mdi-check-bold</v-icon> 
        <v-icon v-else color="red">mdi-close-thick</v-icon> 
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.historic(item)"
            class="px-2"
          >
            <v-icon
              small
              color="purple"
            >mdi-chart-line</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.chart(item)"
            class="px-2"
          >
            <v-icon
              small
              color="green"
            >mdi-chart-bar</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.logs(item)"
            class="px-2"
            v-if=hadPermission(4,4)
          >
            <v-icon
              small
              color="orange"
            >mdi-database</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(4, 3)"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
            v-if="hadPermission(4, 4)"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Estações</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            :max-width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="hadPermission(5, 2)"
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  <div v-if="controller.flagOption == 'create'">Criar Estação</div> 
                  <div v-else>Editar Estação</div>
                  <v-spacer></v-spacer>
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="pt-3">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row>
                    <v-col>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nome da estação"
                        v-model="controller.station.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      Sensores:
                      <v-switch label="Barômetro" v-model="controller.station.barometer"></v-switch>
                      <v-switch label="Umidade" v-model="controller.station.humidity"></v-switch>
                      <v-switch label="Temperatura" v-model="controller.station.temperature"></v-switch>
                      <v-switch label="Velocidade do vento" v-model="controller.station.wind_velocity"></v-switch>
                      <v-switch label="Direção do vento" v-model="controller.station.wind_direction"></v-switch>
                      <v-text-field
                        v-if="controller.station.wind_direction"
                        filled
                        dense
                        color="primary"
                        label="Coeficiente da direção do vento"
                        v-model="controller.station.wind_direction_coefficient"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <v-switch label="Pluviômetro" v-model="controller.station.pluviometer"></v-switch>

                      <v-text-field
                        v-if="controller.station.pluviometer"
                        filled
                        dense
                        color="primary"
                        label="Modelo do pluviômetro"
                        v-model="controller.station.pluviometer_model"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>

                      <v-text-field
                        v-if="controller.station.pluviometer"
                        filled
                        dense
                        color="primary"
                        label="MM por pulso"
                        v-model="controller.station.pluviometer_mmpp"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>

                      <v-autocomplete
                        v-if="isAdmin()"
                        filled
                        dense
                        color="secondary"
                        label="Empresa"
                        v-model="controller.station.company_id"
                        :search-input.sync="controller.searchCompany"
                        :items="controller.companyPagination.items"
                        item-text="name"
                        item-value="id"
                      >
                      </v-autocomplete>

                      <v-text-field
                        filled
                        dense
                        readonly
                        color="primary"
                        label="Latitude"
                        v-model="controller.station.latitude"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        readonly
                        color="primary"
                        label="Longitude"
                        v-model="controller.station.longitude"
                      >
                      </v-text-field>

                      <form-maps
                        :center="controller.center"
                        :zoom="controller.zoom"
                        @get-location="controller.getLocation($event)"
                      ></form-maps>

                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog
      v-model="controller.dialogChart"
    >
      
      <v-card
        class="elevation-0 ma-0 py-3"
        dense
        height="116vh"
        style="background-color: #e0e1e6 !important;"
      >

        <v-card-text>

          <div
            class="d-flex justify-space-around flex-wrap"
            min-height="200"
            v-if="controller.selectedData != undefined"
          >

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.wind_velocity"
              class="ma-2 pa-2"
              id="wind_velocity_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.humidity"
              class="ma-2 pa-2"
              id="humidity_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>
            
            <v-card
              v-if="controller.dialogChart && controller.selectedStation.temperature"
              class="ma-2 pa-2"
              id="temperature_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.temperature && controller.selectedStation.humidity && controller.selectedStation.wind_velocity"
              class="ma-2 pa-2"
              id="temperatures_chart"
              style="height: 55vh; width: 56vh;"
              >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.pluviometer"
              class="ma-2 pa-2"
              id="pluviometer_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.wind_direction"
              class="ma-2 pa-2"
              id="wind_direction_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

          </div>

        </v-card-text>
        
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="controller.dialogHistoric"
    >
      <v-card
        class="elevation-0 ma-0 pa-0"
        dense
        :height="controller.cardHeight"
        id = "historic_card"
        style="background-color: #e0e1e6 !important;"
      >

      <v-card-title>
        Históricos
        <v-form class="d-flex align-center justify-space-around mx-2">

          <date-picker
            style="max-width: 150px"
            v-model="controller.calendarIn"
            filled
            dense
            label="Data inicial"
            class="ml-2 mt-6"
            single-line
            @input="controller.createStationHistoric()"
          ></date-picker>
          <date-picker
            v-model="controller.calendarUntil"
            filled
            dense
            label="Data final"
            style="max-width: 150px"
            class="mx-2 mt-6"
            single-line
            @input="controller.createStationHistoric()"
          ></date-picker>
          <input
            type="time"
            v-model="controller.historicTime"
            @input="controller.createStationHistoric()"
            step="3600"
            min="0"
            max="23"
          />
          <v-select
            label="Período de Pluviosidade"
            :items="['Meses', 'Dias', 'Horas']"
            v-model="controller.pluviometerHistoricType"
            @input="controller.createStationHistoric()"
          ></v-select>
        </v-form>
      </v-card-title>

      <v-card-text>
          <div
            class="d-flex justify-space-around flex-wrap"
            min-height="200"
          >

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.humidity"
              class="ma-2 pa-2"
              id="humidity_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.wind_velocity"
              class="ma-2 pa-2"
              id="wind_velocity_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.temperature"
              class="ma-2 pa-2"
              id="temperature_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.wind_direction"
              class="ma-2 pa-2"
              id="wind_direction_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.pluviometer"
              class="ma-2 pa-2"
              id="pluviometer_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

          </div>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="controller.dialogTracking"
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-data-table
            class="elevation-0 ma-0 pa-0"
            dense
            height="75vh"
            fixed-header
            :headers="controller.columnsTracking"
            :options.sync="controller.optionsTracking"
            :server-items-length="controller.trackingPagination.total"
            :items="controller.trackingPagination.items"
            show-select
            v-model="controller.selected"
            :footer-props="{
              'items-per-page-options': [40, 80],
            }"
            :loading="controller.loadingLogs"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ dayjs(item.created_at).format("DD/MM/YYYY HH:mm:ss") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-btn
                  v-if="hadPermission(4, 3)"
                  icon
                  @click="controller.updateData(item)"
                  class="px-2"
                >
                  <v-icon
                    small
                    color="blue"
                  >mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Dados da estação:
                  {{ controller.selectedStation.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="hadPermission(4, 4)"
                  v-show="controller.selected.length > 0"
                  @click="controller.deleteSelected()"
                  color="red"
                  class="mr-2"
                  style="color: white"
                >
                  <v-icon>mdi-delete</v-icon>Excluir Selecionados
                </v-btn>
                <v-btn
                  :loading="controller.loadingBtnExcel"
                  color="green"
                  class="white--text"
                  @click="controller.exportExcel()"
                >
                  <v-icon left> mdi-file-excel </v-icon>
                  Excel
                </v-btn>
                <v-btn
                  @click="controller.reloadLogs()"
                  icon
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-btn
                  @click="controller.dialogTracking = false"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      width="350"
      persistent
      v-model="controller.dialogStationUpdateData"
    >
      <v-card>
        <v-card-title> Alterar Dados </v-card-title>
        <v-card-text>
          <v-text-field
            type="number"
            filled
            label="Temperatura"
            v-model="controller.selectedMarkStation.temperature"
          ></v-text-field>
          <v-text-field
            type="text"
            filled
            label="Direção do vento"
            v-model="controller.selectedMarkStation.wind_direction"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Velocidade do vento"
            v-model="controller.selectedMarkStation.wind_velocity"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Umidade"
            v-model="controller.selectedMarkStation.humidity"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="MMPP"
            v-model="controller.selectedMarkStation.pluviometer_mmpp"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Status da Bateria"
            v-model="controller.selectedMarkStation.hardware_base.battery_status"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Intensidade do Sinal"
            v-model="controller.selectedMarkStation.hardware_base.signal_intensity"
          ></v-text-field>
          <v-text-field
            type="text"
            filled
            label="Origem do Sinal"
            v-model="controller.selectedMarkStation.hardware_base.signal_origin"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn
            @click="controller.dialogStationUpdateData = false"
            outlined
            color="red"
          >
            <v-icon left>mdi-delete</v-icon>cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="controller.confirmUpdateData()"
            color="primary"
          >
            <v-icon left>mdi-content-save</v-icon>alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <div v-else>

    <v-toolbar
        style="background-color: #e0e1e6 !important;"
        flat
        class="my-2 d-none d-md-block"
      >
        <v-toolbar-title> Estações </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    
    <v-row style="height: 150px; width: 100%; overflow-y: scroll;" class="mb-0 px-2">
      <v-col
        cols="12"
        lg="3"
        v-for="(station, i) in controller.stationPagination.items"
        :key="i"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            style="cursor: pointer;"
            class="ma-0"
            @click="controller.selectStation(station)"
          >
            <v-card-text class="ma-2">
              <v-row>
                <v-col cols="12">
                  <div class="pt-2 d-flex">
                    <h2 class="font-weight-medium">Estação:</h2>
                    <h2 class="font-weight-medium pl-2">
                      {{ station.name }}
                    </h2>
                  </div>
                  <div v-if="controller.dates[i]" class="pt-2 d-flex">
                    <h4 class="font-weight-medium">Atualizado em:</h4>
                    <h4 class="font-weight-medium pl-2">
                      {{
                        dayjs(controller.dates[i]).format("DD/MM/YYYY HH:mm:ss")
                      }}
                    </h4>
                  </div>
                  <div class="pt-2" v-else>
                    <h4 class="font-weight-medium">Nenhum dado registrado.</h4>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <div
      class="d-flex justify-space-around align-center flex-wrap"
      style="height: 750px"
    >

      <v-btn
        @click="controller.chart(controller.selectedStation)"
        class="px-2 ma-16"
        variant="outlined"
        style="width: 200px; height: 100px; font-size: 20px;"
      >
        <v-icon
          color="green"
          size="40"
        >mdi-chart-bar
        </v-icon>
        Gráficos
      </v-btn>
  
      <v-btn
        @click="controller.historic(controller.selectedStation)"
        class="px-2 ma-16"
        variant="outlined"
        size="x-large"
        style="width: 200px; height: 100px; font-size: 20px;"
      >
        <v-icon
          color="purple"
          size="40"
        >mdi-chart-line
        </v-icon>
        Históricos
      </v-btn>
    
    </div>

    <v-dialog
      v-model="controller.dialogChart"
    >
      
      <v-card
        class="elevation-0 ma-0 py-3"
        dense
        height="116vh"
        style="background-color: #e0e1e6 !important;"
      >

        <v-card-text>

          <div
            class="d-flex justify-space-around flex-wrap"
            min-height="200"
            v-if="controller.selectedData != undefined"
          >

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.wind_velocity"
              class="ma-2 pa-2"
              id="wind_velocity_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.humidity"
              class="ma-2 pa-2"
              id="humidity_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>
            
            <v-card
              v-if="controller.dialogChart && controller.selectedStation.temperature"
              class="ma-2 pa-2"
              id="temperature_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.temperature && controller.selectedStation.humidity && controller.selectedStation.wind_velocity"
              class="ma-2 pa-2"
              id="temperatures_chart"
              style="height: 55vh; width: 56vh;"
              >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.pluviometer"
              class="ma-2 pa-2"
              id="pluviometer_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogChart && controller.selectedStation.wind_direction"
              class="ma-2 pa-2"
              id="wind_direction_chart"
              style="height: 55vh; width: 56vh;"
            >
            </v-card>

          </div>

        </v-card-text>
        
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="controller.dialogHistoric"
    >
    <v-card
        class="elevation-0 ma-0 pa-0"
        dense
        :height="controller.cardHeight"
        id = "historic_card"
        style="background-color: #e0e1e6 !important;"
      >

      <v-card-title>
        Históricos
        <v-form class="d-flex align-center justify-space-around mx-2">

          <date-picker
            style="max-width: 150px"
            v-model="controller.calendarIn"
            filled
            dense
            label="Data inicial"
            class="ml-2 mt-6"
            single-line
            @input="controller.createStationHistoric()"
          ></date-picker>
          <date-picker
            v-model="controller.calendarUntil"
            filled
            dense
            label="Data final"
            style="max-width: 150px"
            class="mx-2 mt-6"
            single-line
            @input="controller.createStationHistoric()"
          ></date-picker>
          <input
            type="time"
            v-model="controller.historicTime"
            @input="controller.createStationHistoric()"
            step="3600"
            min="0"
            max="23"
          />
          <v-select
            label="Período de Pluviosidade"
            :items="['Meses', 'Dias', 'Horas']"
            v-model="controller.pluviometerHistoricType"
            @input="controller.createStationHistoric()"
          ></v-select>
        </v-form>
      </v-card-title>

      <v-card-text>
          <div
            class="d-flex justify-space-around flex-wrap"
            min-height="200"
          >

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.humidity"
              class="ma-2 pa-2"
              id="humidity_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.wind_velocity"
              class="ma-2 pa-2"
              id="wind_velocity_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.temperature"
              class="ma-2 pa-2"
              id="temperature_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.wind_direction"
              class="ma-2 pa-2"
              id="wind_direction_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

            <v-card
              v-if="controller.dialogHistoric && controller.selectedStation.pluviometer"
              class="ma-2 pa-2"
              id="pluviometer_historic"
              style="height: 50vh; width: 1000vh;"
            >
            </v-card>

          </div>
        </v-card-text>

      </v-card>
    </v-dialog>

  </div>

</template>
  
  <script>
  import { stationController } from "@/di/di";
  import _ from "lodash";
  
  export default {
    data: (context) => ({
      controller: stationController(context),
    }),
    watch: {
      "controller.options"() {
        this.controller.watchOptions();
      },
      "controller.search"() {
        this.controller.watchSearch();
      },
      "controller.searchTracking": _.debounce(function () {
        this.controller.watchSearchTrackign();
      }, 500),
      "controller.optionsCompany"() {
        this.controller.watchOptionsCompany();
      },
      "controller.optionsTracking"() {
        this.controller.watchOptionsTracking();
      },
      "controller.searchCompany": _.debounce(function () {
        this.controller.watchSearchCompany();
      }, 500),
      "controller.optionsPlace"() {
        this.controller.watchOptionsPlace();
      },
      "controller.searchPlace": _.debounce(function () {
        this.controller.watchSearchPlace();
      }, 500),
    },
    beforeMount() {
      if(this.controller.account.accountGroupID == 3) this.controller.paginate()
    }
  };
  </script>