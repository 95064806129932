import { BombMeasurement } from '../../../pumpMark/domain/model/bomb_measurement';
import { FetchCompanyMeasurementRepository } from '../../data/repository/fetch_company_mesurement_repository';
import { getCompanyID, JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { GetCompanyByIDRepository } from '../../data/repository/get_company_by_id_repository';
import { Company } from '../../../company/domain/model/company';


export interface FetchCompanyMeasurementUseCase {
    (): Promise<Array<BombMeasurement>>
}

const fetchCompanyMeasurementUseCase = (repository: FetchCompanyMeasurementRepository): FetchCompanyMeasurementUseCase => async () => {
    try {
        const companyID : number | null = getCompanyID()
        return await repository(companyID!);
    } catch (error) {
        throw error
    }
}

export default fetchCompanyMeasurementUseCase;

