import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface ChangeScreenCompanyRepository {
  (id: number, data: object): Promise<Screen>
}

const changeScreenCompanyRepository = (axios: AxiosInstance): ChangeScreenCompanyRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/screen-company/${id}`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeScreenCompanyRepository

