import { ChecklistPagination } from '../model/checklist_pagination';
import { FetchChecklistRepository } from '../../data/repository/fetch_checklist_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/checklist';

export interface FetchChecklistUseCase {
    (pagination: DataOptions, search: string): Promise<ChecklistPagination>
}

const fetchChecklistUseCase = (repository: FetchChecklistRepository): FetchChecklistUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchChecklistUseCase;