import { MarkRulerPagination } from '../model/markRuler_pagination';
import { FetchMarkRulerRepository } from '../../data/repository/fetch_markRuler_repository';
import { PaginationDate } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/markRuler';

export interface FetchMarkRulerUseCase {
    (pagination: DataOptions, id:number, startDate: string, endDate: string): Promise<MarkRulerPagination>
}

const fetchMarkRulerUseCase = (repository: FetchMarkRulerRepository): FetchMarkRulerUseCase => async (pagination: DataOptions, id:number, startDate: string, endDate: string) => {
    try {
        const payload: PaginationDate = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: "",
            startDate: startDate,
            endDate: endDate
        }
        return await repository(payload, id);
    } catch (error) {
        throw error
    }
}

export default fetchMarkRulerUseCase;