import { MarkStationPagination } from '../model/markStation_pagination';
import { FetchMarkStationRepository } from '../../data/repository/fetch_markStation_repository';
import { PaginationDate } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/markStation';

export interface FetchMarkStationUseCase {
    (pagination: DataOptions, id:number, startDate: string, endDate: string): Promise<MarkStationPagination>
}

const fetchMarkStationUseCase = (repository: FetchMarkStationRepository): FetchMarkStationUseCase => async (pagination: DataOptions, id:number, startDate: string, endDate: string) => {
    try {
        const payload: PaginationDate = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: "",
            startDate: startDate,
            endDate: endDate
        }
        return await repository(payload, id);
    } catch (error) {
        throw error
    }
}

export default fetchMarkStationUseCase;