export const headersTracking = [
  {
    text: 'Data da criação',
    value: 'created_at'
  },
  {
    text: 'Temperatura',
    value: 'temperature'
  },
  {
    text: 'Direção do vento',
    value: 'wind_direction',
  },
  {
    text: 'Velocidade do vento',
    value: 'wind_velocity'
  },
  {
    text: 'Umidade',
    value: 'humidity'
  },
  {
    text: 'MMPP',
    value: 'pluviometer_mmpp'
  },
  {
    text: 'Status da bateria',
    value: 'hardware_base.battery_status'
  },
  {
    text: 'Intensidade do sinal',
    value: 'hardware_base.signal_intensity'
  },
  {
    text: 'Origem do sinal',
    value: 'hardware_base.signal_origin'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'end'
  }
]

