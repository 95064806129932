import { CallCenter } from '../../domain/model/call_center';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteCallCenterRepository {
    (id: number): Promise<CallCenter>
}

const deleteCallCenterRepository = (axios: AxiosInstance): DeleteCallCenterRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/call-center/${id}`)

        const callCenter: CallCenter = response.data
        return callCenter
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteCallCenterRepository