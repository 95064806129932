import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';
import { RulerGroup } from '../../domain/model/rulerGroup';

export interface DeleteRulerGroupRepository {
    (id: number): Promise<RulerGroup>
}

const deleteRulerGroupRepository = (axios: AxiosInstance): DeleteRulerGroupRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/group/${id}`)

        const rulerGroup: RulerGroup = response.data
        return rulerGroup
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteRulerGroupRepository