<template>
  <div class="mb-2 root background_screen">
    <lottie
      v-if="controller.loadingBtn"
      class="loading-animation"
      :options="controller.lottieLevelAnimation"
      :height="400"
      :width="300"
    />
    <v-form ref="filter" class="mx-2">
      <v-toolbar flat class="my-2 d-none d-md-block background_screen">
        <v-toolbar-title> Marcações dos Grupos de Réguas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-form class="d-flex align-center justify-space-around mx-2">
            <v-autocomplete
              v-model="controller.selectedGroupRuler"
              :items="controller.rulerMarkGroupPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione o grupo de réguas"
              style="max-width: 200px"
              return-object
              single-line
              @change="controller.changeGroupRuler($event)"
            ></v-autocomplete>
            <!--             <v-autocomplete
              v-model="controller.typeOfFilterSelected"
              :items="controller.typeOfFilter"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione tipo de filtro"
              style="max-width: 200px"
              return-object
              single-line
              @change="controller.changePeriod($event)"
            ></v-autocomplete>
            <v-autocomplete
              v-if="controller.typeOfFilterSelected.id == 1"
              v-model="controller.selectedMonth"
              :items="controller.monthsOfYear"
              item-text="name"
              item-value="value"
              dense
              filled
              class="pt-6 pl-2"
              return-object
              single-line
              @change="controller.changeMonth($event)"
            ></v-autocomplete> -->

            <div>
              <date-picker
                style="max-width: 150px"
                v-model="controller.dayOfFilter"
                filled
                dense
                label="Dia"
                class="ml-2 mt-6"
                single-line
              ></date-picker>
            </div>
            <!--             <v-card
              class="ml-2 px-2 day-of-filter"
              v-if="controller.typeOfFilterSelected.id == 1"
            >
              <v-btn @click="controller.lastWeek()" icon
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <b>
                {{ dayjs(controller.startOfWeek).format("DD/MM/YYYY") }} -
                {{ dayjs(controller.endOfWeek).format("DD/MM/YYYY") }}
              </b>
              <v-btn @click="controller.nextWeek()" icon
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-card>
            <v-card
              class="ml-2 px-2 day-of-filter"
              v-if="controller.typeOfFilterSelected.id == 2"
            >
              <v-btn @click="controller.lastMonth()" icon
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <b> {{ dayjs(controller.startOfMonth).format("MMMM YYYY") }} </b>
              <v-btn @click="controller.nextMonth()" icon
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-card> -->
            <!--             <v-autocomplete
              v-if="controller.typeOfFilterSelected.id == 3"
              v-model="controller.selectedHarvest"
              :items="controller.harvestPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione a safra"
              style="max-width: 200px"
              return-object
              @change="controller.changeHarvest($event)"
              single-line
            ></v-autocomplete> -->
          </v-form>
        </v-toolbar-items>
      </v-toolbar>
      <v-card class="my-3 mx-2 d-md-none">
        <v-card-text>
          <h3>Marcações das Réguas</h3>
          <v-row no-gutters justify="center">
            <v-col cols="12" class="d-flex justify-center">
            <v-autocomplete
              v-model="controller.selectedGroupRuler"
              :items="controller.rulerMarkGroupPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6"
              label="Selecione o grupo de réguas"
              return-object
              single-line
              @change="controller.changeGroupRuler($event)"
            ></v-autocomplete>
            </v-col>
<!--             <v-col cols="12">
              <v-autocomplete
                v-model="controller.typeOfFilterSelected"
                :items="controller.typeOfFilter"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione tipo de filtro"
                return-object
                single-line
                @change="controller.changePeriod($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-if="controller.typeOfFilterSelected.id == 1"
                v-model="controller.selectedMonth"
                :items="controller.monthsOfYear"
                item-text="name"
                item-value="value"
                dense
                filled
                return-object
                single-line
                @change="controller.changeMonth($event)"
              ></v-autocomplete>
            </v-col> -->
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <date-picker
                  v-model="controller.dayOfFilter"
                  filled
                  dense
                  label="Dia"
                  single-line
                  class="mt-6"
                ></date-picker>
              </div>
<!--               <v-card
                class="day-of-filter"
                v-if="controller.typeOfFilterSelected.id == 1"
              >
                <v-btn @click="controller.lastWeek()" icon
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <b>
                  {{ dayjs(controller.startOfWeek).format("DD/MM/YYYY") }} -
                  {{ dayjs(controller.endOfWeek).format("DD/MM/YYYY") }}
                </b>
                <v-btn @click="controller.nextWeek()" icon
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-card>
              <v-card
                class="day-of-filter"
                v-if="controller.typeOfFilterSelected.id == 2"
              >
                <v-btn @click="controller.lastMonth()" icon
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <b>
                  {{ dayjs(controller.startOfMonth).format("MMMM YYYY") }}
                </b>
                <v-btn @click="controller.nextMonth()" icon
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-card>
              <v-autocomplete
                v-if="controller.typeOfFilterSelected.id == 3"
                v-model="controller.selectedHarvest"
                :items="controller.harvestPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6 pl-2"
                label="Selecione a safra"
                return-object
                @change="controller.changeHarvest($event)"
                single-line
              ></v-autocomplete> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <!--     <v-form ref="filter" class="mx-2">
      <v-toolbar flat class="my-2 d-none d-md-block background_screen">
        <v-toolbar-title> Marcações dos Grupos de Réguas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-form class="d-flex align-center justify-space-around mx-2">
            <v-autocomplete
              v-model="controller.selectedHarvest"
              :items="controller.harvestPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6"
              label="Selecione a safra"
              style="max-width: 200px"
              return-object
              @change="controller.changeHarvest($event)"
              single-line
            ></v-autocomplete>
            <v-autocomplete
              v-model="controller.selectedGroupRuler"
              :items="controller.rulerMarkGroupPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione o grupo de réguas"
              style="max-width: 200px"
              return-object
              single-line
            ></v-autocomplete>
            <date-picker
              style="max-width: 150px"
              v-model="controller.calendarIn"
              filled
              dense
              label="Data inicial"
              class="ml-2 mt-6"
              single-line
            ></date-picker>
            <date-picker
              v-model="controller.calendarUntil"
              filled
              dense
              label="Data final"
              style="max-width: 150px"
              class="mx-2 mt-6"
              single-line
            ></date-picker>

            <div class="ml-2">
              <v-btn
                height="38"
                :loading="controller.loadingBtn"
                color="primary"
                class="mt-0"
                @click="controller.filter()"
              >
                <v-icon> mdi-filter </v-icon>
                Filtrar
              </v-btn>
            </div>
          </v-form>
        </v-toolbar-items>
      </v-toolbar>

      <v-toolbar flat class="my-3 mx-2 d-md-none">
        <v-toolbar-title> Marcações das Réguas </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="controller.dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar flat>
              <v-toolbar-title> Marcações das Réguas </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="controller.dialog = false">
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="d-flex flex-column">
              <v-autocomplete
                v-model="controller.selectedHarvest"
                :items="controller.harvestPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a safra"
                return-object
                @change="controller.changeHarvest($event)"
                single-line
              ></v-autocomplete>
              <v-autocomplete
                v-model="controller.selectedGroupRuler"
                :items="controller.rulerMarkGroupPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione o grupo de réguas"
                return-object
              ></v-autocomplete>
              <date-picker
                v-model="controller.calendarIn"
                filled
                dense
                label="Data inicial"
              ></date-picker>
              <date-picker
                v-model="controller.calendarUntil"
                filled
                dense
                label="Data final"
              ></date-picker>
              <div class="d-flex align-center">
                <div>
                  <v-btn
                    height="50"
                    :loading="controller.loadingBtn"
                    color="primary"
                    class="mt-0"
                    @click="controller.filter()"
                  >
                    <v-icon> mdi-filter </v-icon>
                    Filtrar
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-form> -->

    <v-row
      no-gutters
      v-for="(chart, index) in controller.marks"
      :key="index"
      v-show="controller.showCharts"
    >
      <v-col cols="12">
        <v-row class="my-1" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="6" xl="6" style="height: 40vh">
            <v-card class="mx-2 my-1 d-flex align-center justify-center">
              <v-card-text>
                <div class="mx-2">
                  <div :id="`ruler_chart${index}`" style="height: 35vh"></div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="chart.marcacoes.length > 0"
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            style="height: 40vh"
          >
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-center">
                <v-slide-group
                  style="height: 40vh"
                  class="d-flex align-center justify-center"
                  show-arrow
                >
                  <v-slide-item
                    v-for="(ruler, i) in chart.marcacoes"
                    :key="i"
                  >
                    <v-card class="ma-1" height="300" width="200">
                      <v-card-text style="height: 85%">
                        <v-row>
                          <v-col cols="12" class="d-flex justify-center">
                            <v-tooltip
                              bottom
                              style="position: relative; right: 100px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-img
                                  v-if="ruler.accountName == null"
                                  :src="
                                    $vuetify.theme.isDark
                                      ? '/NVL.png'
                                      : '/NVL2.png'
                                  "
                                  max-width="60"
                                  max-height="35"
                                  contain
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-img>
                              </template>
                              <span>Medição feita por hardware</span>
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              style="position: relative; right: 100px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <h3
                                  v-if="ruler.accountID != null"
                                  v-bind="attrs"
                                  v-on="on"
                                  class="pr-2"
                                >
                                  {{ ruler.accountName }}
                                </h3>
                              </template>
                              <span>Medição feita por colaborador</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" class="pt-4 d-flex justify-center">
                            <h2 class="font-weight-medium">
                              {{ ruler.rulerName }}
                            </h2>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            class="pt-4 d-flex flex-column align-center"
                          >
                            <h3 class="font-weight-medium">Nível atual:</h3>
                            <h1 class="font-weight-medium">
                              {{ ruler.level.toFixed(2) }}
                            </h1>
                          </v-col>
                        </v-row>

                        <v-row no-gutters class="pt-6">
                          <v-col cols="12" class="d-flex justify-center">
                            <div class="pt-2" style="text-align: center">
                              <span class="font-weight-medium"
                                >Atualizado em:</span
                              >
                              <br />
                              <span>
                                {{
                                  dayjs(ruler.createdDate).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                }}</span
                              >
                              <h4 class="font-weight-medium pl-2"></h4>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions
                        style="height: 15%"
                        :style="{ backgroundColor: ruler.rulerColor }"
                      >
                      </v-card-actions>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>

                <!--                 <v-card>
                  <v-card-text class="ma-2">
                    <v-row>
                      <v-col cols="9">
                        <h3 class="font-weight-medium">
                          {{ ruler.rulerName }}
                        </h3>
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end align-start">
                        <v-tooltip
                          bottom
                          style="position: relative; right: 100px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              v-if="ruler.accountName == null"
                              :src="
                                $vuetify.theme.isDark ? '/NVL.png' : '/NVL2.png'
                              "
                              max-width="50"
                              max-height="35"
                              contain
                              v-bind="attrs"
                              v-on="on"
                            ></v-img>
                          </template>
                          <span>Medição feita por hardware</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          style="position: relative; right: 100px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <h3
                              v-if="ruler.accountID != null"
                              v-bind="attrs"
                              v-on="on"
                              class="pr-2"
                            >
                              {{ ruler.accountName }}
                            </h3>
                          </template>
                          <span>Medição feita por colaborador</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-row no-gutters class="pt-2">
                      <v-col cols="12">
                        <div class="d-flex">
                          <h4 class="font-weight-medium">Nível atual:</h4>
                          <h2 class="font-weight-medium pl-2">
                            {{ ruler.level.toFixed(2) }}
                          </h2>
                        </div>
                        <div class="pt-2">
                          <span class="font-weight-medium">Atualizado em:</span>
                          <br />
                          <span>
                            {{
                              dayjs(ruler.createdDate).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            }}</span
                          >
                          <h4 class="font-weight-medium pl-2"></h4>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions
                    style="height: 20px"
                    :style="{ backgroundColor: ruler.rulerColor }"
                  >
                  </v-card-actions>
                </v-card> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-else
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            style="height: 40vh"
            class="d-flex align-center justify-center"
          >
            <span class="px-10" style="text-weight: 500; font-size: 25px; text-align: center">Não foram encontradas medições nesse período.</span>
          </v-col>
        </v-row>
        <v-divider style="color: black"></v-divider>
      </v-col>
    </v-row>

    <!--  </v-layout> -->
    <!-- </v-container> -->
    <right-nav-drawer :controller="controller" />
  </div>
</template>

<script>
import { rulerGroupMarkController } from "@/di/di";
import rightDrawer from "../components/rightDrawer.vue";
import dayjs from "dayjs";
import _ from "lodash";
import Lottie from "../../../core/component/lottie.vue";

export default {
  components: {
    "right-nav-drawer": rightDrawer,
    Lottie,
  },
  data: (context) => ({
    controller: rulerGroupMarkController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.calendarIn"() {
      dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    "controller.dayOfFilter"() {
      this.controller.changeDay();
    },
  },
  computed: {
    formatDateIn() {
      return dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    formatDateUntil() {
      return dayjs(this.controller.calendarUntil).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.root {
  min-height: 100vh;
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>