import { CreateEngineRepository } from '../../data/repository/create_engine_repository';
import { Engine } from '../model/engine';

export interface CreateEngineUseCase {
    (data: object): Promise<Engine>
}

const createEngineUseCase = (repository: CreateEngineRepository): CreateEngineUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createEngineUseCase;

