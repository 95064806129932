<template>
  <v-app>
    <v-container>

      <v-row
        class="fill-height"
        justify="center"
      >
        <v-col
          cols="12"
          lg="6"
        >
          <v-card>
            <v-card-title>
              Funcionalidades
              <v-spacer></v-spacer>
              <v-btn @click="controller.saveScreens()" color="primary">
                <v-icon left>mdi-content-save</v-icon>salvar
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                v-if="isAdmin()"
                filled
                dense
                color="primary"
                label="Empresa"
                :search-input.sync="controller.searchCompany"
                :items="controller.companyPagination.items"
                item-text="name"
                item-value="id"
                @change="controller.changeCompany($event)"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-autocomplete>

              <v-treeview
                v-model="controller.screensOfCompany"
                selectable
                :items="controller.screens"
                color="primary"
                selected-color="primary"
              ></v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { screenController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: screenController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
  },
};
</script>