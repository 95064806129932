
import { PropType } from 'vue';


type Item = {
  [x: string]: any;
  id: number;
  placeID: number;
  pumpID: number;
  PumpName: string;
  EngineID: number;
  EngineName: string;
  CompanyID: number;
  isFavorite: boolean;
  remoteControl: boolean;
  communicationLevante: boolean;
  energyMonitoring: boolean;
  inUseSet: boolean;
  createdDate: string;
  updatedDate: string;
};

export default {
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
  computed: {
    conection(this: { item: Item }) {
      return this.item.communicationLevante || this.item.energyMonitoring;
    },
  },


  methods: {
    async toggleStatus(this: { item: Item }) {
      // Clonar o objeto item para evitar a mutação direta
      const modifiedItem = { ...this.item, inUseSet: !this.item.inUseSet };

      // Chamar a função change com o item modificado
      this.item.change(modifiedItem);
      
    },
  }
}
