import { Place } from '../../domain/model/place';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeletePlaceRepository {
    (id: number): Promise<Place>
}

const deletePlaceRepository = (axios: AxiosInstance): DeletePlaceRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/place/${id}`)

        const place: Place = response.data
        return place
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deletePlaceRepository