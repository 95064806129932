import { RulerPagination } from './../../domain/model/ruler_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchRulerRepository {
    (pagination: Pagination): Promise<RulerPagination>
}

const fetchRulerRepository = (axios: AxiosInstance): FetchRulerRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/ruler', {
            params: pagination
        })

        const rulerPagination: RulerPagination = response.data
        return rulerPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchRulerRepository