import { MarkRulerPagination } from './../../../rulerMark/domain/model/markRuler_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchRulerTrackingByIDRepository {
    (id: number, pagination: Pagination): Promise<MarkRulerPagination>
}

const fetchRulerTrackingByIDRepository = (axios: AxiosInstance): FetchRulerTrackingByIDRepository => async (id: number, pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get(`/ruler/${id}/tracking`, {
            params: pagination
        })

        const rulerPagination: MarkRulerPagination = response.data
        return rulerPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchRulerTrackingByIDRepository