import { companyDefault } from './../domain/model/company';
import { FetchCityUseCase } from '@/module/city/domain/usecase/fetch_city_usecase';
import { FetchCompanyUseCase } from '../domain/usecase/fetch_company_usecase';
import { DeleteCompanyUseCase } from './../domain/usecase/delete_company_usecase';
import { CreateCompanyUseCase } from '../domain/usecase/create_company_usecase';
import { ChangeCompanyUseCase } from '../domain/usecase/change_company_usecase';
import { CompanyPagination } from '../domain/model/company_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Company } from '../domain/model/company';
import { snackbar } from '@/core/controller/snackbar_controller';
import { CityPagination } from '@/module/city/domain/model/city_pagination';
import { FetchCnpjUseCase } from '../domain/usecase/fetch_cnpj_usecase';
import { FetchCnpj } from '../domain/model/fetchCnpj';

class CompanyController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public mapa: boolean = false
  public center: object | null = {
    lat: -13.956416,
    lng: -52.332572
  }
  public zoom: number = 10
  public searchCity: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public cityPagination: CityPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsCity: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public company: Company = companyDefault()

  public dataCnpj: FetchCnpj = {
    abertura: "",
    atividade_principal: [],
    atividades_secundarias: [],
    bairro: "",
    billing: {},
    capital_social: "",
    cep: "",
    cnpj: "",
    complemento: "",
    data_situacao: "",
    data_situacao_especial: "",
    efr: "",
    email: "",
    extra: {},
    fantasia: "",
    logradouro: "",
    motivo_situacao: "",
    municipio: "",
    natureza_juridica: "",
    nome: "",
    numero: "",
    porte: "",
    qsa: [],
    situacao: "",
    situacao_especial: "",
    status: "",
    telefone: "",
    tipo: "",
    uf: "",
    ultima_atualizacao: "",
    location: {
      lat: 0,
      lng: 0
    }
  }

  private fetchCityUseCase: FetchCityUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase
  private deleteCompanyUseCase: DeleteCompanyUseCase
  private createCompanyUseCase: CreateCompanyUseCase
  private changeCompanyUseCase: ChangeCompanyUseCase
  private fetchCnpjUseCase: FetchCnpjUseCase

  constructor(context: any, fetchCityUseCase: FetchCityUseCase, fetchCompanyUseCase: FetchCompanyUseCase, deleteCompanyUseCase: DeleteCompanyUseCase, createCompanyUseCase: CreateCompanyUseCase, changeCompanyUseCase: ChangeCompanyUseCase, fetchCnpjUseCase: FetchCnpjUseCase) {
    this.context = context
    this.fetchCityUseCase = fetchCityUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
    this.deleteCompanyUseCase = deleteCompanyUseCase
    this.createCompanyUseCase = createCompanyUseCase
    this.changeCompanyUseCase = changeCompanyUseCase
    this.fetchCnpjUseCase = fetchCnpjUseCase
  }

  open() {
    this.company = companyDefault()
    this.zoom = 10
    this.center = {
      lat: -13.956416,
      lng: -52.332572
    }
    this.flagOption = "create"
    //this.company = { id: 0, active: true, address: "", cityID: 0, comporateName: "", cpfCnpj: "", name: "", phone: "", cityName: "" }
    this.paginateCity()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.companyPagination = await this.fetchCompanyUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  async paginateCity() {
    if (this.searchCity != null) {
      try {
        this.cityPagination = await this.fetchCityUseCase(this.optionsCity, this.searchCity)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchOptionsCity() {
    this.paginateCity()
  }

  watchSearchCity() {
    this.paginateCity()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createCompanyUseCase(this.company)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeCompanyUseCase(this.company.id, this.company)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Company) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteCompanyUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Company) {
    this.dialog = true
    this.flagOption = "change"
    this.searchCity = item.cityName.split(" - ")[0]
    this.paginateCity()
    this.company = { ...item }

    this.center = {
      lat: parseFloat(this.company.latitude),
      lng: parseFloat(this.company.longitude),
    }

    this.zoom = 20
  }

  getLocation(value) {
    this.company.latitude = value.lat().toFixed(7)
    this.company.longitude = value.lng().toFixed(7)

    this.center = value
  }

  async getCnpj(event) {
    try {
      const cnpj = event.replace(/[^0-9]/g, '')

      if (cnpj.length === 11) {
        return;
      }else{
        this.dataCnpj = await this.fetchCnpjUseCase(cnpj)
        //let data = await this.fetchCnpjUseCase(cnpj)
  
        if (this.dataCnpj.status != "ERROR") {
          this.searchCity = this.dataCnpj.municipio + " - " + this.dataCnpj.uf
          await this.paginateCity()
          this.company.name = this.dataCnpj.nome
          this.company.address = this.dataCnpj.logradouro
          this.company.comporateName = this.dataCnpj.fantasia
          this.company.cityID = this.cityPagination.items[0].id
          this.company.phone = this.dataCnpj.telefone
          this.company.latitude = this.dataCnpj.location.lat.toFixed(7)
          this.company.longitude = this.dataCnpj.location.lng.toFixed(7)
          this.center = this.dataCnpj.location
        } else {
          this.company = companyDefault()
          snackbar.show({ message: "CNPJ não encontrado!", color: "red" })
        }

      }
    } catch (error: any) {
      snackbar.show({ message: "CNPJ não encontrado. Por favor, adicione as informações ausentes.", color: "red" });
    }
  }

  expandMap() {
    this.mapa = true
  }
}

export default CompanyController