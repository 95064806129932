import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { ChangeHarvestUseCase } from './../domain/usecase/change_harvest_usecase';
import { CreateHarvestUseCase } from './../domain/usecase/create_harvest_usecase';
import { DeleteHarvestUseCase } from './../domain/usecase/delete_harvest_usecase';
import { FetchHarvestUseCase } from './../domain/usecase/fetch_harvest_usecase';
import { HarvestPagination } from './../domain/model/harvest_pagination';
import { Harvest, harvestDefault } from '../domain/model/harvest';
import { snackbar } from '@/core/controller/snackbar_controller';
import { headers } from '../const/headers';
import { DataOptions } from 'vuetify';

class HarvestController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public loading: boolean = false
  public center: object | null = null
  public harvestPagination: HarvestPagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public harvest: Harvest = harvestDefault()

  private fetchHarvestUseCase: FetchHarvestUseCase
  private deleteHarvestUseCase: DeleteHarvestUseCase
  private createHarvestUseCase: CreateHarvestUseCase
  private changeHarvestUseCase: ChangeHarvestUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(
    context: any,
    fetchHarvestUseCase: FetchHarvestUseCase,
    deleteHarvestUseCase: DeleteHarvestUseCase,
    createHarvestUseCase: CreateHarvestUseCase,
    changeHarvestUseCase: ChangeHarvestUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase,
  ) {
    this.context = context
    this.fetchHarvestUseCase = fetchHarvestUseCase
    this.deleteHarvestUseCase = deleteHarvestUseCase
    this.createHarvestUseCase = createHarvestUseCase
    this.changeHarvestUseCase = changeHarvestUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  async open() {
    this.harvest = harvestDefault()
    this.searchCompany = ""
    this.paginateCompany()
  }

  async paginate() {
    this.loading = true
    try {
      this.harvestPagination = await this.fetchHarvestUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.harvest.id != 0) {
          await this.changeHarvestUseCase(this.harvest.id, this.harvest)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })

        } else {
          await this.createHarvestUseCase(this.harvest)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Harvest) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteHarvestUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Harvest) {
    this.dialog = true
    this.harvest = { ...item }
    this.searchCompany = this.harvest.companyName
    this.paginateCompany()
  }
}

export default HarvestController