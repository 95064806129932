export const headersTracking = [
  {
    text: 'Data criação',
    value: 'createdDate'
  },
  {
    text: 'Amperagem',
    value: 'amperage'
  },
  {
    text: 'Voltagem',
    value: 'voltage'
  },
  {
    text: 'Capacitores',
    value: 'capacitors',
    custom: true
  },
  {
    text: 'Motores',
    value: 'engines',
    custom: true
  },
  {
    text: 'Nível Superior',
    value: 'higherLevel',
    sortable: false
  },
  {
    text: 'Nível Inferior',
    value: 'lowerLevel',
    sortable: false
  },
  {
    text: 'Horímetro',
    value: 'hourMeter',
    sortable: false
  },
  {
    text: 'Descrição',
    value: 'description'
  },
  {
    text: 'Ligado',
    value: 'isOn'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'end'
  }
]