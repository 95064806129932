import { PumpMotoPagination } from '../model/pump_moto_pagination';
import { FetchPumpMotoRepository } from '../../data/repository/fetch_pump_moto_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/pump_moto';

export interface FetchPumpMotoUseCase {
    (pagination: DataOptions, search: string, status: number): Promise<PumpMotoPagination>
}

const fetchPumpMotoUseCase = (repository: FetchPumpMotoRepository): FetchPumpMotoUseCase => async (pagination: DataOptions, search: string, status: number) => {
    try {
        const payload: Pagination = {
            sort: "2",
            descending: "false",
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
            status: status
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchPumpMotoUseCase;