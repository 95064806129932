import { BombMeasurement } from './../../domain/model/bomb_measurement';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchPlaceMeasurementRepository {
    (id: number, startDate: string, endDate: string): Promise<Array<BombMeasurement>>
}

const fetchPlaceMeasurementRepository = (axios: AxiosInstance): FetchPlaceMeasurementRepository => async (id: number, startDate: string, endDate: string) => {
    try {
        const response: AxiosResponse = await axios.get(`/place/${id}/measurement?startDate=${startDate}&endDate=${endDate}`)

        const bombMeasurement: Array<BombMeasurement> = response.data
        return bombMeasurement
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchPlaceMeasurementRepository