import { DeleteRulerGroupRepository } from '../../data/repository/delete_rulerGroup_repository';
import { RulerGroup } from '../model/rulerGroup';

export interface DeleteRulerGroupUseCase {
    (id: number): Promise<RulerGroup>
}

const deleteRulerGroupUseCase = (repository: DeleteRulerGroupRepository): DeleteRulerGroupUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteRulerGroupUseCase;