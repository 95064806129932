import { HarvestPagination } from '../../harvest/domain/model/harvest_pagination';
import { FetchMarkRulerUseCase } from '@/module/rulerMark/domain/usecase/fetch_markRuler_usecase';
import { RulerPagination } from '@/module/ruler/domain/model/ruler_pagination';
import { MarkRulerPagination } from '@/module/rulerMark/domain/model/markRuler_pagination';
import { DataOptions } from 'vuetify';
import { Ruler, rulerDefault } from '@/module/ruler/domain/model/ruler';
import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import { snackbar } from '@/core/controller/snackbar_controller';
import * as echarts from 'echarts';
import dayjs from "dayjs";
import { Harvest, harvestDefault } from '@/module/harvest/domain/model/harvest';
import { FetchHarvestUseCase } from '@/module/harvest/domain/usecase/fetch_harvest_usecase';
import { FetchRulerGroupUseCase } from '@/module/rulerGroup/domain/usecase/fetch_rulerGroup_usecase';
import { RulerGroupPagination } from '@/module/rulerGroup/domain/model/rulerGroup_pagination';
import { RulerGroup, rulerGroupDefault } from '@/module/rulerGroup/domain/model/rulerGroup';
import { PeriodSelect } from '@/module/newRulerMark/domain/model/periodSelect';
import { FetchMarkRulerWith30MinutesUseCase } from '@/module/rulerMark/domain/usecase/fetch_markRuler_with_30_minutes_usecase';
import waterLevel from '../../../assets/water_level.json'
import { FetchMarkRulerWith1HourUseCase } from '@/module/rulerMark/domain/usecase/fetch_markRuler_with_1_hour_usecase';

class RulerGroupMarkController {
  public context: any;
  public contextDrawer: any;
  public contextFormRuler: any;
  public status: number = 0
  public search: string = ""
  public loading: boolean = false
  public loadingBtn: boolean = false
  public dialog: boolean = false
  public rightDrawer: boolean = false
  public calendarMenuIn: boolean = false
  public calendarMenuUntil: boolean = false
  public calendarMenuDialogIn: boolean = false
  public calendarMenuDialogUntil: boolean = false
  public calendarIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendarUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public dateMark: any = []
  public levelMark: any = []
  public marks: any = []
  public marksChart: any = []
  public maxLevel: any = 15
  public showCharts: boolean = false

  public typeOfFilter: Array<PeriodSelect> = [
    { id: 0, name: "Dia" },
    { id: 1, name: "Semana" },
    { id: 2, name: "Mês" },
    { id: 3, name: "Safra" },
  ]
  public typeOfFilterSelected: PeriodSelect = { id: 1, name: "Semana" }
  public dayOfFilter: string = dayjs().format("YYYY-MM-DD")

  public startOfWeek: string = dayjs().startOf('week').format("YYYY-MM-DD")
  public endOfWeek: string = dayjs().endOf('week').format("YYYY-MM-DD")

  public startOfMonth: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public endOfMonth: string = dayjs().endOf('month').format("YYYY-MM-DD")

  public monthsOfYear: Array<Object> = [
    { name: 'Janeiro', value: 0 },
    { name: 'Fevereiro', value: 1 },
    { name: 'Março', value: 2 },
    { name: 'Abril', value: 3 },
    { name: 'Maio', value: 4 },
    { name: 'Junho', value: 5 },
    { name: 'Julho', value: 6 },
    { name: 'Agosto', value: 7 },
    { name: 'Setembro', value: 8 },
    { name: 'Outubro', value: 9 },
    { name: 'Novembro', value: 10 },
    { name: 'Dezembro', value: 11 },
  ]

  public selectedMonth: number = dayjs().month()

  public lottieLevelAnimation: object = { animationData: waterLevel }

  public harvestPagination: HarvestPagination = {
    items: [],
    total: 0
  }

  public harvestOptions: DataOptions = {
    page: 1,
    itemsPerPage: 12,
    groupBy: [],
    sortBy: ["startDate", "endDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true, true]
  }

  public selectedHarvest: Harvest = harvestDefault()

  public rulerPagination: RulerPagination = {
    items: [],
    total: 0
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 12,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public markRulerPagination: MarkRulerPagination = {
    total: 0,
    items: []
  }
  public markRulerOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }

  public rulerMarkGroupPagination: RulerGroupPagination = {
    total: 0,
    items: []
  }
  public rulerMarkGroup: DataOptions = {
    page: 1,
    itemsPerPage: 12,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public ruler: Ruler = rulerDefault()

  public selectedRuler: Ruler = rulerDefault()

  public selectedGroupRuler: RulerGroup = rulerGroupDefault()

  private rulerChart: echarts.ECharts | null = null

  private arrRulerChart: Array<echarts.ECharts> = []

  private fetchRulerGroupUseCase: FetchRulerGroupUseCase
  private fetchMarkRulerUseCase: FetchMarkRulerUseCase
  private fetchHarvestUseCase: FetchHarvestUseCase
  private fetchMarkRulerWith30MinutesUseCase: FetchMarkRulerWith30MinutesUseCase
  private fetchMarkRulerWith1HourUseCase: FetchMarkRulerWith1HourUseCase

  constructor(
    context: any,
    fetchRulerGroupUseCase: FetchRulerGroupUseCase,
    fetchMarkRulerUseCase: FetchMarkRulerUseCase,
    fetchHarvestUseCase: FetchHarvestUseCase,
    fetchMarkRulerWith30MinutesUseCase: FetchMarkRulerWith30MinutesUseCase,
    fetchMarkRulerWith1HourUseCase: FetchMarkRulerWith1HourUseCase
  ) {
    this.context = context
    this.fetchMarkRulerUseCase = fetchMarkRulerUseCase
    this.fetchRulerGroupUseCase = fetchRulerGroupUseCase
    this.fetchHarvestUseCase = fetchHarvestUseCase
    this.fetchMarkRulerWith30MinutesUseCase = fetchMarkRulerWith30MinutesUseCase
    this.fetchMarkRulerWith1HourUseCase = fetchMarkRulerWith1HourUseCase
  }


  async mounted() {
    this.loading = true
    this.maxLevel = localStorage.getItem('rulerMaxLevel') ? localStorage.getItem('rulerMaxLevel') : 15;
    try {
      this.rulerMarkGroupPagination = await this.fetchRulerGroupUseCase(this.options, this.search, this.status)
      //this.rulerPagination = await this.fetchRulerUseCase(this.options, this.search, 1) //actives
      this.harvestPagination = await this.fetchHarvestUseCase(this.harvestOptions, "")
      this.selectedHarvest = this.harvestPagination.items.filter((harvest) => harvest.isFavorite)[0] || this.harvestPagination.items?.[0]
      this.changeHarvest(this.selectedHarvest)
      /*       this.selectedRuler = this.rulerPagination.items[0]
            if (this.selectedRuler != null) {
              this.calendarUntil = dayjs().format("YYYY-MM-DD")
              this.filter()
            } */
      if (this.rulerMarkGroupPagination.items) {
        this.selectedGroupRuler = this.rulerMarkGroupPagination.items[0]
        this.changeDay()
      }
      /*  selectedGroupRuler */

    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
    window.addEventListener('resize', () => {
      if (this.arrRulerChart.length > 0) {
        for (let el of this.arrRulerChart) {
          el.resize()
        }
      }
    })
  }

  async filter() {
    this.showCharts = false
    this.clearCharts()
    this.marks = []
    this.marksChart = []
    this.dialog = false
    this.markRulerOptions.page = 1
    if (this.context.$refs.filter.validate()) {
      this.loadingBtn = true
      try {
        this.marks = await Promise.all(
          this.selectedGroupRuler.items.map(async (ruler) => {
            const retorno = await this.fetchMarkRulerUseCase(this.markRulerOptions, ruler.id, this.calendarIn, this.calendarUntil)
            return { marcacoes: retorno.items, ...ruler }
          })
        )

        this.marks.forEach((el, i) => {
          const array = JSON.parse(JSON.stringify(el.marcacoes))
          setTimeout(() => {
            this.createRulerChart(el, i, array.reverse())
          }, 300)
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false
        this.showCharts = true
      }
    }
  }

  clearCharts() {
    for (let chart of this.arrRulerChart) {
      chart.clear()
    }
  }

  /*      async change() {
        if (this.contextDrawer.$refs.rightDrawer.validate()) {
          localStorage.setItem('rulerMaxLevel', this.maxLevel)
          try {
            await this.changeRulerUseCase(this.selectedRuler.id, this.selectedRuler)
            snackbar.show({ message: "Alterado com sucesso!", color: 'green', timeout: 1000 })
          } catch (error: any) {
            snackbar.show({ message: error.toString() })
          } finally {
            this.rightDrawer = false
            this.filter()
          }
        }
      }  */



  createRulerChart(ruler, index, marcacoes) {
    if (ruler) {
      const higher = ruler.higherLevel != null ? ruler.higherLevel : 0
      const lower = ruler.lowerLevel != null ? ruler.lowerLevel : 0

      var chartDom = document.getElementById(`ruler_chart${index}`);
      if (chartDom != null) {
        this.arrRulerChart[index] = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');

        const marcacao = marcacoes.map((mark: MarkRuler, i: number) => [
          {
            symbol: 'none',
            name: '', xAxis: i, yAxis: 0, lineStyle: {
              normal: {
                type: 'solid',
                color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
              },

            },
          },
          {
            symbol: 'none',
            name: '', xAxis: i, yAxis: mark.level, lineStyle: {
              normal: {
                type: 'solid',
                color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
              },

            },
          },
        ])

        if (!this.context.$vuetify.breakpoint.mobile) {
          marcacao.push({
            type: 'average',
            name: 'Média',
            label: {
              formatter: '{b}: {c}',
              position: 'end',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
              fontWeight: 'bold',
              fontSize: 12,
              fontFamily: 'Helvetica',
            },

          })
        }


        marcacao.push({
          label: {
            formatter: '{b}',
            position: 'insideEndTop',
            color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Helvetica',
          },
          name: `Nível máximo: ${higher}`,
          yAxis: higher,
          type: 'value',
          lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            }
          },
        })
        marcacao.push({
          label: {
            formatter: '{b}',
            position: 'insideEndTop',
            color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Helvetica',
          },
          name: `Nível mínimo: ${lower}`,
          yAxis: lower,
          type: 'value',
          lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            }
          },
        })
        let option = {
          title: {
            text: ruler.name,
            left: "center",
            top: "top",

            textStyle: {
              fontSize: 16,
              fontWeight: 'normal',
            },
            padding: [0, 0, 20, 0]
          },
          grid: {
            left: 40,
            top: 20,
            right: !this.context.$vuetify.breakpoint.mobile ? 80 : 40,
            bottom: 25,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: (params: any) => {
              return (
                "Nivel: " + params[0].value +
                '<br/>' +

                "Data: " + params[0].name
              );
            },
          },
          xAxis: {
            type: 'category',
            data: marcacoes.map((mark: MarkRuler) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
            boundaryGap: true,
            axisLabel: {
              interval: marcacoes.length - 2
            }
          },
          yAxis: {
            type: 'value',
          },
          series: [{
            areaStyle: {},
            data: marcacoes.map((mark: MarkRuler) => mark.level.toFixed(2)) ?? [],
            type: 'line',
            itemStyle: {
              color: ruler.color ?? '#8094f7'
            },
            markLine: {
              data:
                marcacao,
            },
          }]
        };
        option && this.arrRulerChart[index].setOption(option);
      }
    }
  }


  /*   async paginateMarks() {
      try {
        this.markRulerPagination = await this.fetchMarkRulerUseCase(this.markRulerOptions, this.selectedRuler.id, this.calendarIn, this.calendarUntil)
        for (let el of this.markRulerPagination.items) {
          this.marks.push(el)
        }
      } catch (error) {
        console.log(error);
      }
    } */

  closeDialog() {
    this.dialog = false
    this.rightDrawer = true
  }

  async changeHarvest(data: Harvest) {
    this.calendarIn = data.startDate
    this.calendarUntil = data.endDate
  }

  lastDay() {
    this.markRulerOptions.itemsPerPage = -1
    this.dayOfFilter = dayjs(this.dayOfFilter).subtract(1, 'day').format("YYYY-MM-DD")
    //const tomorrow = dayjs(this.dayOfFilter).add(1, 'day').format("YYYY-MM-DD")
    this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
  }
  nextDay() {
    this.markRulerOptions.itemsPerPage = -1
    const today = dayjs().format("YYYY-MM-DD")
    //const tomorrow = dayjs(this.dayOfFilter).add(1, 'day').format("YYYY-MM-DD")
    if (today != this.dayOfFilter) {
      this.dayOfFilter = dayjs(this.dayOfFilter).add(1, 'day').format("YYYY-MM-DD")
      this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
    }
  }
  lastWeek() {
    this.markRulerOptions.itemsPerPage = -1
    this.startOfWeek = dayjs(this.startOfWeek).subtract(1, 'week').format("YYYY-MM-DD")
    this.endOfWeek = dayjs(this.endOfWeek).subtract(1, 'week').format("YYYY-MM-DD")
    this.filterByFilter(this.startOfWeek, this.endOfWeek)
  }
  nextWeek() {
    this.markRulerOptions.itemsPerPage = -1
    const thisStartWeek = dayjs().startOf('week').format("YYYY-MM-DD")
    if (thisStartWeek != this.startOfWeek) {
      this.startOfWeek = dayjs(this.startOfWeek).add(1, 'week').format("YYYY-MM-DD")
      this.endOfWeek = dayjs(this.endOfWeek).add(1, 'week').format("YYYY-MM-DD")
      this.filterByFilter(this.startOfWeek, this.endOfWeek)
    }
  }
  lastMonth() {
    this.markRulerOptions.itemsPerPage = -1
    this.startOfMonth = dayjs(this.startOfMonth).subtract(1, 'month').format("YYYY-MM-DD")
    this.endOfMonth = dayjs(this.startOfMonth).endOf('month').format("YYYY-MM-DD")
    this.filterByFilter(this.startOfMonth, this.endOfMonth)
  }
  nextMonth() {
    this.markRulerOptions.itemsPerPage = -1
    const thisStartMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    if (thisStartMonth != this.startOfMonth) {
      this.startOfMonth = dayjs(this.startOfMonth).add(1, 'month').format("YYYY-MM-DD")
      this.endOfMonth = dayjs(this.startOfMonth).endOf('month').format("YYYY-MM-DD")
      this.filterByFilter(this.startOfMonth, this.endOfMonth)
    }
  }


  async filterByFilter(start, end) {
    this.showCharts = false
    this.rulerChart?.clear()
    this.marks = []
    this.marksChart = []
    this.dialog = false
    this.markRulerOptions.page = 1
    this.loadingBtn = true
    try {
      this.marks = await Promise.all(
        this.selectedGroupRuler.items.map(async (ruler) => {
          const retorno = await this.fetchMarkRulerWith1HourUseCase(this.markRulerOptions, ruler.id, start, end)
          return { marcacoes: retorno.items, ...ruler }
        })
      )
      this.marks.forEach((el, i) => {
        const array = JSON.parse(JSON.stringify(el.marcacoes.reverse()))
        setTimeout(() => {
          this.createRulerChart(el, i, array.reverse())
        }, 300)
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.loadingBtn = false
      this.showCharts = true
    }

  }

  changeGroupRuler(event) {
    this.selectedGroupRuler = event
    this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
  }

  changeRuler(event) {
    if (event.id != undefined) {
      switch (this.typeOfFilterSelected.id) {
        case 0:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
          break;
        case 1:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfWeek, this.endOfWeek)
          break;
        case 2:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfMonth, this.endOfMonth)
          break;
        case 3:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.calendarIn, this.calendarUntil)
          break;
        default:
          break;
      }
    }
  }

  changePeriod(event) {
    if (event.id != undefined) {
      switch (event.id) {
        case 0:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
          break;
        case 1:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfWeek, this.endOfWeek)
          break;
        case 2:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfMonth, this.endOfMonth)
          break;
        case 3:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.calendarIn, this.calendarUntil)
          break;
        default:
          break;
      }
    }
  }

  changeMonth(event) {
    this.startOfWeek = dayjs().month(event.value).startOf('month').startOf('week').format("YYYY-MM-DD")
    this.endOfWeek = dayjs().month(event.value).startOf('month').endOf('week').format("YYYY-MM-DD")
    this.markRulerOptions.itemsPerPage = -1
    this.filterByFilter(this.startOfWeek, this.endOfWeek)
  }

  changeDay() {
    this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
  }


}

export default RulerGroupMarkController