export const headers = [
  {
    text: 'ID da estação',
    align: 'start',
    sortable: true,
    value: 'id',
  },
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Barômetro',
    align: 'start',
    sortable: true,
    value: 'barometer',
  },
  {
    text: 'Umidade',
    align: 'start',
    sortable: true,
    value: 'humidity',
  },
  {
    text: 'Pluviômetro',
    align: 'start',
    sortable: true,
    value: 'pluviometer',
  },
  {
    text: 'Temperatura',
    align: 'start',
    sortable: true,
    value: 'temperature',
  },
  {
    text: 'Direção do vento',
    align: 'start',
    sortable: true,
    value: 'wind_direction',
  },
  {
    text: 'Velocidade do vento',
    align: 'start',
    sortable: true,
    value: 'wind_velocity',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }
]

