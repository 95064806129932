export interface CallCenterAccount {
  id: number
  companyID: number
  accountID: number
  callCenterID: number
}

export interface CallCenterAlertType {
  id: number
  companyID: number
  alertTypeID: number
  callCenterID: number
}

export interface CallCenter {
  id: number
  name: string
  companyID: number
  companyName: string
  accounts: number[]
  alertTypes: number[]
}

export const callCenterDefault = (): CallCenter => {
  return {
    id: 0,
    name: "",
    companyID: 0,
    companyName: "",
    accounts: [],
    alertTypes: []
  }
}


const en: any = {
  id: 1,
  name: 2,
  companyID: 3,
  companyName: 4
}
export { en };


