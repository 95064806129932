export interface Place {
  color: string
  companyID: number
  companyName: string
  id: number
  imagePath: string
  latitude: string
  longitude: string
  name: string
}


const en: any = {
  id: 1,
  color: 8,
  companyID: 7,
  companyName: 9,
  imagePath: 5,
  latitude: 3,
  longitude: 4,
  name: 2,
}
export { en };


