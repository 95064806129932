export interface Notification {
  createDate: string,
  fromAccountID: number,
  fromPumpID: number,
  fromRulerID: number,
  id: number,
  message: string,
  seen: boolean,
  seenDate: string,
  toAccountID: number,
  toCompanyID: number
}

const en: any = {
  createDate: 8,
  fromAccountID: 3,
  fromPumpID: 4,
  fromRulerID: 5,
  id: 1,
  message: 6,
  seen: 7,
  seenDate: 8,
  toAccountID: 9,
  toCompanyID: 10
}
export { en };