export const headers = [
  {
    text: 'Número do equipamento',
    align: 'start',
    sortable: true,
    value: 'terminalNumber',
  },
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Coeficiente',
    align: 'start',
    sortable: true,
    value: 'coefficient',
  },
  {
    text: 'Nível max.',
    align: 'start',
    sortable: true,
    value: 'higherLevel',
  },
  {
    text: 'Nível min.',
    align: 'start',
    sortable: true,
    value: 'lowerLevel',
  },
  {
    text: 'Cor',
    align: 'start',
    sortable: true,
    value: 'color',
    custom: true
  },
  {
    text: 'Empresa',
    align: 'start',
    sortable: true,
    value: 'companyName',
  },
  {
    text: 'Status',
    align: 'start',
    sortable: true,
    value: 'status',
  },
  {
    text: 'Favorito',
    align: 'center',
    sortable: true,
    value: 'isFavorite',
    custom: true
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }
]

