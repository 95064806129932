<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.pumpMotoPagination.total"
      :items="controller.pumpMotoPagination.items"
      :loading="controller.loading"
      :group-by="controller.groupBy"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >

      <template v-slot:[`item.placeID`]="{ item }">
        {{ computedPlaceName(item.placeID) }}
      </template>

      <template v-slot:[`item.CompanyID`]="{ item }">
        {{ computedCompanyName(item.CompanyID) }}
      </template>

    
      <template v-slot:[`item.remoteControl`]="{ item }">
        <v-icon v-if="item.remoteControl" color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:[`item.energyMonitoring`]="{ item }">
        <v-icon v-if="item.energyMonitoring" color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:[`item.inUseSet`]="{ item }">
        <v-icon v-if="item.inUseSet" color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:[`item.Status`]="{ item }">
        {{ item.Status === 1 ? "Ativo" : "Inativo" }}
      </template>

      <!-- <template v-slot:[`item.isFavorite`]="{ item }">
        <v-btn
          icon
          @click="controller.changeFav(item)"
        >
          <v-icon :color="item.isFavorite ? '#FFC107' : 'grey'">mdi-star</v-icon>
        </v-btn>
      </template> -->

      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(3, 3)"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hadPermission(3, 4)"
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Conjuntos</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-select
            @change="controller.paginate()"
            class="px-2"
            style="max-width: 200px"
            filled
            dense
            single-line
            color="primary"
            hide-details
            v-model="controller.status"
            :items="controller.visibilityStatus"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field> -->
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
                v-if="hadPermission(3, 2)"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template> -->
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Bombas
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                
                <v-form
                  ref="crud"
                  lazy-validation
                >
                <v-select
                    filled
                    dense
                    color="primary"
                    label="Status"
                    v-model="controller.pumpMoto.Status"
                    :items="controller.itemStatus"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-select>

                <v-combobox
                  v-model="controller.pumpMoto.EngineID"
                  :items="availableEngines"
                  dense
                  filled
                  label="Identificação do Motor"
                  item-text="name"
                  item-value="id"
                  return-object
                />

                <v-combobox
                  v-model="controller.pumpMoto.pumpID"
                  :items="availablePumps"
                  dense
                  filled
                  label="Identificação da Bomba"
                  item-text="name"
                  item-value="id"
                  return-object
                />
                  
                  <!-- <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Identificação do Motor"
                    v-model="controller.pumpMoto.EngineName"
                  >
                  </v-text-field> -->


                  
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-menu
            v-model="controller.menuGroup"
            :close-on-click="false"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-x
          >
            <!--             <template v-slot:activator="{ on, attrs }">
              <v-icon class="mt-0 ml-4" text v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-card scrollable>
              <v-toolbar flat dense>
                <v-toolbar-title>Organizar tabela</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="px-0">
                <v-autocomplete
                  dense
                  class="mt-8 px-3"
                  :items="controller.columns"
                  item-text="text"
                  item-value="value"
                  label="Agrupar por"
                  v-model="controller.groupBy"
                  clearable
                  no-data-text="Sem dados"
                ></v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  small
                  color="red"
                  outlined
                  @click="controller.menuGroup = false"
                  block
                >
                  <v-icon small left>mdi-close</v-icon>Fechar
                </v-btn>
              </v-card-actions>
            </v-card> -->
          </v-menu>
        </v-toolbar>
      </template>
    </v-data-table>

  </div>
</template>

<script>
const defaultParams = {
  sort: 2,
  descending: false,
  page: 1,
  itemsPerPage: 40,
  search: '',
  status: 0
}
const AllParams = {
  sort: 2,
  descending: false,
  page: 1,
  itemsPerPage: 1000,
  search: '',
  status: 0
}
import { axosInstance } from "@/di/axios_instance_di";
import { pumpMotoController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: pumpMotoController(context),
    engines: [],
    places: [],
    companies: [],
    pumps: [],
    selectedEngine: null,
  }),
  computed: {
    availableEngines() {
      return this.engines.filter(engine => !engine.in_use).map(engine => engine.id);
    },
    availablePumps() {
      return this.pumps.filter(pump => !pump.in_use).map(pump => pump.id);
    },
    computedPlace() {
      
      return (id) => this.places.find(place => place.id === id)
    },
    computedCompany() {
      return (id) => this.companies.find(company => company.id === id)
    },
    computedPlaceName() {
      return (id) => {
        const place = this.places.find(place => place.id === id);
        return place ? place.name : '';
      };
    },
    computedCompanyName() {
      return (id) => {
        const company = this.companies.find(company => company.id === id);
        return company ? company.name : '';
      };
    },
    computedPumpName() {
      return (id) => {
        const pump = this.pumps.find(pump => pump.id === id);
        return pump ? pump.name : '';
      };
    },
    computedEngineName() {
      return (id) => {
        const engine = this.engines.find(engine => engine.id === id);
        return engine ? engine.name : '';
      };
    },

  },
  methods: {
    async fetchEngines(params) {
      try {
        const { data } = await axosInstance.get('/engine', {
          params
        })
        
        this.engines = data.items
        
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPlaces(params) {
      try {
        const { data } = await axosInstance.get('/place', {
          params
        })
        this.places = data.items
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCompanies(params) {
      try {
        const { data } = await axosInstance.get('/company', {
          params
        })
        this.companies = data.items
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPumps(params) {
      try {
        const { data } = await axosInstance.get('/pump', {
          params
        })
        this.pumps = data.items
      } catch (error) {
        console.log(error)
      }
    },

  },
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsPlace"() {
      this.controller.watchOptionsPlace();
    },
    "controller.searchPlace": _.debounce(function () {
      this.controller.watchSearchPlace();
    }, 500),
    "controller.searchTracking": _.debounce(function () {
      this.controller.watchSearchTracking();
    }, 500),
    "controller.optionsTracking"() {
      this.controller.watchOptionsTracking();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    'selectedEngine.id': function (newVal) {
      // Atualize a propriedade do controlador quando o id selecionado mudar
      this.controller.pumpMoto.EngineID = newVal;
    },
    'controller.pumpMoto.EngineID': function (newVal) {
      // Atualize a propriedade selecionada quando o id no controlador mudar
      this.selectedEngine = this.availableEngines.find(engine => engine.id === newVal) || null;
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchEngines(AllParams),
      this.fetchPlaces(AllParams),
      this.fetchCompanies(AllParams),
      this.fetchPumps(AllParams),
    ])



  }
};
</script>