type Header = {
  text: string
  align: 'start'|'center'|'end'
  sortable: boolean
  value: string
}
export const headers: Header[] = [
  {
    text: 'ID',
    align: 'start',
    sortable: true,
    value: 'id',
  },
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Levante',
    align: 'start',
    sortable: true,
    value: 'place_id',
  },
  {
    text: 'Companhia',
    align: 'start',
    sortable: true,
    value: 'company_id',
  },
  {
    text: 'Marca',
    align: 'start',
    sortable: true,
    value: 'brand',
  },
  {
    text: 'Modelo',
    align: 'start',
    sortable: true,
    value: 'model',
  },
  {
    text: 'Potência',
    align: 'start',
    sortable: true,
    value: 'power',
  },
  {
    text: 'Tensão Nominal',
    align: 'start',
    sortable: true,
    value: 'nominal_voltage',
  },
  {
    text: 'Ações',
    align: 'start',
    sortable: true,
    value: 'actions',
  },
  
]