import { Harvest } from '../../domain/model/harvest';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteHarvestRepository {
    (id: number): Promise<Harvest>
}

const deleteHarvestRepository = (axios: AxiosInstance): DeleteHarvestRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/harvest/${id}`)

        const harvest: Harvest = response.data
        return harvest
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteHarvestRepository