import { CreatePumpMotoRepository } from '../../data/repository/create_pump_moto_repository';
import { PumpMoto } from '../model/pump_moto';

export interface CreatePumpMotoUseCase {
    (data: object): Promise<PumpMoto>
}

const createPumpMotoUseCase = (repository: CreatePumpMotoRepository): CreatePumpMotoUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createPumpMotoUseCase;

