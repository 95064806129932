import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Engine } from '../../domain/model/engine';

export interface ChangeEngineRepository {
  (id: number, data: object): Promise<Engine>
}

const changeEngineRepository = (axios: AxiosInstance): ChangeEngineRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/engine/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeEngineRepository

