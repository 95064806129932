import { MarkPumpPagination } from '../model/markPump_pagination';
import { FetchMarkPumpRepository } from '../../data/repository/fetch_markPump_repository';
import { PaginationDate } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/markPump';

export interface FetchMarkPumpUseCase {
    (pagination: DataOptions, id:number, startDate: string, endDate: string): Promise<MarkPumpPagination>
}

const fetchMarkPumpUseCase = (repository: FetchMarkPumpRepository): FetchMarkPumpUseCase => async (pagination: DataOptions, id:number, startDate: string, endDate: string) => {
    try {        
        const payload: PaginationDate = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: "",
            startDate: startDate,
            endDate: endDate           
        }
        
        return await repository(payload, id);
    } catch (error) {
        throw error
    }
}

export default fetchMarkPumpUseCase;