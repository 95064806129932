import { ChangeCompanyUseCase } from '../domain/usecase/change_hardware_version_usecase';
import { HardwareVersionPagination } from '../domain/model/hardware_version_pagination';
import { FetchCityUseCase } from '@/module/city/domain/usecase/fetch_city_usecase';
import { HardwareVersion, hardwareVersionDefault } from '../domain/model/hardware_version';
import { snackbar } from '@/core/controller/snackbar_controller';
import { headers } from '../const/headers';
import { DataOptions } from 'vuetify';
import { FetchHardwareVersionUseCase } from '../domain/usecase/fetch_hardware_version_usecase';
import { DeleteHardwareVersionUseCase } from '../domain/usecase/delete_hardware_version_usecase';
import { CreateHardwareVersionUseCase } from '../domain/usecase/create_hardware_version_usecase';

class HardwareVersionController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public file: File | null = null

  public zoom: number = 10
  public searchCity: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public hardwareVersionPagination: HardwareVersionPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public hardwareVersion: HardwareVersion = hardwareVersionDefault()

  private fetchHardwareVersionUseCase: FetchHardwareVersionUseCase
  private deleteHardwareVersionUseCase: DeleteHardwareVersionUseCase
  private createHardwareVersionUseCase: CreateHardwareVersionUseCase

  constructor(
    context: any,
    fetchHardwareVersionUseCase: FetchHardwareVersionUseCase,
    deleteHardwareVersionUseCase: DeleteHardwareVersionUseCase,
    createHardwareVersionUseCase: CreateHardwareVersionUseCase,
  ) {
    this.context = context
    this.fetchHardwareVersionUseCase = fetchHardwareVersionUseCase
    this.deleteHardwareVersionUseCase = deleteHardwareVersionUseCase
    this.createHardwareVersionUseCase = createHardwareVersionUseCase
  }

  open() {
    this.hardwareVersion = hardwareVersionDefault()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.hardwareVersionPagination = await this.fetchHardwareVersionUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        await this.createHardwareVersionUseCase(this.hardwareVersion, this.file!)
        this.paginate()

        snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
        this.dialog = false
        this.context.$refs.crud.resetValidation()
        this.file = null
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: HardwareVersion) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteHardwareVersionUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }
}

export default HardwareVersionController