import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Ruler } from '../../domain/model/ruler';

export interface CreateRulerRepository {
  (data: object): Promise<Ruler>
}

const createRulerRepository = (axios: AxiosInstance): CreateRulerRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/ruler`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createRulerRepository

