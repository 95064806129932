<template>
  <div class="mb-2 root background_screen">
    <v-form
      ref="filter"
      class="mx-2"
    >
      <v-toolbar
        flat
        class="my-3 d-none d-md-block background_screen"
      >
        <v-toolbar-title> Marcações das Bombas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center justify-space-around mx-2">
          <v-autocomplete
            v-model="controller.selectedHarvest"
            :items="controller.harvestPagination.items"
            item-text="name"
            item-value="id"
            dense
            filled
            class="pt-6"
            label="Selecione a safra"
            style="max-width: 200px"
            return-object
            @change="controller.changeHarvest($event)"
            single-line
          ></v-autocomplete>
          <v-autocomplete
            v-model="controller.selectedPlace"
            :items="controller.placePagination.items"
            item-text="name"
            item-value="id"
            dense
            filled
            class="pt-6 pl-2"
            label="Selecione o Levante"
            style="max-width: 150px"
            return-object
            @change="controller.changePlace($event)"
            single-line
          ></v-autocomplete>
          <v-autocomplete
            v-model="controller.selectedPump"
            :items="controller.placePumpPagination.items"
            item-text="name"
            item-value="id"
            dense
            filled
            class="pt-6 pl-2"
            label="Selecione a bomba"
            style="max-width: 150px"
            return-object
            @change="controller.changePump($event)"
            single-line
          ></v-autocomplete>
          <!-- <v-menu
            v-model="controller.calendarMenuIn"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formatDateIn"
                label="De"
                persistent-hint
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                class="pt-6 pl-2"
                style="max-width: 150px"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                single-line
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="controller.calendarIn"
              class="primary"
              no-title
              @input="controller.calendarMenuIn = false"
              :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
              min="2010-NaN-NaN"
            >
            </v-date-picker>
          </v-menu>
          <v-menu
            v-model="controller.calendarMenuUntil"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formatDateUntil"
                label="Até"
                persistent-hint
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                class="pt-6 pl-2"
                style="max-width: 150px"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                single-line
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="controller.calendarUntil"
              class="primary"
              no-title
              @input="controller.calendarMenuUntil = false"
              :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
              min="2010-NaN-NaN"
            ></v-date-picker>
          </v-menu> -->

          <date-picker
            style="max-width: 150px"
            v-model="controller.calendarIn"
            filled
            dense
            label="Data inicial"
            class="ml-2 mt-6"
            single-line
          ></date-picker>
          <date-picker
            v-model="controller.calendarUntil"
            filled
            dense
            label="Data final"
            style="max-width: 150px"
            class="mx-2 mt-6"
            single-line
          ></date-picker>

          <div class="ml-2" v-if="isAdmin()">
            <v-btn
              height="38"
              :loading="controller.loadingBtnExcel"
              color="green"
              class="mt-0 white--text"
              @click="controller.exportExcel()"
            >
              <v-icon left> mdi-file-excel </v-icon>
              Excel
            </v-btn>
          </div>

          <div class="ml-2">
            <v-btn
              height="38"
              :loading="controller.loadingBtn"
              color="primary"
              class="mt-0"
              @click="controller.filter()"
            >
              <v-icon left> mdi-filter </v-icon>
              Filtrar
            </v-btn>
          </div>
          <!--           <div class="ml-2">
            <v-btn icon @click="controller.rightDrawer = true">
              <v-icon x-large color="primary"> mdi-cog </v-icon>
            </v-btn>
          </div> -->
        </v-toolbar-items>
      </v-toolbar>

      <v-toolbar
        flat
        class="my-3 mx-2 d-md-none"
      >
        <v-toolbar-title> Marcações das Bombas </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="controller.dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar flat>
              <v-toolbar-title> Marcações das Bombas </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="controller.dialog = false"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="d-flex flex-column">
              <v-autocomplete
                v-model="controller.selectedHarvest"
                :items="controller.harvestPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a safra"
                return-object
                @change="controller.changeHarvest($event)"
                single-line
              ></v-autocomplete>
              <v-autocomplete
                v-model="controller.selectedPlace"
                :items="controller.placePagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione o Levante"
                return-object
                @change="controller.changePlace($event)"
              ></v-autocomplete>
              <v-autocomplete
                v-model="controller.selectedPump"
                :items="controller.placePumpPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a bomba"
                return-object
                @change="controller.changePump($event)"
              ></v-autocomplete>
              <!-- <v-menu
                v-model="controller.calendarMenuDialogIn"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formatDateIn"
                    label="De"
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    class="pt-6"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="controller.calendarIn"
                  class="primary"
                  no-title
                  @input="controller.calendarMenuDialogIn = false"
                  :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
                  min="2010-NaN-NaN"
                >
                </v-date-picker>
              </v-menu>
              <v-menu
                v-model="controller.calendarMenuDialogUntil"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formatDateUntil"
                    label="Até"
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    class="pt-6"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="controller.calendarUntil"
                  class="primary"
                  no-title
                  @input="controller.calendarMenuDialogUntil = false"
                  :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
                  min="2010-NaN-NaN"
                ></v-date-picker>
              </v-menu> -->

              <date-picker
                v-model="controller.calendarIn"
                filled
                dense
                label="Data inicial"
              ></date-picker>
              <date-picker
                v-model="controller.calendarUntil"
                filled
                dense
                label="Data final"
              ></date-picker>

              <div class="d-flex align-center">
                <div>
                  <v-btn
                    height="50"
                    :loading="controller.loadingBtn"
                    color="primary"
                    class="mt-0"
                    @click="controller.filter()"
                  >
                    <v-icon> mdi-filter </v-icon>
                    Filtrar
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    @click="controller.closeDialog()"
                    color="primary"
                    height="50"
                  >
                    <v-icon color="white"> mdi-cog </v-icon>Configurações
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-form>
    <div class="mr-4">
      <v-carousel
        class="mx-2"
        :continuous="false"
        :cycle="false"
        hide-delimiters
        hide-delimiter-background
        @change="controller.changeChart($event)"
      >
        <v-carousel-item>
          <div class="d-flex">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <div class="text-center pt-2">
                    Nível superior e inferior x Tempo
                  </div>
                  <v-card-text>
                    <div class="mx-2">
                      <div
                        id="mark_place_chart"
                        style="height: 350px; width: 100%"
                      ></div>
                    </div>
                  </v-card-text>
                  <v-row class="pl-4">
                    <v-col cols="12 pa-2">
                      <div class="pt-2 d-flex">
                        <h2 class="font-weight-medium pl-2">
                          {{ controller.nameSelectedPlace }}
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="d-flex">
            <v-row>
              <v-col cols="6">
                <v-card>
                  <div class="text-center pt-2">
                    Potência Média x Tempo (kW)
                  </div>
                  <v-card-text>
                    <div class="mx-2">
                      <div
                        id="mark_power_chart"
                        style="height: 350px; width: 100%"
                      ></div>
                    </div>
                  </v-card-text>
                  <v-row class="pl-4">
                    <v-col cols="12 pa-2">
                      <div class="pt-2 d-flex">
                        <h2 class="font-weight-medium pl-2">
                          {{ controller.nameSelectedPlace }}
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card>
                  <div class="text-center pt-2">Medições x Colaborador</div>
                  <v-card-text>
                    <div class="mx-2">
                      <div
                        id="mark_pie_chart"
                        style="height: 350px; width: 100%"
                      ></div>
                    </div>
                  </v-card-text>
                  <v-row class="pl-4">
                    <v-col cols="12 pa-2">
                      <div class="pt-2 d-flex">
                        <h2 class="font-weight-medium pl-2">
                          {{ controller.nameSelectedPlace }}
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-row class="mb-0 px-2">
      <v-col
        cols="12"
        lg="3"
        v-for="(mark, i) in controller.marks"
        :key="i"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="ma-0"
            @click.passive="controller.info(mark)"
          >
            <v-card-text class="ma-2">
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6"> </v-col>
                <v-col
                  cols="3"
                  class="d-flex justify-end align-start"
                >
                  <v-tooltip
                    bottom
                    style="position: relative; right: 100px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <h3
                        v-bind="attrs"
                        v-on="on"
                        class="pr-2"
                      >
                        {{ mark.accountName }}
                      </h3>
                    </template>
                    <span>Medição feita pelo colaborador</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="pt-2 d-flex">
                    <h2 class="font-weight-medium">Bomba:</h2>
                    <h2 class="font-weight-medium pl-2">
                      {{ controller.nameSelectedPump }}
                    </h2>
                  </div>
                  <div class="pt-2 d-flex">
                    <h2 class="font-weight-medium">Horimetro:</h2>
                    <h2 class="font-weight-medium pl-2">
                      {{ mark.hourMeter }}
                    </h2>
                  </div>
                  <div class="pt-2 d-flex">
                    <h4 class="font-weight-medium">Atualizado em:</h4>
                    <h4 class="font-weight-medium pl-2">
                      {{
                        dayjs(mark.createdDate).format("DD/MM/YYYY HH:mm:ss")
                      }}
                    </h4>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions
              style="height: 40px"
              :style="{ backgroundColor: mark.color }"
              class="d-flex justify-center"
            >
              <span
                :class="{ 'show-visualizar': hover }"
                color="#00FFFFFF"
                class="font-weight-medium"
                style="opacity: 0"
              >Visualizar</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <right-nav-drawer :controller="controller" />
    <v-dialog
      v-model="controller.dialogInfo"
      width="600px"
    >
      <v-card class="background_screen background_info_card">
        <v-card-title class="d-flex">
          <v-img
            :src="$vuetify.theme.isDark ? '/NVL.png' : '/NVL2.png'"
            max-height="60"
            max-width="90"
          ></v-img>
          <v-spacer></v-spacer>
          <div class="pt-2 d-flex">
            <h4 class="font-weight-medium">Bomba:</h4>
            <h4 class="font-weight-medium pl-2">
              {{ controller.nameSelectedPump }}
            </h4>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card class="pt-2 pb-4">
                <v-row>
                  <v-col cols="8">
                    <div class="pl-4">
                      <div class="pt-2 d-flex">
                        <h2 class="font-weight-medium">Horimetro:</h2>
                        <h2 class="font-weight-medium pl-2">
                          {{ controller.markInfoPump.hourMeter }}
                        </h2>
                      </div>
                      <div class="pt-2 d-flex">
                        <h4 class="font-weight-medium">Atualizado em:</h4>
                        <h4 class="font-weight-medium pl-2">
                          {{
                            dayjs(controller.markInfoPump.createdDate).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          }}
                        </h4>
                      </div>
                      <div class="pt-2 d-flex">
                        <h4 class="font-weight-medium">Colaborador:</h4>
                        <h4 class="font-weight-medium pl-2">
                          {{ controller.markInfoPump.accountName }}
                        </h4>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    class="d-flex justify-center align-center"
                  >
                    <v-avatar
                      size="60"
                      :color="controller.markInfoPump.color"
                    >
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
              <!-- <v-row class="pt-4">
                <v-col>
                  <v-card min-height="80">
                    <div class="pt-2">
                      <h4 class="font-weight-medium text-center">
                        Nível Superior:
                      </h4>
                      <h1 class="font-weight-medium text-center pt-2">
                        {{ controller.markInfoPump.higherLevel }}
                      </h1>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card min-height="80">
                    <div class="pt-2">
                      <h4 class="font-weight-medium text-center">
                        Nível Inferior:
                      </h4>
                      <h1 class="font-weight-medium text-center pt-2">
                        {{ controller.markInfoPump.lowerLevel }}
                      </h1>
                    </div>
                  </v-card>
                </v-col>
              </v-row> -->

              <v-carousel
                hide-delimiters
                height="200"
              >
                <v-carousel-item>
                  <v-row class="pt-4">
                    <v-col cols="4">
                      <v-card min-height="165">
                        <div class="pt-2">
                          <h4 class="font-weight-medium text-center">
                            Voltagem:
                          </h4>
                          <h1 class="font-weight-medium text-center pt-8">
                            {{ controller.markInfoPump.voltage }}
                          </h1>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card min-height="165">
                        <div class="pt-2">
                          <h4 class="font-weight-medium text-center">
                            Amperagem:
                          </h4>
                          <h1 class="font-weight-medium text-center pt-8">
                            {{ controller.markInfoPump.amperage }}
                          </h1>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card min-height="165">
                        <div class="pt-2">
                          <h4 class="font-weight-medium text-center">
                            Potência:
                          </h4>

                          <h1 class="font-weight-medium text-center pt-8">
                            {{ controller.markInfoPump.power }}
                          </h1>
                          <span class="d-flex justify-center">(kW)</span>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-carousel-item>
                <v-carousel-item>
                  <v-row class="pt-4">
                    <v-col cols="4">
                      <v-card min-height="165">
                        <div class="pt-2">
                          <h4 class="font-weight-medium text-center">
                            A Bomba está ligada?
                          </h4>

                          <div class="d-flex justify-center pt-8">
                            <h2>
                              {{
                                controller.markInfoPump.capacitors > 0
                                  ? "Sim"
                                  : "Não"
                              }}
                            </h2>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card min-height="165">
                        <div class="pt-2">
                          <h4 class="font-weight-medium text-center">
                            Motores:
                          </h4>
                          <div class="d-flex justify-center pt-8">
                            <h2>
                              {{
                                controller.markInfoPump.engines > 0
                                  ? "Sim"
                                  : "Não"
                              }}
                            </h2>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card min-height="165">
                        <div class="pt-2">
                          <h4 class="font-weight-medium text-center">
                              Capacitores:
                          </h4>
                          <div class="d-flex justify-center pt-8">
                            <h2>
                              {{
                                controller.markInfoPump.capacitors > 0
                                  ? "Sim"
                                  : "Não"
                              }}
                            </h2>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>

              <v-card class="pt-6 mb-8">
                <v-row>
                  <v-col cols="12">
                    <div class="pl-4">
                      <div class="pt-2 d-flex">
                        <h2 class="font-weight-medium">
                          Problema: {{ controller.markInfoPump.description }}
                        </h2>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { pumpMarkController } from "@/di/di";
import rightDrawer from "../components/rightDrawer.vue";
import dayjs from "dayjs";

export default {
  components: {
    "right-nav-drawer": rightDrawer,
  },
  data: (context) => ({
    controller: pumpMarkController(context),
  }),
  mounted() {
    this.controller.mounted();

    window.onscroll = () => {
      var pageHeight = document.documentElement.offsetHeight,
        scrollPosition = window.scrollY;
      window.pageYOffset;
      document.body.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) || 0);

      var intViewportHeight = window.innerHeight;

      if (pageHeight <= scrollPosition + intViewportHeight) {
        this.controller.markPumpOptions.page++;
        this.controller.paginateMarks();
      }
    };
  },

  watch: {
    "controller.calendarIn"() {
      dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
  },
  computed: {
    formatDateIn() {
      return dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    formatDateUntil() {
      return dayjs(this.controller.calendarUntil).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.root{
  min-height: 100vh
}

.show-visualizar {
  color: rgba(255, 255, 255, 1) !important;
  opacity: 1 !important;
}
</style>