import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Engine } from '../../domain/model/engine';

export interface CreateEngineRepository {
  (data: object): Promise<Engine>
}

const createEngineRepository = (axios: AxiosInstance): CreateEngineRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/engine`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createEngineRepository

