import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Checklist } from './../../domain/model/checklist';

export interface CreateChecklistRepository {
  (data: object): Promise<Checklist>
}

const createChecklistRepository = (axios: AxiosInstance): CreateChecklistRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/checklist`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createChecklistRepository

