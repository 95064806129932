import { MarkStation } from '@/module/stationMark/domain/model/markStation';
import { UpdateStationDataUseCase } from '../domain/usecase/update_station_data_usecase';
import { Station, stationDefault } from '@/module/station/domain/model/station';
import { headersTracking } from './../const/headers_tracking';
import { FetchStationTrackingByIDUseCase } from './../domain/usecase/fetch_station_tracking_by_id_usecase';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { MarkStationPagination } from './../../stationMark/domain/model/markStation_pagination';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { DeleteStationUseCase } from '../domain/usecase/delete_station_usecase';
import { CreateStationUseCase } from '../domain/usecase/create_station_usecase';
import { ChangeStationUseCase } from '../domain/usecase/change_station_usecase';
import { FetchStationUseCase } from '../domain/usecase/fetch_station_usecase';
import { StationPagination } from '../domain/model/station_pagination';
import { snackbar } from '@/core/controller/snackbar_controller';
import { headers } from '../const/headers';
import { DataOptions } from 'vuetify';
import { DeleteMarkStationUseCase } from '../domain/usecase/delete_station_mark_usecase';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import * as XLSX from "xlsx";
import * as echarts from 'echarts';
import { getAccount } from '@/core/domain/model/jwtAuthToken';
import { Account } from '@/core/domain/model/account';
import dayjs from 'dayjs';

class StationController {
  public loadingBtnExcel: boolean = false
  public context: any;
  public dialog: boolean = false;
  public center: object = {
    lat: -13.956416,
    lng: -52.332572
  }
  public zoom: number = 10
  public columns: Array<any> = headers
  public columnsTracking: Array<any> = headersTracking
  public search: string = ""
  public searchCompany: string = ""
  public searchTracking: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public loadingLogs: boolean = false
  public calendarIn: string = ""
  public calendarUntil: string = ""
  public historicTime: string = "00:00"
  public pluviometerHistoricType: "Meses" | "Dias" | "Horas" = "Meses"
  public cardHeight: string = "275vh"
  public dialogChart: boolean = false
  public dialogHistoric: boolean = false
  public dialogTracking: boolean = false
  public dialogStationUpdateData: boolean = false
  public selectedStation: Station | null = null
  public selectedData: MarkStation | null = null
  public selected: Array<MarkStation> = []
  public selectedMarkStation: MarkStation = {
    id: 0,
    temperature: 0,
    wind_direction: 0,
    wind_velocity: 0,
    station_id: 0,
    created_at: "",
    humidity: 0,
    pluviometer_mmpp: 0,
    hardware_base: {
      battery_status: 0,
      signal_intensity: 0,
      signal_origin: ""
    }
  }
  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus

  public stationPagination: StationPagination = {
    total: 0,
    items: []
  }
  public trackingPagination: MarkStationPagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsTracking: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["created_at"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }

  public station: Station = stationDefault()
  public account: Account = getAccount();
  public data: MarkStation[] = [];
  public dates: String[] = [];

  private humidityChart: echarts.ECharts | null = null
  private temperatureChart: echarts.ECharts | null = null
  private temperaturesChart: echarts.ECharts | null = null
  private windVelocityChart: echarts.ECharts | null = null
  private windDirectionChart: echarts.ECharts | null = null
  private pluviometerChart: echarts.ECharts | null = null

  private fetchCompanyUseCase: FetchCompanyUseCase
  private fetchStationUseCase: FetchStationUseCase
  private deleteStationUseCase: DeleteStationUseCase
  private createStationUseCase: CreateStationUseCase
  private changeStationUseCase: ChangeStationUseCase
  private fetchStationTrackingByIDUseCase: FetchStationTrackingByIDUseCase
  private updateStationDataUseCase: UpdateStationDataUseCase
  private deleteMarkStationUseCase: DeleteMarkStationUseCase
  private getCompanyByIDUseCase: GetCompanyByIDUseCase

  constructor(
    context: any,
    fetchCompanyUseCase: FetchCompanyUseCase,
    fetchStationUseCase: FetchStationUseCase,
    deleteStationUseCase: DeleteStationUseCase,
    createStationUseCase: CreateStationUseCase,
    changeStationUseCase: ChangeStationUseCase,
    fetchStationTrackingByIDUseCase: FetchStationTrackingByIDUseCase,
    updateStationDataUseCase: UpdateStationDataUseCase,
    deleteMarkStationUseCase: DeleteMarkStationUseCase,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
  ) {
    this.context = context
    this.fetchCompanyUseCase = fetchCompanyUseCase
    this.fetchStationUseCase = fetchStationUseCase
    this.deleteStationUseCase = deleteStationUseCase
    this.createStationUseCase = createStationUseCase
    this.changeStationUseCase = changeStationUseCase
    this.fetchStationTrackingByIDUseCase = fetchStationTrackingByIDUseCase
    this.updateStationDataUseCase = updateStationDataUseCase
    this.deleteMarkStationUseCase = deleteMarkStationUseCase
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
  }

  open() {
    this.station = stationDefault()
    this._setCompanyCenter();
    this.paginateCompany()
    this.flagOption = "create"
  }

  async _setCompanyCenter() {
    const company = await this.getCompanyByIDUseCase()

    this.center = {
      lat: parseFloat(company.latitude),
      lng: parseFloat(company.longitude)
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.stationPagination = await this.fetchStationUseCase(this.options, this.search)
      if(this.account.companyID != 1) this.stationPagination.items = this.stationPagination.items.filter(s => s.company_id == this.account.companyID)
      
      this.data = [];
      this.optionsTracking.itemsPerPage = 1;
      for( let i = 0; i < this.stationPagination.items.length; i++) {
        const data = await this.fetchStationTrackingByIDUseCase(this.stationPagination.items[i].id, this.optionsTracking, "", this.calendarIn, this.calendarUntil)
        this.data.push(data.items[0])
        this.dates.push(data.items[0]?.created_at)
      }
      this.optionsTracking.itemsPerPage = 40;

    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error) {
        console.log(error);
      }
    }
  }

  async paginateTracking() {
    this.loadingLogs = true
    this.calendarIn = "";
    this.calendarUntil = "";
    try {
      if (this.selectedStation) {
        this.trackingPagination = await this.fetchStationTrackingByIDUseCase(this.selectedStation.id, this.optionsTracking, "", this.calendarIn, this.calendarUntil)
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingLogs = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchSearchTracking() {
    this.paginateTracking()
  }

  watchOptionsTracking() {
    this.paginateTracking()
  }

  async createStationChart() {
    
    try {
      if (this.selectedStation) {
        if(this.selectedStation.pluviometer){
          this.optionsTracking.itemsPerPage = 525600;
          this.calendarIn = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
          this.calendarUntil = (new Date()).toISOString()
        }
        else {
          this.optionsTracking.itemsPerPage = 1;
          this.calendarIn = ""
          this.calendarUntil = ""
        }
        this.trackingPagination = await this.fetchStationTrackingByIDUseCase(this.selectedStation.id, this.optionsTracking, "", this.calendarIn, this.calendarUntil)
      }
    } catch (error) {
      console.log(error);
    }

    this.calendarIn = ""
    this.calendarUntil = ""
    this.optionsTracking.itemsPerPage = 40
    if(this.trackingPagination.total == 0) {
      this.dialogChart = false
      snackbar.show({message: "Não há dados disponíveis!"})
      return;
    }

    const humidity = this.trackingPagination.items[0].humidity
    const temperature = this.trackingPagination.items[0].temperature
    const windVelocity = this.trackingPagination.items[0].wind_velocity
    const windDirection = this.trackingPagination.items[0].wind_direction
    const dataMMPP = this.trackingPagination.items[0].pluviometer_mmpp
    const windDirectionCoefficient = this.selectedStation!.wind_direction_coefficient
    const stationMMPP = this.selectedStation!.pluviometer_mmpp

    if(this.selectedStation?.humidity){
      this.humidityChart = echarts.init(document.getElementById('humidity_chart')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.humidityChart.setOption({
        title: {
          text: "Umidade do ar",
          textStyle: {
            fontSize: 30
          },
          left: 'center'
        },
        series: [
          {
            name: 'Umidade do ar',
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            splitNumber: 1,
            progress: {
              show: true,
              width: 60
            },
            axisLine: {
              lineStyle: {
                width: 60
              }
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 20,
              distance: 0,
              formatter: "\n\n{value}"
            },
            splitLine: {
              show: false
            },
            detail: {
              valueAnimation: true,
              offsetCenter: [0, '-10%'],
              fontSize: 35,
              formatter: '{value}%'
            },
            data: [
              {
                value: humidity,
              }
            ],
            center: ["50%", "65%"]
          }
        ]
      })
    }
  
    if(this.selectedStation?.wind_velocity) {
      this.windVelocityChart = echarts.init(document.getElementById('wind_velocity_chart')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.windVelocityChart.setOption({
        title: {
          text: "Velocidade do vento",
          textStyle: {
            fontSize: 30
          },
          left: 'center'
        },
        series: [
          {
            name: 'Velocidade do vento',
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            splitNumber: 1,
            progress: {
              show: true,
              width: 65
            },
            axisLine: {
              lineStyle: {
                width: 65
              }
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 20,
              distance: 0,
              formatter: "\n\n{value}"
            },
            splitLine: {
              show: false
            },
            detail: {
              valueAnimation: true,
              offsetCenter: [0, '-10%'],
              fontSize: 35,
              formatter: '{value}km/h'
            },
            data: [
              {
                value: windVelocity,
              }
            ],
            center: ["50%", "65%"]
          }
        ]
      })
    }

    if(this.selectedStation?.temperature) {
      this.temperatureChart = echarts.init(document.getElementById('temperature_chart')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.temperatureChart.setOption({
        title: {
          text: "Temperatura do ar",
          textStyle: {
            fontSize: 30
          },
          left: 'center'
        },
        grid: {
          left: "center"
        },
        width: 100,
        name: "Temperatura do ar",
        xAxis: {
          type: 'category',
          data: ['Temperatura']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}°C'
          },
        },
        label: {
          show: true,
          position: 'top'
        },
        series: [
          {
            data: [{
              value: temperature,
              itemStyle: {
                color: "#a90000"
              },
            }],
            type: 'bar',
          }
        ]
      })
    }

    if(this.selectedStation?.temperature && this.selectedStation.humidity && this.selectedStation.wind_velocity) {
    
      const windChill = this.calculateWindChill(temperature, windVelocity)
      const dewPoint = this.calculateDewPoint(temperature, humidity)
      const heatIndex = this.calculateHeatIndex(temperature, humidity)

      this.temperaturesChart = echarts.init(document.getElementById('temperatures_chart')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.temperaturesChart.setOption({
        title: {
          text: "Temperatura",
          textStyle: {
            fontSize: 30
          },
          left: 'center'
        },
        name: "Temperatura do ar",
        xAxis: {
          type: 'category',
          data: ['Sensação Térmica', 'Índice de Calor', 'Ponto de Orvalho']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} °C'
          }
        },
        label: {
          show: true,
          position: 'top'
        },
        color: ['blue', 'orange', 'green'],
        series: [
          {
            type: 'bar', 
            data: [windChill, heatIndex, dewPoint],
            colorBy: 'data'
          }
        ]
      })

    }
    

    if(this.selectedStation?.wind_direction) {
      const vc = (1023*windDirectionCoefficient)/359;
      const windDegrees = ((vc + windDirection)%1024).toPrecision(2);

      this.windDirectionChart = echarts.init(document.getElementById('wind_direction_chart')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.windDirectionChart.setOption({
        title: {
          text: "Direção do vento",
          textStyle: {
            fontSize: 30
          },
          left: 'center'
        },
        polar: {},
        radiusAxis: {
          max: 1,
          splitNumber: 1,
          axisLabel: false
        },
        angleAxis: {
          min: 0,
          max: 359,
          interval: 45,
          startAngle: 90,
          splitLine: {
            lineStyle: {
              color: 'black',
            },
          },
          axisLabel: {
            formatter: function (value, index) {
              const array = ['N', 'NE', 'E', 'SE','S', 'SW', 'W', 'NW']
              return array[index]
            }
          },
        },
        color: ['blue'],
        series: [
          {
          type: 'bar',
          data: [windDegrees],
          barWidth: 20,
          coordinateSystem: 'polar',
          }],
        tooltip: {},
      })
    }
    
    if(this.selectedStation?.pluviometer) {

      var mmppDay = 0;
      for(let i = 0; i < this.trackingPagination.items.length && (i < 48); i++){
        if(new Date(this.trackingPagination.items[i].created_at).getDay() != new Date(this.trackingPagination.items[0].created_at).getDay()) break
        mmppDay += this.trackingPagination.items[i].pluviometer_mmpp
      }
      var mmppMonth = 0;
      for(let i = 0; i < this.trackingPagination.items.length && (i < 1440); i++){
        if(new Date(this.trackingPagination.items[i].created_at).getMonth() != new Date(this.trackingPagination.items[0].created_at).getMonth()) break
        mmppMonth += this.trackingPagination.items[i].pluviometer_mmpp
      }
      var mmppYear = 0;
      for(let i = 0; i < this.trackingPagination.items.length && (i < 525600); i++){
        if(new Date(this.trackingPagination.items[i].created_at).getFullYear() != new Date(this.trackingPagination.items[0].created_at).getFullYear()) break
        mmppYear += this.trackingPagination.items[i].pluviometer_mmpp
      }

      this.pluviometerChart = echarts.init(document.getElementById('pluviometer_chart')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.pluviometerChart.setOption({
        title: {
          text: "Pluviosidade",
          textStyle: {
            fontSize: 30
          },
          left: 'center'
        },
        name: "Pluviosidade",
        xAxis: {
          type: 'category',
          data: ['Ano', 'Mês', 'Dia', 'Hora']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            fontSize: 10
          }
        },
        label: {
          show: true,
          position: 'top'
        },
        //(somatório de todos os mmpp recebidos) * stationMMPP
        series: [
          {
            type: 'bar', 
            data: [(stationMMPP * mmppYear).toFixed(2), (stationMMPP * mmppMonth).toFixed(2), (stationMMPP * mmppDay).toFixed(2), (stationMMPP * dataMMPP).toFixed(2)],
            itemStyle: {
              color: "green"
            }
          }
        ]
      })
    }
    
  
  }

  calculateWindChill(temperature: number, windVelocity: number){
    const F = 1.8 * temperature + 32;
    const mph = 0.621371*windVelocity;
    
    var wChill;
    
    wChill = 35.74 + .6215*F - 35.75*Math.pow(mph, 0.16) + 0.4275*F*Math.pow(mph, 0.16);
    return ((wChill - 32) * (5/9)).toPrecision(2);
  
  }

  calculateDewPoint(temperature: number, humidity: number){
    return (temperature - ((100 - humidity) / 5)).toPrecision(2);
  }

  calculateHeatIndex(temperature: number, rh: number){
    const F = 1.8 * temperature + 32;

    const Hindex = -42.379 + 2.04901523 * F + 10.14333127 * rh - 0.22475541 * F * rh - 6.83783 * Math.pow(10, -3) * F * F - 5.481717 * Math.pow(10, -2) * rh * rh + 1.22874 * Math.pow(10, -3) * F * F * rh + 8.5282 * Math.pow(10, -4) * F * rh * rh - 1.99 * Math.pow(10, -6) * F * F * rh * rh;
    return ((Hindex - 32) * (5/9)).toPrecision(2);
  }


  async createStationHistoric(){

    this.cardHeight = (13 + ( Number(this.selectedStation?.humidity) + Number(this.selectedStation?.wind_velocity) + 
      Number(this.selectedStation?.temperature) + Number(this.selectedStation?.wind_direction) + Number(this.selectedStation?.pluviometer)) * 52).toString()
      + "vh"

    this.optionsTracking.itemsPerPage = 48 * (new Date(this.calendarUntil).getTime() - new Date(this.calendarIn).getTime()) / 60 / 60 / 24 / 1000
    this.optionsTracking.sortDesc = [false]

    try {
      
      if (this.selectedStation) {
        this.trackingPagination = await this.fetchStationTrackingByIDUseCase(this.selectedStation.id, this.optionsTracking, "", this.calendarIn, this.calendarUntil)
      }
    } catch (error) {
      console.log(error);
    }
    
    this.optionsTracking.itemsPerPage = 40;
    this.optionsTracking.sortDesc = [true];
    
    if(this.trackingPagination.total == 0){
      this.cardHeight = '13vh';
      const historicCard = document.getElementById('historic_card');
      historicCard!.style.overflow = "hidden";
      return;
    }
    else {
      const historicCard = document.getElementById('historic_card');
      historicCard!.style.overflow = "hidden";
    }

    var dataArray: MarkStation[] = []

    const historicHour = new Date("1/1/1/" + this.historicTime).getHours()

    for(let i = 0; i < this.trackingPagination.total; i++){
      
      const item = this.trackingPagination.items[i]
      const itemDay = new Date(item.created_at).toLocaleDateString()

      if(dataArray.find((d: MarkStation) => new Date(d.created_at).toLocaleDateString() == itemDay)) {}
      else 
      {
        const foundItem = this.trackingPagination.items.find((i: MarkStation) => new Date(i.created_at).getHours() == historicHour && new Date(i.created_at).toLocaleDateString() == itemDay)
        if(foundItem) dataArray.push(foundItem)
        else dataArray.push(item)
      }

    }
      
    const dateArray = dataArray.map((d: MarkStation) => dayjs(d.created_at).format("DD/MM/YYYY HH:mm:ss"))

    if(this.selectedStation?.humidity){

      const humidityArray = dataArray.map((d: MarkStation) => d.humidity)

      this.humidityChart = echarts.init(document.getElementById('humidity_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.humidityChart.setOption({
        title: {
          text: 'Umidade do ar'
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            const p = params[0]
            return `${p.name}<br>${p.marker}${p.seriesName}<span style="float: right; margin-left: 20px"><b>${p.value}%</b></span>`;
          }
        },
        legend: {
          data: ['Umidade']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dateArray
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}%'
          }
        },
        color: ['#00fbff'],
        series: [
          {
            name: 'Umidade',
            type: 'line',
            data: humidityArray,
            areaStyle: {}
          }
        ]
      })
    }

    if(this.selectedStation?.wind_velocity) {

      const windVelocityArray = dataArray.map((d: MarkStation) => d.wind_velocity)

      this.windVelocityChart = echarts.init(document.getElementById('wind_velocity_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.windVelocityChart.setOption({
        title: {
          text: 'Velocidade do vento'
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            const p = params[0]
            return `${p.name}<br>${p.marker}${p.seriesName}<span style="float: right; margin-left: 20px"><b>${p.value}km/h</b></span>`;
          }
        },
        legend: {
          data: ['Velocidade do vento']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dateArray
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}km/h'
          }
        },
        color: ['#34eb64'],
        series: [
          {
            name: 'Velocidade do vento',
            type: 'line',
            data: windVelocityArray,
            areaStyle: {}
          }
        ]
      })
    }

    if(this.selectedStation?.temperature) {
      
      var windChillArray: string[] = []
      if(this.selectedStation.temperature && this.selectedStation.wind_velocity) windChillArray = dataArray.map((d: MarkStation) => this.calculateWindChill(d.temperature, d.wind_velocity))

      var dewPointArray: string[] = []
      var heatIndexArray: string[] = []

      if(this.selectedStation.temperature && this.selectedStation.humidity){
        dewPointArray = dataArray.map((d: MarkStation) => this.calculateDewPoint(d.temperature, d.humidity))
        heatIndexArray = dataArray.map((d: MarkStation) => this.calculateHeatIndex(d.temperature, d.humidity))
      }

      const temperatureArray = dataArray.map((d: MarkStation) => d.temperature)

      this.temperatureChart = echarts.init(document.getElementById('temperature_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.temperatureChart.setOption({
        title: {
          text: 'Temperatura'
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            const p = params[0]
            const p2 = params[1]
            const p3 = params[2]
            const p4 = params[3]
            return `${p.name}<br>${p.marker}${p.seriesName}<span style="float: right; margin-left: 20px"><b>${p.value} °C</b></span>
            <br>${p2.marker}${p2.seriesName}<span style="float: right; margin-left: 20px"><b>${p2.value} °C</b></span>
            <br>${p3.marker}${p3.seriesName}<span style="float: right; margin-left: 20px"><b>${p3.value} °C</b></span>
            <br>${p4.marker}${p4.seriesName}<span style="float: right; margin-left: 20px"><b>${p4.value} °C</b></span>`;
          }
        },
        legend: {
          data: ['Temperatura do ar', 'Sensação Térmica', 'Índice de Calor', 'Ponto de Orvalho']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dateArray
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}°C'
          }
        },
        color: ['red','blue', 'orange', 'green'],
        series: [
          {
            name: 'Temperatura do ar',
            type: 'line',
            data: temperatureArray
          },
          {
            name: 'Sensação Térmica',
            type: 'line',
            data: windChillArray
          },
          {
            name: 'Índice de Calor',
            type: 'line',
            data: heatIndexArray
          },
          {
            name: 'Ponto de Orvalho',
            type: 'line',
            data: dewPointArray
          }
        ]
      })

    }

    if(this.selectedStation?.wind_direction) {

      const windDirectionCoefficient = this.selectedStation.wind_direction_coefficient;
      const array = ['N', 'NE', 'E', 'SE','S', 'SW', 'W', 'NW']

      const windDirectionArray = dataArray.map((d: MarkStation) => {
        const value = ((((1023*windDirectionCoefficient)/359) + d.wind_direction)%1024)
        return value.toFixed(2)
      })

      this.windDirectionChart = echarts.init(document.getElementById('wind_direction_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      this.windDirectionChart.setOption({
        title: {
          text: 'Direção do vento'
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            var dir = ""
            const p = params[0]

            if(p.value > 0 && p.value < 128) dir = 'N ↑'
            else if(p.value >= 128 && p.value < 256) dir = 'NE ↗'
            else if(p.value >= 256 && p.value < 384) dir = 'E →'
            else if(p.value >= 384 && p.value < 512) dir = 'SE ↘'
            else if(p.value >= 512 && p.value < 640) dir = 'S ↓'
            else if(p.value >= 640 && p.value < 768) dir = 'SW ↙'
            else if(p.value >= 768 && p.value < 896) dir = 'W ←'
            else if(p.value >= 896 && p.value < 1024) dir = 'NW ↖'

            return `${p.name}<br>${p.marker}${p.seriesName}<span style="float: right; margin-left: 20px"><b> ${dir}</b></span>`;
          }
        },
        legend: {
          data: ['Direção do vento']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dateArray,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        color: ['#34eb64'],
        series: [
          {
            name: 'Direção do vento',
            type: 'line',
            data: windDirectionArray,
            areaStyle: {}
          }
        ]
      })

    }

    if(this.selectedStation?.pluviometer) {

      const mesesArray = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

      if(this.pluviometerHistoricType == "Meses") {
        
        const meses = new Map()

        for(let i = 0; i < this.trackingPagination.items.length; i++){
          const item = this.trackingPagination.items[i]
          const itemMonth = new Date(item.created_at).getMonth()
          if(!meses.get(itemMonth)) {
            meses.set(itemMonth,  item.pluviometer_mmpp)
          }
          else meses.set(itemMonth , meses.get(itemMonth) + item.pluviometer_mmpp)
          
        }

        const keys = Array.from(meses.keys()).map((i) => mesesArray[i])
        const values = Array.from(meses.values()).map((v) => (v * this.selectedStation!.pluviometer_mmpp).toFixed(2))


        this.temperatureChart = echarts.init(document.getElementById('pluviometer_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
        this.temperatureChart.setOption({
          title: {
            text: 'Pluviosidade Acumulada'
          },
          tooltip: {
            trigger: 'axis',
            formatter(params) {
              const p = params[0]
              return `${p.name}<br>${p.marker}${p.seriesName}<span style="float: right; margin-left: 20px"><b>${p.value} mm</b></span>`;
            }
          },
          legend: {
            data: ['Pluviosidade']
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          calculable: true,
          xAxis: [
            {
              type: 'category',
              // prettier-ignore
              data: keys
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                formatter: '{value}mm'
              }
            }
          ],
          series: [
            {
              name: 'Pluviosidade',
              type: 'bar',
              data: values,
            },
          ]
        })
      }

      else if(this.pluviometerHistoricType == "Dias"){
        const dias = new Map()

        for(let i = 0; i < this.trackingPagination.items.length; i++){
          const item = this.trackingPagination.items[i]
          const itemDay = new Date(item.created_at).toLocaleDateString()
          if(!dias.get(itemDay)) {
            dias.set(itemDay,  item.pluviometer_mmpp)
          }
          else dias.set(itemDay , dias.get(itemDay) + item.pluviometer_mmpp)
          
        }

        const keys = Array.from(dias.keys())
        const values = Array.from(dias.values()).map((v) => (v * this.selectedStation!.pluviometer_mmpp).toPrecision(2))

        this.temperatureChart = echarts.init(document.getElementById('pluviometer_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
        this.temperatureChart.setOption({
          xAxis: {
            type: 'category',
            data: keys
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: values,
              type: 'bar'
            }
          ]
        })

      }

      else if(this.pluviometerHistoricType == "Horas") {
        const horas = new Map()

        for(let i = 0; i < this.trackingPagination.items.length; i++){
          const item = this.trackingPagination.items[i]
          const itemHour = new Date(item.created_at).toISOString()
          if(!horas.get(itemHour)) {
            horas.set(itemHour,  item.pluviometer_mmpp)
          }
          else horas.set(itemHour , horas.get(itemHour) + item.pluviometer_mmpp)
          
        }

        const keys = Array.from(horas.keys()).map((d) => dayjs(d).format("DD/MM/YYYY HH:mm:ss"))
        const values = Array.from(horas.values()).map((v) => (v * this.selectedStation!.pluviometer_mmpp).toPrecision(2))

        this.temperatureChart = echarts.init(document.getElementById('pluviometer_historic')!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
        this.temperatureChart.setOption({
          xAxis: {
            type: 'category',
            data: keys
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: values,
              type: 'bar'
            }
          ]
        })
      }



    }

  }

  async create() {
    this.station.wind_direction_coefficient = (Number)(this.station.wind_direction_coefficient)
    this.station.pluviometer_mmpp = (Number)(this.station.pluviometer_mmpp)

    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createStationUseCase(this.station)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeStationUseCase(this.station.id, this.station)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: Station) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteStationUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Station) {
    this.flagOption = "change"
    this.dialog = true
    this.searchCompany = item.company_name
    this.paginateCompany()
    this.station = { ...item }

    if (!isNaN(parseFloat(this.station.latitude)) && !isNaN(parseFloat(this.station.longitude))) {
      this.center = {
        lat: parseFloat(this.station.latitude),
        lng: parseFloat(this.station.longitude)
      }
    } else {
      this._setCompanyCenter();
    }
  }

  getLocation(value) {
    this.station.latitude = value.lat().toFixed(7)
    this.station.longitude = value.lng().toFixed(7)

    this.center = value
  }

  async selectStation(item: Station){
    this.selectedStation = item;
  }

  async chart(item: Station) {
    try {
      this.selectedStation = item
      this.selectedData = this.data[this.stationPagination.items.indexOf(this.selectedStation)]
      this.calendarIn = ""
      this.calendarUntil = ""
      this.dialogChart = true
      this.createStationChart()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async historic(item: Station) {
    try {
      this.selectedStation = item
      this.calendarIn = dayjs().startOf('month').format("YYYY-MM-DD")
      this.calendarUntil = dayjs().endOf('month').format("YYYY-MM-DD")
      this.dialogHistoric = true
      await this.createStationHistoric()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async logs(item: Station) {
    try {
      this.selectedStation = item
      this.paginateTracking()
      this.dialogTracking = true

    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async reloadLogs() {
    try {
      this.paginateTracking()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  updateData(item: MarkStation) {
    this.selectedMarkStation = { ...item }
    this.dialogStationUpdateData = true
  }

  async confirmUpdateData() {
    try {
      this.selectedMarkStation.humidity = (Number)(this.selectedMarkStation.humidity)
      this.selectedMarkStation.temperature = (Number)(this.selectedMarkStation.temperature)
      this.selectedMarkStation.wind_velocity = (Number)(this.selectedMarkStation.wind_velocity)
      this.selectedMarkStation.wind_direction = (Number)(this.selectedMarkStation.wind_direction)
      this.selectedMarkStation.pluviometer_mmpp = (Number)(this.selectedMarkStation.pluviometer_mmpp)
      this.selectedMarkStation.hardware_base.battery_status = (Number)(this.selectedMarkStation.hardware_base.battery_status)
      this.selectedMarkStation.hardware_base.signal_intensity = (Number)(this.selectedMarkStation.hardware_base.signal_intensity)
      await this.updateStationDataUseCase(this.selectedMarkStation)
      this.dialogStationUpdateData = false

      this.paginateTracking()

    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async deleteSelected() {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        if (this.selected.length > 0) {
          for (let el of this.selected) {
            await this.deleteMarkStationUseCase(el.id)
          }
          snackbar.show({ message: "Item(s) excluídos com sucesso!", color: "green", timeout: 1000 })
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.selected = []
      this.paginateTracking()
    }
  }


  async exportExcel() {
    try {
      const heading = [['ID', 'ID REGUA', 'NIVEL', 'TEMPERATURA', 'BATERIA', 'DATA CRIAÇÃO', 'COEFICIENTE', 'COD EMPRESA', 'EMPRESA', 'NOME REGUA', 'COR DA REGUA', 'USUÁRIO']];
      this.loadingBtnExcel = true
      const paginate = {...this.optionsTracking}
      paginate.itemsPerPage = -1
      const data =  await this.fetchStationTrackingByIDUseCase(this.selectedStation!.id, paginate, "", this.calendarIn, this.calendarUntil)



      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);

      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        const i = JSON.parse(JSON.stringify(item))
        delete i.levelPerCoeficient
        delete i.accountID
        delete i.version
        delete i.description
        return i
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'regua');

      XLSX.writeFile(wb, `regua.xlsx`);
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingBtnExcel = false
    }
  }

}

export default StationController