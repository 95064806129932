<template>
  <div class="mb-2 root background_screen">
    <v-form
      ref="filter"
      class="mx-2"
    >
      <v-toolbar
        flat
        class="my-2 d-none d-md-block background_screen"
      >
        <v-toolbar-title> Marcações das Réguas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-form class="d-flex align-center justify-space-around mx-2">
            <v-autocomplete
              v-model="controller.selectedHarvest"
              :items="controller.harvestPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6"
              label="Selecione a safra"
              style="max-width: 200px"
              return-object
              @change="controller.changeHarvest($event)"
              single-line
            ></v-autocomplete>
            <v-autocomplete
              v-model="controller.selectedRuler"
              :items="controller.rulerPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione a Régua"
              style="max-width: 200px"
              return-object
              single-line
            ></v-autocomplete>
             <date-picker
              style="max-width: 150px"
              v-model="controller.calendarIn"
              filled
              dense
              label="Data inicial"
              class="ml-2 mt-6"
              single-line
            ></date-picker>
             <date-picker
              v-model="controller.calendarUntil"
              filled
              dense
              label="Data final"
              style="max-width: 150px"
              class="mx-2 mt-6"
              single-line
            ></date-picker>
            <!-- <v-menu
              v-model="controller.calendarMenuIn"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  v-model="formatDateIn"
                  label="De"
                  readonly
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  class="pt-6 pl-2"
                  style="max-width: 150px"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  single-line
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="controller.calendarIn"
                class="primary"
                no-title
                @input="controller.calendarMenuIn = false"
                :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
                min="2010-NaN-NaN"
              >
              </v-date-picker>
            </v-menu> -->
           
            <!-- <v-menu
              v-model="controller.calendarMenuUntil"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  v-model="formatDateUntil"
                  label="Até"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  class="pt-6 pl-2"
                  style="max-width: 150px"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  single-line
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="controller.calendarUntil"
                class="primary"
                no-title
                @input="controller.calendarMenuUntil = false"
                :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
                min="2010-NaN-NaN"
              ></v-date-picker>
            </v-menu> -->

            <div class="ml-2">
              <v-btn
                height="38"
                :loading="controller.loadingBtn"
                color="primary"
                class="mt-0"
                @click="controller.filter()"
              >
                <v-icon> mdi-filter </v-icon>
                Filtrar
              </v-btn>
            </div>
            <div class="ml-2">
              <v-btn
                icon
                @click="controller.rightDrawer = true"
              >
                <v-icon
                  x-large
                  color="primary"
                > mdi-cog </v-icon>
              </v-btn>
            </div>
          </v-form>
        </v-toolbar-items>
      </v-toolbar>

      <v-toolbar
        flat
        class="my-3 mx-2 d-md-none"
      >
        <v-toolbar-title> Marcações das Réguas </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="controller.dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar flat>
              <v-toolbar-title> Marcações das Réguas </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="controller.dialog = false"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="d-flex flex-column">
              <v-autocomplete
                v-model="controller.selectedHarvest"
                :items="controller.harvestPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a safra"
                return-object
                @change="controller.changeHarvest($event)"
                single-line
              ></v-autocomplete>
              <v-autocomplete
                v-model="controller.selectedRuler"
                :items="controller.rulerPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a Régua"
                return-object
              ></v-autocomplete>
              <!-- <v-menu
                v-model="controller.calendarMenuDialogIn"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formatDateIn"
                    label="De"
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    class="pt-6"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="controller.calendarIn"
                  class="primary"
                  no-title
                  @input="controller.calendarMenuDialogIn = false"
                  :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
                  min="2010-NaN-NaN"
                >
                </v-date-picker>
              </v-menu> -->
               <date-picker
              v-model="controller.calendarIn"
              filled
              dense
              label="Data inicial"
            ></date-picker>
              <date-picker
                v-model="controller.calendarUntil"
                filled
                dense
                label="Data final"
              ></date-picker>
              <!-- <v-menu
                v-model="controller.calendarMenuDialogUntil"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="auto"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formatDateUntil"
                    label="Até"
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    class="pt-6"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="controller.calendarUntil"
                  class="primary"
                  no-title
                  @input="controller.calendarMenuDialogUntil = false"
                  :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
                  min="2010-NaN-NaN"
                ></v-date-picker>
              </v-menu> -->

              <div class="d-flex align-center">
                <div>
                  <v-btn
                    height="50"
                    :loading="controller.loadingBtn"
                    color="primary"
                    class="mt-0"
                    @click="controller.filter()"
                  >
                    <v-icon> mdi-filter </v-icon>
                    Filtrar
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    @click="controller.closeDialog()"
                    color="primary"
                    height="50"
                  >
                    <v-icon color="white"> mdi-cog </v-icon>Configurações
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-form>
    <!--     <v-container
      ref="abertosc"
      fluid
      id="scroll-target-a"
      style="max-height: 85vh; min-height: 85vh"
      class="overflow-y-auto overflow-x-hidden"
    >  -->
    <v-card class="mx-2">
      <v-card-text>
        <div class="mx-2">
          <div
            id="ruler_chart"
            style="height: 50vh; width: 100%"
          ></div>
        </div>
      </v-card-text>
    </v-card>

    <!--     <v-layout
      v-scroll:#scroll-target-a="onScrollAbertos"
      ref="abertos"
      class="mt-2"
    > -->
    <v-row class="pt-2 mb-0 px-2">
      <v-col
        cols="12"
        lg="3"
        v-for="(ruler, i) in controller.marks"
        :key="i"
      >
        <v-card class="ma-0">
          <v-card-text class="ma-2">
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="6"> </v-col>
              <v-col
                cols="3"
                class="d-flex justify-end align-start"
              >
                <v-tooltip
                  bottom
                  style="position: relative; right: 100px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      v-if="ruler.accountName == null"
                      :src="$vuetify.theme.isDark ? '/NVL.png' : '/NVL2.png'"
                      max-width="50"
                      max-height="35"
                      contain
                      v-bind="attrs"
                      v-on="on"
                    ></v-img>
                  </template>
                  <span>Medição feita por hardware</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  style="position: relative; right: 100px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h3
                      v-if="ruler.accountID != null"
                      v-bind="attrs"
                      v-on="on"
                      class="pr-2"
                    >
                      {{ ruler.accountName }}
                    </h3>
                  </template>
                  <span>Medição feita por colaborador</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h1 class="font-weight-medium">
                  {{ ruler.rulerName }}
                </h1>
                <div class="pt-2 d-flex">
                  <h2 class="font-weight-medium">Nível atual:</h2>
                  <h2 class="font-weight-medium pl-2">
                    {{ ruler.level.toFixed(2) }}
                  </h2>
                </div>
                <div class="pt-2 d-flex">
                  <h4 class="font-weight-medium">Atualizado em:</h4>
                  <h4 class="font-weight-medium pl-2">
                    {{ dayjs(ruler.createdDate).format("DD/MM/YYYY HH:mm:ss") }}
                  </h4>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            style="height: 40px"
            :style="{ backgroundColor: ruler.rulerColor }"
          >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!--  </v-layout> -->
    <!-- </v-container> -->
    <right-nav-drawer :controller="controller" />
  </div>
</template>

<script>
import { rulerMarkController } from "@/di/di";
import rightDrawer from "../components/rightDrawer.vue";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  components: {
    "right-nav-drawer": rightDrawer,
  },
  data: (context) => ({
    controller: rulerMarkController(context),
  }),
  mounted() {
    this.controller.mounted();

    window.onscroll = () => {
      var pageHeight = document.documentElement.offsetHeight,
        scrollPosition = window.scrollY;
      window.pageYOffset;
      document.body.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) || 0);

      var intViewportHeight = window.innerHeight;

      if (pageHeight <= scrollPosition + intViewportHeight) {
        this.controller.markRulerOptions.page++;
        this.controller.paginateMarks();
      }
    };
  },
  watch: {
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.calendarIn"() {
      dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
  },
  computed: {
    formatDateIn() {
      return dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    formatDateUntil() {
      return dayjs(this.controller.calendarUntil).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.root {
  min-height: 100vh;
}
</style>