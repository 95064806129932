<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.placePagination.total"
      :items="controller.placePagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.color`]="{ item }">
        <v-avatar size="30" :color="item.color"> </v-avatar>
      </template>

      <template v-slot:[`item.isFavorite`]="{ item }">
        <v-btn icon @click="controller.changeFav(item)">
          <v-icon :color="item.isFavorite ? '#FFC107' : 'grey'"
            >mdi-star</v-icon
          >
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(2, 3)"
          >
            <v-icon small color="blue">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="hadPermission(2, 4)"
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon small color="red">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Levantes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                v-if="hadPermission(2, 2)"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Locais
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form ref="crud" lazy-validation>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Nome"
                    v-model="controller.place.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <div class="d-flex flex-column align-center">
                    <h4>Selecionar cor</h4>
                    <div>
                      <v-color-picker
                        class="no-alpha"
                        dot-size="25"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="controller.place.color"
                        hide-inputs
                      ></v-color-picker>
                    </div>
                  </div>
                  <v-text-field
                    filled
                    dense
                    readonly
                    color="primary"
                    label="Latitude"
                    v-model="controller.place.latitude"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    readonly
                    color="primary"
                    label="Longitude"
                    v-model="controller.place.longitude"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>

                  <form-maps
                    :center="controller.center"
                    :zoom="controller.zoom"
                    @get-location="controller.getLocation($event)"
                  ></form-maps>

                  <!-- @update-center="controller.updateCenter($event)" -->

                  <!-- <div style="width: 100%; height: 300px">
                    <google-map
                      v-if="controller.center != null"
                      :center="controller.center"
                      @center_changed="controller.updateCenter($event)"
                      :zoom="18"
                      style="width: 100%; height: 100%"
                      map-type-id="hybrid"
                      :options="$vuetify.theme.isDark ? mapDark : mapLight"
                    >
                    </google-map>
                    <v-icon
                      color="orange"
                      x-large
                      style="
                        position: relative;
                        bottom: 54%;
                        left: 48%;
                        z-index: 9999;
                      "
                      >mdi-map-marker</v-icon
                    >
                  </div> -->
                  <v-autocomplete
                    v-if="isAdmin()"
                    class="pt-4"
                    filled
                    dense
                    color="primary"
                    label="Empresa"
                    v-model="controller.place.companyID"
                    :search-input.sync="controller.searchCompany"
                    :items="controller.companyPagination.items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-autocomplete>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn @click="controller.close()" outlined color="red">
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn color="primary" @click="controller.create()">
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { placeController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: placeController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
  },
};
</script>