export type Engine = {
  id?: any
  name: string
  place_id: any
  company_id: any
  brand?: string
  model?: string
  nominal_voltage?: string | number
}

export const en: any = {
  id: 1,
  placeID: 4,
  placeName: 7,
  companyName: 5,
  companyID: 1,
  name: 2,
  status: 6
}