import { ChangeScreenCompanyRepository } from './../../data/repository/change_screen_company_repository';
export interface ChangeScreenCompanyUseCase {
    (id: number, data: object): Promise<Screen>
}

const changeScreenCompanyUseCase = (repository: ChangeScreenCompanyRepository): ChangeScreenCompanyUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, {
            screens: data
        });
    } catch (error) {
        throw error
    }
}

export default changeScreenCompanyUseCase;

