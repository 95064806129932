<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.checklistPagination.total"
      :items="controller.checklistPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.color`]="{ item }">
        <v-avatar
          size="30"
          :color="item.color"
        > </v-avatar>
      </template>

      <template v-slot:[`item.isFavorite`]="{ item }">
        <v-btn
          icon
          @click="controller.changeFav(item)"
        >
          <v-icon :color="item.isFavorite ? '#FFC107' : 'grey'">mdi-star</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(2, 3)"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="hadPermission(2, 4)"
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Operações diarias</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                v-if="hadPermission(2, 2)"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>

              <v-card-title class="pa-0">
                <v-toolbar
                  color="primary"
                  class="white--text"
                >Manutenção do checklist
                  <v-spacer></v-spacer>

                </v-toolbar>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text class="py-5">

                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Nome"
                    v-model="controller.checklist.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>

                  <v-autocomplete
                    v-if="isAdmin()"
                    class="pt-4"
                    filled
                    dense
                    color="primary"
                    label="Empresa"
                    v-model="controller.checklist.companyID"
                    :search-input.sync="controller.searchCompany"
                    :items="controller.companyPagination.items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-autocomplete>
                  <v-divider></v-divider>
                  <div class="mt-10">Tarefas</div>

                  <div class="d-flex justify-center align-center">
                    <v-text-field
                      class="py-2"
                      hide-details
                      dense
                      filled
                      label="Descrição"
                      v-model="controller.taskDescription"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      class="my-2"
                      fab
                      text
                      small
                      @click="controller.pushItemToChecklist()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>

                  <v-simple-table height="200" fixed-header dense v-if="controller.checklist.items.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Descrição
                          </th>
                          <th class="text-right">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, key) in controller.checklist.items"
                          :key="key"
                        >
                          <td>{{ item.name }}</td>
                          <td class="text-right">
                            <v-icon
                              color="red"
                              small
                              @click="controller.deleteChecklistTask(item)"
                            >mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { checklistController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: checklistController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
  },
};
</script>