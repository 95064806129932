<template>
  <!--   <v-app
    style="
      background-color: #1d212a;
      background-image: url(/pivo.jpg);
      min-width: 100%;
      min-height: 100%;
      position: fixed;
      top: 0;
      left: 0;
    "
  > -->
  <v-app style="
      background: url(/pivo.jpg) no-repeat left bottom;
      background-size: cover;
      background-color: #cccccc;
      min-width: 100%;
      min-height: 100%;
      overflow: hidden;
    ">
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        class="pa-0 d-flex justify-center align-center flex-column"
        cols="12"
        lg="4"
        v-if="$vuetify.breakpoint.lgAndUp"
      >
        <v-img
          class="d-flex justify-center align-center"
          src="/logo_branco.png"
          max-height="180"
          max-width="300"
        >
        </v-img>
        <v-btn
          elevation="0"
          color="transparent"
          height="65"
          width="200"
          class="d-flex justify-center align-center mt-15"
          href="https://play.google.com/store/apps/details?id=com.greennext.fieldnotes"
          target="_blank"
        >
          <v-img
            src="/android_store.png"
            max-width="250"
            contain
          >
          </v-img>
        </v-btn>
        <v-btn
          elevation="0"
          color="transparent"
          height="65"
          width="200"
          class="d-flex justify-center align-center mt-3"
          href="https://apps.apple.com/br/app/meu-hydra/id1631922907"
          target="_blank"
        >
          <v-img
            src="/apple_store.png"
            max-width="235"
            contain
          >
          </v-img>
        </v-btn>
      </v-col>
      <v-col
        class="pa-0"
        cols="12"
        lg="3"
        md="5"
        sm="10"
      >
        <v-card
          dark
          :max-height="$vuetify.breakpoint.smAndDown ? '100vh' : '800'"
          style="overflow-y: auto"
          class="pa-8"
          color="#14171E"
        >
          <v-card-text>
            <v-form
              ref="form"
              v-if="!controller.newAccount && !controller.newCompany"
            >
              <div
                v-if="$vuetify.breakpoint.mdAndDown"
                class="d-flex justify-center align-center flex-column mb-10"
              >
                <v-img
                  src="/logo_branco.png"
                  max-height="150"
                  max-width="150"
                >
                </v-img>
              </div>

              <div
                v-else
                class="text-center white--text display-1 pb-10"
              >
                Acesse o Hydra
              </div>
              <div class="white--text">Usuário ou E-mail</div>
              <v-text-field
                :rules="[(v) => !!v || 'Preencha o campo']"
                filled
                v-model="controller.username"
                placeholder="E-mail"
              ></v-text-field>
              <div class="white--text">Sua senha</div>
              <v-text-field
                filled
                v-model="controller.password"
                :rules="[(v) => !!v || 'Preencha o campo']"
                :type="passwordFieldType"
                placeholder="Senha"
                :append-icon="eyeIcon"
                @click:append="togglePasswordVisibility"
              ></v-text-field>
              <v-row>
                <v-col>
                  <div class="primary--text text-center">
                    <v-btn x-small text href="/password-recovery" target="_blank">Esqueci minha senha</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                @click="controller.login()"
                class="mt-5"
                color="primary"
                block
                height="50"
                :loading="controller.loading"
              >Entrar</v-btn>
              <v-btn
                to="/account"
                class="mt-2"
                color="primary"
                block
                height="50"
                outlined
                :disabled="controller.loading"
              >Criar conta</v-btn>
            </v-form>
            <!-- <v-form
              ref="formRegistration"
              v-if="controller.newAccount"
            >
              <div class="text-center white--text display-1 pb-10">
                Crie sua conta no Hydra
              </div>
              <v-text-field
                label="E-mail"
                :rules="[(v) => !!v || 'Preencha o campo']"
                filled
                v-model="controller.account.email"
                dense
                placeholder="@email"
              ></v-text-field>
              <v-text-field
                label="Login"
                :rules="[(v) => !!v || 'Preencha o campo']"
                filled
                v-model="controller.account.login"
                dense
                placeholder="login..."
              ></v-text-field>
              <v-text-field
                label="Seu nome"
                :rules="[(v) => !!v || 'Preencha o campo']"
                filled
                v-model="controller.account.name"
                dense
                placeholder="nome..."
              ></v-text-field>
              <v-text-field
                label="Sua senha"
                filled
                v-model="controller.account.password"
                :rules="[(v) => !!v || 'Preencha o campo', (controller.account.password === controller.account.confirmationPassword) || 'As senhas não conferem']"
                dense
                type="password"
                placeholder="Senha"
              ></v-text-field>
              <v-text-field
                label="Confirme sua senha"
                filled
                dense
                v-model="controller.account.confirmationPassword"
                :rules="[(v) => !!v || 'Preencha o campo', (controller.account.password === controller.account.confirmationPassword) || 'As senhas não conferem']"
                type="password"
                placeholder="Confirmação da senha"
              ></v-text-field>
              <v-btn
                @click="controller.create()"
                class="mt-5"
                color="primary"
                block
                height="50"
                :loading="controller.loading"
              >cadastrar</v-btn>
              <v-btn
                @click="controller.newAccount = false; controller.newCompany = false"
                to="/"
                class="mt-2"
                color="primary"
                block
                height="50"
                outlined
              >
                <v-icon left>mdi-arrow-left</v-icon> voltar
              </v-btn>
            </v-form>
            <v-form
              ref="formCompanyRegistration"
              v-if="controller.newCompany"
            >
              <div class="text-center white--text display-1 pb-10">
                Olá {{getAccountName()}}
              </div>
              <div class="text-center white--text font-weight-bold pb-10">Quase pronto. Cadastre sua empresa!</div>
              <v-text-field
                filled
                dense
                color="primary"
                label="CPF/CNPJ"
                v-model="controller.company.cpfCnpj"
                @blur="controller.getCnpj()"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
              >
              </v-text-field>
              <v-text-field
                filled
                dense
                color="primary"
                label="Nome da empresa"
                v-model="controller.company.name"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-text-field>
              <v-text-field
                filled
                dense
                color="primary"
                label="Nome fantasia"
                v-model="controller.company.comporateName"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-text-field>
              <v-text-field
                filled
                dense
                color="primary"
                label="Telefone"
                v-model="controller.company.phone"
                :rules="[(v) => !!v || 'Preencha o campo']"
                v-mask="['(##) ####-####', '(##) #####-####']"
              >
              </v-text-field>
              <v-text-field
                filled
                dense
                color="primary"
                label="Endereço"
                v-model="controller.company.address"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-text-field>

              <v-autocomplete
                filled
                dense
                color="primary"
                label="Cidade"
                v-model="controller.company.cityID"
                :search-input.sync="controller.searchCity"
                :items="controller.cityPagination.items"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-autocomplete>
              <v-text-field
                filled
                dense
                readonly
                color="primary"
                label="Latitude"
                v-model="controller.company.latitude"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-text-field>
              <v-text-field
                filled
                dense
                readonly
                color="primary"
                label="Longitude"
                v-model="controller.company.longitude"
                :rules="[(v) => !!v || 'Preencha o campo']"
              >
              </v-text-field>
              <form-maps
                :center="controller.center"
                :zoom="controller.zoom"
                @get-location="controller.getLocation($event)"
              ></form-maps>
              <v-btn
                @click="controller.createCompany()"
                class="mt-5"
                color="primary"
                block
                height="50"
                :loading="controller.loading"
              >cadastrar</v-btn>
              <v-btn
                @click="controller.logOut()"
                to="/"
                class="mt-2"
                color="primary"
                block
                height="50"
                outlined
              >
                <v-icon left>mdi-exit-to-app</v-icon> sair
              </v-btn>
            </v-form> -->
          </v-card-text>

          <v-divider></v-divider>
          <div class="text-center white--text pt-5">
            Siga a Green Next nas redes sociais
          </div>
          <div class="text-center">
            <v-btn
              icon
              class="ma-2"
              href="https://www.facebook.com/greennextbrasil"
              target="_blank"
            >
              <v-icon
                color="blue"
                x-large
              >mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              href="https://www.instagram.com/greennextbrasil/"
              target="_blank"
              icon
              class="ma-2"
            >
              <v-icon
                color="pink"
                x-large
              >mdi-instagram</v-icon>
            </v-btn>
            <v-btn
              icon
              href="https://www.linkedin.com/company/greennext/"
              target="_blank"
              class="ma-2"
            >
              <v-icon
                color="#0F638C"
                x-large
              >mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { loginController } from "@/di/di";
export default {
  data: (context) => ({
    controller: loginController(context),
    showPassword: false,
  }),
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    eyeIcon() {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  mounted() {
    this.controller.mounted();
  },
};
</script>
