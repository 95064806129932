import { ChangeNotificationRepository } from '../../data/repository/change_notification_repository';
import { Notification } from '../model/notification';

export interface ChangeNotificationUseCase {
    (id: number, data: object): Promise<Notification>
}

const changeNotificationUseCase = (repository: ChangeNotificationRepository): ChangeNotificationUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeNotificationUseCase;

