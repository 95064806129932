import { MarkStationPagination } from './../../../stationMark/domain/model/markStation_pagination';
import { PaginationDate } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchStationTrackingByIDRepository {
    (id: number, pagination: PaginationDate): Promise<MarkStationPagination>
}

const fetchStationTrackingByIDRepository = (axios: AxiosInstance): FetchStationTrackingByIDRepository => async (id: number, pagination: PaginationDate) => {
    try {
        const response: AxiosResponse = await axios.get(`https://stationapihydra.com/station/${id}/data`, {
            params: pagination
        })
        const stationPagination: MarkStationPagination = response.data
        return stationPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchStationTrackingByIDRepository