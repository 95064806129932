import { CallCenterPagination } from '../model/call_center_pagination';
import { FetchCallCenterRepository } from '../../data/repository/fetch_call_center_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/call_center';

export interface FetchCallCenterUseCase {
    (pagination: DataOptions, search: string): Promise<CallCenterPagination>
}

const fetchCallCenterUseCase = (repository: FetchCallCenterRepository): FetchCallCenterUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchCallCenterUseCase;