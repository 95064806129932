<template>
  <v-app style="overflow: hidden">
    <div
      v-if="!$vuetify.breakpoint.smAndDown"
      class="circle"
      :style="{'width': $vuetify.breakpoint.mdAndDown ? '80%' : '60%'}"
    ></div>
    <v-row
      :align="!$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
      class="fill-height pa-0 ma-0"
    >
      <v-col
        cols="12"
        lg="8"
        md="9"
        style="z-index: 1"
      >
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            lg="8"
            cols="12"
            :class="{'pr-15': $vuetify.breakpoint.mdAndUp}"
          >
            <v-icon
              :class="{'mb-6': $vuetify.breakpoint.mdAndDown}"
              :color="!$vuetify.breakpoint.smAndDown ? 'white' : 'black'"
              @click="controller.returnOptions()"
            >mdi-arrow-left</v-icon>
            <div>
              <v-img
                v-if="!$vuetify.breakpoint.smAndDown"
                :src="!$vuetify.breakpoint.smAndDown ? '/logo_branco.png' : '/NVL2.png'"
                max-height="200"
                max-width="300"
                contain
                class="mb-16"
              ></v-img>
            </div>
            <div
              class="font-weight-bold text-lg-h3 text-md-h4 text-sm-h4 text-xs-h4 text-center text-md-left"
              :class="{'white--text': !$vuetify.breakpoint.smAndDown}"
            >
              <span id="quest1"></span>
            </div>
            <div
              style="opacity: 0.7"
              class="font-weight-bold text-lg-h5 text-md-h6 text-sm-h6 text-xs-h6 text-center text-md-left"
              :class="{'white--text': !$vuetify.breakpoint.smAndDown}"
            >
              <span id="desc1"></span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        lg="3"
        md="3"
        v-if="controller.showOptions && controller.option == 0"
      >
        <v-card
          @click="controller.isProducer()"
          elevation="0"
          class="my-4"
          style="border-radius: 20px; background: linear-gradient(345deg,rgba(0, 138, 252, 1) 40%,rgba(81, 169, 241, 1) 100%);"
          height="150"
        >
          <v-card-text class="white--text font-weight-bold headline d-flex justify-center align-center fill-height">
            Sou produtor
          </v-card-text>
        </v-card>
        <v-card
          @click="controller.isCollaborator()"
          outlined
          class="my-4"
          style="border-radius: 20px"
          height="150"
        >
          <v-card-text
            class="font-weight-bold headline d-flex justify-center align-center fill-height"
            style="background: linear-gradient(345deg,rgba(0, 138, 252, 1) 40%,rgba(81, 169, 241, 1) 100%);  -webkit-background-clip: text;  -webkit-text-fill-color: transparent;"
          >
            Sou colaborador
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        lg="3"
        v-if="controller.showCompanies && controller.option == 2"
      >

        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              solo
              v-model="controller.search"
              placeholder="Pesquisar minha empresa..."
              single-line
            ></v-text-field>
          </v-card-title>
          <v-card-text
            id="style-7"
            style="overflow-y: auto; max-height: 75vh"
          >
            <v-card
              @click="controller.selectedCompany(company)"
              class="my-1"
              v-for="(company, index) in controller.companyPagination.items"
              :key="index"
            >
              <v-card-text>
                <strong>{{ company.name }}</strong>
                <br>
                {{ company.comporateName }}
              </v-card-text>
            </v-card>
            <v-card
              class="my-1"
              @click="controller.copyToClipboard()"
            >
              <v-card-text
                class="white--text"
                style="background: linear-gradient(345deg,rgba(0, 138, 252, 1) 40%,rgba(81, 169, 241, 1) 100%);"
              >
                <strong>Não achei minha empresa</strong>
                <br>
                Clique aqui e convide o produtor para realizar o cadastro da empresa na plataforma
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        lg="3"
        v-if="controller.option == 1"
      >
        <v-form
          ref="formRegistration"
          v-if="controller.newAccount"
        >
          <v-text-field
            label="E-mail"
            filled
            v-model="controller.account.email"
            dense
            placeholder="@email"
            :rules="controller.emailRuler"
          ></v-text-field>
          <v-text-field
            label="Login"
            :rules="[(v) => !!v || 'Preencha o campo']"
            filled
            v-model="controller.account.login"
            dense
            placeholder="login..."
          ></v-text-field>
          <v-text-field
            label="Seu nome"
            :rules="[(v) => !!v || 'Preencha o campo']"
            filled
            v-model="controller.account.name"
            dense
            placeholder="nome..."
          ></v-text-field>
          <v-text-field
            label="Sua senha"
            filled
            v-model="controller.account.password"
            :rules="[(v) => !!v || 'Preencha o campo', (controller.account.password === controller.account.confirmationPassword) || 'As senhas não conferem']"
            dense
            type="password"
            placeholder="Senha"
          ></v-text-field>
          <v-text-field
            label="Confirme sua senha"
            filled
            dense
            v-model="controller.account.confirmationPassword"
            :rules="[(v) => !!v || 'Preencha o campo', (controller.account.password === controller.account.confirmationPassword) || 'As senhas não conferem']"
            type="password"
            placeholder="Confirmação da senha"
          ></v-text-field>
          <v-btn
            elevation="0"
            @click="controller.create()"
            class="mt-5"
            color="primary"
            style="background: linear-gradient(345deg,rgba(0, 138, 252, 1) 40%,rgba(81, 169, 241, 1) 100%);"
            block
            height="50"
            :loading="controller.loading"
          >cadastrar</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { newAccountController } from "../../../di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: newAccountController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
  },
};
</script>

<style scoped>
.circle {
  height: 120vh;
  left: -100px;
  background: linear-gradient(
    345deg,
    rgba(0, 138, 252, 1) 10%,
    rgba(81, 169, 241, 1) 100%
  );
  -moz-border-radius: 0 30% 30% 0;
  -webkit-border-radius: 0 30% 30% 0;
  border-radius: 0 30% 30% 0;
  position: absolute;
  transform: rotate(10deg);
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(0, 138, 252, 1)),
    color-stop(0.72, rgb(81, 169, 241, 1))
  );
}
</style>
