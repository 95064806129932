import { PumpPagination } from '../../domain/model/pump_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchPumpRepository {
    (pagination: Pagination): Promise<PumpPagination>
}

const fetchPumpRepository = (axios: AxiosInstance): FetchPumpRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/pump', {
            params: pagination
        })

        const pumpPagination: PumpPagination = response.data
        return pumpPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchPumpRepository