


<template>
  <div class="mb-2 root background_screen">
    
    <v-form
      ref="filter"
      class="mx-2"
    >
      <v-toolbar
        flat
        class="my-3 background_screen"
      >
        
        <v-toolbar-title> Acionamento de Conjuntos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center justify-space-around mx-2">
          
          <v-autocomplete
            v-model="controller.selectedPlace"
            :items="controller.placePagination.items"
            item-text="name"
            item-value="id"
            dense
            filled
            class="pt-6 pl-2"
            label="Selecione o Levante"
            style="max-width: 14rem"
            return-object
            @change="controller.changePlace($event)"
            single-line
          ></v-autocomplete>
          <div class="ml-2">
            <v-btn
              height="38"
              color="primary"
              class="mt-0"
              @click="toggleStatusForSelectedItems" v-if="selectedItems.length !== 0"
            >
              <v-icon left> mdi-swap-horizontal </v-icon>
              Alterar Estado
            </v-btn>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      
    </v-form>
    
    
      <div v-if="!controller.selectedPlace.name || controller.pumpMotoPagination.items.length === 0" class="warning-message">
        <v-alert class="text-lg">
          <v-img class="img-center"
            src="/atencao.png"
            max-height="150"
            max-width="150"
          ></v-img>
          {{ !controller.selectedPlace.name ? 'Selecione um levante para visualizar os conjuntos.' : 'Não foram encontrados conjuntos para o levante selecionado.' }}
        </v-alert>
      </div>
     
      
        <div v-if="controller.selectedPlace.name" class="row  mt-3 mb-n4 pl-8 pt-5" :class="$vuetify.theme.isDark ? 'background_screen'  : 'white-bg'">    
          <div class="text-container">
            <span>Acionamentos</span>
          </div>
          <div class="text-container">
            <v-icon class="mr-2">mdi-chevron-right</v-icon>
            <span>{{ controller.selectedPlace.name }}</span>
          </div>     
        </div>
    
      <v-row  class="mt-4 pa-4" :class="$vuetify.theme.isDark ? 'background_screen'  : 'white-bg'">
        
        
        <v-col v-for="(item, index) in controller.pumpMotoPagination.items" :key="index" cols="12" sm="6" md="4" lg="3">
          <div class="card" :class="{
            'border-grey': item.loading,
            'border-yellow': !item.loading && !conection(item),
            'border-green': !item.loading && conection(item) && on(item),
            'border-red': !item.loading && conection(item) && !on(item),
            'white-bg': !$vuetify.theme.isDark,
            'gray-bg': $vuetify.theme.isDark


          } ">
          
      
          <v-row align="start">
            <v-col cols="1">
              <v-checkbox v-model="item.checkbox" @change="toggleItemSelected(item)" class="mt-2 mr-0"></v-checkbox>
            </v-col>
            <v-col cols="1">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mt-4 ml-0 icon-size">mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  Para realizar um acionamento em conjunto, marque a caixa de seleção 
                  <br/>
                  em cada conjunto desejado para alterar seu estado de ativação para ligado ou desligado.
                </span>
              </v-tooltip>
            </v-col>
            
            <v-col cols="8">
              <div class="card__title mt-3">{{ computedEngineName(item.EngineID) }} / {{ computedPumpName(item.pumpID) }}</div>
            </v-col>
          </v-row>
          <div class="card__subtitle">
            <div class="item">
              {{ item.remoteControl ? 'Remoto' : 'Local' }}
              <v-icon color="green">mdi-check-circle</v-icon>
            </div>
            <div class="item">
              {{ on(item)  ? 'Ligado' : 'Desligado' }}
              <v-icon :color="on(item) ? 'green' : 'red'"> {{ on(item) ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
            </div>

          </div>
          <div class="card__content">
            <v-img src="https://img.freepik.com/premium-vector/water-pump-machine-icon-logo-vector-design-template_827767-2266.jpg" alt=""/>
          </div>
          <div class="card__actions">
      
            <v-btn
                outlined
                :disabled="item.loading || item.isButtonDisabled"
                @click="toggleStatus(item)"
              >
                <template v-if="item.loading ">
                  <v-icon class="rotate" color="grey">mdi-loading</v-icon>{{ on(item) ? 'Desligando...' : 'Ligando...'}}
                </template>
                <template v-else>
                  {{ item.Status === 0 ? 'Ligar' : 'Desligar' }}
                </template>
              </v-btn>

          </div>
          <div class="card__notification">
            <div class="notify" v-if="!conection(item)">
              <v-icon color="yellow">mdi-alert</v-icon>
              Falha na comunicação
            </div>
            <div class="notify" v-else>
              <v-icon color="green">mdi-check</v-icon>
              Comunicação OK
            </div>
          </div>
        </div>
        </v-col>
      </v-row>    
  </div>
  
  
</template>

<script>
const defaultParams = {
  sort: 2,
  descending: false,
  page: 1,
  itemsPerPage: 40,
  search: '',
  status: 0
}
const AllParams = {
  sort: 2,
  descending: false,
  page: 1,
  itemsPerPage: 1000,
  search: '',
  status: 0
}
const MockDriveItems = [
  
]
import { axosInstance } from "@/di/axios_instance_di";
import DriveCard from '../components/DriveCard.vue'
import { remoteControlController } from "@/di/di";
import _ from "lodash";

export default {
  components: {
    DriveCard
  },
  data: (context) => ({
    controller: remoteControlController(context),
    activationModal: false,
    pumpMoto: [],
    engines: [],
    pumps: [],
    mocked: MockDriveItems,
    delayedStatus: '',
    selectAll: false,

  }),
  methods: {
    async fetchPumpMoto(params) {

      await this.fetchPumps(AllParams);
      await this.fetchEngines(AllParams);

    },
    
    async fetchEngines(params) {
      try {
        const { data } = await axosInstance.get('/engine', {
          params
        })
        
        this.engines = data.items
        
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPumps(params) {
      try {
        const { data } = await axosInstance.get('/pump', {
          params
        })
        this.pumps = data.items
      } catch (error) {
        console.log(error)
      }
    },

    async updatePumpMoto(id, state) {
      
      
      const response = await axosInstance.put(`/remote-control/${id}`, {
        inUseSet: state
      })
    },
    conection(item) {
      return item.withPump;
    },
    on(item) {

     
      return item.Status === 1;
     
    },
    toggleSelectAll() {

      this.controller.pumpMotoPagination.items.forEach(item => {
        item.checkbox = this.selectAll;
      });
    },

    async toggleStatus(item) {
      this.controller.pumpMotoPagination.items.forEach(i => i.isButtonDisabled = true);

      const modifiedItem = { ...item, companyID: item.CompanyID };
      await this.controller.change(modifiedItem);
      this.$set(item, 'loading', true);
      const success = await this.controller.create();

      this.controller.pumpMotoPagination.items.forEach(i => i.isButtonDisabled = false);

      if (!success) {
        this.$set(item, 'withPump', false);
        this.$set(item, 'loading', false);
        return;
      } else {
        
        this.$set(item, 'loading', true);
        setTimeout(() => {
          this.controller.paginate()
          this.$set(item, 'loading', false);
          this.$set(item, 'remoteControl', true);
        }, 10000);
        this.$set(item, 'withPump', true);
      }

      this.controller.paginate()
      
    },

    computedPumpName(id) {
      
        const pump = this.pumps.find(pump => pump.id === id);
        return pump ? pump.name : '';
      
    },
    computedEngineName(id) {
     
        const engine = this.engines.find(engine => engine.id === id);
        return engine ? engine.name : '';
      
    },
    toggleSelectAll() {
      
      this.controller.pumpMotoPagination.items.forEach(item => {
        item.checkbox = this.selectAll;
      });
    },
    toggleItemSelected(item) {
      
      //this.selectAll = this.controller.pumpMotoPagination.items.every(item => item.checkbox);
      this.controller.pumpMotoPagination.items.forEach((i) => {
        this.$set(i, 'isButtonDisabled', true);
      });
      if(this.selectedItems.length === 0) {
        this.controller.pumpMotoPagination.items.forEach((i) => {
          this.$set(i, 'isButtonDisabled', false);
        });
      }
    },
    async toggleStatusForSelectedItems() {

      this.selectedItems.forEach(item => {
        this.$set(item, 'loading', true);

      }); 

      this.controller.multichange(this.controller.pumpMotoPagination.items[0]);

      const success = await this.controller.create();

      this.selectedItems.forEach(item => {
        

        if (!success) {

          this.$set(item, 'withPump', false);
          this.$set(item, 'loading', false);
          return;
        } else {
          
          this.$set(item, 'loading', true);
          setTimeout(() => {
            this.controller.paginate()
            this.$set(item, 'loading', false);
            this.$set(item, 'remoteControl', true);
            
          }, 10000);

          this.$set(item, 'withPump', true);
        }
      });
      
      

      this.controller.pumpMotoPagination.items.forEach((i) => {
          this.$set(i, 'isButtonDisabled', false);
      });
      this.selectedItems.forEach(item => {
        
        item.checkbox = false;
      });

    },
    
  },
  
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsPlace"() {
      this.controller.watchOptionsPlace();
    },
    "controller.searchPlace": _.debounce(function () {
      this.controller.watchSearchPlace();
    }, 500),
    "controller.searchTracking": _.debounce(function () {
      this.controller.watchSearchTracking();
    }, 500),
    "controller.optionsTracking"() {
      this.controller.watchOptionsTracking();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    'selectedEngine.id': function (newVal) {
      // Atualize a propriedade do controlador quando o id selecionado mudar
      this.controller.pumpMoto.EngineID = newVal;
    },
    
    
  },
  computed: {
    selectedItems() {
      return this.controller.pumpMotoPagination.items.filter(item => item.checkbox);
    },
    
  },

  async mounted() {

    await this.fetchPumpMoto(defaultParams);
    this.controller.paginate();   
  }
}
</script>
<style>
.card {
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 16px;
}
.card__subtitle {
  display: flex;
  justify-content: space-between;
}
.card__actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.card__notification {
  margin-top: 10px;
  border-top: 2px solid rgb(69, 69, 69);
  display: flex;
  justify-content: center;
}

.card__notification .notify {
  display: flex;
  align-items: center;
  padding-top: 14px;
}


.border-green{
  border: 4px solid green;
}
.border-red{
  border: 4px solid red;
}
.border-yellow{
  border: 4px solid rgb(250, 206, 45);
}

.white-bg {
  background-color: white !important;
}
.gray-bg {
  background-color: gray !important;
}
.icon-size {
  font-size: 15px !important; 
}
.text-container {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.text-container span {
  margin-right: 8px;
}

.text-container v-icon {
  font-size: 20px;
  color: #3498db; /* ou a cor desejada */
}
.rotate {
  animation: rotate 1s infinite linear;
}
.v-tooltip__content {
    background-color: #1b1b1b;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    border-radius: 8px;
}
.warning-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: red;
  font-size: 0.625rem;
}
.img-center {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.text-lg {
  font-size: 1.5rem; 
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>