export const headersTracking = [
  {
    text: 'Data criação',
    value: 'createdDate'
  },
  {
    text: 'Nivel bateria',
    value: 'batery'
  },
  {
    text: 'Nível',
    value: 'level'
  },
  {
    text: 'Temperatura',
    value: 'temperature'
  },
  {
    text: 'Coeficiente',
    value: 'coefficient'
  },
  {
    text: 'Empresa',
    value: 'companyName'
  },
  {
    text: 'Usuário',
    value: 'accountName'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'end'
  }
]

