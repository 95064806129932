import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PumpMoto } from '../../domain/model/pump_moto';

export interface CreatePumpMotoRepository {
  (data: object): Promise<PumpMoto>
}

const createPumpMotoRepository = (axios: AxiosInstance): CreatePumpMotoRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/pump-moto`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createPumpMotoRepository

