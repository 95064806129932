export const headers = [
  {
    text: 'ID',
    align: 'start',
    sortable: true,
    value: 'id',
  },
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Local',
    align: 'start',
    sortable: true,
    value: 'placeName',
  },
  {
    text: 'Empresa',
    align: 'start',
    sortable: true,
    value: 'companyName',
  },
  {
    text: 'Status',
    align: 'start',
    sortable: true,
    value: 'status',
  },
  {
    text: 'Favorito',
    align: 'center',
    sortable: true,
    value: 'isFavorite',
    custom: true
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }

]
