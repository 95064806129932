import { MarkRulerPagination } from './../../../rulerMark/domain/model/markRuler_pagination';
import { FetchRulerTrackingByIDRepository } from './../../data/repository/fetch_ruler_tracking_by_id_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '@/module/rulerMark/domain/model/markRuler';

export interface FetchRulerTrackingByIDUseCase {
    (id: number, pagination: DataOptions, search: string): Promise<MarkRulerPagination>
}

const fetchRulerTrackingByIDUseCase = (repository: FetchRulerTrackingByIDRepository): FetchRulerTrackingByIDUseCase => async (id: number, pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        
        return await repository(id, payload);
    } catch (error) {
        throw error
    }
}

export default fetchRulerTrackingByIDUseCase;