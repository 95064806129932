export interface HardwareVersion {
  id: number;
  version: string;
  description: string;
  binaryPath: string;
  createdDate: string;
}

export const hardwareVersionDefault = () => {
  const hardware: HardwareVersion = {
    id: 0,
    version: "",
    description: "",
    binaryPath: "",
    createdDate: "",
  }
  return hardware
}

const en: any = {
  id: 1,
  version: 2,
  description: 3,
  binaryPath: 4,
  createdDate: 5
};
export { en };
