export const headers = [
  {
    text: 'ID',
    align: 'start',
    sortable:true,
    value: 'id'
  },
  {
    text: 'Levante',
    align: 'start',
    sortable:true,
    value: 'placeID'
  },
  {
    text: 'Empresa',
    align: 'start',
    sortable:true,
    value: 'CompanyID'
  },
  {
    text: 'Motor (ID)',
    align: 'start',
    sortable:true,
    value: 'EngineID'
  },
  {
    text: 'Bomba (ID)',
    align: 'start',
    sortable:true,
    value: 'pumpID'
  },
  {
    text: 'Status',
    align: 'start',
    sortable:true,
    value: 'Status'
  },
  {
    text: 'Controlado Remotamente',
    align: 'start',
    sortable:true,
    value: 'remoteControl'
  },
  {
    text: 'Monitoramento de Energia',
    align: 'start',
    sortable:true,
    value: 'energyMonitoring'
  },
  {
    text: 'Em Uso',
    align: 'start',
    sortable:true,
    value: 'inUseSet'
  },
  {
    text: 'Ações',
    align: 'start',
    sortable:true,
    value: 'actions'
  }

]
