import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { RulerGroup } from '../../domain/model/rulerGroup';

export interface ChangeRulerGroupRepository {
  (id: number, data: object): Promise<RulerGroup>
}

const changeRulerGroupRepository = (axios: AxiosInstance): ChangeRulerGroupRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/group/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeRulerGroupRepository

