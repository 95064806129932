export interface Ruler {
  id: number;
  coefficient: number;
  color: string;
  companyID: number;
  companyName: string
  name: string;
  higherLevel: number;
  lowerLevel: number;
  latitude: string
  longitude: string
  terminalNumber: string;
  divisionFactor: number;
  status: number;
}

export const rulerDefault = () => {
  const ruler: Ruler = {
    id: 0,
    coefficient: 0,
    color: "",
    companyID: 0,
    companyName: "",
    name: "",
    higherLevel: 0,
    lowerLevel: 0,
    latitude: "",
    longitude: "",
    terminalNumber: "",
    divisionFactor: 0,
    status: 1
  }
  return ruler
}

const en: any = {
  id: 1,
  coefficient: 5,
  color: 4,
  companyID: 3,
  companyName: 14,
  name: 2,
  higherLevel: 8,
  lowerLevel: 9,
  latitude: 3,
  longitude: 4,
  terminalNumber: 6,
  divisionFactor: 10,
  status: 11,
  isFavorite: 13
}
export { en };

