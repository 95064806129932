export interface MarkRuler {
  id: number;
  batery: number;
  companyID: number;
  companyName: string;
  level: number;
  rulerID: number;
  temperature: number;
  rulerName: string;
  rulerColor: string;
  createdDate: string;
  coefficient: number;
  levelPerCoeficient: number;
}


const en: any = {
  rulerID: 2,
  level: 3,
  temperature: 4,
  batery: 5,
  createdDate: 6,
  companyID: 7,
  coefficient: 8,
  companyName: 9,
};
export { en };


