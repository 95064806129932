import { FetchNotificationByRulerIDRepository } from '../../data/repository/fetch_notification_by_ruler_id_repository';
import { NotificationPagination } from '../model/notification_pagination';
import { PaginationNotification } from "@/core/domain/model/pagination";
import { en } from '../model/notification';
import { DataOptions } from "vuetify";

export interface FetchNotificationByRulerIDUseCase {
    (pagination: DataOptions, search: string, startDate: string, endDate: string, rulerId: number): Promise<NotificationPagination>
}

const fetchNotificationByRulerIDUseCase = (repository: FetchNotificationByRulerIDRepository): FetchNotificationByRulerIDUseCase => async (pagination: DataOptions, search: string, startDate: string, endDate: string, rulerId: number) => {
    try {
        const payload: PaginationNotification = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
            startDate: startDate,
            endDate: endDate,   
        }
        return await repository(payload, rulerId);
    } catch (error) {
        throw error
    }
}

export default fetchNotificationByRulerIDUseCase;