import { MarkPumpPagination } from '../../domain/model/markPump_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchMarkPumpRepository {
    (pagination: Pagination, id: number): Promise<MarkPumpPagination>
}

const fetchMarkPumpRepository = (axios: AxiosInstance): FetchMarkPumpRepository => async (pagination: Pagination, id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/pump/${id}/tracking`, {
            params: pagination
        })

        const pumpPagination: MarkPumpPagination = response.data
        return pumpPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchMarkPumpRepository