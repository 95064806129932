import { FetchScreenCompanyUseCase } from './../domain/usecase/fetch_screen_company_usecase';
import { ChangeScreenCompanyUseCase } from './../domain/usecase/change_screen_company_usecase';
import { FetchScreensUseCase } from './../domain/usecase/fetch_screens_usecase';
import { defaultPagination, defaultModelPagination } from '../../../core/domain/model/pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { Account, accountDefault } from '@/core/domain/model/account';
import { getAccountGroupID } from '@/core/domain/model/jwtAuthToken';
import { snackbar } from '@/core/controller/snackbar_controller';
import { DataOptions } from 'vuetify';
class ScreenController {
  public context: any;
  public dialog: boolean = false;
  public searchCompany: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public companyPagination: CompanyPagination = defaultModelPagination()
  public optionsCompany: DataOptions = defaultPagination({ itemsPerPage: 10, sortBy: ["name"] })

  private fetchScreensUsecase: FetchScreensUseCase
  private fetchCompanyUsecase: FetchCompanyUseCase
  private changeScreenCompanyUseCase: ChangeScreenCompanyUseCase
  private fetchScreenCompanyUsecase: FetchScreenCompanyUseCase
  public screens: Screen[] = []
  public screensOfCompany: number[] = []
  private companyID: number = 0

  constructor(
    context: any,
    fetchScreensUsecase: FetchScreensUseCase,
    changeScreenCompanyUseCase: ChangeScreenCompanyUseCase,
    fetchCompanyUsecase: FetchCompanyUseCase,
    fetchScreenCompanyUsecase: FetchScreenCompanyUseCase
  ) {
    this.context = context
    this.fetchScreensUsecase = fetchScreensUsecase
    this.changeScreenCompanyUseCase = changeScreenCompanyUseCase
    this.fetchCompanyUsecase = fetchCompanyUsecase
    this.fetchScreenCompanyUsecase = fetchScreenCompanyUsecase
  }

  async mounted() {
    this.companyPagination = await this.fetchCompanyUsecase(this.optionsCompany, "")
    this.screens = await this.fetchScreensUsecase()
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUsecase(this.optionsCompany, this.searchCompany)
      } catch (error) {
        console.log(error);
      }
    }
  }

  async changeCompany(id: number) {
    try {
      this.companyID = id
      this.screensOfCompany = await this.fetchScreenCompanyUsecase(id)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }

  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  async saveScreens() {
    try {
      await this.changeScreenCompanyUseCase(this.companyID, this.screensOfCompany)
      snackbar.show({ message: "Dados salvos com sucesso!", color: "green" })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }
}

export default ScreenController