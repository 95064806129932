import { AlertTypePagination } from '../model/alert_type_pagination';
import { FetchAlertTypeRepository } from '../../data/repository/fetch_alert_type_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/alert';

export interface FetchAlertTypeUseCase {
    (pagination: DataOptions, search: string): Promise<AlertTypePagination>
}

const fetchAlertTypeUseCase = (repository: FetchAlertTypeRepository): FetchAlertTypeUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchAlertTypeUseCase;