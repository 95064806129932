export interface AlertType {
  id: number
  name: string
  companyID: number
  companyName: string
}

export const alertTypeDefault = (): AlertType => {
  return {
    id: 0,
    name: "",
    companyID: 0,
    companyName: "",
  }
}


const en: any = {
  id: 1,
  name: 2,
  companyID: 3,
  companyName: 4
}
export { en };


