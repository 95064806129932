import { RulerGroupPagination } from '../model/rulerGroup_pagination';
import { FetchRulerGroupRepository } from '../../data/repository/fetch_rulerGroup_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/rulerGroup';

export interface FetchRulerGroupUseCase {
    (pagination: DataOptions, search: string, status: number): Promise<RulerGroupPagination>
}

const fetchRulerGroupUseCase = (repository: FetchRulerGroupRepository): FetchRulerGroupUseCase => async (pagination: DataOptions, search: string, status: number) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
            status: status
        }
        
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchRulerGroupUseCase;