import { HarvestPagination } from './../../harvest/domain/model/harvest_pagination';
import { FetchRulerUseCase } from '@/module/ruler/domain/usecase/fetch_ruler_usecase';
import { CreateRulerUseCase } from '@/module/ruler/domain/usecase/create_ruler_usecase';
import { ChangeRulerUseCase } from '@/module/ruler/domain/usecase/change_ruler_usecase';
import { FetchMarkRulerUseCase } from '@/module/rulerMark/domain/usecase/fetch_markRuler_usecase';
import { RulerPagination } from '@/module/ruler/domain/model/ruler_pagination';
import { MarkRulerPagination } from '../domain/model/markRuler_pagination'
import { DataOptions } from 'vuetify';
import { Ruler, rulerDefault } from '@/module/ruler/domain/model/ruler';
import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import { snackbar } from '@/core/controller/snackbar_controller';
import * as echarts from 'echarts';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import dayjs from "dayjs";
import { Harvest, harvestDefault } from '@/module/harvest/domain/model/harvest';
import { FetchHarvestUseCase } from '@/module/harvest/domain/usecase/fetch_harvest_usecase';

class RulerMarkController {
  public context: any;
  public contextDrawer: any;
  public contextFormRuler: any;
  public search: string = ""
  public loading: boolean = false
  public loadingBtn: boolean = false
  public dialog: boolean = false
  public rightDrawer: boolean = false
  public calendarMenuIn: boolean = false
  public calendarMenuUntil: boolean = false
  public calendarMenuDialogIn: boolean = false
  public calendarMenuDialogUntil: boolean = false
  public calendarIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendarUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public searchCompany: string = ""
  public dateMark: any = []
  public levelMark: any = []
  public marks: any = []
  public marksChart: any = []
  public maxLevel: any = 15

  public harvestPagination: HarvestPagination = {
    items: [],
    total: 0
  }

  public harvestOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["startDate", "endDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true, true]
  }

  public selectedHarvest: Harvest = harvestDefault()


  // positiveRuler = (v) => {
  //   if (!isNaN(parseFloat(v)) && v > -999999999 && v < 999999999) return true;
  //   return "Preencha o campo!";
  // };


  public rulerPagination: RulerPagination = {
    items: [],
    total: 0
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["isFavorite"],
    groupDesc: [false],
    multiSort: false,
    mustSort: true,
    sortDesc: []
  }
  public markRulerPagination: MarkRulerPagination = {
    total: 0,
    items: []
  }
  public markRulerOptions: DataOptions = {
    page: 1,
    itemsPerPage: 48,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }

  public ruler: Ruler = rulerDefault()

  public selectedRuler: Ruler = rulerDefault()

  private rulerChart: echarts.ECharts | null = null

  private fetchRulerUseCase: FetchRulerUseCase
  private fetchMarkRulerUseCase: FetchMarkRulerUseCase
  private changeRulerUseCase: ChangeRulerUseCase
  private fetchHarvestUseCaseImpl: FetchHarvestUseCase

  constructor(
    context: any,
    fetchRulerUseCase: FetchRulerUseCase,
    fetchMarkRulerUseCase: FetchMarkRulerUseCase,
    changeRulerUseCase: ChangeRulerUseCase,
    fetchHarvestUseCaseImpl: FetchHarvestUseCase
  ) {
    this.context = context
    this.fetchRulerUseCase = fetchRulerUseCase
    this.fetchMarkRulerUseCase = fetchMarkRulerUseCase
    this.changeRulerUseCase = changeRulerUseCase
    this.fetchHarvestUseCaseImpl = fetchHarvestUseCaseImpl
  }


  async mounted() {
    this.loading = true
    this.maxLevel = localStorage.getItem('rulerMaxLevel') ? localStorage.getItem('rulerMaxLevel') : 15;
    //localStorage.setItem("mytime", Date.now());

    //this.maxLevel = localStorage.getItem("rulerMaxLevel") : 15;
    try {
      this.rulerPagination = await this.fetchRulerUseCase(this.options, this.search, 1) //actives
      this.harvestPagination = await this.fetchHarvestUseCaseImpl(this.harvestOptions, "")
      this.selectedHarvest = this.harvestPagination.items.filter((harvest) => harvest.isFavorite)[0] || this.harvestPagination.items?.[0]
      //this.selectedHarvest = this.harvestPagination.items?.[0] ?? harvestDefault()
      this.changeHarvest(this.selectedHarvest)
      this.selectedRuler = this.rulerPagination.items[0]
      if (this.selectedRuler != null) {
        this.calendarUntil = dayjs().format("YYYY-MM-DD")
        this.filter()
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }


    window.addEventListener('resize', () => {
      if (this.rulerChart) {
        this.rulerChart.resize();
      }
    })
  }

  async filter() {
    this.marks = []
    this.marksChart = []
    this.dialog = false
    this.markRulerOptions.page = 1
    if (this.context.$refs.filter.validate()) {
      this.loadingBtn = true
      try {
        this.markRulerOptions.itemsPerPage = this.context.$vuetify.breakpoint.mobile ? 10 : 48
        this.markRulerPagination = await this.fetchMarkRulerUseCase(this.markRulerOptions, this.selectedRuler.id, this.calendarIn, this.calendarUntil)
        for (let el of this.markRulerPagination.items) {
          this.marks.push(el)
          this.marksChart.push(el)
        }
        this.marksChart.reverse()
        this.createRulerChart()
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false
      }
    }
  }

  async change() {
    if (this.contextDrawer.$refs.rightDrawer.validate()) {
      localStorage.setItem('rulerMaxLevel', this.maxLevel)
      try {
        await this.changeRulerUseCase(this.selectedRuler.id, this.selectedRuler)
        snackbar.show({ message: "Alterado com sucesso!", color: 'green', timeout: 1000 })
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.rightDrawer = false
        this.filter()
      }
    }
  }



  createRulerChart() {
    if (this.selectedRuler) {
      const higher = this.selectedRuler.higherLevel != null ? this.selectedRuler.higherLevel : 0
      const lower = this.selectedRuler.lowerLevel != null ? this.selectedRuler.lowerLevel : 0

      var chartDom = document.getElementById('ruler_chart');
      if (chartDom != null) {
        this.rulerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');

        const marcacao = this.marksChart.map((mark: MarkRuler, i: number) => [
          {
            symbol: 'none',
            name: '', xAxis: i, yAxis: 0, lineStyle: {
              normal: {
                type: 'solid',
                color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
              },

            },
          },
          {
            symbol: 'none',
            name: '', xAxis: i, yAxis: mark.level, lineStyle: {
              normal: {
                type: 'solid',
                color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
              },

            },
          },
        ])

        if (!this.context.$vuetify.breakpoint.mobile) {
          marcacao.push({
            type: 'average',
            name: 'Média',
            label: {
              formatter: '{b}: {c}',
              position: 'end',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
              fontWeight: 'bold',
              fontSize: 12,
              fontFamily: 'Helvetica',
            },

          })
        }


        marcacao.push({
          label: {
            formatter: '{b}',
            position: 'insideEndTop',
            color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Helvetica',
          },
          name: `Nível máximo: ${higher}`,
          yAxis: higher,
          type: 'value',
          lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            }
          },
        })
        marcacao.push({
          label: {
            formatter: '{b}',
            position: 'insideEndTop',
            color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Helvetica',
          },
          name: `Nível mínimo: ${lower}`,
          yAxis: lower,
          type: 'value',
          lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            }
          },
        })
        let option = {
          title: {
            text: this.selectedRuler.name,
            left: "center",
            top: "top",

            textStyle: {
              fontSize: 16,
              fontWeight: 'normal',
            },
            padding: [0, 0, 20, 0]
          },
          grid: {
            left: 40,
            top: 20,
            right: !this.context.$vuetify.breakpoint.mobile ? 80 : 40,
            bottom: 25,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: (params: any) => {
              return (
                "Nivel: " + params[0].value +
                '<br/>' +

                "Data: " + params[0].name
              );
            },
          },
          xAxis: {
            type: 'category',
            data: this.marksChart.map((mark: MarkRuler) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
            boundaryGap: true,
            axisLabel: {
              interval: this.marksChart.length - 2
            }
            //axisLabel: { show: false, interval: 0, fontSize: 10, rotate: 90 }
          },
          yAxis: {
            type: 'value',
            //max: this.maxLevel
          },
          /*           dataZoom: [
                                 {
                                    show: true,
                                    realtime: true,
                                }, 
                      {
                        type: 'inside',
                        realtime: true,
                      }
                    ], */
          series: [{
            areaStyle: {},
            data: this.marksChart.map((mark: MarkRuler) => mark.level.toFixed(2)) ?? [],
            type: 'line',
            itemStyle: {
              color: this.selectedRuler.color ?? '#8094f7'
            },
            markLine: {
              data:

                marcacao,

              /*{
                type: 'average',
                name: 'Média',
                label: {
                  formatter: '{b}: {c}',
                  position: 'end',
                  color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                  fontWeight: 'bold',
                  fontSize: 12,
                  fontFamily: 'Helvetica',
                },

              },
              {
                label: {
                  formatter: '{b}',
                  position: 'insideEndTop',
                  color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                  fontWeight: 'bold',
                  fontSize: 16,
                  fontFamily: 'Helvetica',
                },
                name: `Nível máximo: ${higher}`,
                yAxis: higher,
                type: 'value',
                lineStyle: {
                  normal: {
                    type: 'solid',
                    color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                  }
                },
              },
              {
                label: {
                  formatter: '{b}',
                  position: 'insideEndTop',
                  color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                  fontWeight: 'bold',
                  fontSize: 16,
                  fontFamily: 'Helvetica',
                },
                name: `Nível mínimo: ${lower}`,
                yAxis: lower,
                type: 'value',
                lineStyle: {
                  normal: {
                    type: 'solid',
                    color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                  }
                },
              },*/


            },
          }]
        };

        option && this.rulerChart.setOption(option);
      }
    }
  }


  async paginateMarks() {
    try {
      this.markRulerPagination = await this.fetchMarkRulerUseCase(this.markRulerOptions, this.selectedRuler.id, this.calendarIn, this.calendarUntil)
      for (let el of this.markRulerPagination.items) {
        this.marks.push(el)
      }

      //this.createRulerChart()
    } catch (error) {
      console.log(error);
    }
  }

  closeDialog() {
    this.dialog = false
    this.rightDrawer = true
  }

  async changeHarvest(data: Harvest) {
    this.calendarIn = data.startDate
    this.calendarUntil = data.endDate
  }


}

export default RulerMarkController