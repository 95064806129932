import { MarkPump } from '@/module/pumpMark/domain/model/markPump'
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { MarkPumpPagination } from '@/module/pumpMark/domain/model/markPump_pagination';
import Handler from '@/core/domain/usecase/handler';

export interface UpdatePumpTrackingLevelRepository {
    (pumpTracking: MarkPump): Promise<MarkPumpPagination>
}

const updatePumpTrackingLevelRepository = (axios: AxiosInstance): UpdatePumpTrackingLevelRepository => async (pumpTracking: MarkPump) => {
    try {
        const response: AxiosResponse = await axios.put(`/pump-tracking/${pumpTracking.id}`,
            pumpTracking
        )

        const rulerPagination: MarkPumpPagination = response.data
        return rulerPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default updatePumpTrackingLevelRepository