import { MarkRulerPagination } from '../../domain/model/markRuler_pagination';
import { PaginationDate } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchMarkRulerWith30MinutesRepository {
    (pagination: PaginationDate, id: number): Promise<MarkRulerPagination>
}

const fetchMarkRulerWith30MinutesRepository = (axios: AxiosInstance): FetchMarkRulerWith30MinutesRepository => async (pagination: PaginationDate, id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/ruler/${id}/tracking/30-minutes`, {
            params: pagination,
        })

        const rulerPagination: MarkRulerPagination = response.data
        return rulerPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchMarkRulerWith30MinutesRepository