import { ChangeRemoteControlRepository } from '../../data/repository/change_remote_control_repository';
import { PumpMoto } from '../model/pump_moto';

export interface ChangeRemoteControlUseCase {
    (data: object): Promise<PumpMoto>
}

const changeRemoteControlUseCase = (repository: ChangeRemoteControlRepository): ChangeRemoteControlUseCase => async (data: Object) => {
    try {
        
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default changeRemoteControlUseCase;

