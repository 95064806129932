<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.alertPagination.total"
      :items="controller.alertPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
      :sort-by="['createdDate']"
      :sort-desc="[true]"
    >
      <template v-slot:[`item.currentStatus`]="{ item }">
        {{ item.currentStatus === 1 ? 'Aberto' : item.currentStatus === 2 ? 'Encaminhado' : 'Finalizado'  }}
      </template>

      <template v-slot:[`item.createdDate`]="{ item }">
        {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm")  }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ dayjs(item.updatedAt).format("DD/MM/YYYY HH:mm")  }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.viewHistory(item.history)"
            class="px-2"
          >
            <v-icon
              small
              color="purple"
            >mdi-eye</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="controller.share(item, 1)"
            class="px-2"
            v-if="isClientAdmin() && item.currentStatus < 3"
          >
            <v-icon
              small
              color="orange"
            >mdi-share</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="controller.share(item, 2)"
            class="px-2"
            v-if="isClientAdmin() && item.currentStatus < 3"
          >
            <v-icon
              small
              color="green"
            >mdi-check</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Chamados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                v-if="hadPermission(2, 2)"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>

              <v-card-title class="pa-0">
                <v-toolbar
                  color="primary"
                  class="white--text"
                >Abrir novo chamado
                  <v-spacer></v-spacer>

                </v-toolbar>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text class="py-5">

                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-textarea
                    filled
                    dense
                    color="primary"
                    label="Descrição"
                    v-model="controller.alert.description"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-textarea>

                  <v-select
                    filled
                    dense
                    color="primary"
                    v-model="controller.alert.alertType"
                    label="Tipo do chamado"
                    :items="controller.alertTypes.items"
                    item-text="name"
                    item-value="id"
                  ></v-select>

                  <v-autocomplete
                    v-if="isAdmin()"
                    class="pt-4"
                    filled
                    dense
                    color="primary"
                    label="Empresa"
                    v-model="controller.alert.companyID"
                    :search-input.sync="controller.searchCompany"
                    :items="controller.companyPagination.items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-autocomplete>
                </v-form>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            scrollable
            persistent
            v-model="controller.dialogShare"
            max-width="500px"
          >
            <v-card>

              <v-card-title class="pa-0">
                <v-toolbar
                  color="primary"
                  class="white--text"
                >{{ controller.option === 1 ? 'Encaminhar' : 'Finalizar'  }} chamado
                  <v-spacer></v-spacer>

                </v-toolbar>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text class="py-5">

                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-textarea
                    filled
                    dense
                    color="primary"
                    label="Descrição"
                    v-model="controller.alert.description"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-textarea>

                  <v-autocomplete
                    v-if="controller.option === 1"
                    class="pt-4"
                    filled
                    dense
                    color="primary"
                    label="Usuário"
                    v-model="controller.alert.whoIs"
                    :search-input.sync="controller.searchAccount"
                    :items="controller.accountPagination.items"
                    item-text="name"
                    item-value="id"
                    clearable
                  >
                  </v-autocomplete>

                  <v-autocomplete
                    v-if="controller.option === 1"
                    class="pt-4"
                    filled
                    dense
                    color="primary"
                    label="Central de atendimento"
                    v-model="controller.alert.whoIsCallCenter"
                    :items="controller.callCenterPagination.items"
                    item-text="name"
                    item-value="id"
                    clearable
                  >
                  </v-autocomplete>
                </v-form>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            scrollable
            persistent
            v-model="controller.dialogHistory"
            max-width="700px"
          >
            <v-card>
              <v-card-title class="pa-0">
                <v-toolbar
                  color="primary"
                  class="white--text"
                >História do chamado
                  <v-spacer></v-spacer>
                  <v-icon
                    color="white"
                    @click="controller.close()"
                  >mdi-close</v-icon>
                </v-toolbar>
              </v-card-title>
              <v-card-text class="py-0">
                <v-timeline
                  align-top
                  dense
                >
                  <v-timeline-item
                    v-for="(hist, key) in controller.alertHistory"
                    :key="key"
                    color="primary"
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="4">
                        <strong>{{ dayjs(hist.createdDate).format('DD/MM/YYYY HH:mm') }}</strong>
                      </v-col>
                      <v-col>
                        <div class="d-flex flex-column">
                          <div v-if="hist.whoIsName">Usuário responsável: <strong>{{hist.whoIsName }}</strong></div>
                          <div v-if="hist.whoIsNameCallCenter">Central de atendimento: <strong>{{hist.whoIsNameCallCenter }}</strong></div>
                        </div>
                        <div class="text-caption primary--text">
                          {{ hist.status === 1 ? 'Aberto' : hist.status === 2 ? 'Encaminhado' : 'Finalizado'  }}
                        </div>
                        <v-card class="mt-5">
                          <v-card-text>
                            {{hist.description}}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-5"></v-divider>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { alertController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: alertController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.optionsAccount"() {
      this.controller.watchOptionsAccount();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.searchAccount": _.debounce(function () {
      this.controller.watchSearchAccount();
    }, 500),
  },
};
</script>