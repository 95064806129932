import { FetchScreenCompanyRepository } from './../../data/repository/fetch_screen__company_repository';

export interface FetchScreenCompanyUseCase {
    (id: number): Promise<number[]>
}

const fetchScreenCompanyUseCase = (repository: FetchScreenCompanyRepository): FetchScreenCompanyUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default fetchScreenCompanyUseCase;