import { FetchPlaceUseCase } from '@/module/place/domain/usecase/fetch_place_usecase';
import { FetchPumpMotoUseCase } from '../domain/usecase/fetch_pump_moto_usecase';
import { DeletePumpMotoUseCase } from '../domain/usecase/delete_pump_moto_usecase';
import { CreatePumpMotoUseCase } from '../domain/usecase/create_pump_moto_usecase';
import { ChangePumpMotoUseCase } from '../domain/usecase/change_pump_moto_usecase';
import { PumpMotoPagination } from '../domain/model/pump_moto_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { PumpMoto } from '../domain/model/pump_moto';
import { snackbar } from '@/core/controller/snackbar_controller';
import { PlacePagination } from '@/module/place/domain/model/place_pagination';
import { MarkPumpPagination } from '@/module/pumpMark/domain/model/markPump_pagination';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import * as XLSX from "xlsx";

class PumpMotoController {
  public context: any;
  public status: number = 0
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchPlace: string = ""
  public searchCompany: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public loadingLogs: boolean = false
  public groupBy: string = ""
  public menuGroup: boolean = false
  public dialogTracking: boolean = false
  public dialogPumpTracking: boolean = false
  public dialogRulerTrackingLevel: boolean = false
  public selectedPumpMoto: PumpMoto | null = null
  public selected: Array<MarkPump> = []
  public loadingBtnExcel : boolean = false
  public selectedMarkPump: MarkPump = {
    id: 0,
    accountID: 0,
    amperage: 0,
    capacitors: 0,
    createdDate: "",
    description: "",
    engines: 0,
    higherLevel: 0,
    hourMeter: 0,
    lowerLevel: 0,
    pumpID: 0,
    voltage: 0,
    power: 0
  }
  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus
  public pumpMotoPagination: PumpMotoPagination = {
    total: 0,
    items: []
  }
  public trackingPagination: MarkPumpPagination = {
    total: 0,
    items: []
  }
  public placePagination: PlacePagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsPlace: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsTracking: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public pumpMoto: PumpMoto = {
    id: 0, placeID: 0, name: "", EngineID: 0, EngineName: "", CompanyID: 0, isFavorite: false, remoteControl: false, energyMonitoring: false, inUseSet: false, Status: 0, CompanyName: "", placeName: ""
  }

  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchPumpMotoUseCase: FetchPumpMotoUseCase
  private deletePumpMotoUseCase: DeletePumpMotoUseCase
  private createPumpMotoUseCase: CreatePumpMotoUseCase
  private changePumpMotoUseCase: ChangePumpMotoUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(context: any, fetchPlaceUseCase: FetchPlaceUseCase, fetchPumpMotoUseCase: FetchPumpMotoUseCase, deletePumpMotoUseCase: DeletePumpMotoUseCase, createPumpMotoUseCase: CreatePumpMotoUseCase, changePumpMotoUseCase: ChangePumpMotoUseCase, fetchCompanyUseCase: FetchCompanyUseCase) {
    
    this.context = context
    
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchPumpMotoUseCase = fetchPumpMotoUseCase
    this.deletePumpMotoUseCase = deletePumpMotoUseCase
    this.createPumpMotoUseCase = createPumpMotoUseCase
    this.changePumpMotoUseCase = changePumpMotoUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  open() {
    this.flagOption = "create"
    //this.pump = { id: 0, placeID: 0, name: "", placeName: "" }
    this.paginatePlace()
    this.paginateCompany()
  }

  close(){

    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.pumpMotoPagination = await this.fetchPumpMotoUseCase(this.options, this.search, this.status)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  async paginatePlace() {
    if (this.searchPlace != null) {
      try {
        this.placePagination = await this.fetchPlaceUseCase(this.optionsPlace, this.searchPlace)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }



  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changePumpMotoUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }
  watchOptionsPlace() {
    this.paginatePlace()
  }

  watchSearchPlace() {
    this.paginatePlace()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    
      try {        
        switch (this.flagOption) {
          case "create": {
            await this.createPumpMotoUseCase(this.pumpMoto)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changePumpMotoUseCase(this.pumpMoto.id, this.pumpMoto)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        
      }
    
  }

  async delete(item: PumpMoto) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deletePumpMotoUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: PumpMoto) {
    this.flagOption = "change"
    this.dialog = true
    this.searchPlace = item.placeName
    this.searchCompany = item.CompanyName    
    this.pumpMoto = { ...item }
    this.paginateCompany()
    this.paginatePlace()
  }



  updateLevel(item: MarkPump) {
    this.selectedMarkPump = {...item}
    this.dialogPumpTracking = true
  }


}

export default PumpMotoController