import { PumpPagination } from '@/module/pump/domain/model/pump_pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Pagination } from '@/core/domain/model/pagination';
import Handler from '@/core/domain/usecase/handler';

export interface FetchPlacePumpRepository {
    (pagination: Pagination, id: number): Promise<PumpPagination>
}

const fetchPlacePumpRepository = (axios: AxiosInstance): FetchPlacePumpRepository => async (pagination: Pagination, id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/place/${id}/pump`, {
            params: pagination
        })

        const placePumpPagination: PumpPagination = response.data
        return placePumpPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchPlacePumpRepository