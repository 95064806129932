import { defaultModelPagination } from '@/core/domain/model/pagination';
import { defaultPagination } from '@/core/domain/model/pagination';
import { checklistDefault, ChecklistItem } from './../domain/model/checklist';
import { FetchChecklistUseCase } from '../domain/usecase/fetch_checklist_usecase';
import { DeleteChecklistUseCase } from '../domain/usecase/delete_checklist_usecase';
import { CreateChecklistUseCase } from '../domain/usecase/create_checklist_usecase';
import { ChangeChecklistUseCase } from '../domain/usecase/change_checklist_usecase';
import { ChecklistPagination } from '../domain/model/checklist_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Checklist } from '../domain/model/checklist';
import { snackbar } from '@/core/controller/snackbar_controller';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';

class ChecklistController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public loading: boolean = false
  public checklistPagination: ChecklistPagination = defaultModelPagination()
  public companyPagination: CompanyPagination = defaultModelPagination()
  public options: DataOptions = defaultPagination({})
  public optionsCompany: DataOptions = defaultPagination({})
  public taskDescription: string = ""

  public checklist: Checklist = checklistDefault()

  private fetchChecklistUseCase: FetchChecklistUseCase
  private deleteChecklistUseCase: DeleteChecklistUseCase
  private createChecklistUseCase: CreateChecklistUseCase
  private changeChecklistUseCase: ChangeChecklistUseCase
  private getCompanyByIDUseCase: GetCompanyByIDUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(
    context: any,
    fetchChecklistUseCase: FetchChecklistUseCase,
    deleteChecklistUseCase: DeleteChecklistUseCase,
    createChecklistUseCase: CreateChecklistUseCase,
    changeChecklistUseCase: ChangeChecklistUseCase,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase
  ) {
    this.context = context
    this.fetchChecklistUseCase = fetchChecklistUseCase
    this.deleteChecklistUseCase = deleteChecklistUseCase
    this.createChecklistUseCase = createChecklistUseCase
    this.changeChecklistUseCase = changeChecklistUseCase
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }


  async open() {
    this.paginateCompany()
    this.checklist = checklistDefault()
    this.taskDescription = ""
    this.context.$refs.crud.resetValidation()
  }

  async paginate() {
    this.loading = true
    try {
      this.checklistPagination = await this.fetchChecklistUseCase(this.options, this.search)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changeChecklistUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.checklist.id === 0) {
          await this.createChecklistUseCase(this.checklist)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          return
        }

        await this.changeChecklistUseCase(this.checklist.id, this.checklist)
        this.paginate()
        snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
        this.context.$refs.crud.resetValidation()
        this.checklist = checklistDefault()
        this.taskDescription = ""
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
      }
    }
  }

  async delete(item: Checklist) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteChecklistUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Checklist) {
    this.dialog = true
    this.checklist = { ...item }
    this.searchCompany = item.companyName
    this.paginateCompany()
  }

  pushItemToChecklist() {
    if (!this.taskDescription) {
      snackbar.show({message: "Preencha a descrição da tarefa!"})
      return
    }
    
    this.checklist.items.push({
      name: this.taskDescription,
      companyID: 0,
      checklistID: this.checklist.id,
      createdDate: this.checklist.createdDate,
      id: 0
    })
    this.taskDescription = ""
  }

  deleteChecklistTask(item: ChecklistItem) {
    const index = this.checklist.items.indexOf(item)
    const a = this.checklist.items.splice(index, 1)
    console.log(a);
    
  }
}

export default ChecklistController