import Handler from "@/core/domain/usecase/handler"
import { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import { User } from "../../domain/model/user"

export interface ChangeUserProfileImageRepository {
    (id: number, image: any): Promise<User>
}

const changeUserProfileImageRepository = (axios: AxiosInstance): ChangeUserProfileImageRepository => async (id: number, image: any) => {
    const formData = new FormData();
    formData.append("image", image)
    try {
        const response: AxiosResponse = await axios.put(`/account/${id}/profile-image`,
            formData,
            {
                //withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        return response.data
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default changeUserProfileImageRepository

