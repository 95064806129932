import { ChangePumpRepository } from '../../data/repository/change_pump_repository';
import { Pump } from '../model/pump';

export interface ChangePumpUseCase {
    (id: number, data: object): Promise<Pump>
}

const changePumpUseCase = (repository: ChangePumpRepository): ChangePumpUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changePumpUseCase;

