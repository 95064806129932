import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { CallCenter } from '../../domain/model/call_center';

export interface CreateCallCenterRepository {
  (data: object): Promise<CallCenter>
}

const createCallCenterRepository = (axios: AxiosInstance): CreateCallCenterRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/call-center`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createCallCenterRepository

