import { CreateCallCenterRepository } from '../../data/repository/create_call_center_repository';
import { CallCenter } from '../model/call_center';

export interface CreateCallCenterUseCase {
    (data: object): Promise<CallCenter>
}

const createCallCenterUseCase = (repository: CreateCallCenterRepository): CreateCallCenterUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createCallCenterUseCase;

