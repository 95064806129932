<template>
  <div class="root">
    <lottie
      v-if="controller.loadingBtn"
      class="loading-animation"
      :options="controller.lottieLevelAnimation"
      :height="400"
      :width="300"
    />
    <v-form ref="filter" class="mx-2">
      <v-toolbar flat class="my-2 d-none d-md-block background_screen">
        <v-toolbar-title> Marcações das Réguas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-form class="d-flex align-center justify-space-around mx-2">
            <v-autocomplete
              v-model="controller.selectedRuler"
              :items="controller.rulerPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione a Régua"
              style="min-width: 300px"
              return-object
              single-line
              @change="controller.changeRuler($event)"
            ></v-autocomplete>
            <v-autocomplete
              v-model="controller.typeOfFilterSelected"
              :items="controller.typeOfFilter"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione tipo de filtro"
              style="max-width: 200px"
              return-object
              single-line
              @change="controller.changePeriod($event)"
            ></v-autocomplete>
            <v-autocomplete
              v-if="controller.typeOfFilterSelected.id == 1"
              v-model="controller.selectedMonth"
              :items="controller.monthsOfYear"
              item-text="name"
              item-value="value"
              dense
              filled
              class="pt-6 pl-2"
              return-object
              single-line
              @change="controller.changeMonth($event)"
            ></v-autocomplete>

            <div v-if="controller.typeOfFilterSelected.id == 0">
              <date-picker
                style="max-width: 150px"
                v-model="controller.dayOfFilter"
                filled
                dense
                label="Dia"
                class="ml-2 mt-6"
                single-line
              ></date-picker>
            </div>
            <v-card
              class="px-2 day-of-filter"
              v-if="controller.typeOfFilterSelected.id == 1"
            >
              <v-btn @click="controller.lastWeek()" icon
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <b>
                {{ dayjs(controller.startOfWeek).format("DD/MM/YYYY") }} -
                {{ dayjs(controller.endOfWeek).format("DD/MM/YYYY") }}
              </b>
              <v-btn @click="controller.nextWeek()" icon
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-card>
            <v-card
              class="px-2 day-of-filter"
              v-if="controller.typeOfFilterSelected.id == 2"
            >
              <v-btn @click="controller.lastMonth()" icon
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <b> {{ dayjs(controller.startOfMonth).format("MMMM YYYY") }} </b>
              <v-btn @click="controller.nextMonth()" icon
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-card>
            <v-autocomplete
              v-if="controller.typeOfFilterSelected.id == 3"
              v-model="controller.selectedHarvest"
              :items="controller.harvestPagination.items"
              item-text="name"
              item-value="id"
              dense
              filled
              class="pt-6 pl-2"
              label="Selecione a safra"
              style="max-width: 200px"
              return-object
              @change="controller.changeHarvest($event)"
              single-line
            ></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="controller.compare = true"
                  ><v-icon color="primary">mdi-compare</v-icon></v-btn
                >
              </template>
              <span>Comparar períodos</span>
            </v-tooltip>
          </v-form>
        </v-toolbar-items>
      </v-toolbar>
      <v-card class="my-3 mx-2 d-md-none">
        <v-card-text>
          <h3>Marcações das Réguas</h3>
          <v-row no-gutters justify="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="controller.selectedRuler"
                :items="controller.rulerPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a Régua"
                return-object
                single-line
                @change="controller.changeRuler($event)"
                hide-details="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="controller.typeOfFilterSelected"
                :items="controller.typeOfFilter"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione tipo de filtro"
                return-object
                single-line
                @change="controller.changePeriod($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-if="controller.typeOfFilterSelected.id == 1"
                v-model="controller.selectedMonth"
                :items="controller.monthsOfYear"
                item-text="name"
                item-value="value"
                dense
                filled
                return-object
                single-line
                @change="controller.changeMonth($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <div v-if="controller.typeOfFilterSelected.id == 0">
                <date-picker
                  v-model="controller.dayOfFilter"
                  filled
                  dense
                  label="Dia"
                  single-line
                ></date-picker>
              </div>
              <v-card
                class="day-of-filter"
                v-if="controller.typeOfFilterSelected.id == 1"
              >
                <v-btn @click="controller.lastWeek()" icon
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <b>
                  {{ dayjs(controller.startOfWeek).format("DD/MM/YYYY") }} -
                  {{ dayjs(controller.endOfWeek).format("DD/MM/YYYY") }}
                </b>
                <v-btn @click="controller.nextWeek()" icon
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-card>
              <v-card
                class="day-of-filter"
                v-if="controller.typeOfFilterSelected.id == 2"
              >
                <v-btn @click="controller.lastMonth()" icon
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <b>
                  {{ dayjs(controller.startOfMonth).format("MMMM YYYY") }}
                </b>
                <v-btn @click="controller.nextMonth()" icon
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-card>
              <v-autocomplete
                v-if="controller.typeOfFilterSelected.id == 3"
                v-model="controller.selectedHarvest"
                :items="controller.harvestPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6 pl-2"
                label="Selecione a safra"
                return-object
                @change="controller.changeHarvest($event)"
                single-line
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card class="mx-2">
      <v-card-text>
        <div class="mx-2">
          <div id="ruler_chart" style="height: 85vh; width: 100%"></div>
          <div class="d-flex justify-center">
            <b
              >Total de marcações: {{ controller.markRulerPagination.total }}</b
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="controller.compare" max-width="800">
      <v-card>
        <v-card-title class="primary" style="color: white"
          >Comparar Régua entre 2 períodos
          <v-spacer></v-spacer>
          <v-btn icon @click="controller.compare = false"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-autocomplete
                v-model="controller.selectedRuler"
                :items="controller.rulerPagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                class="pt-6"
                label="Selecione a Régua"
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <span class="pl-4"> Selecione o primeiro período: </span>
            <v-col cols="12" class="d-flex">
              <date-picker
                v-model="controller.calendar1StIn"
                filled
                dense
                label="Data inicial"
                class="pr-1"
              ></date-picker>
              <date-picker
                v-model="controller.calendar1StUntil"
                filled
                dense
                label="Data final"
                class="pl-1"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <span class="pl-4"> Selecione o segundo período: </span>
            <v-col cols="12" class="d-flex">
              <date-picker
                v-model="controller.calendar2ndIn"
                filled
                dense
                label="Data inicial"
                class="pr-1"
              ></date-picker>
              <date-picker
                v-model="controller.calendar2ndUntil"
                filled
                dense
                label="Data final"
                class="pl-1"
              ></date-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="controller.filterCompare()" text class="primary"
            >Gerar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { newRulerMarkController } from "@/di/di";
import dayjs from "dayjs";
import _ from "lodash";
import Lottie from "../../../core/component/lottie.vue";

export default {
  components: {
    Lottie,
  },
  data: (context) => ({
    controller: newRulerMarkController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.calendarIn"() {
      dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    "controller.dayOfFilter"() {
      this.controller.changeDay();
    },
  },
  computed: {
    formatDateIn() {
      return dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    formatDateUntil() {
      return dayjs(this.controller.calendarUntil).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.root {
  height: 100vh;
}
.day-of-filter {
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin: 0px 10px;
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>