import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteMarkRulerRepository {
    (id: number): Promise<MarkRuler>
}

const deleteRulerMarkRepository = (axios: AxiosInstance): DeleteMarkRulerRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/ruler-tracking/${id}`)

        const markRuler: MarkRuler = response.data
        return markRuler
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteRulerMarkRepository