export interface MarkStation {
  id: number;
  temperature: number;
  wind_direction: number;
  wind_velocity: number;
  station_id: number;
  created_at: string;
  humidity: number;
  pluviometer_mmpp: number;
  hardware_base: {
    battery_status: number;
    signal_intensity: number;
    signal_origin: string;
  }
}


const en: any = {
  id: 1,
  temperature: 2,
  wind_direction: 3,
  wind_velocity: 4,
  station_id: 5,
  created_at: 6,
  humidity: 7,
  pluviometer_mmpp: 8,
  "hardware_base.battery_status": 9,
  "hardware_base.signal_intensity": 10,
  "hardware_base.signal_origin": 11
};
export { en };


