import { ChangeAlertTypeRepository } from '../../data/repository/change_alert_type_repository';
import { AlertType } from '../model/alert';

export interface ChangeAlertTypeUseCase {
    (id: number, data: object): Promise<AlertType>
}

const changeAlertTypeUseCase = (repository: ChangeAlertTypeRepository): ChangeAlertTypeUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeAlertTypeUseCase;

