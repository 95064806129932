import { StationPagination } from './../model/station_pagination';
import { FetchStationRepository } from './../../data/repository/fetch_station_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/station';

export interface FetchStationUseCase {
    (pagination: DataOptions, search: string): Promise<StationPagination>
}

const fetchStationUseCase = (repository: FetchStationRepository): FetchStationUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchStationUseCase;