import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { MarkStation } from '@/module/stationMark/domain/model/markStation';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteMarkStationRepository {
    (id: number): Promise<MarkStation>
}

const deleteStationMarkRepository = (axios: AxiosInstance): DeleteMarkStationRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`https://stationapihydra.com/data/${id}`)

        const markStation: MarkStation = response.data
        return markStation
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteStationMarkRepository