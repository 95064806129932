import { AlertType } from '../../domain/model/alert';
import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';


export interface ChangeAlertTypeRepository {
  (id: number, data: object): Promise<AlertType>
}

const changeAlertTypeRepository = (axios: AxiosInstance): ChangeAlertTypeRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/alert-type/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeAlertTypeRepository

