import { CreateHardwareVersionRepository } from '../../data/repository/create_hardware_version_repository';
import { HardwareVersion } from '../model/hardware_version';

export interface CreateHardwareVersionUseCase {
    (data: HardwareVersion, file: File): Promise<HardwareVersion>
}

const createHardwareVersionUseCase = (repository: CreateHardwareVersionRepository): CreateHardwareVersionUseCase => async (data: HardwareVersion, file: File) => {
    try {
        return await repository(data, file);
    } catch (error) {
        throw error
    }
}

export default createHardwareVersionUseCase;

