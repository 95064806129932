import { ChangeChecklistRepository } from '../../data/repository/change_checklist_repository';
import { Checklist } from '../model/checklist';

export interface ChangeChecklistUseCase {
    (id: number, data: object): Promise<Checklist>
}

const changeChecklistUseCase = (repository: ChangeChecklistRepository): ChangeChecklistUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeChecklistUseCase;

