import { AlertType } from '../../domain/model/alert';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteAlertTypeRepository {
    (id: number): Promise<AlertType>
}

const deleteAlertTypeRepository = (axios: AxiosInstance): DeleteAlertTypeRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/alert-type/${id}`)

        const alertType: AlertType = response.data
        return alertType
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteAlertTypeRepository