import { ChangeRulerRepository } from './../../data/repository/change_ruler_repository';
import { Ruler } from '../model/ruler';

export interface ChangeRulerUseCase {
    (id: number, data: object): Promise<Ruler>
}

const changeRulerUseCase = (repository: ChangeRulerRepository): ChangeRulerUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeRulerUseCase;

