import { HarvestPagination } from '../../domain/model/harvest_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchHarvestRepository {
    (pagination: Pagination): Promise<HarvestPagination>
}

const fetchHarvestRepository = (axios: AxiosInstance): FetchHarvestRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/harvest', {
            params: pagination,
        })

        const harvestPagination: HarvestPagination = response.data
        return harvestPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchHarvestRepository