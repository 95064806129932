import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteMarkPumpRepository {
    (id: number): Promise<MarkPump>
}

const deletePumpMarkRepository = (axios: AxiosInstance): DeleteMarkPumpRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/pump-tracking/${id}`)

        const markPump: MarkPump = response.data
        return markPump
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)

        } else {
            throw err
        }
    }
}

export default deletePumpMarkRepository