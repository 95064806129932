import { FetchScreensRepository } from './../../data/repository/fetch_screens_repository';
import { Account } from '../../../../core/domain/model/account';

export interface FetchScreensUseCase {
    (): Promise<Screen[]>
}

const fetchScreensUseCase = (repository: FetchScreensRepository): FetchScreensUseCase => async () => {
    try {
        return await repository();
    } catch (error) {
        throw error
    }
}

export default fetchScreensUseCase;