import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Pump } from '../../domain/model/pump';

export interface ChangePumpRepository {
  (id: number, data: object): Promise<Pump>
}

const changePumpRepository = (axios: AxiosInstance): ChangePumpRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/pump/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changePumpRepository

