import { DeleteChecklistRepository } from '../../data/repository/delete_checklist_repository';
import { Checklist } from '../model/checklist';

export interface DeleteChecklistUseCase {
    (id: number): Promise<Checklist>
}

const deleteChecklistUseCase = (repository: DeleteChecklistRepository): DeleteChecklistUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteChecklistUseCase;