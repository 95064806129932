<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.rulerPagination.total"
      :items="controller.rulerPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.color`]="{ item }">
        <v-avatar
          size="30"
          :color="item.color"
        > </v-avatar>
      </template>

      <template v-slot:[`item.isFavorite`]="{ item }">
        <v-btn
          icon
          @click="controller.changeFav(item)"
        >
          <v-icon :color="item.isFavorite ? '#FFC107' : 'grey'">mdi-star</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{ item.status === 1 ? "Ativo" : "Inativo" }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.logs(item)"
            class="px-2"
          >
            <v-icon
              small
              color="orange"
            >mdi-database</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(4, 3)"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
            v-if="hadPermission(4, 4)"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Réguas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            @change="controller.paginate()"
            class="px-2"
            style="max-width: 200px"
            filled
            dense
            single-line
            color="primary"
            hide-details
            v-model="controller.status"
            :items="controller.visibilityStatus"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            :max-width="controller.hardwareMesurement ? 800 : 500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="hadPermission(4, 2)"
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Réguas
                  <v-spacer></v-spacer>
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="pt-3">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row justify="center">
                    <v-switch
                      hide-details
                      dense
                      v-model="controller.hardwareMesurement"
                      inset
                      label="Medições por hardware"
                    ></v-switch>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        filled
                        dense
                        color="primary"
                        label="Status"
                        v-model="controller.ruler.status"
                        :items="controller.itemStatus"
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-select>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nome da régua"
                        v-model="controller.ruler.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <div class="d-flex flex-column align-center">
                        <h4>Selecionar cor</h4>
                        <div>
                          <v-color-picker
                            class="no-alpha"
                            dot-size="25"
                            hide-mode-switch
                            mode="hexa"
                            swatches-max-height="200"
                            v-model="controller.ruler.color"
                            hide-inputs
                          ></v-color-picker>
                        </div>
                      </div>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nível máximo"
                        type="number"
                        v-model.number="controller.ruler.higherLevel"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nível mínimo"
                        type="number"
                        v-model.number="controller.ruler.lowerLevel"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        readonly
                        color="primary"
                        label="Latitude"
                        v-model="controller.ruler.latitude"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        readonly
                        color="primary"
                        label="Longitude"
                        v-model="controller.ruler.longitude"
                      >
                      </v-text-field>

                      <form-maps
                        :center="controller.center"
                        :zoom="controller.zoom"
                        @get-location="controller.getLocation($event)"
                      ></form-maps>
                      <!-- <div style="width: 100%; height: 300px">
                        <google-map
                          v-if="controller.center != null"
                          :center="controller.center"
                          @center_changed="controller.updateCenter($event)"
                          :zoom="18"
                          style="width: 100%; height: 100%"
                          map-type-id="hybrid"
                          :options="$vuetify.theme.isDark ? mapDark : mapLight"
                        >
                        </google-map>
                        <v-icon
                          color="orange"
                          x-large
                          style="
                            position: relative;
                            bottom: 54%;
                            left: 48%;
                            z-index: 9999;
                          "
                          >mdi-map-marker</v-icon
                        >
                      </div> -->
                      <v-autocomplete
                        class="pt-4"
                        filled
                        dense
                        color="primary"
                        label="Local"
                        v-model="controller.ruler.placeID"
                        :search-input.sync="controller.searchPlace"
                        :items="controller.placePagination.items"
                        item-text="name"
                        item-value="id"
                      >
                      </v-autocomplete>
                      <v-autocomplete
                        v-if="isAdmin()"
                        filled
                        dense
                        color="primary"
                        label="Empresa"
                        v-model="controller.ruler.companyID"
                        :search-input.sync="controller.searchCompany"
                        :items="controller.companyPagination.items"
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-divider
                      vertical
                      v-if="controller.hardwareMesurement"
                    ></v-divider>
                    <v-col v-if="controller.hardwareMesurement">
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Número do equipamento"
                        placeholder="ID do hardware"
                        v-model="controller.ruler.terminalNumber"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <div>Fórmula para cálculo de equivalência de nível</div>
                      <div class="font-weight-bold pb-8">
                        (coeficiente - nível) / fator de divisão
                      </div>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Coeficiente"
                        v-model.number="controller.ruler.coefficient"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Fator de divisão"
                        v-model.number="controller.ruler.divisionFactor"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog
      persistent
      v-model="controller.dialogTracking"
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-data-table
            class="elevation-0 ma-0 pa-0"
            dense
            height="75vh"
            fixed-header
            :headers="controller.columnsTracking"
            :options.sync="controller.optionsTracking"
            :server-items-length="controller.trackingPagination.total"
            :items="controller.trackingPagination.items"
            show-select
            v-model="controller.selected"
            :footer-props="{
              'items-per-page-options': [40, 80],
            }"
            :loading="controller.loadingLogs"
          >
            <template v-slot:[`item.createdDate`]="{ item }">
              {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm:ss") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-btn
                  v-if="hadPermission(4, 3)"
                  icon
                  @click="controller.updateLevel(item)"
                  class="px-2"
                >
                  <v-icon
                    small
                    color="blue"
                  >mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Medições da régua:
                  {{ controller.selectedRuler.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-text-field
                  class="px-2"
                  style="max-width: 250px"
                  filled
                  color="primary"
                  hide-details
                  dense
                  placeholder="Pesquisar..."
                  v-model="controller.searchTracking"
                ></v-text-field> -->
                <v-btn
                  v-if="hadPermission(4, 4)"
                  v-show="controller.selected.length > 0"
                  @click="controller.deleteSelected()"
                  color="red"
                  class="mr-2"
                  style="color: white"
                >
                  <v-icon>mdi-delete</v-icon>Excluir Selecionados
                </v-btn>
                <v-btn
                  :loading="controller.loadingBtnExcel"
                  color="green"
                  class="white--text"
                  @click="controller.exportExcel()"
                >
                  <v-icon left> mdi-file-excel </v-icon>
                  Excel
                </v-btn>
                <v-btn
                  @click="controller.reloadLogs()"
                  icon
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-btn
                  @click="controller.dialogTracking = false"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      width="350"
      persistent
      v-model="controller.dialogRulerTrackingLevel"
    >
      <v-card>
        <v-card-title> Alterar nível </v-card-title>
        <v-card-text>
          <v-text-field
            type="number"
            filled
            label="Nível"
            v-model="controller.selectedMarkRuler.level"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn
            @click="controller.dialogRulerTrackingLevel = false"
            outlined
            color="red"
          >
            <v-icon left>mdi-delete</v-icon>cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="controller.confirmUpdateLevel()"
            color="primary"
          >
            <v-icon left>mdi-content-save</v-icon>alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { rulerController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: rulerController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
    "controller.searchTracking": _.debounce(function () {
      this.controller.watchSearchTrackign();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.optionsTracking"() {
      this.controller.watchOptionsTracking();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.optionsPlace"() {
      this.controller.watchOptionsPlace();
    },
    "controller.searchPlace": _.debounce(function () {
      this.controller.watchSearchPlace();
    }, 500),
  },
};
</script>