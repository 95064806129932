import { MarkStationPagination } from './../../../stationMark/domain/model/markStation_pagination';
import { FetchStationTrackingByIDRepository } from './../../data/repository/fetch_station_tracking_by_id_repository';
import { PaginationDate } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '@/module/stationMark/domain/model/markStation';

export interface FetchStationTrackingByIDUseCase {
    (id: number, pagination: DataOptions, search: string, startDate: string, endDate: string): Promise<MarkStationPagination>
}

const fetchStationTrackingByIDUseCase = (repository: FetchStationTrackingByIDRepository): FetchStationTrackingByIDUseCase => async (id: number, pagination: DataOptions, search: string, startDate: string, endDate: string) => {
    try {
        const payload: PaginationDate = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
            startDate: startDate,
            endDate: endDate
        }
        
        return await repository(id, payload);
    } catch (error) {
        throw error
    }
}

export default fetchStationTrackingByIDUseCase;