import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Notification } from '../../domain/model/notification';

export interface ChangeNotificationRepository {
  (id: number, data: object): Promise<Notification>
}

const changeNotificationRepository = (axios: AxiosInstance): ChangeNotificationRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/notification/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeNotificationRepository

