import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"mb-0",attrs:{"absolute":"","clipped":"","right":"","width":"350"},model:{value:(_vm.controller.rightDrawer),callback:function ($$v) {_vm.$set(_vm.controller, "rightDrawer", $$v)},expression:"controller.rightDrawer"}},[_c(VContainer,[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"font-weight-light"},[_vm._v("Configurações")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){_vm.controller.rightDrawer = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c('div',{staticClass:"text-center pt-3"},[_c('h4',{staticClass:"font-weight-light"},[_vm._v("Geral")])]),_c(VTextField,{staticClass:"pt-4",attrs:{"filled":"","dense":"","color":"primary","label":"Marcação superior da bomba"},model:{value:(_vm.controller.maxLevelPump),callback:function ($$v) {_vm.$set(_vm.controller, "maxLevelPump", $$v)},expression:"controller.maxLevelPump"}}),_c(VTextField,{staticClass:"pt-4",attrs:{"filled":"","dense":"","color":"primary","label":"Marcação superior do levante"},model:{value:(_vm.controller.maxLevelPlace),callback:function ($$v) {_vm.$set(_vm.controller, "maxLevelPlace", $$v)},expression:"controller.maxLevelPlace"}}),_c(VTextField,{staticClass:"pt-4",attrs:{"filled":"","dense":"","color":"primary","label":"Marcação inferior do levante"},model:{value:(_vm.controller.minLevelPlace),callback:function ($$v) {_vm.$set(_vm.controller, "minLevelPlace", $$v)},expression:"controller.minLevelPlace"}}),_c('div',{staticClass:"d-flex justify-center pt-2"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.controller.change()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("salvar ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }