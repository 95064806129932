import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PumpMoto } from '../../domain/model/pump_moto';

export interface ChangePumpMotoRepository {
  (id: number, data: object): Promise<PumpMoto>
}

const changePumpMotoRepository = (axios: AxiosInstance): ChangePumpMotoRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/pump-moto/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changePumpMotoRepository

