import { darkTheme, whiteTheme } from './plugins/echarts';
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { snackbar } from './core/controller/snackbar_controller';
import { alert } from './core/controller/alert_controller';
import snackbarVue from "./core/component/snackbar.vue";
import alertVue from "./core/component/alert.vue";
import datePicker from "./core/component/date_picker.vue";
import formMaps from "./core/component/form_maps.vue";
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import './plugins/maps'
import * as echarts from 'echarts';
import { mapDark, mapLight } from './core/domain/model/map_style';
import { hadPermission, hadScreenAccess } from './core/domain/model/permission'
import { getAccountName, isAdmin, isClientAdmin } from '@/core/domain/model/jwtAuthToken'

window.snackbarController = snackbar
window.alertController = alert
Vue.component('snackbar', snackbarVue)
Vue.component('alert', alertVue)
Vue.component('date-picker', datePicker)
Vue.component('form-maps', formMaps)

Vue.prototype.dayjs = dayjs
Vue.config.productionTip = false

Vue.prototype.mapDark = mapDark
Vue.prototype.mapLight = mapLight
Vue.prototype.hadPermission = hadPermission
Vue.prototype.hadScreenAccess = hadScreenAccess
Vue.prototype.isAdmin = isAdmin
Vue.prototype.isClientAdmin = isClientAdmin
Vue.prototype.getAccountName = getAccountName

echarts.registerTheme('dark', darkTheme)
echarts.registerTheme('light', whiteTheme)

dayjs.locale('pt-br')

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
