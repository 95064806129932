<template>
  <v-app style="background-color: #14171E">
    <v-row
      justify="center"
      align="center"
      class="fill-height"
      v-if="!controller.changePass && !controller.expired"
    >
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="6"
        sm="12"
      >
        <v-card
          elevation="0"
          color="transparent"
        >
          <v-row justify="center">
            <v-col cols="6">
              <v-img src="/logo_branco.png"></v-img>
            </v-col>
          </v-row>
          <v-card-title class="white--text">
            <v-spacer></v-spacer>
            Recuperar senha
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-form ref="formMail">
              <v-text-field
                class="pb-3"
                dense
                placeholder="E-mail"
                solo
                :rules="controller.emailRuler"
                v-model="controller.mail"
                height="55"
              ></v-text-field>
              <v-btn
                elevation="0"
                height="55"
                block
                color="blue"
                class="white--text mb-3"
                @click="controller.sendEmail()"
              >Enviar</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="fill-height"
      v-if="controller.changePass || controller.expired"
    >
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="6"
        sm="12"
      >
        <v-card
          elevation="0"
          color="transparent"
        >
          <v-row justify="center">
            <v-col cols="6">
              <v-img src="/logo_branco.png"></v-img>
            </v-col>
          </v-row>
          <v-card-title class="white--text">
            <v-spacer></v-spacer>
            Alterar senha
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text v-if="controller.changePass">
            <v-form ref="formPass">
              <v-text-field
                label="Nova senha"
                solo
                height="55"
                v-model="controller.account.password"
                :rules="[(v) => !!v || 'Preencha o campo', (controller.account.password === controller.account.confirmationPassword) || 'As senhas não conferem']"
                type="password"
                placeholder="Senha"
              ></v-text-field>
              <v-text-field
                label="Confirme sua senha"
                solo
                height="55"
                v-model="controller.account.confirmationPassword"
                :rules="[(v) => !!v || 'Preencha o campo', (controller.account.password === controller.account.confirmationPassword) || 'As senhas não conferem']"
                type="password"
                placeholder="Confirmação da senha"
              ></v-text-field>
              <v-btn
                elevation="0"
                height="55"
                block
                color="blue"
                class="white--text mb-3"
                @click="controller.updatePassword()"
              >Alterar senha</v-btn>
            </v-form>
          </v-card-text>
          <v-card-title
            class="white--text"
            v-if="controller.expired"
          >
            <v-spacer></v-spacer>
            O tempo expirou!
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text v-if="controller.expired">
            <v-btn
              elevation="0"
              height="55"
              block
              color="blue"
              class="white--text mb-3"
              @click="controller.reset()"
              to="/password-recovery"
            >Esqueci a senha</v-btn>
            <v-btn
              elevation="0"
              height="55"
              block
              outlined
              color="white"
              to="/"
            >voltar</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { passwordRecoveryController } from "../../../di/di";
export default {
  data: (context) => ({
    controller: passwordRecoveryController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>
