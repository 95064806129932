import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';
import { PlaceTrackingPagination } from '../../domain/model/place_tracking_pagination';

export interface FetchPlaceTrackingRepository {
    (pagination: Pagination, id: number): Promise<PlaceTrackingPagination>
}

const fetchPlaceTrackingRepository = (axios: AxiosInstance): FetchPlaceTrackingRepository => async (pagination: Pagination, id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/place/${id}/tracking`, {
            params: pagination
        })

        const pumpPagination: PlaceTrackingPagination = response.data
        return pumpPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchPlaceTrackingRepository