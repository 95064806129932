import { UpdateRulerTrackingLevelRepository } from './../../data/repository/update_ruler_tracking_level_repository';
import { MarkRulerPagination } from './../../../rulerMark/domain/model/markRuler_pagination';
import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';

export interface UpdateRulerTrackingLevelUseCase {
    (rulerTracking: MarkRuler): Promise<MarkRulerPagination>
}

const updateRulerTrackingLevelUseCase = (repository: UpdateRulerTrackingLevelRepository): UpdateRulerTrackingLevelUseCase => async (rulerTracking: MarkRuler) => {
    try {
        rulerTracking.level = parseFloat(rulerTracking.level.toString())
        return await repository(rulerTracking);
    } catch (error) {
        throw error
    }
}

export default updateRulerTrackingLevelUseCase;