import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PumpMoto } from '../../domain/model/pump_moto';

export interface ChangeRemoteControlRepository {
  ( data: object): Promise<PumpMoto>
}

const changeRemoteControlRepository = (axios: AxiosInstance): ChangeRemoteControlRepository => async (data: object) => {
  try {
  
    const response: AxiosResponse = await axios.put(`/remote-control`,
      data, 
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeRemoteControlRepository

