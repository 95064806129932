import { EnginePagination } from '../model/engine_pagination';
import { FetchEngineRepository } from '../../data/repository/fetch_engine_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/engine';

export interface FetchEngineUseCase {
    (pagination: DataOptions, search: string, status: number): Promise<EnginePagination>
}

const fetchEngineUseCase = (repository: FetchEngineRepository): FetchEngineUseCase => async (pagination: DataOptions, search: string, status: number) => {
    try {
        
        const payload: Pagination = {
            sort: pagination?.sortBy?.map((s: string) => en[s]).join(','),
            descending: "false",
            page: pagination?.page,
            itemsPerPage: pagination?.itemsPerPage,
            search ,
            status 
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchEngineUseCase;