import fetchPreRegistrationRepository from './../module/user/data/repository/fetch_pre_registration_repository';
import createHarvestUseCase from './../module/harvest/domain/usecase/create_harvest_usecase';
import DefaultController from "@/module/default/controller/default_controller";
import LoginController from '@/module/login/controller/login_controller';
import HomeController from '@/module/home/controller/home_controller';
import UserController from '@/module/user/controller/user_controller';
import CompanyController from '@/module/company/controller/company_controller';
import RulerMarkController from "@/module/rulerMark/controller/rulerMark_controller";
import PumpMarkController from "@/module/pumpMark/controller/pumpMark_controller";
import RulerController from '@/module/ruler/controller/ruler_controller';
import PlaceController from '@/module/place/controller/place_controller';
import PumpController from '@/module/pump/controller/pump_controller';
import PumpMotoController from '@/module/pump_moto/controller/pump_moto_controller';
import RemoteControlController from '@/module/drive/controller/remote_control_controller';
import NotificationController from '@/module/notifications/controller/notifications_controller';
import loginRepostory from "@/core/data/repository/login_repository";
import loginUseCase from "@/core/domain/usecase/login_usecase";
import RulerGroupController from '@/module/rulerGroup/controller/rulerGroup_controller';

//User
import fetchUserRepository from "@/module/user/data/repository/fetch_user_repository";
import fetchUserUseCase from "@/module/user/domain/usecase/fetch_user_usecase";
import deleteUserRepository from "@/module/user/data/repository/delete_user_repository";
import deleteUserUseCase from "@/module/user/domain/usecase/delete_user_usecase";
import createUserRepository from "@/module/user/data/repository/create_user_repository";
import createUserUseCase from "@/module/user/domain/usecase/create_user_usecase";
import changeUserRepository from "@/module/user/data/repository/change_user_repository";
import changeUserUseCase from "@/module/user/domain/usecase/change_user_usecase";

//City
import fetchCityRepository from "@/module/city/repository/fetch_city_repository";
import fetchCityUseCase from "@/module/city/domain/usecase/fetch_city_usecase";

//Company
import fetchCompanyRepository from "@/module/company/data/repository/fetch_company_repository";
import fetchCompanyUseCase from "@/module/company/domain/usecase/fetch_company_usecase";
import deleteCompanyRepository from "@/module/company/data/repository/delete_company_repository";
import deleteCompanyUseCase from "@/module/company/domain/usecase/delete_company_usecase";
import createCompanyRepository from "@/module/company/data/repository/create_company_repository";
import createCompanyUseCase from "@/module/company/domain/usecase/create_company_usecase";
import changeCompanyRepository from "@/module/company/data/repository/change_company_repository";
import changeCompanyUseCase from "@/module/company/domain/usecase/change_company_usecase";
import LogsController from "@/module/logs/controller/logs_controller";
import fetchRulerLogsRepository from "@/module/logs/data/repository/fetch_logs_repository";
import fetchRulerLogsUseCase from "@/module/logs/domain/usecase/fetch_logs_usecase";
import fetchCnpjRepository from "@/module/company/data/repository/fetch_cnpj_repository";
import fetchCnpjUseCase from "@/module/company/domain/usecase/fetch_cnpj_usecase";


//Ruler
import fetchRulerRepository from "@/module/ruler/data/repository/fetch_ruler_repository";
import fetchRulerUseCase from "@/module/ruler/domain/usecase/fetch_ruler_usecase";
import deleteRulerRepository from "@/module/ruler/data/repository/delete_ruler_repository";
import deleteRulerUseCase from "@/module/ruler/domain/usecase/delete_ruler_usecase";
import createRulerRepository from "@/module/ruler/data/repository/create_ruler_repository";
import createRulerUseCase from "@/module/ruler/domain/usecase/create_ruler_usecase";
import changeRulerRepository from "@/module/ruler/data/repository/change_ruler_repository";
import changeRulerUseCase from "@/module/ruler/domain/usecase/change_ruler_usecase";
import deleteMarkRulerRepository from "@/module/ruler/data/repository/delete_ruler_mark_repository";
import deleteMarkRulerUseCase from "@/module/ruler/domain/usecase/delete_ruler_mark_usecase";

//RulerGroup
import fetchRulerGroupRepository from "@/module/rulerGroup/data/repository/fetch_rulerGroup_repository";
import fetchRulerGroupUseCase from "@/module/rulerGroup/domain/usecase/fetch_rulerGroup_usecase";
import deleteRulerGroupRepository from "@/module/rulerGroup/data/repository/delete_rulerGroup_repository";
import deleteRulerGroupUseCase from "@/module/rulerGroup/domain/usecase/delete_rulerGroup_usecase";
import createRulerGroupRepository from "@/module/rulerGroup/data/repository/create_rulerGroup_repository";
import createRulerGroupUseCase from "@/module/rulerGroup/domain/usecase/create_rulerGroup_usecase";
import changeRulerGroupRepository from "@/module/rulerGroup/data/repository/change_rulerGroup_repository";
import changeRulerGroupUseCase from "@/module/rulerGroup/domain/usecase/change_rulerGroup_usecase";

//MarkRuler
import fetchMarkRulerRepository from "@/module/rulerMark/data/repository/fetch_markRuler_repository";
import fetchMarkRulerUseCase from "@/module/rulerMark/domain/usecase/fetch_markRuler_usecase";
import fetchMarkRulerWith30MinutesRepository from "@/module/rulerMark/data/repository/fetch_markRuler_with_30_minutes_repository";
import fetchMarkRulerWith30MinutesUseCase from "@/module/rulerMark/domain/usecase/fetch_markRuler_with_30_minutes_usecase";

//Station
import fetchStationRepository from '@/module/station/data/repository/fetch_station_repository';
import fetchStationUseCase from '@/module/station/domain/usecase/fetch_station_usecase';
import deleteStationRepository from '@/module/station/data/repository/delete_station_repository';
import deleteStationUseCase from '@/module/station/domain/usecase/delete_station_usecase';
import createStationRepository from '@/module/station/data/repository/create_station_repository';
import createStationUseCase from '@/module/station/domain/usecase/create_station_usecase';
import changeStationRepository from '@/module/station/data/repository/change_station_repository';
import changeStationUseCase from '@/module/station/domain/usecase/change_station_usecase';
import deleteMarkStationRepository from '@/module/station/data/repository/delete_station_mark_repository';
import deleteMarkStationUseCase from '@/module/station/domain/usecase/delete_station_mark_usecase';

//MarkStation
import fetchMarkStationRepository from '@/module/stationMark/data/repository/fetch_markStation_repository';
import fetchMarkStationUseCase from '@/module/stationMark/domain/usecase/fetch_markStation_usecase';
import fetchMarkStationWith30MinutesRepository from '@/module/stationMark/data/repository/fetch_markStation_with_30_minutes_repository';
import fetchMarkStationWith30MinutesUseCase from '@/module/stationMark/domain/usecase/fetch_markStation_with_30_minutes_usecase';

//Place
import fetchPlaceRepository from "@/module/place/data/repository/fetch_place_repository";
import fetchPlaceUseCase from "@/module/place/domain/usecase/fetch_place_usecase";
import deletePlaceRepository from "@/module/place/data/repository/delete_place_repository";
import deletePlaceUseCase from "@/module/place/domain/usecase/delete_place_usecase";
import createPlaceRepository from "@/module/place/data/repository/create_place_repository";
import createPlaceUseCase from "@/module/place/domain/usecase/create_place_usecase";
import changePlaceRepository from "@/module/place/data/repository/change_place_repository";
import changePlaceUseCase from "@/module/place/domain/usecase/change_place_usecase";

//Checklist
import fetchChecklistRepository from "@/module/checklist/data/repository/fetch_checklist_repository";
import fetchChecklistUseCase from "@/module/checklist/domain/usecase/fetch_checklist_usecase";
import deleteChecklistRepository from "@/module/checklist/data/repository/delete_checklist_repository";
import deleteChecklistUseCase from "@/module/checklist/domain/usecase/delete_checklist_usecase";
import createChecklistRepository from "@/module/checklist/data/repository/create_checklist_repository";
import createChecklistUseCase from "@/module/checklist/domain/usecase/create_checklist_usecase";
import changeChecklistRepository from "@/module/checklist/data/repository/change_checklist_repository";
import changeChecklistUseCase from "@/module/checklist/domain/usecase/change_checklist_usecase";

//CallCenter
import fetchCallCenterRepository from "@/module/callCenter/data/repository/fetch_call_center_repository";
import fetchCallCenterUseCase from "@/module/callCenter/domain/usecase/fetch_call_center_usecase";
import deleteCallCenterRepository from "@/module/callCenter/data/repository/delete_call_center_repository";
import deleteCallCenterUseCase from "@/module/callCenter/domain/usecase/delete_call_center_usecase";
import createCallCenterRepository from "@/module/callCenter/data/repository/create_call_center_repository";
import createCallCenterUseCase from "@/module/callCenter/domain/usecase/create_call_center_usecase";
import changeCallCenterRepository from "@/module/callCenter/data/repository/change_call_center_repository";
import changeCallCenterUseCase from "@/module/callCenter/domain/usecase/change_call_center_usecase";

//AlertType
import fetchAlertTypeRepository from "@/module/alertType/data/repository/fetch_alert_type_repository";
import fetchAlertTypeUseCase from "@/module/alertType/domain/usecase/fetch_alert_type_usecase";
import deleteAlertTypeRepository from "@/module/alertType/data/repository/delete_alert_type_repository";
import deleteAlertTypeUseCase from "@/module/alertType/domain/usecase/delete_alert_type_usecase";
import createAlertTypeRepository from "@/module/alertType/data/repository/create_alert_type_repository";
import createAlertTypeUseCase from "@/module/alertType/domain/usecase/create_alert_type_usecase";
import changeAlertTypeRepository from "@/module/alertType/data/repository/change_alert_type_repository";
import changeAlertTypeUseCase from "@/module/alertType/domain/usecase/change_alert_type_usecase";

//Alert
import fetchAlertRepository from "@/module/alert/data/repository/fetch_alert_repository";
import fetchAlertUseCase from "@/module/alert/domain/usecase/fetch_alert_usecase";
import createAlertRepository from "@/module/alert/data/repository/create_alert_repository";
import createAlertUseCase from "@/module/alert/domain/usecase/create_alert_usecase";
import changeAlertRepository from "@/module/alert/data/repository/change_alert_repository";
import changeAlertUseCase from "@/module/alert/domain/usecase/change_alert_usecase";

//Pump
import fetchPumpRepository from "@/module/pump/data/repository/fetch_pump_repository";
import fetchPumpUseCase from "@/module/pump/domain/usecase/fetch_pump_usecase";
import deletePumpRepository from "@/module/pump/data/repository/delete_pump_repository";
import deletePumpUseCase from "@/module/pump/domain/usecase/delete_pump_usecase";
import createPumpRepository from "@/module/pump/data/repository/create_pump_repository";
import createPumpUseCase from "@/module/pump/domain/usecase/create_pump_usecase";
import changePumpRepository from "@/module/pump/data/repository/change_pump_repository";
import changePumpUseCase from "@/module/pump/domain/usecase/change_pump_usecase";
import fetchPumpTrackingByIDUseCase from "@/module/pump/domain/usecase/fetch_pump_tracking_by_id_usecase";
import fetchPumpTrackingByIDRepository from "@/module/pump/data/repository/fetch_pump_tracking_by_id_repository";
import updatePumpTrackingLevelRepository from "@/module/pump/data/repository/update_pump_tracking_level_repository";
import updatePumpTrackingLevelUseCase from "@/module/pump/domain/usecase/update_pump_tracking_level_usecase";
import deletePumpMarkRepository from "@/module/pump/data/repository/delete_pump_mark_repository";
import deletePumpMarkUseCase from "@/module/pump/domain/usecase/delete_pump_mark_usecase";


//Pump Moto
import fetchPumpMotoRepository from "@/module/pump_moto/data/repository/fetch_pump_moto_repository";
import fetchPumpMotoUseCase from "@/module/pump_moto/domain/usecase/fetch_pump_moto_usecase";
import deletePumpMotoRepository from "@/module/pump_moto/data/repository/delete_pump_moto_repository";
import deletePumpMotoUseCase from "@/module/pump_moto/domain/usecase/delete_pump_moto_usecase";
import createPumpMotoRepository from "@/module/pump_moto/data/repository/create_pump_moto_repository";
import createPumpMotoUseCase from "@/module/pump_moto/domain/usecase/create_pump_moto_usecase";
import changePumpMotoRepository from "@/module/pump_moto/data/repository/change_pump_moto_repository";
import changePumpMotoUseCase from "@/module/pump_moto/domain/usecase/change_pump_moto_usecase";

//Remote Control
import fetchRemoteControlRepository from "@/module/drive/data/repository/fetch_remote_control_repository";
import fetchRemoteControlUseCase from "@/module/drive/domain/usecase/fetch_remote_control_usecase";
import deleteRemoteControlRepository from "@/module/drive/data/repository/delete_remote_control_repository";
import deleteRemoteControlUseCase from "@/module/drive/domain/usecase/delete_remote_control_usecase";
import createRemoteControlRepository from "@/module/drive/data/repository/create_remote_control_repository";
import createRemoteControlUseCase from "@/module/drive/domain/usecase/create_remote_control_usecase";
import changeRemoteControlRepository from "@/module/drive/data/repository/change_remote_control_repository";
import changeRemoteControlUseCase from "@/module/drive/domain/usecase/change_remote_control_usecase";


//MarkPump
import fetchMarkPumpRepository from "@/module/pumpMark/data/repository/fetch_markPump_repository";
import fetchMarkPumpUseCase from "@/module/pumpMark/domain/usecase/fetch_markPump_usecase";
import fetchPlacePumpRepository from "@/module/pumpMark/data/repository/fetch_placePump_repository";
import fetchPlacePumpUseCase from "@/module/pumpMark/domain/usecase/fetch_placePump_usecase";
import fetchPlaceMeasurementRepository from "@/module/pumpMark/data/repository/fetch_placeMeasurement_repository";
import fetchPlaceMeasurementUseCase from "@/module/pumpMark/domain/usecase/fetch_placeMeasurement_usecase";
import fetchPlaceTrackingRepository from "@/module/pumpMark/data/repository/fetch_placeTracking_repository";
import fetchPlaceTrackingUseCase from "@/module/pumpMark/domain/usecase/fetch_placeTracking_usecase";

//Home
import fetchAccounPermissionsRepository from "@/core/data/repository/fetch_account_permissions_repository";
import fetchAccountPermissionsUseCase from "@/core/domain/usecase/fetch_account_permissions_usecase";
import DashboardController from "@/module/dashboard/controller/dashboard_controller";
import getCompanyByIDRepository from "@/module/dashboard/data/repository/get_company_by_id_repository";
import getCompanyByIDUseCase from "@/module/dashboard/domain/usecase/get_company_by_id_usecase";
import fetchRulerTrackingByIDUseCase from "@/module/ruler/domain/usecase/fetch_ruler_tracking_by_id_usecase";
import fetchRulerTrackingByIDRepository from "@/module/ruler/data/repository/fetch_ruler_tracking_by_id_repository";
import updateRulerTrackingLevelRepository from "@/module/ruler/data/repository/update_ruler_tracking_level_repository";
import updateRulerTrackingLevelUseCase from "@/module/ruler/domain/usecase/update_ruler_tracking_level_usecase";
import fetchCompanyMeasurementRepository from "@/module/dashboard/data/repository/fetch_company_mesurement_repository";
import fetchCompanyMeasurementUseCase from "@/module/dashboard/domain/usecase/fetch_company_measurement_usecase";
import fetchHardwareVersionRepository from "@/module/hardwareVersion/data/repository/fetch_hardware_version_repository";
import fetchHardwareVersionUseCase from "@/module/hardwareVersion/domain/usecase/fetch_hardware_version_usecase";
import deleteHardwareVersionRepository from "@/module/hardwareVersion/data/repository/delete_hardware_version_repository";
import deleteHardwareVersionUseCase from "@/module/hardwareVersion/domain/usecase/delete_hardware_version_usecase";
import createHardwareVersionRepository from "@/module/hardwareVersion/data/repository/create_hardware_version_repository";
import createHardwareVersionUseCase from "@/module/hardwareVersion/domain/usecase/create_hardware_version_usecase";
import HardwareVersionController from "@/module/hardwareVersion/controller/hardware_version_controller";
import fetchHarvestRepository from "@/module/harvest/data/repository/fetch_harvest_repository";
import fetchHarvestUseCase from "@/module/harvest/domain/usecase/fetch_harvest_usecase";
import deleteHarvestRepository from "@/module/harvest/data/repository/delete_harvest_repository";
import deleteHarvestUseCase from "@/module/harvest/domain/usecase/delete_harvest_usecase";
import createHarvestRepository from "@/module/harvest/data/repository/create_harvest_repository";
import changeHarvestRepository from '@/module/harvest/data/repository/change_harvest_repository';
import changeHarvestUseCase from '@/module/harvest/domain/usecase/change_harvest_usecase';
import HarvestController from '@/module/harvest/controller/harvest_controller';

//Notification
import fetchNotificationRepository from "@/module/notifications/data/repository/fetch_notification_repository";
import fetchNotificationUseCase from "@/module/notifications/domain/usecase/fetch_notification_usecase";
import changeNotificationRepository from "@/module/notifications/data/repository/change_notification_repository";
import changeNotificationUseCase from "@/module/notifications/domain/usecase/change_notification_usecase";
import fetchAccessPlanRepository from '@/module/accessPlan/data/repository/fetch_access_plan_repository';
import fetchAccessPlanUseCase from '@/module/accessPlan/domain/usecase/fetch_access_plan_usecase';
import deleteAccessPlanRepository from '@/module/accessPlan/data/repository/delete_access_plan_repository';
import deleteAccessPlanUseCase from '@/module/accessPlan/domain/usecase/delete_access_plan_usecase';
import createAccessPlanRepository from '@/module/accessPlan/data/repository/create_access_plan_repository';
import createAccessPlanUseCase from '@/module/accessPlan/domain/usecase/create_access_plan_usecase';
import changeAccessPlanRepository from '@/module/accessPlan/data/repository/change_access_plan_repository';
import changeAccessPlanUseCase from '@/module/accessPlan/domain/usecase/change_access_plan_usecase';
import AccessPlanController from '@/module/accessPlan/controller/access_plan_controller';
import NewAccountController from '@/module/newAccount/controller/new_account_controller';
import fetchPublicCompanyRepository from '@/module/company/data/repository/fetch_public_company_repository';
import fetchPublicCompanyUseCase from '@/module/company/domain/usecase/fetch_public_company_usecase';
import IntroController from '@/module/intro/controller/intro_controller';
import fetchPreRegistrationUseCase from '@/module/user/domain/usecase/fetch_pre_registration_usecase';
import PasswordRecoveryController from '@/module/passwordRecovery/controller/password_recovery_controller';
import sendMailRecoveryRepository from '@/module/passwordRecovery/data/repository/send_mail_recovery_repository';
import sendMailRecoveryUseCase from '@/module/passwordRecovery/domain/usecase/send_mail_recovery_usecase';
import { axosInstance, refreshUseCaseImpl } from './axios_instance_di';
import fetchNotificationByRulerIDRepository from '@/module/notifications/data/repository/fetch_notification_by_ruler_id_repository';
import fetchNotificationByRulerIDUseCase from '@/module/notifications/domain/usecase/fetch_notification_by_ruler_id_usecase';
import fetchUserProfileImageRepository from '@/module/user/data/repository/fetch_profile_image_repository';
import fetchUserProfileImageUseCase from '@/module/user/domain/usecase/fetch_user_profile_image_usecase';
import changeUserProfileImageRepository from '@/module/user/data/repository/change_profile_image_repository';
import changeUserProfileImageUseCase from '@/module/user/domain/usecase/change_user_profile_image_usecase';
import RulerGroupMarkController from '@/module/rulerGroupMark/controller/rulerGroupMark_controller';
import NewRulerMarkController from '@/module/newRulerMark/controller/newRulerMark_controller';
import ChecklistController from '@/module/checklist/controller/checklist_controller';
import AlertTypeController from '@/module/alertType/controller/alert_type_controller';
import AlertController from '@/module/alert/controller/alert_controller';
import fetchScreensRepository from '@/module/screen/data/repository/fetch_screens_repository';
import fetchScreensUseCase from '@/module/screen/domain/usecase/fetch_screens_usecase';
import changeScreenCompanyRepository from '@/module/screen/data/repository/change_screen_company_repository';
import changeScreenCompanyUseCase from '@/module/screen/domain/usecase/change_screen_company_usecase';
import ScreenController from '@/module/screen/controller/screen_controller';
import fetchScreenCompanyRepository from '@/module/screen/data/repository/fetch_screen__company_repository';
import fetchScreenCompanyUseCase from '@/module/screen/domain/usecase/fetch_screen_company_usecase';
import CallCenterController from '@/module/callCenter/controller/call_center_controller';
import fetchMarkRulerWith1HourRepository from '@/module/rulerMark/data/repository/fetch_markRuler_with_1_hour_repository';
import fetchMarkRulerWith1HourUseCase from '@/module/rulerMark/domain/usecase/fetch_markRuler_with_1_hour_usecase';
import EngineController from '@/module/engine/controller/engine_controller';

//engine
import fetchEngineRepository from '@/module/engine/data/repository/fetch_engine_repository'
import fetchEngineUseCase from '@/module/engine/domain/usecase/fetch_engine_usecase';
import createEngineUseCase from '@/module/engine/domain/usecase/create_engine_usecase';
import createEngineRepository from '@/module/engine/data/repository/create_engine_repository'
import changeEngineUseCase from '@/module/engine/domain/usecase/change_engine_usecase';
import changeEngineRepository from '@/module/engine/data/repository/change_engine_repository'
import deleteEngineUseCase from '@/module/engine/domain/usecase/delete_engine_usecase';
import deleteEngineRepository from '@/module/engine/data/repository/delete_engine_repository'

import fetchMarkStationWith1HourRepository from '@/module/stationMark/data/repository/fetch_markStation_with_1_hour_repository';
import fetchMarkStationWith1HourUseCase from '@/module/stationMark/domain/usecase/fetch_markStation_with_1_hour_usecase';
import StationController from '@/module/station/controller/station_controller';

import fetchStationTrackingByIDUseCase from "@/module/station/domain/usecase/fetch_station_tracking_by_id_usecase";
import fetchStationTrackingByIDRepository from "@/module/station/data/repository/fetch_station_tracking_by_id_repository";

import updateStationTrackingLevelRepository from '@/module/station/data/repository/update_station_data_repository';
import updateStationTrackingLevelUseCase from '@/module/station/domain/usecase/update_station_data_usecase';

const loginRepositoryImpl = loginRepostory(axosInstance)
const loginUseCaseImpl = loginUseCase(loginRepositoryImpl)

//Home
const fetchAccounPermissionsRepositoryImpl = fetchAccounPermissionsRepository(axosInstance)
const fetchAccountPermissionsUseCaseImpl = fetchAccountPermissionsUseCase(fetchAccounPermissionsRepositoryImpl)
const fetchScreenCompanyRepositoryImpl = fetchScreenCompanyRepository(axosInstance)
const fetchScreenCompanyUsecaseImpl = fetchScreenCompanyUseCase(fetchScreenCompanyRepositoryImpl)


//User
const fetchUserRepositoryImpl = fetchUserRepository(axosInstance)
const deleteUserRepositoryImpl = deleteUserRepository(axosInstance)
const fetchUserUseCaseImpl = fetchUserUseCase(fetchUserRepositoryImpl)
const deleteUserUseCaseImpl = deleteUserUseCase(deleteUserRepositoryImpl)
const createUserRepositoryImpl = createUserRepository(axosInstance)
const createUserUseCaseImpl = createUserUseCase(createUserRepositoryImpl)
const changeUserRepositoryImpl = changeUserRepository(axosInstance)
const changeUserUseCaseImpl = changeUserUseCase(changeUserRepositoryImpl)
const fetchUserProfileImageRepositoryImpl = fetchUserProfileImageRepository(axosInstance)
const fetchUserProfileImageUseCaseImpl = fetchUserProfileImageUseCase(fetchUserProfileImageRepositoryImpl)
const changeUserProfileImageRepositoryImpl = changeUserProfileImageRepository(axosInstance)
const changeUserProfileImageUseCaseImpl = changeUserProfileImageUseCase(changeUserProfileImageRepositoryImpl)

//City
const fetchCityRepositoryImpl = fetchCityRepository(axosInstance)
const fetchCityUseCaseImpl = fetchCityUseCase(fetchCityRepositoryImpl)

//Company
const fetchCompanyRepositoryImpl = fetchCompanyRepository(axosInstance)
const fetchCompanyUseCaseImpl = fetchCompanyUseCase(fetchCompanyRepositoryImpl)
const deleteCompanyRepositoryImpl = deleteCompanyRepository(axosInstance)
const deleteCompanyUseCaseImpl = deleteCompanyUseCase(deleteCompanyRepositoryImpl)
const createCompanyRepositoryImpl = createCompanyRepository(axosInstance)
const createCompanyUseCaseImpl = createCompanyUseCase(createCompanyRepositoryImpl)
const changeCompanyRepositoryImpl = changeCompanyRepository(axosInstance)
const changeCompanyUseCaseImpl = changeCompanyUseCase(changeCompanyRepositoryImpl)
const fetchCnpjRepositoryImpl = fetchCnpjRepository(axosInstance)
const fetchCnpjUseCaseImpl = fetchCnpjUseCase(fetchCnpjRepositoryImpl)
const fetchPublicCompanyRepositoryImpl = fetchPublicCompanyRepository(axosInstance)
const fetchPublicCompanyUseCaseImpl = fetchPublicCompanyUseCase(fetchPublicCompanyRepositoryImpl)

//Ruler
const fetchRulerRepositoryImpl = fetchRulerRepository(axosInstance)
const fetchRulerUseCaseImpl = fetchRulerUseCase(fetchRulerRepositoryImpl)
const deleteRulerRepositoryImpl = deleteRulerRepository(axosInstance)
const deleteRulerUseCaseImpl = deleteRulerUseCase(deleteRulerRepositoryImpl)
const createRulerRepositoryImpl = createRulerRepository(axosInstance)
const createRulerUseCaseImpl = createRulerUseCase(createRulerRepositoryImpl)
const changeRulerRepositoryImpl = changeRulerRepository(axosInstance)
const changeRulerUseCaseImpl = changeRulerUseCase(changeRulerRepositoryImpl)
const deleteMarkRulerRepositoryImpl = deleteMarkRulerRepository(axosInstance)
const deleteMarkRulerUseCaseImpl = deleteMarkRulerUseCase(deleteMarkRulerRepositoryImpl)

//RulerGroup
const fetchRulerGroupRepositoryImpl = fetchRulerGroupRepository(axosInstance)
const fetchRulerGroupUseCaseImpl = fetchRulerGroupUseCase(fetchRulerGroupRepositoryImpl)
const deleteRulerGroupRepositoryImpl = deleteRulerGroupRepository(axosInstance)
const deleteRulerGroupUseCaseImpl = deleteRulerGroupUseCase(deleteRulerGroupRepositoryImpl)
const createRulerGroupRepositoryImpl = createRulerGroupRepository(axosInstance)
const createRulerGroupUseCaseImpl = createRulerGroupUseCase(createRulerGroupRepositoryImpl)
const changeRulerGroupRepositoryImpl = changeRulerGroupRepository(axosInstance)
const changeRulerGroupUseCaseImpl = changeRulerGroupUseCase(changeRulerGroupRepositoryImpl)

//MarkRuler
const fetchMarkRulerRepositoryImpl = fetchMarkRulerRepository(axosInstance)
const fetchMarkRulerUseCaseImpl = fetchMarkRulerUseCase(fetchMarkRulerRepositoryImpl)
const fetchMarkRulerWith30MinutesRepositoryImpl = fetchMarkRulerWith30MinutesRepository(axosInstance)
const fetchMarkRulerWith30MinutesUseCaseImpl = fetchMarkRulerWith30MinutesUseCase(fetchMarkRulerWith30MinutesRepositoryImpl)
const fetchMarkRulerWith1HourRepositoryImpl = fetchMarkRulerWith1HourRepository(axosInstance)
const fetchMarkRulerWith1HourUseCaseImpl = fetchMarkRulerWith1HourUseCase(fetchMarkRulerWith1HourRepositoryImpl)

//Station
const fetchStationRepositoryImpl = fetchStationRepository(axosInstance)
const fetchStationUseCaseImpl = fetchStationUseCase(fetchStationRepositoryImpl)
const deleteStationRepositoryImpl = deleteStationRepository(axosInstance)
const deleteStationUseCaseImpl = deleteStationUseCase(deleteStationRepositoryImpl)
const createStationRepositoryImpl = createStationRepository(axosInstance)
const createStationUseCaseImpl = createStationUseCase(createStationRepositoryImpl)
const changeStationRepositoryImpl = changeStationRepository(axosInstance)
const changeStationUseCaseImpl = changeStationUseCase(changeStationRepositoryImpl)
const deleteMarkStationRepositoryImpl = deleteMarkStationRepository(axosInstance)
const deleteMarkStationUseCaseImpl = deleteMarkStationUseCase(deleteMarkStationRepositoryImpl)

//MarkStation
const fetchMarkStationRepositoryImpl = fetchMarkStationRepository(axosInstance)
const fetchMarkStationUseCaseImpl = fetchMarkStationUseCase(fetchMarkStationRepositoryImpl)
const fetchMarkStationWith30MinutesRepositoryImpl = fetchMarkStationWith30MinutesRepository(axosInstance)
const fetchMarkStationWith30MinutesUseCaseImpl = fetchMarkStationWith30MinutesUseCase(fetchMarkStationWith30MinutesRepositoryImpl)
const fetchMarkStationWith1HourRepositoryImpl = fetchMarkStationWith1HourRepository(axosInstance)
const fetchMarkStationWith1HourUseCaseImpl = fetchMarkStationWith1HourUseCase(fetchMarkStationWith1HourRepositoryImpl)

//MarkPump
const fetchMarkPumpRepositoryImpl = fetchMarkPumpRepository(axosInstance)
const fetchMarkPumpUseCaseImpl = fetchMarkPumpUseCase(fetchMarkPumpRepositoryImpl)
const fetchPlacePumpRepositoryImpl = fetchPlacePumpRepository(axosInstance)
const fetchPlacePumpUseCaseImpl = fetchPlacePumpUseCase(fetchPlacePumpRepositoryImpl)
const fetchPlaceMeasurementRepositoryImpl = fetchPlaceMeasurementRepository(axosInstance)
const fetchPlaceMeasurementUseCaseImpl = fetchPlaceMeasurementUseCase(fetchPlaceMeasurementRepositoryImpl)
const fetchPlaceTrackingRepositoryImpl = fetchPlaceTrackingRepository(axosInstance)
const fetchPlaceTrackingUseCaseImpl = fetchPlaceTrackingUseCase(fetchPlaceTrackingRepositoryImpl)



//Place
const fetchPlaceRepositoryImpl = fetchPlaceRepository(axosInstance)
const fetchPlaceUseCaseImpl = fetchPlaceUseCase(fetchPlaceRepositoryImpl)
const deletePlaceRepositoryImpl = deletePlaceRepository(axosInstance)
const deletePlaceUseCaseImpl = deletePlaceUseCase(deletePlaceRepositoryImpl)
const createPlaceRepositoryImpl = createPlaceRepository(axosInstance)
const createPlaceUseCaseImpl = createPlaceUseCase(createPlaceRepositoryImpl)
const changePlaceRepositoryImpl = changePlaceRepository(axosInstance)
const changePlaceUseCaseImpl = changePlaceUseCase(changePlaceRepositoryImpl)

//Checklist
const fetchChecklistRepositoryImpl = fetchChecklistRepository(axosInstance)
const fetchChecklistUseCaseImpl = fetchChecklistUseCase(fetchChecklistRepositoryImpl)
const deleteChecklistRepositoryImpl = deleteChecklistRepository(axosInstance)
const deleteChecklistUseCaseImpl = deleteChecklistUseCase(deleteChecklistRepositoryImpl)
const createChecklistRepositoryImpl = createChecklistRepository(axosInstance)
const createChecklistUseCaseImpl = createChecklistUseCase(createChecklistRepositoryImpl)
const changeChecklistRepositoryImpl = changeChecklistRepository(axosInstance)
const changeChecklistUseCaseImpl = changeChecklistUseCase(changeChecklistRepositoryImpl)

//Call Center
const fetchCallCenterRepositoryImpl = fetchCallCenterRepository(axosInstance)
const fetchCallCenterUseCaseImpl = fetchCallCenterUseCase(fetchCallCenterRepositoryImpl)
const deleteCallCenterRepositoryImpl = deleteCallCenterRepository(axosInstance)
const deleteCallCenterUseCaseImpl = deleteCallCenterUseCase(deleteCallCenterRepositoryImpl)
const createCallCenterRepositoryImpl = createCallCenterRepository(axosInstance)
const createCallCenterUseCaseImpl = createCallCenterUseCase(createCallCenterRepositoryImpl)
const changeCallCenterRepositoryImpl = changeCallCenterRepository(axosInstance)
const changeCallCenterUseCaseImpl = changeCallCenterUseCase(changeCallCenterRepositoryImpl)



//Alert Type
const fetchAlertTypeRepositoryImpl = fetchAlertTypeRepository(axosInstance)
const fetchAlertTypeUseCaseImpl = fetchAlertTypeUseCase(fetchAlertTypeRepositoryImpl)
const deleteAlertTypeRepositoryImpl = deleteAlertTypeRepository(axosInstance)
const deleteAlertTypeUseCaseImpl = deleteAlertTypeUseCase(deleteAlertTypeRepositoryImpl)
const createAlertTypeRepositoryImpl = createAlertTypeRepository(axosInstance)
const createAlertTypeUseCaseImpl = createAlertTypeUseCase(createAlertTypeRepositoryImpl)
const changeAlertTypeRepositoryImpl = changeAlertTypeRepository(axosInstance)
const changeAlertTypeUseCaseImpl = changeAlertTypeUseCase(changeAlertTypeRepositoryImpl)

//Alert
const fetchAlertRepositoryImpl = fetchAlertRepository(axosInstance)
const fetchAlertUseCaseImpl = fetchAlertUseCase(fetchAlertRepositoryImpl)
const createAlertRepositoryImpl = createAlertRepository(axosInstance)
const createAlertUseCaseImpl = createAlertUseCase(createAlertRepositoryImpl)
const changeAlertRepositoryImpl = changeAlertRepository(axosInstance)
const changeAlertUseCaseImpl = changeAlertUseCase(changeAlertRepositoryImpl)

//Pump
const fetchPumpRepositoryImpl = fetchPumpRepository(axosInstance)
const fetchPumpUseCaseImpl = fetchPumpUseCase(fetchPumpRepositoryImpl)
const deletePumpRepositoryImpl = deletePumpRepository(axosInstance)
const deletePumpUseCaseImpl = deletePumpUseCase(deletePumpRepositoryImpl)
const createPumpRepositoryImpl = createPumpRepository(axosInstance)
const createPumpUseCaseImpl = createPumpUseCase(createPumpRepositoryImpl)
const changePumpRepositoryImpl = changePumpRepository(axosInstance)
const changePumpUseCaseImpl = changePumpUseCase(changePumpRepositoryImpl)
const deletePumpMarkRepositoryImpl = deletePumpMarkRepository(axosInstance)
const deletePumpMarkUseCaseImpl = deletePumpMarkUseCase(deletePumpMarkRepositoryImpl)

//Pump Moto
const fetchPumpMotoRepositoryImpl = fetchPumpMotoRepository(axosInstance)
const fetchPumpMotoUseCaseImpl = fetchPumpMotoUseCase(fetchPumpMotoRepositoryImpl)
const deletePumpMotoRepositoryImpl = deletePumpMotoRepository(axosInstance)
const deletePumpMotoUseCaseImpl = deletePumpMotoUseCase(deletePumpMotoRepositoryImpl)
const createPumpMotoRepositoryImpl = createPumpMotoRepository(axosInstance)
const createPumpMotoUseCaseImpl = createPumpMotoUseCase(createPumpMotoRepositoryImpl)
const changePumpMotoRepositoryImpl = changePumpMotoRepository(axosInstance)
const changePumpMotoUseCaseImpl = changePumpMotoUseCase(changePumpMotoRepositoryImpl)

//Remote eControl
const fetchRemoteControlRepositoryImpl = fetchRemoteControlRepository(axosInstance)
const fetchRemoteControlUseCaseImpl = fetchRemoteControlUseCase(fetchRemoteControlRepositoryImpl)
const deleteRemoteControlRepositoryImpl = deleteRemoteControlRepository(axosInstance)
const deleteRemoteControlUseCaseImpl = deleteRemoteControlUseCase(deleteRemoteControlRepositoryImpl)
const createRemoteControlRepositoryImpl = createRemoteControlRepository(axosInstance)
const createRemoteControlUseCaseImpl = createRemoteControlUseCase(createRemoteControlRepositoryImpl)
const changeRemoteControlRepositoryImpl = changeRemoteControlRepository(axosInstance)
const changeRemoteControlUseCaseImpl = changeRemoteControlUseCase(changeRemoteControlRepositoryImpl)

//Engine
const fetchEngineRepositoryImpl = fetchEngineRepository(axosInstance)
const fetchEngineUseCaseImpl = fetchEngineUseCase(fetchEngineRepositoryImpl)
const deleteEngineRepositoryImpl = deleteEngineRepository(axosInstance)
const deleteEngineUseCaseImpl = deleteEngineUseCase(deleteEngineRepositoryImpl)
const createEngineRepositoryImpl = createEngineRepository(axosInstance)
const createEngineUseCaseImpl = createEngineUseCase(createEngineRepositoryImpl)
const changeEngineRepositoryImpl = changeEngineRepository(axosInstance)
const changeEngineUseCaseImpl = changeEngineUseCase(changeEngineRepositoryImpl)

const fetchRulerLogsRepositoryImpl = fetchRulerLogsRepository(axosInstance)
const fetchRulerLogsUseCaseImpl = fetchRulerLogsUseCase(fetchRulerLogsRepositoryImpl)

const getCompanyByIDRepositoryImpl = getCompanyByIDRepository(axosInstance)
const getCompanyByIDUsecaseImpl = getCompanyByIDUseCase(getCompanyByIDRepositoryImpl)

const fetchRulerTrackingByIDRepositoryImpl = fetchRulerTrackingByIDRepository(axosInstance)
const fetchRulerTrackingByIDUseCaseImpl = fetchRulerTrackingByIDUseCase(fetchRulerTrackingByIDRepositoryImpl)

const updateRulerTrackingLevelRepositoryImpl = updateRulerTrackingLevelRepository(axosInstance)
const updateRulerTrackingLevelUseCaseImpl = updateRulerTrackingLevelUseCase(updateRulerTrackingLevelRepositoryImpl)

const fetchPumpTrackingByIDRepositoryImpl = fetchPumpTrackingByIDRepository(axosInstance)
const fetchPumpTrackingByIDUseCaseImpl = fetchPumpTrackingByIDUseCase(fetchPumpTrackingByIDRepositoryImpl)

const updatePumpTrackingLevelRepositoryImpl = updatePumpTrackingLevelRepository(axosInstance)
const updatePumpTrackingLevelUseCaseImpl = updatePumpTrackingLevelUseCase(updatePumpTrackingLevelRepositoryImpl)

const fetchCompanyMeasurementRepositoryImpl = fetchCompanyMeasurementRepository(axosInstance)
const fetchCompanyMeasurementUseCaseImpl = fetchCompanyMeasurementUseCase(fetchCompanyMeasurementRepositoryImpl)

const fetchStationTrackingByIDRepositoryImpl = fetchStationTrackingByIDRepository(axosInstance)
const fetchStationTrackingByIDUseCaseImpl = fetchStationTrackingByIDUseCase(fetchStationTrackingByIDRepositoryImpl)

const updateStationTrackingLevelRepositoryImpl = updateStationTrackingLevelRepository(axosInstance)
const updateStationTrackingLevelUseCaseImpl = updateStationTrackingLevelUseCase(updateStationTrackingLevelRepositoryImpl)


//HardwareVersion
const fetchHardwareVersionRepositoryImpl = fetchHardwareVersionRepository(axosInstance)
const fetchHardwareVersionUseCaseImpl = fetchHardwareVersionUseCase(fetchHardwareVersionRepositoryImpl)
const deleteHardwareVersionRepositoryImpl = deleteHardwareVersionRepository(axosInstance)
const deleteHardwareVersionUseCaseImpl = deleteHardwareVersionUseCase(deleteHardwareVersionRepositoryImpl)
const createHardwareVersionRepositoryImpl = createHardwareVersionRepository(axosInstance)
const createHardwareVersionUseCaseImpl = createHardwareVersionUseCase(createHardwareVersionRepositoryImpl)



//Harvest
const fetchHarvestRepositoryImpl = fetchHarvestRepository(axosInstance)
const fetchHarvestUseCaseImpl = fetchHarvestUseCase(fetchHarvestRepositoryImpl)
const deleteHarvestRepositoryImpl = deleteHarvestRepository(axosInstance)
const deleteHarvestUseCaseImpl = deleteHarvestUseCase(deleteHarvestRepositoryImpl)
const createHarvestRepositoryImpl = createHarvestRepository(axosInstance)
const createHarvestUseCaseImpl = createHarvestUseCase(createHarvestRepositoryImpl)
const changeHarvestRepositoryImpl = changeHarvestRepository(axosInstance)
const changeHarvestUseCaseImpl = changeHarvestUseCase(changeHarvestRepositoryImpl)

//Notification
const fetchNotificationRepositoryImpl = fetchNotificationRepository(axosInstance)
const fetchNotificationUseCaseImpl = fetchNotificationUseCase(fetchNotificationRepositoryImpl)
const changeNotificationRepositoryImpl = changeNotificationRepository(axosInstance)
const changeNotificationUseCaseImpl = changeNotificationUseCase(changeNotificationRepositoryImpl)

//Company
const fetchAccessPlanRepositoryImpl = fetchAccessPlanRepository(axosInstance)
const fetchAccessPlanUseCaseImpl = fetchAccessPlanUseCase(fetchAccessPlanRepositoryImpl)
const deleteAccessPlanRepositoryImpl = deleteAccessPlanRepository(axosInstance)
const deleteAccessPlanUseCaseImpl = deleteAccessPlanUseCase(deleteAccessPlanRepositoryImpl)
const createAccessPlanRepositoryImpl = createAccessPlanRepository(axosInstance)
const createAccessPlanUseCaseImpl = createAccessPlanUseCase(createAccessPlanRepositoryImpl)
const changeAccessPlanRepositoryImpl = changeAccessPlanRepository(axosInstance)
const changeAccessPlanUseCaseImpl = changeAccessPlanUseCase(changeAccessPlanRepositoryImpl)

const fetchPreRegistrationRepositoryImpl = fetchPreRegistrationRepository(axosInstance)
const fetchPreRegistrationUseCaseImpl = fetchPreRegistrationUseCase(fetchPreRegistrationRepositoryImpl)


const sendMailRecoveryRepositoryImpl = sendMailRecoveryRepository(axosInstance)
const sendMailRecoveryUseCaseImpl = sendMailRecoveryUseCase(sendMailRecoveryRepositoryImpl)

const fetchNotificationByRulerIDRepositoryImpl = fetchNotificationByRulerIDRepository(axosInstance)
const fetchNotificationByRulerIDUseCaseImpl = fetchNotificationByRulerIDUseCase(fetchNotificationByRulerIDRepositoryImpl)

const fetchScreensRepositoryImpl = fetchScreensRepository(axosInstance)
const fetchScreensUseCaseImpl = fetchScreensUseCase(fetchScreensRepositoryImpl)
const changeScreenCompanyRepositoryImpl = changeScreenCompanyRepository(axosInstance)
const changeScreenCompanyUsecaseImpl = changeScreenCompanyUseCase(changeScreenCompanyRepositoryImpl)

const screenController = (context: any) => new ScreenController(context, fetchScreensUseCaseImpl, changeScreenCompanyUsecaseImpl, fetchCompanyUseCaseImpl, fetchScreenCompanyUsecaseImpl)

const accessPlanController = (context: any) => new AccessPlanController(
    context,
    fetchAccessPlanUseCaseImpl,
    deleteAccessPlanUseCaseImpl,
    createAccessPlanUseCaseImpl,
    changeAccessPlanUseCaseImpl,
)
const dashboardController = (context: any) => new DashboardController(
    context,
    getCompanyByIDUsecaseImpl,
    fetchRulerUseCaseImpl,
    fetchPlaceUseCaseImpl,
    fetchPumpUseCaseImpl,
    fetchMarkRulerUseCaseImpl,
    fetchCompanyMeasurementUseCaseImpl,
    fetchPlacePumpUseCaseImpl,
    fetchMarkPumpUseCaseImpl,
)
const defaultController = (context: any) => new DefaultController(
    context,
    refreshUseCaseImpl
);
const loginController = (context: any) => new LoginController(
    context,
    loginUseCaseImpl,
)
const homeController = (context: any) => new HomeController(
    context,
    fetchAccountPermissionsUseCaseImpl,
    fetchNotificationUseCaseImpl,
    fetchUserProfileImageUseCaseImpl,
    changeUserProfileImageUseCaseImpl,
    fetchScreenCompanyUsecaseImpl
);
const userController = (context: any) => new UserController(
    context,
    fetchUserUseCaseImpl,
    deleteUserUseCaseImpl,
    createUserUseCaseImpl,
    changeUserUseCaseImpl,
    fetchCompanyUseCaseImpl,
    fetchPreRegistrationUseCaseImpl
)
const companyController = (context: any) => new CompanyController(
    context,
    fetchCityUseCaseImpl,
    fetchCompanyUseCaseImpl,
    deleteCompanyUseCaseImpl,
    createCompanyUseCaseImpl,
    changeCompanyUseCaseImpl,
    fetchCnpjUseCaseImpl,
)
const rulerController = (context: any) => new RulerController(
    context,
    fetchPlaceUseCaseImpl,
    fetchCompanyUseCaseImpl,
    fetchRulerUseCaseImpl,
    deleteRulerUseCaseImpl,
    createRulerUseCaseImpl,
    changeRulerUseCaseImpl,
    fetchRulerTrackingByIDUseCaseImpl,
    updateRulerTrackingLevelUseCaseImpl,
    deleteMarkRulerUseCaseImpl,
    getCompanyByIDUsecaseImpl,
)

const stationController = (context: any) => new StationController(
    context,
    fetchCompanyUseCaseImpl,
    fetchStationUseCaseImpl,
    deleteStationUseCaseImpl,
    createStationUseCaseImpl,
    changeStationUseCaseImpl,
    fetchStationTrackingByIDUseCaseImpl,
    updateStationTrackingLevelUseCaseImpl,
    deleteMarkStationUseCaseImpl,
    getCompanyByIDUsecaseImpl,
)

const rulerGroupController = (context: any) => new RulerGroupController(
    context,
    fetchRulerGroupUseCaseImpl,
    deleteRulerGroupUseCaseImpl,
    createRulerGroupUseCaseImpl,
    changeRulerGroupUseCaseImpl,
    fetchRulerUseCaseImpl,
    fetchCompanyUseCaseImpl
)
const rulerGroupMarkController = (context: any) => new RulerGroupMarkController(
    context,
    fetchRulerGroupUseCaseImpl,
    fetchMarkRulerUseCaseImpl,
    fetchHarvestUseCaseImpl,
    fetchMarkRulerWith30MinutesUseCaseImpl,
    fetchMarkRulerWith1HourUseCaseImpl
)
const placeController = (context: any) => new PlaceController(
    context,
    fetchPlaceUseCaseImpl,
    deletePlaceUseCaseImpl,
    createPlaceUseCaseImpl,
    changePlaceUseCaseImpl,
    getCompanyByIDUsecaseImpl,
    fetchCompanyUseCaseImpl,
)
const checklistController = (context: any) => new ChecklistController(
    context,
    fetchChecklistUseCaseImpl,
    deleteChecklistUseCaseImpl,
    createChecklistUseCaseImpl,
    changeChecklistUseCaseImpl,
    getCompanyByIDUsecaseImpl,
    fetchCompanyUseCaseImpl,
)

const callCenterController = (context: any) => new CallCenterController(
    context,
    fetchCallCenterUseCaseImpl,
    deleteCallCenterUseCaseImpl,
    createCallCenterUseCaseImpl,
    changeCallCenterUseCaseImpl,
    getCompanyByIDUsecaseImpl,
    fetchCompanyUseCaseImpl,
    fetchAlertTypeUseCaseImpl,
    fetchUserUseCaseImpl,

)

const alertTypeController = (context: any) => new AlertTypeController(
    context,
    fetchAlertTypeUseCaseImpl,
    deleteAlertTypeUseCaseImpl,
    createAlertTypeUseCaseImpl,
    changeAlertTypeUseCaseImpl,
    getCompanyByIDUsecaseImpl,
    fetchCompanyUseCaseImpl,
)

const alertController = (context: any) => new AlertController(
    context,
    fetchAlertUseCaseImpl,
    createAlertUseCaseImpl,
    changeAlertUseCaseImpl,
    fetchCompanyUseCaseImpl,
    fetchUserUseCaseImpl,
    fetchAlertTypeUseCaseImpl,
    fetchCallCenterUseCaseImpl
)

const engineController = (context: any) => new EngineController(
    context,
    fetchPlaceUseCaseImpl,
    fetchEngineUseCaseImpl,
    deleteEngineUseCaseImpl,
    createEngineUseCaseImpl,
    changeEngineUseCaseImpl,
    fetchCompanyUseCaseImpl
)
const pumpController = (context: any) => new PumpController(
    context,
    fetchPlaceUseCaseImpl,
    fetchPumpUseCaseImpl,
    deletePumpUseCaseImpl,
    createPumpUseCaseImpl,
    changePumpUseCaseImpl,
    fetchPumpTrackingByIDUseCaseImpl,
    updatePumpTrackingLevelUseCaseImpl,
    deletePumpMarkUseCaseImpl,
    fetchCompanyUseCaseImpl,
)

const pumpMotoController = (context: any) => new PumpMotoController(
    context,
    fetchPlaceUseCaseImpl,
    fetchPumpMotoUseCaseImpl,
    deletePumpMotoUseCaseImpl,
    createPumpMotoUseCaseImpl,
    changePumpMotoUseCaseImpl,
    fetchCompanyUseCaseImpl,
)

const remoteControlController = (context: any) => new RemoteControlController(
    context,
    fetchPlaceUseCaseImpl,
    fetchRemoteControlUseCaseImpl,
    deleteRemoteControlUseCaseImpl,
    createRemoteControlUseCaseImpl,
    changeRemoteControlUseCaseImpl,
    fetchCompanyUseCaseImpl,
)

const logsController = (context: any) => new LogsController(
    context,
    fetchRulerLogsUseCaseImpl,
)
const rulerMarkController = (context: any) => new RulerMarkController(
    context,
    fetchRulerUseCaseImpl,
    fetchMarkRulerUseCaseImpl,
    changeRulerUseCaseImpl,
    fetchHarvestUseCaseImpl,
)
const newRulerMarkController = (context: any) => new NewRulerMarkController(
    context,
    fetchRulerUseCaseImpl,
    fetchMarkRulerUseCaseImpl,
    changeRulerUseCaseImpl,
    fetchHarvestUseCaseImpl,
    fetchMarkRulerWith30MinutesUseCaseImpl,
)
const pumpMarkController = (context: any) => new PumpMarkController(
    context,
    fetchPlaceUseCaseImpl,
    fetchPlacePumpUseCaseImpl,
    fetchMarkPumpUseCaseImpl,
    fetchPlaceMeasurementUseCaseImpl,
    fetchHarvestUseCaseImpl,
    fetchPlaceTrackingUseCaseImpl,
)
const hardwareVersionController = (context: any) => new HardwareVersionController(
    context,
    fetchHardwareVersionUseCaseImpl,
    deleteHardwareVersionUseCaseImpl,
    createHardwareVersionUseCaseImpl,
)
const harvestController = (context: any) => new HarvestController(
    context,
    fetchHarvestUseCaseImpl,
    deleteHarvestUseCaseImpl,
    createHarvestUseCaseImpl,
    changeHarvestUseCaseImpl,
    fetchCompanyUseCaseImpl,
)
const notificationController = (context: any) => new NotificationController(
    context,
    fetchNotificationUseCaseImpl,
    changeNotificationUseCaseImpl,
    fetchRulerUseCaseImpl,
    fetchNotificationByRulerIDUseCaseImpl
)

const newAccountController = (context: any) => new NewAccountController(
    context,
    fetchPublicCompanyUseCaseImpl,
    createUserUseCaseImpl,
    refreshUseCaseImpl,
    loginUseCaseImpl,
)


const introController = (context: any) => new IntroController(
    context,
    refreshUseCaseImpl,
    fetchCnpjUseCaseImpl,
    fetchCityUseCaseImpl,
    createCompanyUseCaseImpl,
    changeUserUseCaseImpl,
    fetchAccessPlanUseCaseImpl,
    changeCompanyUseCaseImpl,
    getCompanyByIDUsecaseImpl
)

const passwordRecoveryController = (context: any) => new PasswordRecoveryController(
    context,
    sendMailRecoveryUseCaseImpl,
    changeUserUseCaseImpl
)

export {
    defaultController,
    loginController,
    homeController,
    userController,
    companyController,
    rulerController,
    stationController,
    placeController,
    pumpController,
    pumpMotoController,
    remoteControlController,
    engineController,
    logsController,
    rulerMarkController,
    dashboardController,
    pumpMarkController,
    hardwareVersionController,
    harvestController,
    notificationController,
    accessPlanController,
    newAccountController,
    introController,
    passwordRecoveryController,
    rulerGroupController,
    rulerGroupMarkController,
    newRulerMarkController,
    checklistController,
    alertController,
    screenController,
    alertTypeController,
    callCenterController
}

