import { Checklist } from './../../domain/model/checklist';
import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';


export interface ChangeChecklistRepository {
  (id: number, data: object): Promise<Checklist>
}

const changeChecklistRepository = (axios: AxiosInstance): ChangeChecklistRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/checklist/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeChecklistRepository

