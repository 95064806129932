<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      v-if="controller.preRegistrations.length > 0"
      :items="controller.preRegistrations"
      :headers="controller.columns"
      disable-pagination
      hide-default-footer
      fixed-header
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuários aguardando liberação</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-start">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="hadPermission(1, 3) && item.preRegistration"
                icon
                @click="controller.updatePreRegistration(item)"
                class="px-2"
              >
                <v-icon
                  small
                  class="mx-2"
                  color="red"
                >mdi-alert-decagram</v-icon>
              </v-btn>
            </template>
            <span>Aguardando liberação, clique para liberar o usuário</span>
          </v-tooltip>
          <v-btn
            v-if="hadPermission(1, 4)"
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.userPagination.total"
      :items="controller.userPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-start">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            v-if="hadPermission(1, 3)"
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hadPermission(1, 4)"
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuários</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="hadPermission(1, 2)"
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>

            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção usuário
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-checkbox
                    v-model="controller.user.active"
                    color="primary"
                    label="Ativo"
                  ></v-checkbox>
                  <!-- <v-checkbox
                    v-model="controller.user.preRegistration"
                    color="primary"
                    label="Aguardando liberação"
                  ></v-checkbox> -->
                  <v-select
                    label="Grupo de Usuário"
                    v-model="controller.user.accountGroupID"
                    :items="controller.groupItems"
                    item-text="group"
                    item-value="id"
                    filled
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-select>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Nome Completo"
                    v-model="controller.user.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Login"
                    v-model="controller.user.login"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Email"
                    v-model="controller.user.email"
                    :rules="emailRules"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    :type="passwordFieldType"
                    :append-icon="eyeIcon"
                    @click:append="togglePasswordVisibility"
                    color="primary"
                    label="Password"
                    v-model="controller.user.password"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-autocomplete
                    v-if="isAdmin()"
                    filled
                    dense
                    color="secondary"
                    label="Empresa"
                    v-model="controller.user.companyID"
                    :search-input.sync="controller.searchCompany"
                    :items="controller.companyPagination.items"
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn 
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { userController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: userController(context),
    passwordWasEmpty: false,
    emailRules: [
      (v) => !!v || "Preencha o campo",
      (v) => /.+@.+/.test(v) || "Deve ser um E-mail",
    ],
  }),
  computed: {
    passwordFieldType() {
      if (this.controller.flagOption === 'change') {
        if (!(this.controller.user.password === '') && !this.passwordWasEmpty) {
          return 'password';
        }
      }
      return this.controller.showPassword ? 'text' : 'password';
    },
    eyeIcon() {
    if (this.controller.flagOption === 'change') {
      if (!(this.controller.user.password === '') && !this.passwordWasEmpty) {
        return null;
      }
    }
    return this.controller.showPassword ? 'mdi-eye' : 'mdi-eye-off';
  },
  },
  methods: {
    togglePasswordVisibility() {
      this.controller.showPassword = !this.controller.showPassword;
    },
  },
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    'controller.user.password'(newValue) {
      if (!newValue) {
        this.passwordWasEmpty = true;
      }
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
  },
};
</script>