export const headers = [
  {
    text: 'Versão',
    align: 'start',
    sortable: true,
    value: 'version',
  },
  {
    text: 'Arquivo',
    value: 'binaryPath',
    sortable: true
  },
  {
    text: 'Descrição',
    value: 'description',
    sortable: false
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }
]
