<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.rulerGroupPagination.total"
      :items="controller.rulerGroupPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(4, 3)"
          >
            <v-icon small color="blue">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
            v-if="hadPermission(4, 4)"
          >
            <v-icon small color="red">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Grupos de Réguas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            width="800"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="hadPermission(4, 2)"
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Grupo de Réguas
                  <v-spacer></v-spacer>
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="pt-3">
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-form ref="crud" lazy-validation>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nome do Grupo"
                        v-model="controller.rulerGroup.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <v-autocomplete
                        v-if="isAdmin()"
                        filled
                        dense
                        color="primary"
                        label="Empresa"
                        v-model="controller.rulerGroup.companyID"
                        :search-input.sync="controller.searchCompany"
                        :items="controller.companyPagination.items"
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                        clearable
                        @change="controller.changeCompanyRulers($event)"
                      >
                      </v-autocomplete>
                      <v-autocomplete
                        filled
                        dense
                        color="primary"
                        label="Selecionar Régua"
                        v-model="controller.rulerGroup.items"
                        :search-input.sync="controller.searchRuler"
                        :items="controller.rulerPagination.items"
                        item-text="name"
                        item-value="id"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <span>{{ item.name }}</span>
                                  <v-spacer></v-spacer>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click.stop="controller.addRuler(item)"
                                      >
                                        <v-icon color="primary"
                                          >mdi-plus</v-icon
                                        >
                                      </v-btn>
                                    </template>
                                    <span>Adicionar régua</span>
                                  </v-tooltip>
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-form>
                  </v-col>
                  <v-divider
                    v-show="$vuetify.breakpoint.mdAndUp"
                    vertical
                  ></v-divider>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div v-if="controller.rulerGroup.items.length > 0">
                      <div class="pt-5 pb-2 d-flex justify-center">
                        <h2 class="font-weight-light">Réguas</h2>
                      </div>
                      <v-divider></v-divider>
                    </div>
                    <v-list dense nav shaped>
                      <v-list-item-group mandatory>
                        <draggable
                          v-model="controller.rulerGroup.items"
                          @change="controller.reOrder()"
                        >
                          <template
                            v-for="(item, i) in controller.rulerGroup.items"
                          >
                            <v-list-item
                              :key="i"
                              style="border: 1px solid #424242"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-menu</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  item.name
                                }}</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-icon class="d-flex align-center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      @click.stop="controller.removeRuler(item)"
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Remover régua</span>
                                </v-tooltip>
                              </v-list-item-icon>
                            </v-list-item>
                          </template>
                        </draggable>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn @click="controller.close()" outlined color="red">
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn color="primary" @click="controller.create()">
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!--       <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Grupos de Réguas</v-toolbar-title>
          <v-spacer></v-spacer>
           <v-select
            @change="controller.paginate()"
            class="px-2"
            style="max-width: 200px"
            filled
            dense
            single-line
            color="primary"
            hide-details
            v-model="controller.status"
            :items="controller.visibilityStatus"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            :max-width="controller.hardwareMesurement ? 800 : 500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="hadPermission(4, 2)"
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Réguas
                  <v-spacer></v-spacer>
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="pt-3">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row justify="center">
                    <v-switch
                      hide-details
                      dense
                      v-model="controller.hardwareMesurement"
                      inset
                      label="Medições por hardware"
                    ></v-switch>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        filled
                        dense
                        color="primary"
                        label="Status"
                        v-model="controller.ruler.status"
                        :items="controller.itemStatus"
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-select>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nome da régua"
                        v-model="controller.ruler.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <div class="d-flex flex-column align-center">
                        <h4>Selecionar cor</h4>
                        <div>
                          <v-color-picker
                            class="no-alpha"
                            dot-size="25"
                            hide-mode-switch
                            mode="hexa"
                            swatches-max-height="200"
                            v-model="controller.ruler.color"
                            hide-inputs
                          ></v-color-picker>
                        </div>
                      </div>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nível máximo"
                        type="number"
                        v-model.number="controller.ruler.higherLevel"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Nível mínimo"
                        type="number"
                        v-model.number="controller.ruler.lowerLevel"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        readonly
                        color="primary"
                        label="Latitude"
                        v-model="controller.ruler.latitude"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        readonly
                        color="primary"
                        label="Longitude"
                        v-model="controller.ruler.longitude"
                      >
                      </v-text-field>

                      <form-maps
                        :center="controller.center"
                        :zoom="controller.zoom"
                        @get-location="controller.getLocation($event)"
                      ></form-maps>
                      <v-autocomplete
                        class="pt-4"
                        filled
                        dense
                        color="primary"
                        label="Local"
                        v-model="controller.ruler.placeID"
                        :search-input.sync="controller.searchPlace"
                        :items="controller.placePagination.items"
                        item-text="name"
                        item-value="id"
                      >
                      </v-autocomplete>
                      <v-autocomplete
                        v-if="isAdmin()"
                        filled
                        dense
                        color="primary"
                        label="Empresa"
                        v-model="controller.ruler.companyID"
                        :search-input.sync="controller.searchCompany"
                        :items="controller.companyPagination.items"
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-divider
                      vertical
                      v-if="controller.hardwareMesurement"
                    ></v-divider>
                    <v-col v-if="controller.hardwareMesurement">
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Número do equipamento"
                        placeholder="ID do hardware"
                        v-model="controller.ruler.terminalNumber"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <div>Fórmula para cálculo de equivalência de nível</div>
                      <div class="font-weight-bold pb-8">
                        (coeficiente - nível) / fator de divisão
                      </div>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Coeficiente"
                        v-model.number="controller.ruler.coefficient"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                      <v-text-field
                        filled
                        dense
                        color="primary"
                        label="Fator de divisão"
                        v-model.number="controller.ruler.divisionFactor"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import { rulerGroupController } from "@/di/di";
import _ from "lodash";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data: (context) => ({
    controller: rulerGroupController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    /*     "controller.optionsRuler"() {
      this.controller.watchOptionsRuler();
    },
    "controller.searchRuler": _.debounce(function () {
      this.controller.watchSearchRuler();
    }, 500), */
  },
};
</script>

<style scoped>
.p20 {
  padding: 20px;
}
.normal {
  background-color: grey;
}
.dragArea {
  min-height: 10px;
}
.sortable-chosen {
  opacity: 0.7;
  background-color: #dcdcdc;
}
.sortable-ghost {
  background-color: #dcdcdc;
}
</style>