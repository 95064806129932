export const headers = [
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Data inicial',
    align: 'start',
    sortable: true,
    value: 'startDate',
  },
  {
    text: 'Data final',
    align: 'start',
    sortable: true,
    value: 'endDate',
  },
  {
    text: 'Empresa',
    align: 'start',
    sortable: true,
    value: 'companyName',
  },
  {
    text: 'Favorito',
    align: 'center',
    sortable: true,
    value: 'isFavorite',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }

]
