import { ChangePlaceRepository } from '../../data/repository/change_place_repository';
import { Place } from '../model/place';

export interface ChangePlaceUseCase {
    (id: number, data: object): Promise<Place>
}

const changePlaceUseCase = (repository: ChangePlaceRepository): ChangePlaceUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changePlaceUseCase;

