import { HarvestPagination } from './../../harvest/domain/model/harvest_pagination';
import { BombMeasurement } from './../domain/model/bomb_measurement';
import { FetchPlaceUseCase } from '@/module/place/domain/usecase/fetch_place_usecase';
import { Place } from '@/module/place/domain/model/place';
import { PlacePagination } from '@/module/place/domain/model/place_pagination';
import { MarkPumpPagination } from '../domain/model/markPump_pagination';
import { FetchPlacePumpUseCase } from '../domain/usecase/fetch_placePump_usecase';
import { FetchMarkPumpUseCase } from '../domain/usecase/fetch_markPump_usecase';

//import { ChangePlaceUseCase } from '@/module/place/domain/usecase/change_place_usecase';
//import { FetchMarkPlaceUseCase } from '@/module/pumpMark/domain/usecase/fetch_markPump_usecase';

//import { MarkPlacePagination } from '../domain/model/markPump_pagination'
import { DataOptions } from 'vuetify';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
//import { snackbar } from '@/core/controller/snackbar_controller';
import * as echarts from 'echarts';
import dayjs from "dayjs";
import { Pump } from '@/module/pump/domain/model/pump';
import { FetchPlaceMeasurementUseCase } from '../domain/usecase/fetch_placeMeasurement_usecase';
import { FetchHarvestUseCase } from '@/module/harvest/domain/usecase/fetch_harvest_usecase';
import { Harvest, harvestDefault } from '@/module/harvest/domain/model/harvest';
import { PumpPagination } from '@/module/pump/domain/model/pump_pagination';
import { FetchPlaceTrackingUseCase } from '../domain/usecase/fetch_placeTracking_usecase';
import { PlaceTrackingPagination } from '../domain/model/place_tracking_pagination';
import { snackbar } from '@/core/controller/snackbar_controller';
import * as XLSX from "xlsx";

class PumpMarkController {
  public context: any;

  public contextDrawer: any;
  public contextFormPlace: any;

  public search: string = ""

  public loading: boolean = false
  public loadingBtn: boolean = false
  public dialog: boolean = false
  public dialogInfo: boolean = false
  public loadingBtnExcel: boolean = false

  public rightDrawer: boolean = false
  public calendarMenuIn: boolean = false
  public calendarMenuUntil: boolean = false
  public calendarMenuDialogIn: boolean = false
  public calendarMenuDialogUntil: boolean = false
  public calendarIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendarUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public searchCompany: string = ""
  public dateMark: any = []
  public levelMark: any = []
  public marks: any = []
  public marksChart: any = []
  public maxLevelPump: any = 5
  public maxLevelPlace: any = 100
  public minLevelPlace: any = -100
  public nameSelectedPump: string = ""
  public nameSelectedPlace: string = ""
  public chartPage = 0


  positivePlace = (v) => {
    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 15) return true;
    return "Valor entre 0 e 15";
  };


  public placePagination: PlacePagination = {
    items: [],
    total: 0
  }
  public placePumpPagination: PumpPagination = {
    items: [],
    total: 0
  }
  public harvestPagination: HarvestPagination = {
    items: [],
    total: 0
  }
  public bombMeasurements: Array<BombMeasurement> = []
  public markPumpPagination: MarkPumpPagination = {
    total: 0,
    items: []
  }
  public placeTrackingPagination: PlaceTrackingPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["isFavorite"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public placePumpOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["1"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public harvestOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["startDate", "endDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true, true]
  }
  public markPumpOptions: DataOptions = {
    page: 1,
    itemsPerPage: 48,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }


  /*   public markPlacePagination: MarkPlacePagination = {
      total: 0,
      items: []
    }
    public markPlaceOptions: DataOptions = {
      page: 1,
      itemsPerPage: 12,
      groupBy: [],
      sortBy: [],
      groupDesc: [false],
      multiSort: false,
      mustSort: false,
      sortDesc: []
    }  */

  /*   public place: Place = {
      id: 0, coefficient: 0, color: "", companyID: 0, companyName: "", name: "", higherLevel: 0, lowerLevel: 0
    } */

  public selectedPlace: Place = {
    color: "",
    companyID: 0,
    companyName: "",
    id: 0,
    imagePath: "",
    latitude: "",
    longitude: "",
    name: ""
  }

  public selectedPump: Pump = {
    id: 0,
    placeID: 0,
    placeName: "",
    name: "",
    companyID: 0,
    status: 1,
    companyName: ""
  }

  public selectedHarvest: Harvest = harvestDefault()

  public markInfoPump: MarkPump = {
    id: 0,
    accountID: 0,
    amperage: 0,
    capacitors: 0,
    createdDate: "",
    description: "",
    engines: 0,
    higherLevel: 0,
    hourMeter: 0,
    lowerLevel: 0,
    pumpID: 0,
    voltage: 0,
    power: 0
  }

  private powerChart: echarts.ECharts | null = null
  private pumpChart: echarts.ECharts | null = null
  private pieChart: echarts.ECharts | null = null

  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchPlacePumpUseCaseImpl: FetchPlacePumpUseCase
  private fetchMarkPumpUseCaseImpl: FetchMarkPumpUseCase
  private fetchPlaceMeasurementUseCaseImpl: FetchPlaceMeasurementUseCase
  private fetchHarvestUseCaseImpl: FetchHarvestUseCase
  private fetchPlaceTrackingUseCaseImpl: FetchPlaceTrackingUseCase

  constructor(
    context: any,
    fetchPlaceUseCase: FetchPlaceUseCase,
    fetchPlacePumpUseCaseImpl: FetchPlacePumpUseCase,
    fetchMarkPumpUseCaseImpl: FetchMarkPumpUseCase,
    fetchPlaceMeasurementUseCaseImpl: FetchPlaceMeasurementUseCase,
    fetchHarvestUseCaseImpl: FetchHarvestUseCase,
    fetchPlaceTrackingUseCaseImpl: FetchPlaceTrackingUseCase
  ) {
    this.context = context
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchPlacePumpUseCaseImpl = fetchPlacePumpUseCaseImpl
    this.fetchMarkPumpUseCaseImpl = fetchMarkPumpUseCaseImpl
    this.fetchPlaceMeasurementUseCaseImpl = fetchPlaceMeasurementUseCaseImpl
    this.fetchHarvestUseCaseImpl = fetchHarvestUseCaseImpl
    this.fetchPlaceTrackingUseCaseImpl = fetchPlaceTrackingUseCaseImpl
  }


  async mounted() {
    this.loading = true
    this.maxLevelPump = localStorage.getItem('maxLevelPump') ? localStorage.getItem('maxLevelPump') : 5;
    this.maxLevelPlace = localStorage.getItem('maxLevelPlace') ? localStorage.getItem('maxLevelPlace') : 100;
    this.minLevelPlace = localStorage.getItem('minLevelPlace') ? localStorage.getItem('minLevelPlace') : -100;

    try {
      this.placePagination = await this.fetchPlaceUseCase(this.options, this.search)
      this.harvestPagination = await this.fetchHarvestUseCaseImpl(this.harvestOptions, "")
      this.selectedHarvest = this.harvestPagination.items.filter((harvest) => harvest.isFavorite)[0] || this.harvestPagination.items?.[0]
      //this.selectedHarvest = this.harvestPagination.items?.[0] ?? harvestDefault()
      this.changeHarvest(this.selectedHarvest)
      this.selectedPlace = this.placePagination.items[0]
      if (this.selectedPlace != null) {
        await this.changePlace(this.selectedPlace)
        this.calendarUntil = dayjs().format("YYYY-MM-DD")
        await this.filter()
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }


    window.addEventListener('resize', () => {
      if (this.pumpChart) {
        this.pumpChart.resize();
      }
    })
  }

  async filter() {
    this.marks = []
    this.marksChart = []
    this.bombMeasurements = []
    this.dialog = false
    this.markPumpOptions.page = 1
    this.nameSelectedPump = this.selectedPump.name
    this.nameSelectedPlace = this.selectedPlace.name
    if (this.context.$refs.filter.validate()) {
      this.loadingBtn = true
      try {
        this.markPumpOptions.itemsPerPage = this.context.$vuetify.breakpoint.mobile ? 10 : 48
        this.bombMeasurements = await this.fetchPlaceMeasurementUseCaseImpl(this.selectedPlace.id, this.calendarIn, this.calendarUntil)
        this.markPumpPagination = await this.fetchMarkPumpUseCaseImpl(this.markPumpOptions, this.selectedPump.id, this.calendarIn, this.calendarUntil)
        this.placeTrackingPagination = await this.fetchPlaceTrackingUseCaseImpl(this.markPumpOptions, this.selectedPlace.id, this.calendarIn, this.calendarUntil)
        for (let el of this.markPumpPagination.items) {
          el["power"] = (el.amperage * el.voltage) / 1000
          el["color"] = this.selectedPlace.color
          this.marks.push(el)
          //this.marksChart.push(el)
        }
        this.marksChart = this.placeTrackingPagination.items
        this.marksChart.reverse()
      } catch (error) {
        console.log(error);
      } finally {
        switch (this.chartPage) {
          case 0:
            this.createMarkPumpChart()
            break;

          case 1:
            this.createAveragePowerChart()
            this.createPieChart()
            break

        }
        this.loadingBtn = false
      }
    }
  }

  async change() {
    this.createAveragePowerChart()
    localStorage.setItem('maxLevelPump', this.maxLevelPump)
    localStorage.setItem('maxLevelPlace', this.maxLevelPlace)
    localStorage.setItem('minLevelPlace', this.minLevelPlace)
    this.rightDrawer = false
    this.filter()
  }



  async createAveragePowerChart() {
    await new Promise(resolver => setTimeout(resolver, 500))
    var chartDom = document.getElementById('mark_power_chart');
    const data = this.marks.slice(0, 15);
    if (chartDom != null) {
      const marcacao = data.map((mark: MarkPump, i: number) => [
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: 0, lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
            },

          },
        },
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: mark.power.toFixed(4), lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
            },

          },
        },
      ])
      this.powerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      let option = {
        grid: {
          left: 60,
          top: 50,
          right: 50,
          bottom: 25
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: data.map((mark: MarkPump) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
          boundaryGap: true,
          axisLabel: {
            interval: data.length - 2
          }
          //axisLabel: { show: false, interval: 0, fontSize: 8, rotate: -20 }
        },
        yAxis: {
          type: 'value',
          //max: this.maxLevelPump
        },
        /*         dataZoom: [
                            {
                                show: true,
                                realtime: true,
                            },
                  {
                    type: 'inside',
                    realtime: true,
                  }
                ], */
        series: [{
          itemStyle: {
            color: '#8094f7'
          },
          markLine: {
            data: marcacao,
          },
          name: 'Média',
          areaStyle: {},
          data: data.map((mark: MarkPump) => mark.power.toFixed(4)) ?? [],
          type: 'line'
        },
        ]
      }
      option && this.powerChart.setOption(option);
      this.powerChart.resize()
    }
  }

  async createMarkPumpChart() {
    await new Promise(resolver => setTimeout(resolver, 500))
    var chartDom = document.getElementById('mark_place_chart');
    if (chartDom != null) {

      const marcacaoHigherLevel = this.marksChart.map((mark: MarkPump, i: number) => [
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: 0, lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
            },

          },
        },
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: mark.higherLevel, lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
            },

          },
        },
      ])
      const marcacaoLowerLevel = this.marksChart.map((mark: MarkPump, i: number) => [
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: 0, lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : '#A05151',
            },

          },
        },
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: mark.lowerLevel, lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : '#A05151',
            },

          },
        },
      ])


      this.pumpChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      let option = {
        legend: {
          data: ['Nível Superior', 'Nível Inferior']
        },
        grid: {
          left: 60,
          top: 50,
          right: 50,
          bottom: 25
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.marksChart.map((mark: MarkPump) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
          boundaryGap: true,
          axisLabel: {
            interval: this.marksChart.length - 2
          }
          //axisLabel: { show: false, interval: 0, fontSize: 8, rotate: -20 }
        },
        yAxis: {
          type: 'value',
          //min: this.minLevelPlace,
          //max: this.maxLevelPlace
        },
        /*         dataZoom: [
                           {
                                show: true,
                                realtime: true,
                            }, 
                  {
                    type: 'inside',
                    realtime: true,
                  }
                ], */
        series: [{
          itemStyle: {
            color: '#8094f7'
          },
          markLine: {
            data: marcacaoHigherLevel
          },
          name: 'Nível Superior',
          areaStyle: {},
          data: this.marksChart.map((mark: MarkPump) => mark.higherLevel) ?? [],
          type: 'line'
        }, {
          itemStyle: {
            color: '#e57575'
          },
          markLine: {
            data: marcacaoLowerLevel
          },
          name: 'Nível Inferior',
          areaStyle: {},
          data: this.marksChart.map((mark: MarkPump) => mark.lowerLevel) ?? [],
          type: 'line'
        }]
      }
      option && this.pumpChart.setOption(option);

      this.pumpChart.resize()
    }
  }

  async createPieChart() {
    await new Promise(resolver => setTimeout(resolver, 500))
    var chartDom = document.getElementById('mark_pie_chart');
    if (chartDom != null) {
      this.pieChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      var option;
      this.bombMeasurements.map(el => {
        el["value"] = el.qtd
        el["name"] = el.accountName
      })
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          right: 100,
          top: 20,
          bottom: 20,
          data: this.bombMeasurements.map(measur => measur.accountName)
        },
        series: [
          {
            name: 'Medições',
            type: 'pie',
            center: ['30%', '50%'],
            radius: '80%',
            data: this.bombMeasurements,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: false
            },
            label: {
              show: false
            }
          }
        ]
      };

      option && this.pieChart.setOption(option);
      this.pieChart.resize()
    }
  }


  closeDialog() {
    this.dialog = false
    this.rightDrawer = true
  }

  async changePlace(data) {
    try {
      this.placePumpPagination = await this.fetchPlacePumpUseCaseImpl(this.placePumpOptions, data.id)
      if (this.placePumpPagination != null) {
        this.selectedPump = this.placePumpPagination.items[0]
      }
    } catch (error) {
      console.log(error);
    }

  }

  async changeHarvest(data: Harvest) {
    this.calendarIn = data.startDate
    this.calendarUntil = data.endDate
  }

  info(item) {
    this.dialogInfo = true
    this.markInfoPump = {
      ...item,
      power: item.power.toFixed(4)
    }
  }

  async changeChart(chart: number) {
    this.chartPage = chart
    switch (chart) {
      case 0:
        this.createMarkPumpChart()
        break;

      case 1:
        this.createAveragePowerChart()
        this.createPieChart()
        break
    }

  }

  async paginateMarks() {
    try {
      this.markPumpPagination = await this.fetchMarkPumpUseCaseImpl(this.markPumpOptions, this.selectedPump.id, this.calendarIn, this.calendarUntil)
      for (let el of this.markPumpPagination.items) {
        el["power"] = (el.amperage * el.voltage) / 1000
        el["color"] = this.selectedPlace.color
        this.marks.push(el)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async exportExcel() {
    try {
      const heading = [['ID', 'ID LEVANTE', 'NIVEL MAXIMO', 'NIVEL MINIMO', 'DESCRIÇÃO', 'DATA CRIAÇÃO', 'ID DO USUÁRIO', 'NOME USUÁRIO']];
      this.loadingBtnExcel = true
      const paginate = {...this.markPumpOptions}
      paginate.itemsPerPage = -1
      const data =  await this.fetchPlaceTrackingUseCaseImpl(paginate, this.selectedPlace.id, this.calendarIn, this.calendarUntil)

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);

      XLSX.utils.sheet_add_json(ws, data.items, { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'levante');

      XLSX.writeFile(wb, `levante.xlsx`);
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingBtnExcel = false
    }
  }


}

export default PumpMarkController