import { FetchPlacePumpRepository } from '../../data/repository/fetch_placePump_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/markPump';
import { PumpPagination } from '@/module/pump/domain/model/pump_pagination';

export interface FetchPlacePumpUseCase {
    (pagination: DataOptions, id: number): Promise<PumpPagination>
}

const fetchPlacePumpUseCase = (repository: FetchPlacePumpRepository): FetchPlacePumpUseCase => async (pagination: DataOptions, id: number) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: "",
        }
        return await repository(payload, id);
    } catch (error) {
        throw error
    }
}

export default fetchPlacePumpUseCase;