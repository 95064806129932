import { CreatePlaceRepository } from '../../data/repository/create_place_repository';
import { Place } from '../model/place';

export interface CreatePlaceUseCase {
    (data: object): Promise<Place>
}

const createPlaceUseCase = (repository: CreatePlaceRepository): CreatePlaceUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createPlaceUseCase;

