import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchScreenCompanyRepository {
    (id: number): Promise<number[]>
}

const fetchScreenCompanyRepository = (axios: AxiosInstance): FetchScreenCompanyRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/screen-company/${id}`)
        const screens: number[] = response.data.map((screen: any) => screen.screenID)
        return screens
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchScreenCompanyRepository