export interface Logs {
    batery: number;
    level: boolean;
    rulerID: number;
    createdDate: string;
    temperature: number;
    companyID: number;
    companyName: string;
}

const en: any = {
    rulerID: 2,
    level: 3,
    temperature: 4,
    batery: 5,
    createdDate: 6,
    companyID: 7,
    companyName: 8
};
export { en };
