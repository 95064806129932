import { NotificationPagination } from '../model/notification_pagination';
import { FetchNotificationRepository } from '../../data/repository/fetch_notification_repository';
import { PaginationNotification } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/notification';

export interface FetchNotificationUseCase {
    (pagination: DataOptions, search: string, startDate: string, endDate: string): Promise<NotificationPagination>
}

const fetchNotificationUseCase = (repository: FetchNotificationRepository): FetchNotificationUseCase => async (pagination: DataOptions, search: string, startDate: string, endDate: string) => {
    try {
        const payload: PaginationNotification = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
            startDate: startDate,
            endDate: endDate
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchNotificationUseCase;