import { ChangeEngineRepository } from '../../data/repository/change_engine_repository';
import { Engine } from '../model/engine';

export interface ChangeEngineUseCase {
    (id: number, data: object): Promise<Engine>
}

const changeEngineUseCase = (repository: ChangeEngineRepository): ChangeEngineUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeEngineUseCase;

