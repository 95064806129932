import { ChangeAlertRepository } from '../../data/repository/change_alert_repository';
import { Alert } from '../model/alert';

export interface ChangeAlertUseCase {
    (id: number, data: object): Promise<Alert>
}

const changeAlertUseCase = (repository: ChangeAlertRepository): ChangeAlertUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeAlertUseCase;

