import { ChangeRulerGroupRepository } from '../../data/repository/change_rulerGroup_repository';
import { RulerGroup } from '../model/rulerGroup';

export interface ChangeRulerGroupUseCase {
    (id: number, data: object): Promise<RulerGroup>
}

const changeRulerGroupUseCase = (repository: ChangeRulerGroupRepository): ChangeRulerGroupUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeRulerGroupUseCase;

