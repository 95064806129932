import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { RulerGroup } from '../../domain/model/rulerGroup';

export interface CreateRulerGroupRepository {
  (data: object): Promise<RulerGroup>
}

const createRulerGroupRepository = (axios: AxiosInstance): CreateRulerGroupRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/group`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createRulerGroupRepository

