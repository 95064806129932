import { DeleteEngineRepository } from '../../data/repository/delete_engine_repository';
import { Engine } from '../model/engine';

export interface DeleteEngineUseCase {
    (id: number): Promise<Engine>
}

const deleteEngineUseCase = (repository: DeleteEngineRepository): DeleteEngineUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteEngineUseCase;