import { FetchPlaceUseCase } from '../domain/usecase/fetch_place_usecase';
import { DeletePlaceUseCase } from '../domain/usecase/delete_place_usecase';
import { CreatePlaceUseCase } from '../domain/usecase/create_place_usecase';
import { ChangePlaceUseCase } from '../domain/usecase/change_place_usecase';
import { PlacePagination } from '../domain/model/place_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Place } from '../domain/model/place';
import { snackbar } from '@/core/controller/snackbar_controller';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';

class PlaceController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public center: any = {
    lat: 0.0,
    lng: 0.0
  }
  public placePagination: PlacePagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public place: Place = {
    id: 0, color: "", companyID: 0, companyName: "", imagePath: "", latitude: "", longitude: "", name: ""
  }

  private fetchPlaceUseCase: FetchPlaceUseCase
  private deletePlaceUseCase: DeletePlaceUseCase
  private createPlaceUseCase: CreatePlaceUseCase
  private changePlaceUseCase: ChangePlaceUseCase
  private getCompanyByIDUseCase: GetCompanyByIDUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(
    context: any,
    fetchPlaceUseCase: FetchPlaceUseCase,
    deletePlaceUseCase: DeletePlaceUseCase,
    createPlaceUseCase: CreatePlaceUseCase,
    changePlaceUseCase: ChangePlaceUseCase,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase
  ) {
    this.context = context
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.deletePlaceUseCase = deletePlaceUseCase
    this.createPlaceUseCase = createPlaceUseCase
    this.changePlaceUseCase = changePlaceUseCase
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  async _setCompanyCenter() {
    const company = await this.getCompanyByIDUseCase()

    this.center = {
      lat: parseFloat(company.latitude),
      lng: parseFloat(company.longitude)
    }
  }

  async open() {
    this._setCompanyCenter();
    this.paginateCompany()
    this.flagOption = "create"
    //this.place = { id: 0, color: "#FF00FF", companyID: 0, companyName: "", imagePath: "", latitude: "", longitude: "", name: ""  }
  }

  async paginate() {
    this.loading = true
    try {
      this.placePagination = await this.fetchPlaceUseCase(this.options, this.search)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changePlaceUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createPlaceUseCase(this.place)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changePlaceUseCase(this.place.id, this.place)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Place) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deletePlaceUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Place) {
    this.flagOption = "change"
    this.dialog = true
    this.place = { ...item }
    this.searchCompany = item.companyName
    this.paginateCompany()

    if (!isNaN(parseFloat(this.place.latitude)) && !isNaN(parseFloat(this.place.longitude))) {
      this.center = {
        lat: parseFloat(this.place.latitude),
        lng: parseFloat(this.place.longitude)
      }
    } else {
      this._setCompanyCenter();
    }

  }

  getLocation(value) {
    this.place.latitude = value.lat().toFixed(7)
    this.place.longitude = value.lng().toFixed(7)

    this.center = value
  }
}

export default PlaceController