import Handler from "@/core/domain/usecase/handler"
import { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import { User } from "../../domain/model/user"

export interface FetchUserProfileImageRepository {
    (id: number): Promise<User>
}

const fetchUserProfileImageRepository = (axios: AxiosInstance): FetchUserProfileImageRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/account/${id}/profile-image`, {
        })

        const userProfileImagePagination: User = response.data
        return userProfileImagePagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchUserProfileImageRepository