export interface Pump {
  id: number;
  placeID: number;
  placeName: string;
  name: string;
  companyID: number;
  status: number;
  companyName: string;
  EngineName?: any;
  engineName?: any;
}


const en: any = {
  id: 1,
  placeID: 4,
  placeName: 7,
  companyName: 5,
  companyID: 1,
  name: 2,
  status: 6
}
export { en };
