import { CreateAlertTypeRepository } from '../../data/repository/create_alert_type_repository';
import { AlertType } from '../model/alert';

export interface CreateAlertTypeUseCase {
    (data: object): Promise<AlertType>
}

const createAlertTypeUseCase = (repository: CreateAlertTypeRepository): CreateAlertTypeUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createAlertTypeUseCase;

