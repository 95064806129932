import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { HardwareVersion } from '../../domain/model/hardware_version';

export interface CreateHardwareVersionRepository {
  (data: HardwareVersion, file: File): Promise<HardwareVersion>
}

const createHardwareVersionRepository = (axios: AxiosInstance): CreateHardwareVersionRepository => async (data: HardwareVersion, file: File) => {
  try {
    var formData = new FormData();
    formData.append("doc", file);
    formData.append("version", data.version);
    formData.append("description", data.description);

    const response: AxiosResponse = await axios.post(`/hardware-version`,
      formData,
      {
        headers: {
          "Content-Type": "application/form-data"
        }
      })
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createHardwareVersionRepository

