import { CreateRulerGroupRepository } from '../../data/repository/create_rulerGroup_repository'
import { RulerGroup } from '../model/rulerGroup';

export interface CreateRulerGroupUseCase {
    (data: object): Promise<RulerGroup>
}

const createRulerGroupUseCase = (repository: CreateRulerGroupRepository): CreateRulerGroupUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        console.log(error);
        
        throw error
    }
}

export default createRulerGroupUseCase;

