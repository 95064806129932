<template>
  <div class="background_screen">
    <v-app-bar
      v-if="$vuetify.breakpoint.mobile"
      color="app_bar"
      clipped-left
      clipped-right
      app
      height="70"
    >
      <v-row>
        <v-col cols="3">
          <v-app-bar-nav-icon
            color="white"
            v-if="$vuetify.breakpoint.mobile"
            class="d-lg-none d-xl-flex"
            @click="controller.openMenu()"
          ></v-app-bar-nav-icon>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-center"
        >
          <v-img
            class="ml-4"
            max-height="60"
            max-width="110"
            src="logo_branco2.png"
          ></v-img>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <component :is="controller.selected"></component>
    </v-main>
    <v-navigation-drawer
      right
      :value="controller.userMenu"
      absolute
      style="z-index: 9999"
    >
      <div>
        <div class="ml-4 mb-6 d-flex flex-column align-start">
          <v-flex
            class="d-flex justify-space-between"
            style="width: 100%"
          >
            <h2 class="pt-4 font-weight-light">Olá</h2>
            <v-btn
              icon
              @click="controller.userMenu = false"
            >
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-flex>
          <h2 class="font-weight-medium">{{ controller.userName }}</h2>
        </div>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center align-center"
          >
            <div class="avatar-upload">
              <div class="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  @change="previewFile"
                />
                <label
                  for="imageUpload"
                  class="d-flex justify-center align-center"
                >
                  <v-icon size="20">mdi-image-edit-outline</v-icon>
                </label>
              </div>

              <v-avatar size="120">
                <img
                  id="imagem"
                  :src="
                    controller.profileImage
                      ? controller.profileImage
                      : `profile.png`
                  "
                  alt="Imagem de Perfil"
                />
              </v-avatar>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-center py-2">
          <a @click="controller.termos()">Termos e Condições</a>
        </div>
        <v-divider></v-divider>
        <div class="mb-4 d-flex justify-center">
          <div>
            <v-switch
              inset
              hide-details
              v-model="controller.dark"
              color="primary"
              :label="controller.dark ? 'Modo escuro' : 'Modo claro'"
            ></v-switch>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- <div
      @mouseenter="controller.mini = false"
      @mouseleave="controller.mini = true"
      v-if="!controller.loading"
    > -->
    <v-navigation-drawer
      :value="controller.sidebarMenu"
      left
      app
      clipped
      :mini-variant.sync="controller.mini"
      :temporary="$vuetify.breakpoint.mobile"
      color="primary"
      dark
      :expand-on-hover="!$vuetify.breakpoint.mobile"
      mini-variant-width="68"
    >
      <v-list
        dense
        nav
        subheader
        id="nav-list"
      >
        <v-list-item-group
          class="mt-2"
          mandatory
          color="white"
        >
          <v-list-item
            @click="controller.navigate(0)"
            v-if="hadPermission(5, 1) && hadScreenAccess(1)"
          >
            <v-list-item-icon>
              <v-img
                src="/logo.png"
                max-height="50"
                max-width="25"
                contain
              ></v-img>
              <!-- <v-icon>mdi-home</v-icon> -->
            </v-list-item-icon>
            <v-list-item-content>
              <v-img
                src="/logo_branco2.png"
                max-height="45"
                max-width="70"
                contain
                eager
              ></v-img>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="hadPermission(5, 1) && hadScreenAccess(1)"></v-divider>
          <v-list-item @click="controller.navigate(13)">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meus Dados</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(10)"
            v-if="hadScreenAccess(2)"
          >
            <v-list-item-icon>
              <v-badge
                color="red"
                :content="
                    controller.countNotifications > 29
                      ? '30+'
                      : controller.countNotifications
                  "
                :value="controller.countNotifications"
                overlap
              >
                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Notificações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            class="pt-10"
            v-if="hadScreenAccess(2)"
          ></v-divider>

          <v-list-group
            v-if="hadScreenAccess(3)"
            active-class="primary"
            color="white"
            prepend-icon="mdi-menu"
          >
            <template v-slot:activator>
              <v-list-item-title>Réguas</v-list-item-title>
            </template>
            <v-list-item @click="controller.navigate(1)">
              <v-list-item-icon>
                <v-icon>mdi-chart-timeline-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gráfico</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controller.navigate(16)">
              <v-list-item-icon>
                <v-icon>mdi-chart-tree</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cards</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controller.navigate(15)">
              <v-list-item-icon>
                <v-icon>mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Grupos de Réguas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            @click="controller.navigate(2)"
            v-if="hadScreenAccess(4)"
          >
            <v-list-item-icon>
              <v-icon>mdi-water-pump</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Levantes e Bombas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(24)"
            v-if="hadScreenAccess(16)"
          >
            <v-list-item-icon>
              <v-icon>mdi-motion-play-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Acionamentos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(25)"
            v-if="hadPermission(2, 1) && hadScreenAccess(17)"
          >
            <v-list-item-icon>
              <v-icon>mdi-home-silo-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Estações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="hadScreenAccess(5)"
            active-class="primary"
            color="white"
            prepend-icon="mdi-phone"
          >
            <template v-slot:activator>
              <v-list-item-title>Chamados</v-list-item-title>
            </template>
            <v-list-item @click="controller.navigate(20)">
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tipos de alertas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controller.navigate(21)">
              <v-list-item-icon>
                <v-icon>mdi-account-switch</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Centrais de atendimento</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controller.navigate(18)">
              <v-list-item-icon>
                <v-icon>mdi-book-alert</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registro de alertas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- teste -->

          <v-subheader
            class="mt-10"
            v-show="!controller.mini || $vuetify.breakpoint.mobile"
          >Configurações</v-subheader>
          <v-list-item
            @click="controller.navigate(3)"
            v-if="hadPermission(1, 1) && hadScreenAccess(6)"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Usuários</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(4)"
            v-if="hadPermission(0, 0) && hadScreenAccess(7)"
          >
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Empresas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(5)"
            v-if="hadPermission(2, 1) && hadScreenAccess(8)"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pontos de réguas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(6)"
            v-if="hadPermission(2, 1) && hadScreenAccess(9)"
          >
            <v-list-item-icon>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Levantes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(7)"
            v-if="hadPermission(3, 1) && hadScreenAccess(10)"
          >
            <v-list-item-icon>
              <v-icon>mdi-water-pump</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Bombas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(22)"
            v-if="hadPermission(3, 1) && hadScreenAccess(14)"
          >
            <v-list-item-icon>
              <v-icon>mdi-engine</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Motores</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(23)"
            v-if="hadPermission(3, 1) && hadScreenAccess(15)"
          >
            <v-list-item-icon>
              <v-icon>mdi-video-input-component</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Conjuntos (Motor/Bomba)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(9)"
            v-if="hadPermission(6, 1) && hadScreenAccess(11)"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Safras</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="controller.navigate(17)"
            v-if="hadPermission(6, 1) && hadScreenAccess(12)"
          >
            <v-list-item-icon>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Operações diarias</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            active-class="primary"
            color="white"
            prepend-icon="mdi-menu"
            :value="groups"
            v-if="hadPermission(4, 1) && hadScreenAccess(13)"
          >
            <template v-slot:activator>
              <v-list-item-title>Agrupamentos</v-list-item-title>
            </template>

            <v-list-item @click="controller.navigate(14)">
              <v-list-item-icon>
                <v-icon>mdi-pencil-ruler</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Grupo de réguas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-subheader
            class="mt-10"
            v-if="hadPermission(0, 0)"
            v-show="!controller.mini || $vuetify.breakpoint.mobile"
          >Configurações internas</v-subheader>
          <v-list-item
            @click="controller.navigate(8)"
            v-if="hadPermission(0, 0)"
          >
            <v-list-item-icon>
              <v-icon>mdi-server-security</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Versões de hardware</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="controller.navigate(12)"
            v-if="hadPermission(0, 0)"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Planos de acesso</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="controller.navigate(19)"
            v-if="hadPermission(0, 0)"
          >
            <v-list-item-icon>
              <v-icon>mdi-mushroom-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Funcionalidades</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            class="mt-10"
            v-if="hadPermission(0, 0)"
            v-show="!controller.mini || $vuetify.breakpoint.mobile"
          >Documentações</v-subheader>
          <v-list-item
            @click="controller.navigate(11)"
            v-if="hadPermission(0, 0)"
          >
            <v-list-item-icon>
              <v-icon>mdi-text-box-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>API Swagger</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-list dense id="nav-list-append">
          <v-list-item @click="controller.logout()">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- </div> -->
    <v-dialog
      max-width="1200px"
      v-model="controller.termosDialog"
    >
      <termos :termosController="controller" />
    </v-dialog>
  </div>
</template>

<script>
import { homeController } from "@/di/di";
import Termos from "../components/termos.vue";

export default {
  components: {
    Termos,
  },
  data: (context) => ({
    controller: homeController(context),
    groups: false,
  }),
  async mounted() {
    this.controller.mounted();

    if (this.$vuetify.breakpoint.mobile) {
      this.controller.mini = false;
    }

    this.ajustListOnProd()
  },
  watch: {
    "controller.dark"() {
      this.$vuetify.theme.dark = this.controller.dark;
      localStorage.setItem("dark", this.controller.dark);
    },
    "controller.mini"() {
      setTimeout(() => {
        this.ajustListOnProd()
      }, 200);
    },
  },
  methods: {
    ajustListOnProd() {
      const nav = document.getElementsByClassName(
        "v-navigation-drawer--custom-mini-variant"
      );
      const list = document.getElementById("nav-list");
      const listAppend = document.getElementById("nav-list-append");

      if (nav.length > 0) {
        nav[0].classList.remove("v-navigation-drawer--custom-mini-variant");
      }
      
      list.style.paddingLeft = "12px";
      list.style.paddingRight = "12px";
      listAppend.style.paddingLeft = "4px";
      listAppend.style.paddingLeft = "4px";
    },
    previewFile() {
      let preview = document.getElementById("imagem");
      let file = document.querySelector("input[type=file]").files[0];
      this.controller.saveProfileImage(file);
      let reader = new FileReader();

      reader.onloadend = function () {
        preview.src = reader.result;
      };

      this.controller.previewImage = reader.readAsDataURL(file);

      if (file) {
        reader.readAsDataURL(file);
      } else {
        preview.src = "";
      }
    },
  },
};
</script>

<style scoped>
.card:hover {
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
}
.notification-card {
  top: 70px;
  float: right;
  position: fixed;
  /* margin-top: 70px; */
  right: 100px;
  width: 430px;
  z-index: 9999;
}
.avatar-card {
  top: 70px;
  float: right;
  position: fixed;
  /* margin-top: 70px; */
  right: 10px;
  width: 250px;
  z-index: 9999;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 1px;
  z-index: 1;
  top: 1px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style> 



