import { MarkStation } from '@/module/stationMark/domain/model/markStation';
import { MarkStationPagination } from '../../../stationMark/domain/model/markStation_pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface UpdateStationDataRepository {
    (stationTracking: MarkStation): Promise<MarkStationPagination>
}

const updateStationDataRepository = (axios: AxiosInstance): UpdateStationDataRepository => async (stationTracking: MarkStation) => {
    try {
        const response: AxiosResponse = await axios.put(`https://stationapihydra.com/data/${stationTracking.id}`,
            stationTracking
        )

        const stationPagination: MarkStationPagination = response.data
        return stationPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default updateStationDataRepository