export const headers = [
  {
    text: 'ID',
    align: 'start',
    sortable: true,
    value: 'id',
  },
  {
    text: 'Tipo do alerta',
    align: 'start',
    sortable: true,
    value: 'alertTypeName',
  },
  {
    text: 'Quem abriu',
    align: 'start',
    sortable: true,
    value: 'whoOpenedName',
  },
  {
    text: 'Aberto em',
    align: 'start',
    sortable: true,
    value: 'createdDate',
  },
  {
    text: 'Atualizado em',
    align: 'start',
    sortable: true,
    value: 'updatedAt',
  },
  {
    text: 'Status atual',
    align: 'start',
    sortable: true,
    value: 'currentStatus',
  },
  {
    text: 'Empresa',
    align: 'start',
    sortable: true,
    value: 'corporateName',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }
]
