import { DeletePlaceRepository } from '../../data/repository/delete_place_repository';
import { Place } from '../model/place';

export interface DeletePlaceUseCase {
    (id: number): Promise<Place>
}

const deletePlaceUseCase = (repository: DeletePlaceRepository): DeletePlaceUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deletePlaceUseCase;