import { PumpMotoPagination } from '../../domain/model/pump_moto_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchPumpMotoRepository {
    (pagination: Pagination): Promise<PumpMotoPagination>
}

const fetchPumpMotoRepository = (axios: AxiosInstance): FetchPumpMotoRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/pump-moto', {
            params: pagination
        })
        const pumpMotoPagination: PumpMotoPagination = response.data
        return pumpMotoPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchPumpMotoRepository