export interface AccessPlan {
  id: 0,
  maxEmployeeRegistration: 0,
  maxPlaceRegistration: 0,
  maxPumpMesurement: 0,
  maxPumpPerPlaceRegistration: 0,
  maxPumpRegistration: 0,
  maxRulerMesurement: 0,
  maxRulerRegistration: 0
  name: "",
  price: 0.0,
  description: ""
}

const accessPlanDefault = (): AccessPlan => {
  return {
    id: 0,
    maxEmployeeRegistration: 0,
    maxPlaceRegistration: 0,
    maxPumpMesurement: 0,
    maxPumpPerPlaceRegistration: 0,
    maxPumpRegistration: 0,
    maxRulerMesurement: 0,
    maxRulerRegistration: 0,
    name: "",
    price: 0,
    description: ""
  }
}

const en: any = {
  id: 1,
  name: 2,
};

export { en, accessPlanDefault };
