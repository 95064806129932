import dayjs from "dayjs"

export interface AlertHistory {
  id: number
  created_date: string
  company_id: number
  status: number
  who_is: number
  alert_id: number
}

export interface Alert {
  id: number
  alertType: number
  companyID: number
  description: string
  currentStatus: number
  createdDate: string
  updatedAt: string
  whoOpened: number
  history: AlertHistory[]
  whoIs: number
  whoIsCallCenter: number
}

export const alertDefault = (): Alert => {
  return {
    id: 0,
    alertType: 0,
    companyID: 0,
    description: "",
    currentStatus: 1,
    createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
    updatedAt: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
    whoOpened: 0,
    history: [],
    whoIs: 0,
    whoIsCallCenter: 0
  }
}


const en: any = {
  id: 1,
  alertType: 2,
  companyID: 3,
  description: 4,
  currentStatus: 5,
  createdDate: 6,
  updatedAt: 7,
  whoOpened: 8,
  corporateName: 9
}
export { en };


