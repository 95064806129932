import { DeleteStationRepository } from './../../data/repository/delete_station_repository';
import { Station } from '../model/station';

export interface DeleteStationUseCase {
    (id: number): Promise<Station>
}

const deleteStationUseCase = (repository: DeleteStationRepository): DeleteStationUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteStationUseCase;