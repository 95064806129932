import { ChangePumpMotoRepository } from '../../data/repository/change_pump_moto_repository';
import { PumpMoto } from '../model/pump_moto';

export interface ChangePumpMotoUseCase {
    (id: number, data: object): Promise<PumpMoto>
}

const changePumpMotoUseCase = (repository: ChangePumpMotoRepository): ChangePumpMotoUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changePumpMotoUseCase;

