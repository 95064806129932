import { DeleteHardwareVersionRepository } from './../../data/repository/delete_hardware_version_repository';
import { HardwareVersion } from '../model/hardware_version';

export interface DeleteHardwareVersionUseCase {
    (id: number): Promise<HardwareVersion>
}

const deleteHardwareVersionUseCase = (repository: DeleteHardwareVersionRepository): DeleteHardwareVersionUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteHardwareVersionUseCase;