export interface PlaceTracking {
  id: number;
  accountID: number;
  createdDate: string;
  description: string;
  higherLevel: number;
  lowerLevel: number;
  placeID: number;
}


const en: any = {
  id: 1,
  placeID: 2,
  higherLevel: 3,
  lowerLevel: 4,
  description: 5,
  createdDate: 6,
  accountID: 7
};
export { en };


