
import refreshRepostory from "@/core/data/repository/refresh_repository";
import { getJwtAuthToken } from "@/core/domain/model/jwtAuthToken";
import refreshUseCase from "@/core/domain/usecase/refresh_usecase";
import { baseUrl } from "@/constants";
import axios from "axios";

const refreshRepositoryImpl = refreshRepostory(baseUrl)
const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl)

const retries = {
    maxRetries: 3,
    attempt: 1
}

const axosInstance = axios.create({
    baseURL: baseUrl,
})

axosInstance.interceptors.request.use((config) => {
    const jwtAuthToken = getJwtAuthToken()

    if (jwtAuthToken.token) {
        config.headers = {
            authorization: `Bearer ${jwtAuthToken.token}`
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

axosInstance.interceptors.response.use((response: any) => {
    retries.attempt = 1
    return response;
}, async (err: any) => {
    const originalRequest = err.config;
    const status = err.response ? err.response.status : null

    if (status === 401) {
        if (retries.attempt < retries.maxRetries) {
            retries.attempt++

            const refreshed = await refreshUseCaseImpl()

            if (refreshed) {
                const jwtAuthToken = getJwtAuthToken()
                originalRequest.headers['Authorization'] = 'Bearer ' + jwtAuthToken.token;

                return axosInstance(originalRequest);
            }
        }
        window.router.login()
        return Promise.reject();
    }

    return Promise.reject(err);
});

export { axosInstance, refreshUseCaseImpl }