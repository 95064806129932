import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import { UpdateRulerTrackingLevelUseCase } from './../domain/usecase/update_ruler_tracking_level_usecase';
import { Ruler, rulerDefault } from '@/module/ruler/domain/model/ruler';
import { headersTracking } from './../const/headers_tracking';
import { FetchRulerTrackingByIDUseCase } from './../domain/usecase/fetch_ruler_tracking_by_id_usecase';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { MarkRulerPagination } from './../../rulerMark/domain/model/markRuler_pagination';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { DeleteRulerUseCase } from '../domain/usecase/delete_ruler_usecase';
import { CreateRulerUseCase } from '../domain/usecase/create_ruler_usecase';
import { ChangeRulerUseCase } from '../domain/usecase/change_ruler_usecase';
import { FetchRulerUseCase } from '../domain/usecase/fetch_ruler_usecase';
import { RulerPagination } from '../domain/model/ruler_pagination';
import { snackbar } from '@/core/controller/snackbar_controller';
import { headers } from '../const/headers';
import { DataOptions } from 'vuetify';
import { PlacePagination } from '@/module/place/domain/model/place_pagination';
import { FetchPlaceUseCase } from '@/module/place/domain/usecase/fetch_place_usecase';
import { DeleteMarkRulerUseCase } from '../domain/usecase/delete_ruler_mark_usecase';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import * as XLSX from "xlsx";

class RulerController {
  public loadingBtnExcel: boolean = false
  public hardwareMesurement: Boolean = false
  public context: any;
  public status: number = 0
  public dialog: boolean = false;
  public center: object = {
    lat: -13.956416,
    lng: -52.332572
  }
  public zoom: number = 10
  public columns: Array<any> = headers
  public columnsTracking: Array<any> = headersTracking
  public search: string = ""
  public searchCompany: string = ""
  public searchTracking: string = ""
  public searchPlace: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public loadingLogs: boolean = false
  public dialogTracking: boolean = false
  public dialogRulerTrackingLevel: boolean = false
  public selectedRuler: Ruler | null = null
  public selected: Array<MarkRuler> = []
  public selectedMarkRuler: MarkRuler = {
    batery: 0,
    coefficient: 0,
    companyID: 0,
    companyName: "",
    createdDate: "",
    id: 0,
    level: 0,
    levelPerCoeficient: 0,
    rulerColor: "",
    rulerID: 0,
    rulerName: "",
    temperature: 0
  }
  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus

  // positiveRuler = (v: any) => {
  //   if (!isNaN(parseFloat(v)) && v >= 0 && v <= 15) return true;
  //   return "Valor entre 0 e 15";
  // };

  public rulerPagination: RulerPagination = {
    total: 0,
    items: []
  }
  public trackingPagination: MarkRulerPagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public placePagination: PlacePagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsTracking: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }
  public optionsPlace: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public ruler: Ruler = rulerDefault()

  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase
  private fetchRulerUseCase: FetchRulerUseCase
  private deleteRulerUseCase: DeleteRulerUseCase
  private createRulerUseCase: CreateRulerUseCase
  private changeRulerUseCase: ChangeRulerUseCase
  private fetchRulerTrackingByIDUseCase: FetchRulerTrackingByIDUseCase
  private updateRulerTrackingLevelUseCase: UpdateRulerTrackingLevelUseCase
  private deleteMarkRulerUseCase: DeleteMarkRulerUseCase
  private getCompanyByIDUseCase: GetCompanyByIDUseCase

  constructor(
    context: any,
    fetchPlaceUseCase: FetchPlaceUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase,
    fetchRulerUseCase: FetchRulerUseCase,
    deleteRulerUseCase: DeleteRulerUseCase,
    createRulerUseCase: CreateRulerUseCase,
    changeRulerUseCase: ChangeRulerUseCase,
    fetchRulerTrackingByIDUseCase: FetchRulerTrackingByIDUseCase,
    updateRulerTrackingLevelUseCase: UpdateRulerTrackingLevelUseCase,
    deleteMarkRulerUseCase: DeleteMarkRulerUseCase,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
  ) {
    this.context = context
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
    this.fetchRulerUseCase = fetchRulerUseCase
    this.deleteRulerUseCase = deleteRulerUseCase
    this.createRulerUseCase = createRulerUseCase
    this.changeRulerUseCase = changeRulerUseCase
    this.fetchRulerTrackingByIDUseCase = fetchRulerTrackingByIDUseCase
    this.updateRulerTrackingLevelUseCase = updateRulerTrackingLevelUseCase
    this.deleteMarkRulerUseCase = deleteMarkRulerUseCase
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
  }

  open() {
    this.ruler = rulerDefault()
    this.ruler.color = Math.floor(Math.random() * 16777215).toString(16);
    this.hardwareMesurement = false
    this._setCompanyCenter();
    this.paginateCompany()
    this.paginatePlace()
    this.flagOption = "create"
  }

  async _setCompanyCenter() {
    const company = await this.getCompanyByIDUseCase()

    this.center = {
      lat: parseFloat(company.latitude),
      lng: parseFloat(company.longitude)
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.rulerPagination = await this.fetchRulerUseCase(this.options, this.search, this.status)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error) {
        console.log(error);
      }
    }
  }

  async paginateTracking() {
    this.loadingLogs = true
    try {
      if (this.selectedRuler) {
        this.trackingPagination = await this.fetchRulerTrackingByIDUseCase(this.selectedRuler.id, this.optionsTracking, "")
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingLogs = false
    }
  }

  async paginatePlace() {
    if (this.searchPlace != null) {
      try {
        this.placePagination = await this.fetchPlaceUseCase(this.optionsPlace, this.searchPlace)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changeRulerUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchSearchTracking() {
    this.paginateTracking()
  }

  watchOptionsTracking() {
    this.paginateTracking()
  }

  watchSearchPlace() {
    this.paginatePlace()
  }
  watchOptionsPlace() {
    this.paginatePlace()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.ruler.coefficient > 0 && this.ruler.divisionFactor > 0 && !this.hardwareMesurement) {
          const confirm = await snackbar.confirm(
            {
              message: "Deseja realmente remover o equipamento da régua? Você irá zerar o coeficiente, fator de divisão e número do equipamento!",
              color: 'red',
              timeout: 9000
            }
          )
          if (!confirm) {
            return
          } else {
            this.ruler.coefficient = 0
            this.ruler.divisionFactor = 0
            this.ruler.terminalNumber = ""
          }
        }
        switch (this.flagOption) {
          case "create": {
            await this.createRulerUseCase(this.ruler)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeRulerUseCase(this.ruler.id, this.ruler)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: Ruler) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteRulerUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Ruler) {
    this.hardwareMesurement = false
    this.flagOption = "change"
    this.dialog = true
    this.searchCompany = item.companyName
    this.paginateCompany()
    this.ruler = { ...item }

    if (this.ruler.coefficient > 0 && this.ruler.divisionFactor > 0) {
      this.hardwareMesurement = true
    }

    this.paginatePlace()


    if (!isNaN(parseFloat(this.ruler.latitude)) && !isNaN(parseFloat(this.ruler.longitude))) {
      this.center = {
        lat: parseFloat(this.ruler.latitude),
        lng: parseFloat(this.ruler.longitude)
      }
    } else {
      this._setCompanyCenter();
    }
  }

  getLocation(value) {
    this.ruler.latitude = value.lat().toFixed(7)
    this.ruler.longitude = value.lng().toFixed(7)

    this.center = value
  }

  async logs(item: Ruler) {
    try {
      this.selectedRuler = item
      this.paginateTracking()
      this.dialogTracking = true

    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async reloadLogs() {
    try {
      this.paginateTracking()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  updateLevel(item: MarkRuler) {
    this.selectedMarkRuler = { ...item }
    this.dialogRulerTrackingLevel = true
  }

  async confirmUpdateLevel() {
    try {
      await this.updateRulerTrackingLevelUseCase(this.selectedMarkRuler)

      this.dialogRulerTrackingLevel = false

      this.paginateTracking()

    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async deleteSelected() {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        if (this.selected.length > 0) {
          for (let el of this.selected) {
            await this.deleteMarkRulerUseCase(el.id)
          }
          snackbar.show({ message: "Item(s) excluídos com sucesso!", color: "green", timeout: 1000 })
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.selected = []
      this.paginateTracking()
    }
  }


  async exportExcel() {
    try {
      const heading = [['ID', 'ID REGUA', 'NIVEL', 'TEMPERATURA', 'BATERIA', 'DATA CRIAÇÃO', 'COEFICIENTE', 'COD EMPRESA', 'EMPRESA', 'NOME REGUA', 'COR DA REGUA', 'USUÁRIO']];
      this.loadingBtnExcel = true
      const paginate = {...this.optionsTracking}
      paginate.itemsPerPage = -1
      const data =  await this.fetchRulerTrackingByIDUseCase(this.selectedRuler!.id, paginate, "")



      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);

      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        const i = JSON.parse(JSON.stringify(item))
        delete i.levelPerCoeficient
        delete i.accountID
        delete i.version
        delete i.description
        delete i.terminalNumber
        return i
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'regua');

      XLSX.writeFile(wb, `regua.xlsx`);
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingBtnExcel = false
    }
  }

}

export default RulerController