import { HardwareVersionPagination } from '../../domain/model/hardware_version_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchHardwareVersionRepository {
    (pagination: Pagination): Promise<HardwareVersionPagination>
}

const fetchHardwareVersionRepository = (axios: AxiosInstance): FetchHardwareVersionRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/hardware-version', {
            params: pagination
        })

        const hardwareVersionPagination: HardwareVersionPagination = response.data
        return hardwareVersionPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchHardwareVersionRepository