import { CreateChecklistRepository } from '../../data/repository/create_checklist_repository';
import { Checklist } from '../model/checklist';

export interface CreateChecklistUseCase {
    (data: object): Promise<Checklist>
}

const createChecklistUseCase = (repository: CreateChecklistRepository): CreateChecklistUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createChecklistUseCase;

