import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Alert } from '../../domain/model/alert';

export interface CreateAlertRepository {
  (data: object): Promise<Alert>
}

const createAlertRepository = (axios: AxiosInstance): CreateAlertRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/alert`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createAlertRepository

