import { CreatePumpRepository } from '../../data/repository/create_pump_repository';
import { Pump } from '../model/pump';

export interface CreatePumpUseCase {
    (data: object): Promise<Pump>
}

const createPumpUseCase = (repository: CreatePumpRepository): CreatePumpUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createPumpUseCase;

