export interface MarkPump {
  id: number;
  accountID: number;
  amperage: number;
  capacitors: number;
  createdDate: string;
  description: string;
  engines: number;
  higherLevel: number;
  hourMeter: number;
  lowerLevel: number;
  pumpID: number;
  voltage: number;
  power: number
}


const en: any = {
  idA: 1,
  pumpID: 2,
  voltage: 3,
  amperage: 4,
  hourMeter: 5,
  higherLevel: 6,
  lowerLevel: 7,
  capacitors: 8,
  engines: 9,
  description: 10,
  createdDate: 11,
  accountID: 12,
};
export { en };


