export const headers = [
  {
    text: 'ID',
    align: 'start',
    sortable: true,
    value: 'id',
  },
  {
    text: 'Tipo do Grupo',
    align: 'start',
    sortable: true,
    value: 'groupType',
  },
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }
]
