import { FetchCompanyMeasurementUseCase } from './../domain/usecase/fetch_company_measurement_usecase';
import { BombMeasurement } from './../../pumpMark/domain/model/bomb_measurement';
import { Ruler } from '@/module/ruler/domain/model/ruler';
import { MarkRulerPagination } from './../../rulerMark/domain/model/markRuler_pagination';
import { FetchMarkRulerUseCase } from '@/module/rulerMark/domain/usecase/fetch_markRuler_usecase';
import { PumpPagination } from './../../pump/domain/model/pump_pagination';
import { FetchPumpUseCase } from './../../pump/domain/usecase/fetch_pump_usecase';
import { FetchPlaceUseCase } from './../../place/domain/usecase/fetch_place_usecase';
import { PlacePagination } from './../../place/domain/model/place_pagination';
import { RulerPagination } from './../../ruler/domain/model/ruler_pagination';
import { DataOptions } from 'vuetify';
import { FetchRulerUseCase } from './../../ruler/domain/usecase/fetch_ruler_usecase';
import { snackbar } from '@/core/controller/snackbar_controller';
import { Company } from './../../company/domain/model/company';
import { GetCompanyByIDUseCase } from './../domain/usecase/get_company_by_id_usecase';
import * as echarts from 'echarts';
import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import animationData from '../../../assets/dashboard_charts.json'
import dayjs from 'dayjs';
import { FetchPlacePumpUseCase } from '@/module/pumpMark/domain/usecase/fetch_placePump_usecase';
import { Pump } from '@/module/pump/domain/model/pump';
import { Place } from '@/module/place/domain/model/place';
import { MarkPumpPagination } from '@/module/pumpMark/domain/model/markPump_pagination';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import { FetchMarkPumpUseCase } from '@/module/pumpMark/domain/usecase/fetch_markPump_usecase';
class DashboardController {
  private context: any;
  private getCompanyByIDUseCase: GetCompanyByIDUseCase
  private fetchRulerUseCase: FetchRulerUseCase
  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchPumpUseCase: FetchPumpUseCase
  private fetchMarkRulerUseCase: FetchMarkRulerUseCase
  private fetchCompanyMeasurementUseCase: FetchCompanyMeasurementUseCase
  private fetchPlacePumpUseCaseImpl: FetchPlacePumpUseCase
  private fetchMarkPumpUseCaseImpl: FetchMarkPumpUseCase

  public search: string = ""
  public marks: any = []

  private pagination: DataOptions = {
    groupBy: [],
    sortBy: ["name"],
    itemsPerPage: 30,
    page: 1,
    groupDesc: [],
    multiSort: false,
    mustSort: true,
    sortDesc: []
  }

  public rulers: RulerPagination = {
    items: [],
    total: 0
  }
  public places: PlacePagination = {
    items: [],
    total: 0
  }
  public pumps: PumpPagination = {
    items: [],
    total: 0
  }
  public markRuler: MarkRulerPagination = {
    items: [],
    total: 0
  }
  public pieChart: echarts.ECharts | null = null
  public powerChart: echarts.ECharts | null = null
  public rulerChart: echarts.ECharts | null = null

  public ruler: Ruler | null = null

  public company: Company | null = null
  public center: object | null = null

  public lottieAnimation: object = { animationData: animationData }

  public bombMeasurement: Array<BombMeasurement> = []

  public placePagination: PlacePagination = {
    items: [],
    total: 0
  }
  public placePumpPagination: PumpPagination = {
    items: [],
    total: 0
  }
  public markPumpPagination: MarkPumpPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsPlace: DataOptions = {
    groupBy: [],
    sortBy: ["name"],
    itemsPerPage: 10,
    page: 1,
    groupDesc: [],
    multiSort: false,
    mustSort: true,
    sortDesc: []
  }

  public placePumpOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public selectedPlace: Place = {
    color: "",
    companyID: 0,
    companyName: "",
    id: 0,
    imagePath: "",
    latitude: "",
    longitude: "",
    name: ""
  }
  public markPumpOptions: DataOptions = {
    page: 1,
    itemsPerPage: 5,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [false]
  }

  public selectedPump: Pump = {
    id: 0,
    placeID: 0,
    placeName: "",
    name: "",
    companyID: 0,
    status: 1,
    companyName: ""
  }


  constructor(
    context: any,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
    fetchRulerUseCase: FetchRulerUseCase,
    fetchPlaceUseCase: FetchPlaceUseCase,
    fetchPumpUseCase: FetchPumpUseCase,
    fetchMarkRulerUseCase: FetchMarkRulerUseCase,
    fetchCompanyMeasurementUseCase: FetchCompanyMeasurementUseCase,
    fetchPlacePumpUseCaseImpl: FetchPlacePumpUseCase,
    fetchMarkPumpUseCaseImpl: FetchMarkPumpUseCase,
  ) {
    this.context = context
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
    this.fetchRulerUseCase = fetchRulerUseCase
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchPumpUseCase = fetchPumpUseCase
    this.fetchMarkRulerUseCase = fetchMarkRulerUseCase
    this.fetchCompanyMeasurementUseCase = fetchCompanyMeasurementUseCase
    this.fetchPlacePumpUseCaseImpl = fetchPlacePumpUseCaseImpl
    this.fetchMarkPumpUseCaseImpl = fetchMarkPumpUseCaseImpl
  }

  async mounted() {

    window.addEventListener("resize", () => {
      this.pieChart?.resize()
      this.powerChart?.resize()
      this.rulerChart?.resize()
    });

    try {
      this.company = await this.getCompanyByIDUseCase()
      this.rulers = await this.fetchRulerUseCase(this.pagination, "", 1)
      this.ruler = this.rulers.items?.[0] ?? null
      this.bombMeasurement = await this.fetchCompanyMeasurementUseCase()

      this.placePagination = await this.fetchPlaceUseCase(this.options, this.search)
      this.selectedPlace = this.placePagination.items[0]
      if (this.selectedPlace != null) {
        await this.changePlace(this.selectedPlace)
      }

      this.center = {
        lat: parseFloat(this.company.latitude),
        lng: parseFloat(this.company.longitude)
      }

      await new Promise(resolver => setTimeout(resolver, 500))
      if (this.bombMeasurement.length > 0) {
        this.createPieChart()
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async searchRuler(search: string) {
    try {
      this.rulers = await this.fetchRulerUseCase(this.pagination, search ?? "", 1)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async changePlace(data) {
    try {
      this.placePumpPagination = await this.fetchPlacePumpUseCaseImpl(this.placePumpOptions, data.id)
      if (this.placePumpPagination != null) {
        this.selectedPump = this.placePumpPagination.items[0]
        this.changePump(this.selectedPump)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async changePump(item) {
    this.marks = []
    try {
      this.markPumpPagination = await this.fetchMarkPumpUseCaseImpl(this.markPumpOptions, item.id, "", "")
      for (let el of this.markPumpPagination.items) {
        el["power"] = (el.amperage * el.voltage) / 1000
        this.marks.push(el)
      }
      await new Promise(resolver => setTimeout(resolver, 500))
      if (this.markPumpPagination.items.length > 0) {
        await new Promise(resolver => setTimeout(resolver, 500))
        this.createAveragePowerChart()
      }
    } catch (error) {
      console.log(error);
    }
  }

  async searchPump(search: string) {
    try {
      this.pumps = await this.fetchPumpUseCase(this.pagination, search ?? "", 1)
      
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async fetchRulerLogs() {
    try {
      const paginate = { ...this.pagination }
      paginate.sortBy = ["createdDate"]
      paginate.sortDesc = [true]
      paginate.itemsPerPage = 5
      if (this.ruler) {
        const marks = await this.fetchMarkRulerUseCase(paginate, this.ruler.id, "", "")
        if (marks.items) {
          marks?.items?.reverse()
          this.markRuler = marks
        }
        await new Promise(resolver => setTimeout(resolver, 500))
        this.createRulerChart()
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  createRulerChart() {
    if (this.ruler) {
      const higher = this.rulers.items[0].higherLevel != null ? this.rulers.items[0].higherLevel : 0
      const lower = this.rulers.items[0].lowerLevel != null ? this.rulers.items[0].lowerLevel : 0
      var chartDom = document.getElementById('ruler_chart');
      if (chartDom != null) {
        this.rulerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
        let option = {
          grid: {
            left: 50,
            top: 30,
            right: 70,
            bottom: 60
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: (params: any) => {
              return (
                "Nivel: " + params[0].value +
                '<br/>' +

                "Data: " + params[0].name
              );
            },
          },
          xAxis: {
            type: 'category',
            data: this.markRuler?.items?.map((mark: MarkRuler) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
            boundaryGap: false,
            axisLabel: { show: false, interval: 0, fontSize: 10, rotate: -35 }
          },
          yAxis: {
            type: 'value',
          },
          series: [{
            itemStyle: {
              color: '#8094f7'
            },
            data: this.markRuler?.items?.map((mark: MarkRuler) => mark.level.toFixed(2)) ?? [],
            type: 'line',
            areaStyle: {},
            markLine: {
              data: [
                {
                  label: {
                    formatter: '{b}',
                    position: 'insideEndTop',
                    color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                    //fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 16,
                    fontFamily: 'Helvetica',
                  },
                  name: `Nível superior: ${higher}`,
                  yAxis: higher,
                  type: 'value',
                  lineStyle: {
                    normal: {
                      type: 'solid',
                      color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                    }
                  },
                },
                {
                  label: {
                    formatter: '{b}',
                    position: 'insideEndBottom',
                    color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                    //fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 16,
                    fontFamily: 'Helvetica',
                  },
                  name: `Nível inferior: ${lower}`,
                  yAxis: lower,
                  type: 'value',
                  lineStyle: {
                    normal: {
                      type: 'solid',
                      color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                    }
                  },
                },
              ]
            },
          }]
        };
        option && this.rulerChart.setOption(option);
        this.rulerChart.resize()
      }
    }
  }


  async createAveragePowerChart() {
    await new Promise(resolver => setTimeout(resolver, 500))
    var chartDom = document.getElementById('mark_power_chart');
    if (chartDom != null) {
      this.powerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      let option = {
        grid: {
          left: 30,
          top: 10,
          right: 40,
          bottom: 60
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.marks.map((mark: MarkPump) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
          boundaryGap: true,
          axisLabel: { show: false, interval: 0, fontSize: 10, rotate: -35 }
        },
        yAxis: {
          type: 'value',
        },
        series: [{
          itemStyle: {
            color: '#8094f7'
          },
          name: 'Média',
          areaStyle: {},
          data: this.marks.map((mark: MarkPump) => mark.power.toFixed(4)) ?? [],
          type: 'line'
        }]
      }
      option && this.powerChart.setOption(option);
      this.powerChart.resize()
    }
  }

  createPieChart() {
    var chartDom = document.getElementById('mark_pie_chart');
    if (chartDom != null) {
      this.pieChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      var option: echarts.EChartsCoreOption;
      this.bombMeasurement.map(el => {
        el["value"] = el.qtd
        el["name"] = el.accountName
      })
      option = {
        grid: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 20
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          right: 10,
          top: 20,
          bottom: 20,
          data: this.bombMeasurement.map(measur => measur.accountName)
        },
        series: [
          {
            name: 'Medições',
            type: 'pie',
            radius: '65%',
            center: ['35%', '50%'],
            data: this.bombMeasurement,
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            labelLine: {
              show: false
            },
            label: {
              show: false
            }
          }
        ]
      };

      option && this.pieChart.setOption(option);
      this.pieChart.resize()
    }
  }



}

export default DashboardController