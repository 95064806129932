import { MarkStationPagination } from '../../domain/model/markStation_pagination';
import { PaginationDate } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchMarkStationWith30MinutesRepository {
    (pagination: PaginationDate, id: number): Promise<MarkStationPagination>
}

const fetchMarkStationWith30MinutesRepository = (axios: AxiosInstance): FetchMarkStationWith30MinutesRepository => async (pagination: PaginationDate, id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/station/${id}/tracking/30-minutes`, {
            params: pagination,
        })

        const stationPagination: MarkStationPagination = response.data
        return stationPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchMarkStationWith30MinutesRepository