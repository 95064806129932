import { defaultModelPagination } from '@/core/domain/model/pagination';
import { defaultPagination } from '@/core/domain/model/pagination';
import { alertTypeDefault } from '../domain/model/alert';
import { FetchAlertTypeUseCase } from '../domain/usecase/fetch_alert_type_usecase';
import { DeleteAlertTypeUseCase } from '../domain/usecase/delete_alert_type_usecase';
import { CreateAlertTypeUseCase } from '../domain/usecase/create_alert_type_usecase';
import { ChangeAlertTypeUseCase } from '../domain/usecase/change_alert_type_usecase';
import { AlertTypePagination } from '../domain/model/alert_type_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { AlertType } from '../domain/model/alert';
import { snackbar } from '@/core/controller/snackbar_controller';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';

class AlertTypeController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public loading: boolean = false
  public alertTypePagination: AlertTypePagination = defaultModelPagination()
  public companyPagination: CompanyPagination = defaultModelPagination()
  public options: DataOptions = defaultPagination({})
  public optionsCompany: DataOptions = defaultPagination({})
  public taskDescription: string = ""

  public alertType: AlertType = alertTypeDefault()

  private fetchAlertTypeUseCase: FetchAlertTypeUseCase
  private deleteAlertTypeUseCase: DeleteAlertTypeUseCase
  private createAlertTypeUseCase: CreateAlertTypeUseCase
  private changeAlertTypeUseCase: ChangeAlertTypeUseCase
  private getCompanyByIDUseCase: GetCompanyByIDUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(
    context: any,
    fetchAlertTypeUseCase: FetchAlertTypeUseCase,
    deleteAlertTypeUseCase: DeleteAlertTypeUseCase,
    createAlertTypeUseCase: CreateAlertTypeUseCase,
    changeAlertTypeUseCase: ChangeAlertTypeUseCase,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase
  ) {
    this.context = context
    this.fetchAlertTypeUseCase = fetchAlertTypeUseCase
    this.deleteAlertTypeUseCase = deleteAlertTypeUseCase
    this.createAlertTypeUseCase = createAlertTypeUseCase
    this.changeAlertTypeUseCase = changeAlertTypeUseCase
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }


  async open() {
    this.paginateCompany()
    this.alertType = alertTypeDefault()
    this.taskDescription = ""
    this.context.$refs.crud.resetValidation()
  }

  async paginate() {
    this.loading = true
    try {
      this.alertTypePagination = await this.fetchAlertTypeUseCase(this.options, this.search)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changeAlertTypeUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.alertType.id === 0) {
          await this.createAlertTypeUseCase(this.alertType)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          return
        }

        await this.changeAlertTypeUseCase(this.alertType.id, this.alertType)
        this.paginate()
        snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
        this.context.$refs.crud.resetValidation()
        this.alertType = alertTypeDefault()
        this.taskDescription = ""
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
      }
    }
  }

  async delete(item: AlertType) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteAlertTypeUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: AlertType) {
    this.dialog = true
    this.alertType = { ...item }
    this.searchCompany = item.companyName
    this.paginateCompany()
  }
}

export default AlertTypeController