import { PaginationDate } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/place_tracking';
import { FetchPlaceTrackingRepository } from '../../data/repository/fetch_placeTracking_repository';
import { PlaceTrackingPagination } from "../model/place_tracking_pagination";

export interface FetchPlaceTrackingUseCase {
    (pagination: DataOptions, id: number, startDate: string, endDate: string): Promise<PlaceTrackingPagination>
}

const fetchPlaceTrackingUseCase = (repository: FetchPlaceTrackingRepository): FetchPlaceTrackingUseCase => async (pagination: DataOptions, id: number, startDate: string, endDate: string) => {
    try {
        const payload: PaginationDate = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: "",
            startDate: startDate,
            endDate: endDate
        }

        return await repository(payload, id);
    } catch (error) {
        throw error
    }
}

export default fetchPlaceTrackingUseCase;