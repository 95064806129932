import { AlertTypePagination } from '../../domain/model/alert_type_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchAlertTypeRepository {
    (pagination: Pagination): Promise<AlertTypePagination>
}

const fetchAlertTypeRepository = (axios: AxiosInstance): FetchAlertTypeRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/alert-type', {
            params: pagination,
        })

        const alertTypePagination: AlertTypePagination = response.data
        return alertTypePagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchAlertTypeRepository