<template>
  <v-navigation-drawer
    absolute
    clipped
    right
    v-model="controller.rightDrawer"
    width="350"
    class="mb-0"
  >
    <v-container>
      <div class="d-flex">
        <h3 class="font-weight-light">Configurações</h3>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          icon
          @click="controller.rightDrawer = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="text-center pt-3">
        <h4 class="font-weight-light">Geral</h4>
      </div>
      <v-text-field
        class="pt-4"
        filled
        dense
        color="primary"
        label="Marcação superior da bomba"
        v-model="controller.maxLevelPump"
      ></v-text-field>
      <v-text-field
        class="pt-4"
        filled
        dense
        color="primary"
        label="Marcação superior do levante"
        v-model="controller.maxLevelPlace"
      ></v-text-field>
      <v-text-field
        class="pt-4"
        filled
        dense
        color="primary"
        label="Marcação inferior do levante"
        v-model="controller.minLevelPlace"
      ></v-text-field>
      <div class="d-flex justify-center pt-2">
        <v-btn color="primary" @click="controller.change()">
          <v-icon left>mdi-content-save</v-icon>salvar
        </v-btn>
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.controller.contextDrawer = this;
  },
};
</script>