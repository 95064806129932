import dayjs from "dayjs";

export interface Harvest {
  id: number;
  name: string
  startDate: string
  endDate: string
  companyID: number
  companyName: string
  isFavorite: boolean
}

export const harvestDefault = () => {
  const harvest: Harvest = {
    id: 0,
    name: "",
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
    companyID: 0,
    companyName: "",
    isFavorite: false
  }
  return harvest
}


const en: any = {
  id: 1,
  name: 2,
  startDate: 3,
  endDate: 4,
  companyID: 5,
  companyName: 6,
  isFavorite: 7,
}
export { en };


