import { DeleteAlertTypeRepository } from '../../data/repository/delete_alert_type_repository';
import { AlertType } from '../model/alert';

export interface DeleteAlertTypeUseCase {
    (id: number): Promise<AlertType>
}

const deleteAlertTypeUseCase = (repository: DeleteAlertTypeRepository): DeleteAlertTypeUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteAlertTypeUseCase;