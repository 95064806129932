import { PumpPagination } from '../model/pump_pagination';
import { FetchPumpRepository } from '../../data/repository/fetch_pump_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/pump';

export interface FetchPumpUseCase {
    (pagination: DataOptions, search: string, status: number): Promise<PumpPagination>
}

const fetchPumpUseCase = (repository: FetchPumpRepository): FetchPumpUseCase => async (pagination: DataOptions, search: string, status: number) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search,
            status: status
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchPumpUseCase;