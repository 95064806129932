<template>
  <div>
    <google-map
      v-if="controller.center != null"
      :center="controller.center"
      :zoom="15"
      map-type-id="hybrid"
      style="width: 100%; height: 100vh"
      :options="$vuetify.theme.isDark ? mapDark : mapLight"
    >
      <google-marker :position="controller.center"></google-marker>
    </google-map>
<!--     <v-row class="pa-2 mb-6">
      <v-col cols="12" lg="4">
        <v-card>
          <v-toolbar-title class="text-center">
            Marcações das Réguas
          </v-toolbar-title>
          <v-divider></v-divider>
          <v-row>
            <v-col class="ma-2 d-flex justify-center">
              <v-autocomplete
                clearable
                hide-details
                dense
                :items="controller.rulers.items"
                item-text="name"
                item-value="id"
                v-model="controller.ruler"
                return-object
                @update:search-input="controller.searchRuler($event)"
                filled
                single-line
                placeholder="Selecione a régua"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-text class="pa-0">
            <div
              v-if="controller.ruler && controller.markRuler.items.length > 1"
            >
              <div id="ruler_chart" style="height: 325px; width: 100%"></div>
              <v-divider></v-divider>
              <div class="px-8 py-4">
                <h1 class="font-weight-bold">
                  {{ controller.ruler.name }}
                </h1>
                <h3 class="pt-2 font-weight-bold">
                  Nível atual: {{ controller.markRuler.items.at(-1).level.toFixed(2) }}
                </h3>
                <h3 class="font-weight-bold">
                  Atualizado em:
                  {{
                    dayjs(controller.markRuler.items.at(-1).createdDate).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                  }}
                </h3>
              </div>
            </div>
            <lottie
              v-else
              class="mt-10"
              :options="controller.lottieAnimation"
              :height="405"
              :width="300"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card>
          <v-toolbar-title class="text-center">
            Potencia média x Tempo
          </v-toolbar-title>
          <v-divider></v-divider>
          <v-row>
            <v-col class="ma-2 d-flex justify-center">
              <v-autocomplete
                v-model="controller.selectedPlace"
                :items="controller.placePagination.items"
                item-text="name"
                item-value="id"
                dense
                filled
                label="Selecione o Levante"
                return-object
                @change="controller.changePlace($event)"
                single-line
              ></v-autocomplete>
              <v-autocomplete
                clearable
                v-model="controller.selectedPump"
                class="pl-2"
                hide-details
                dense
                :items="controller.placePumpPagination.items"
                @change="controller.changePump($event)"
                item-text="name"
                item-value="id"
                filled
                single-line
                placeholder="Selecione a bomba"
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-text class="pa-0">
            <div v-if="controller.markPumpPagination.items.length > 0">
              <div
                id="mark_power_chart"
                style="height: 300px; width: 100%"
              ></div>
              <v-divider></v-divider>
              <div class="px-8 py-4">
                <h1 class="font-weight-bold">
                  Bomba: {{ controller.selectedPump.name }}
                </h1>
                <h3 class="pt-2 font-weight-bold">
                  {{ controller.selectedPlace.name }}
                </h3>
                <h3 v-if="controller.marks.length > 0" class="font-weight-bold">
                  Atualizado em:
                  {{
                    dayjs(controller.marks.at(-1).createdDate).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                  }}
                </h3>
              </div>
            </div>
            <lottie
              v-else
              :options="controller.lottieAnimation"
              :height="405"
              :width="300"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card>
          <v-toolbar-title class="text-center">
            Medições por colaborador
          </v-toolbar-title>
          <v-divider></v-divider>
          <v-card-text class="pt-9">
            <div
              v-if="controller.bombMeasurement.length > 0"
              id="mark_pie_chart"
              style="height: 435px; width: 100%"
            ></div>
            <div v-else>
              <div class="text-center font-weight-bold">
                Nenhuma medição disponível
              </div>
              <lottie
                class="mt-11"
                :options="controller.lottieAnimation"
                :height="375"
                :width="300"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { dashboardController } from "@/di/di";
import Lottie from "../../../core/component/lottie.vue";

export default {
  components: {
    Lottie,
  },
  data: (context) => ({
    controller: dashboardController(context),
  }),
  async mounted() {
    await this.controller.mounted();
  },
  watch: {
    "controller.ruler"() {
      this.controller.fetchRulerLogs();
    },
  },
};
</script>
