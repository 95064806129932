export interface RulerGroup {
  id: number;
  companyID: number;
  groupType: number;
  name: string;
  items: Array<RulerFromGroup>
}

export interface RulerFromGroup {
  rulerID: number, itemOrder: number, name: string, id: number
}


export const rulerGroupDefault = () => {
  const rulerGroup: RulerGroup = {
    id: 0,
    companyID: 0,
    groupType: 1,
    name: "",
    items: []
  }
  return rulerGroup
}

const en: any = {
  id: 1,
  companyID: 2,
  groupType: 3,
  name: 4
}
export { en };

