import { CreateAlertRepository } from '../../data/repository/create_alert_repository';
import { Alert } from '../model/alert';

export interface CreateAlertUseCase {
    (data: object): Promise<Alert>
}

const createAlertUseCase = (repository: CreateAlertRepository): CreateAlertUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createAlertUseCase;

