import { PumpMoto } from '../../domain/model/pump_moto';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeletePumpMotoRepository {
    (id: number): Promise<PumpMoto>
}

const deletePumpMotoRepository = (axios: AxiosInstance): DeletePumpMotoRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/pump-moto/${id}`)
        const pumpMoto: PumpMoto = response.data
        return pumpMoto
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deletePumpMotoRepository