import { ChecklistPagination } from './../../domain/model/checklist_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchChecklistRepository {
    (pagination: Pagination): Promise<ChecklistPagination>
}

const fetchChecklistRepository = (axios: AxiosInstance): FetchChecklistRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/checklist', {
            params: pagination,
        })

        const checklistPagination: ChecklistPagination = response.data
        return checklistPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchChecklistRepository