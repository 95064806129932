<template>
  <v-navigation-drawer
    absolute
    clipped
    right
    v-model="controller.rightDrawer"
    width="350"
    class="mb-0"
  >
    <v-container>
      <div class="d-flex">
        <h3 class="font-weight-light">Configurações</h3>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          icon
          @click="controller.rightDrawer = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
<!--       <v-text-field
        class="pt-4"
        filled
        dense
        color="primary"
        label="Nível máximo do gráfico"
        v-model="controller.maxLevel"
      ></v-text-field> -->
      <v-form ref="rightDrawer" lazy-validation>
        <v-text-field
          class="pt-4"
          filled
          dense
          color="primary"
          label="Nível superior"
          type="number"
          v-model.number="controller.selectedRuler.higherLevel"
        >
        </v-text-field>
        <v-text-field
          class="pt-4"
          filled
          dense
          color="primary"
          label="Nível inferior"
          type="number"
          v-model.number="controller.selectedRuler.lowerLevel"
        >
        </v-text-field>
      </v-form>
      <div class="d-flex justify-center">
        <v-btn color="primary" @click="controller.change()">
          <v-icon left>mdi-content-save</v-icon>salvar
        </v-btn>
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.controller.contextDrawer = this;
  },
};
</script>