import { Ruler } from './../../domain/model/ruler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteRulerRepository {
    (id: number): Promise<Ruler>
}

const deleteRulerRepository = (axios: AxiosInstance): DeleteRulerRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/ruler/${id}`)

        const ruler: Ruler = response.data
        return ruler
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteRulerRepository