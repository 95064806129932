import { CreateRulerRepository } from './../../data/repository/create_ruler_repository'
import { Ruler } from '../model/ruler';

export interface CreateRulerUseCase {
    (data: object): Promise<Ruler>
}

const createRulerUseCase = (repository: CreateRulerRepository): CreateRulerUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        console.log(error);
        
        throw error
    }
}

export default createRulerUseCase;

