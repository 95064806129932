import { FetchPlaceUseCase } from '@/module/place/domain/usecase/fetch_place_usecase';
import { FetchPumpUseCase } from '../domain/usecase/fetch_pump_usecase';
import { DeletePumpUseCase } from '../domain/usecase/delete_pump_usecase';
import { CreatePumpUseCase } from '../domain/usecase/create_pump_usecase';
import { ChangePumpUseCase } from '../domain/usecase/change_pump_usecase';
import { PumpPagination } from '../domain/model/pump_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Pump } from '../domain/model/pump';
import { snackbar } from '@/core/controller/snackbar_controller';
import { PlacePagination } from '@/module/place/domain/model/place_pagination';
import { MarkPumpPagination } from '@/module/pumpMark/domain/model/markPump_pagination';
import { FetchPumpTrackingByIDUseCase } from '../domain/usecase/fetch_pump_tracking_by_id_usecase';
import { UpdatePumpTrackingLevelUseCase } from '../domain/usecase/update_pump_tracking_level_usecase';
import { headersTracking } from '../const/headers_tracking';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import { DeleteMarkPumpUseCase } from '../domain/usecase/delete_pump_mark_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import * as XLSX from "xlsx";

class PumpController {
  public context: any;
  public status: number = 0
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public columnsTracking: Array<any> = headersTracking
  public search: string = ""
  public searchPlace: string = ""
  public searchCompany: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public loadingLogs: boolean = false
  public groupBy: string = ""
  public menuGroup: boolean = false
  public dialogTracking: boolean = false
  public dialogPumpTracking: boolean = false
  public dialogRulerTrackingLevel: boolean = false
  public selectedPump: Pump | null = null
  public selected: Array<MarkPump> = []
  public loadingBtnExcel : boolean = false
  public selectedMarkPump: MarkPump = {
    id: 0,
    accountID: 0,
    amperage: 0,
    capacitors: 0,
    createdDate: "",
    description: "",
    engines: 0,
    higherLevel: 0,
    hourMeter: 0,
    lowerLevel: 0,
    pumpID: 0,
    voltage: 0,
    power: 0
  }
  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus
  public pumpPagination: PumpPagination = {
    total: 0,
    items: []
  }
  public trackingPagination: MarkPumpPagination = {
    total: 0,
    items: []
  }
  public placePagination: PlacePagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsPlace: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsTracking: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public pump: Pump = {
    id: 0, placeID: 0, name: "", placeName: "", companyID: 0, status: 1, companyName: "", EngineName: ''
  }

  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchPumpUseCase: FetchPumpUseCase
  private deletePumpUseCase: DeletePumpUseCase
  private createPumpUseCase: CreatePumpUseCase
  private changePumpUseCase: ChangePumpUseCase
  private fetchPumpTrackingByIDUseCase: FetchPumpTrackingByIDUseCase
  private updatePumpTrackingLevelUseCase: UpdatePumpTrackingLevelUseCase
  private deletePumpMarkUseCase: DeleteMarkPumpUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(context: any, fetchPlaceUseCase: FetchPlaceUseCase, fetchPumpUseCase: FetchPumpUseCase, deletePumpUseCase: DeletePumpUseCase, createPumpUseCase: CreatePumpUseCase, changePumpUseCase: ChangePumpUseCase, fetchPumpTrackingByIDUseCase: FetchPumpTrackingByIDUseCase, updatePumpTrackingLevelUseCase: UpdatePumpTrackingLevelUseCase, deletePumpMarkUseCase: DeleteMarkPumpUseCase, fetchCompanyUseCase: FetchCompanyUseCase) {
    
    this.context = context
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchPumpUseCase = fetchPumpUseCase
    this.deletePumpUseCase = deletePumpUseCase
    this.createPumpUseCase = createPumpUseCase
    this.changePumpUseCase = changePumpUseCase
    this.fetchPumpTrackingByIDUseCase = fetchPumpTrackingByIDUseCase
    this.updatePumpTrackingLevelUseCase = updatePumpTrackingLevelUseCase
    this.deletePumpMarkUseCase = deletePumpMarkUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  open() {
    this.flagOption = "create"

    //this.pump = { id: 0, placeID: 0, name: "", placeName: "" }
    this.paginatePlace()
    this.paginateCompany()
  }

  close(){
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.pumpPagination = await this.fetchPumpUseCase(this.options, this.search, this.status)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  async paginatePlace() {
    if (this.searchPlace != null) {
      try {
        this.placePagination = await this.fetchPlaceUseCase(this.optionsPlace, this.searchPlace)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async paginateTracking() {
    this.loadingLogs = true
    try {
      if (this.selectedPump) {
        this.trackingPagination = await this.fetchPumpTrackingByIDUseCase(this.selectedPump.id, this.optionsTracking, "")
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingLogs = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changePumpUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }
  watchOptionsPlace() {
    this.paginatePlace()
  }

  watchSearchPlace() {
    this.paginatePlace()
  }

  watchSearchTracking() {
    this.paginateTracking()
  }

  watchOptionsTracking() {
    this.paginateTracking()
  }
  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        
        switch (this.flagOption) {
          case "create": {
            await this.createPumpUseCase(this.pump)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changePumpUseCase(this.pump.id, this.pump)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Pump) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deletePumpUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Pump) {
    this.flagOption = "change"
    this.dialog = true
    this.searchPlace = item.placeName
    this.searchCompany = item.companyName    
    this.pump = { ...item }
    this.paginateCompany()
    this.paginatePlace()
  }

   async logs(item: Pump) {
    try {      
      this.selectedPump = item
      this.paginateTracking()
      this.dialogTracking = true

    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  } 

  async reloadLogs(){
    try {
      this.paginateTracking()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  updateLevel(item: MarkPump) {
    this.selectedMarkPump = {...item}
    this.dialogPumpTracking = true
  }

  async confirmUpdate() {
    try {
      await this.updatePumpTrackingLevelUseCase(this.selectedMarkPump)
      this.dialogPumpTracking = false
      this.paginateTracking()
      snackbar.show({ message: "Alterado com sucesso!!", color: "green", timeout: 1000})
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async deleteSelected() {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000})
      if (confirm) {
        if (this.selected.length > 0) {
          for (let el of this.selected) {
            await this.deletePumpMarkUseCase(el.id)
          }
          snackbar.show({ message: "Item(s) excluídos com sucesso!", color: "green", timeout: 1000 })
        }
      }
    } catch (error) {
      console.log(error)
    }finally{
      this.selected = []
      this.paginateTracking()
    }
  }


  async exportExcel() {
    try {
      const heading = [['ID', 'ID BOMBA', 'VOLTAGEM', 'AMPEREAGEM', 'HORIMETRO', 'NIVEL MAXIMO', 'NIVEL MINIMO', 'CAPACITORES', 'MOTORES', 'DESCRIÇÃO', 'DATA CRIAÇÃO', 'ID USUARIO', 'NOME USUARIO', 'LIGADA']];
      this.loadingBtnExcel = true
      const paginate = {...this.optionsTracking}
      paginate.itemsPerPage = -1
      const data =  await this.fetchPumpTrackingByIDUseCase(this.selectedPump!.id, paginate, "")

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);

      XLSX.utils.sheet_add_json(ws, data.items, { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'bomba');

      XLSX.writeFile(wb, `bomba.xlsx`);
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadingBtnExcel = false
    }
  }

}

export default PumpController