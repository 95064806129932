import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Place } from '../../domain/model/place';

export interface ChangePlaceRepository {
  (id: number, data: object): Promise<Place>
}

const changePlaceRepository = (axios: AxiosInstance): ChangePlaceRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/place/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changePlaceRepository

