<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.hardwareVersionPagination.total"
      :items="controller.hardwareVersionPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-start">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            v-if="hadPermission(3, 4)"
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon small color="red">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.binaryPath`]="{ item }">
        <a target="_blank" :href="item.signedURL">{{ item.binaryPath }}</a>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Versões do hardware</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção da versão do hardware
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form ref="crud" lazy-validation>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Número da versão"
                    v-model="controller.hardwareVersion.version"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-textarea
                    filled
                    dense
                    color="primary"
                    label="Descrição da versão"
                    v-model="controller.hardwareVersion.description"
                  >
                  </v-textarea>

                  <v-file-input
                    prepend-icon=""
                    :rules="[(v) => !!v || 'Preencha o campo']"
                    v-model="controller.file"
                    label="Arquivo de atualização"
                    filled
                    truncate-length="15"
                  ></v-file-input>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn @click="controller.close()" outlined color="red">
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn color="primary" @click="controller.create()">
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { hardwareVersionController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: hardwareVersionController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
  },
};
</script>