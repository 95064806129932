<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.companyPagination.total"
      :items="controller.companyPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-start">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Empresas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Empresa
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-checkbox
                    v-model="controller.company.active"
                    color="primary"
                    label="Ativo"
                  ></v-checkbox>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="CPF/CNPJ"
                    v-model="controller.company.cpfCnpj"
                    @change="controller.getCnpj($event)"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Nome da empresa"
                    v-model="controller.company.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Nome fantasia"
                    v-model="controller.company.comporateName"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Telefone"
                    v-model="controller.company.phone"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Endereço"
                    v-model="controller.company.address"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>

                  <v-autocomplete
                    filled
                    dense
                    color="primary"
                    label="Cidade"
                    v-model="controller.company.cityID"
                    :search-input.sync="controller.searchCity"
                    :items="controller.cityPagination.items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-autocomplete>
                  <v-text-field
                    filled
                    dense
                    readonly
                    color="primary"
                    label="Latitude"
                    v-model="controller.company.latitude"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    readonly
                    color="primary"
                    label="Longitude"
                    v-model="controller.company.longitude"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <form-maps
                    :center="controller.center"
                    :zoom="controller.zoom"
                    @get-location="controller.getLocation($event)"
                  ></form-maps>
                  <!-- <div style="width: 100%; height: 300px">
                    <google-map
                      v-if="controller.center != null"
                      :center="controller.center"
                      @center_changed="controller.updateCenter($event)"
                      :zoom="controller.zoom"
                      style="width: 100%; height: 100%"
                      map-type-id="hybrid"
                      :options="$vuetify.theme.isDark ? mapDark : mapLight"
                    >
                    </google-map>
                    <v-icon
                      color="orange"
                      x-large
                      style="
                        position: relative;
                        bottom: 54%;
                        left: 48%;
                        z-index: 9999;
                      "
                      >mdi-map-marker</v-icon
                    >
                  </div> -->

                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
    <!--     <v-dialog width="900" v-model="controller.mapa">
      <div style="width: 100%; height: 300px">
        <google-map
          v-if="controller.center != null"
          :center="controller.center"
          @center_changed="controller.updateCenter($event)"
          :zoom="controller.zoom"
          style="width: 100%; height: 100%"
          map-type-id="hybrid"
          :options="$vuetify.theme.isDark ? mapDark : mapLight"
        >
        </google-map>
      </div>
    </v-dialog> -->
  </div>
</template>

<script>
import { companyController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: companyController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsCity"() {
      this.controller.watchOptionsCity();
    },
    "controller.searchCity": _.debounce(function () {
      this.controller.watchSearchCity();
    }, 500),
  },
};
</script>