import { DeleteHarvestRepository } from '../../data/repository/delete_harvest_repository';
import { Harvest } from '../model/harvest';

export interface DeleteHarvestUseCase {
    (id: number): Promise<Harvest>
}

const deleteHarvestUseCase = (repository: DeleteHarvestRepository): DeleteHarvestUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteHarvestUseCase;