import { DeletePumpMotoRepository } from '../../data/repository/delete_pump_moto_repository';
import { PumpMoto } from '../model/pump_moto';

export interface DeletePumpMotoUseCase {
    (id: number): Promise<PumpMoto>
}

const deletePumpMotoUseCase = (repository: DeletePumpMotoRepository): DeletePumpMotoUseCase => async (id: number) => {
    try {

        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deletePumpMotoUseCase;