import { ChangeHarvestRepository } from '../../data/repository/change_harvest_repository';
import { Harvest } from '../model/harvest';

export interface ChangeHarvestUseCase {
    (id: number, data: Harvest): Promise<Harvest>
}

const changeHarvestUseCase = (repository: ChangeHarvestRepository): ChangeHarvestUseCase => async (id: number, data: Harvest) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeHarvestUseCase;

