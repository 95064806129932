
import { ChangeNotificationUseCase } from '../domain/usecase/change_notification_usecase';
import { FetchNotificationUseCase } from '../domain/usecase/fetch_notification_usecase';
import { DataOptions } from 'vuetify';
import { NotificationPagination } from '../domain/model/notification_pagination';
import { Notification } from '../domain/model/notification';
import dayjs from 'dayjs';
import { Ruler, rulerDefault } from '@/module/ruler/domain/model/ruler';
import { RulerPagination } from '@/module/ruler/domain/model/ruler_pagination';
import { FetchRulerUseCase } from '@/module/ruler/domain/usecase/fetch_ruler_usecase';
import { FetchNotificationByRulerIDUseCase } from '../domain/usecase/fetch_notification_by_ruler_id_usecase';

class NotificationController {
  public search: string = ""
  public loadingBtn: boolean = false
  public calendarMenuIn: boolean = false
  public calendarMenuUntil: boolean = false
  public calendarMenuDialogIn: boolean = false
  public calendarMenuDialogUntil: boolean = false
  public calendarIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendarUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public loading: boolean = false
  public selectedRuler: Ruler = rulerDefault()
  public notifications: Array<Notification> = []
  public notificationPagination: NotificationPagination = {
    total: 0,
    items: []
  }
  public rulerPagination: RulerPagination = {
    items: [],
    total: 0
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 15,
    groupBy: [],
    sortBy: ["createDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }

  public rulerOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }


  public notification: Notification = {
    createDate: "", fromAccountID: 0, fromPumpID: 0, fromRulerID: 0, id: 0, message: "", seen: false, seenDate: "", toAccountID: 0, toCompanyID: 0
  }

  constructor(
    private context: any,
    private fetchNotificationUseCase: FetchNotificationUseCase,
    private changeNotificationUseCase: ChangeNotificationUseCase,
    private fetchRulerUseCase: FetchRulerUseCase,
    private fetchNotificationByRulerIDUseCase: FetchNotificationByRulerIDUseCase,
  ) { }

  async mounted() {
    this.rulerPagination = await this.fetchRulerUseCase(this.rulerOptions, this.search, 1)
  }

  async paginate() {
    // this.notificationPagination.items = []
    this.loading = true
    try {
      if (this.selectedRuler != null && this.selectedRuler.id > 0) {
        this.notificationPagination = await this.fetchNotificationByRulerIDUseCase(this.options, this.search, this.calendarIn, this.calendarUntil, this.selectedRuler.id)
      } else {
        this.notificationPagination = await this.fetchNotificationUseCase(this.options, this.search, this.calendarIn, this.calendarUntil)
      }
      // for (let el of data.items) {
      //   this.notificationPagination.items.push(el)
      // }
      for (let el of this.notificationPagination.items) {
        this.notifications.push(el)
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async filter() {
    this.notifications = []
    this.loadingBtn = true
    await this.paginate()
    this.loadingBtn = false
  }

  async change(item: Notification) {
    try {
      this.notification = { ...item }
      this.notification.seen = true
      await this.changeNotificationUseCase(this.notification.id, this.notification)
    } catch (error) {
      console.log(error);
    }
  }

  async beforeDestroy() {
    for (let el of this.notificationPagination.items) {
      if (el.seen == false) {
        await this.change(el)
      }
    }
  }

}

export default NotificationController