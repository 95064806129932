import { DeleteRulerRepository } from './../../data/repository/delete_ruler_repository';
import { Ruler } from '../model/ruler';

export interface DeleteRulerUseCase {
    (id: number): Promise<Ruler>
}

const deleteRulerUseCase = (repository: DeleteRulerRepository): DeleteRulerUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteRulerUseCase;