export interface Company {
  id: number;
  active: boolean;
  address: string;
  cityID: number;
  comporateName: string;
  cpfCnpj: string;
  name: string;
  phone: string;
  cityName: string;
  latitude: string;
  longitude: string;
  timezoneID: number;
  accessPlanID: number | null
  accessPlanName: string | null
}

export const companyDefault = (): Company => {
  return {
    id: 0,
    active: true,
    address: "",
    cityID: 0,
    comporateName: "",
    cpfCnpj: "",
    name: "",
    phone: "",
    cityName: "",
    latitude: "",
    longitude: "",
    timezoneID: 1,
    accessPlanID: null,
    accessPlanName: null,
  }
}


const en: any = {
  id: 1,
  active: 8,
  address: 7,
  cityID: 6,
  comporateName: 3,
  cpfCnpj: 4,
  name: 2,
  phone: 5,
  cityName: 13,
  latitude: 11,
  longitude: 12,
  timezoneID: 1,
};
export { en };
