import { CreateStationRepository } from './../../data/repository/create_station_repository'
import { Station } from '../model/station';

export interface CreateStationUseCase {
    (data: object): Promise<Station>
}

const createStationUseCase = (repository: CreateStationRepository): CreateStationUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        console.log(error);
        
        throw error
    }
}

export default createStationUseCase;

