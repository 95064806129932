import { MarkStation } from '@/module/stationMark/domain/model/markStation';
import { DeleteMarkStationRepository } from '../../data/repository/delete_station_mark_repository';

export interface DeleteMarkStationUseCase {
    (id: number): Promise<MarkStation>
}

const deleteStationMarkUseCase = (repository: DeleteMarkStationRepository): DeleteMarkStationUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteStationMarkUseCase;