import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import { DeleteMarkRulerRepository } from '../../data/repository/delete_ruler_mark_repository';

export interface DeleteMarkRulerUseCase {
    (id: number): Promise<MarkRuler>
}

const deleteRulerMarkUseCase = (repository: DeleteMarkRulerRepository): DeleteMarkRulerUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteRulerMarkUseCase;