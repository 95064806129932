<template>
  <div>
    <!-- <div class=" d-flex justify-end">
      <v-btn color="primary"
            dark
            height="40"
            class="mt-4 mr-8"
            @click="generatePDF" :disabled="!pdfCarregado">Gerar PDF</v-btn>

    </div> -->
    <div id="swagger-ui"></div>
  </div>
</template>

<script>
import { baseUrl } from "@/constants";
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import 'jspdf-autotable';
import 'jspdf-html2canvas';

export default {
  data() {
    return {
      pdfCarregado: true
    };
  },
  async mounted() {
    const auth = localStorage.getItem("auth");
    const jwtAuthToken = JSON.parse(auth);

    const spec = await axios.get(baseUrl + "/doc/doc.json", {
      headers: {
        authorization: `Bearer ${jwtAuthToken.token}`,
      },
    });

    SwaggerUI({
      spec: spec.data,
      dom_id: "#swagger-ui",
    });

    const elements = document.getElementsByClassName(
      "information-container wrapper"
    );

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  },
  methods: {
    async generatePDF() {
      this.pdfCarregado = false;

      const pdf = new jsPDF('p', 'pt', 'a4');
      const options = { background: 'white' };

      const htmlContent = document.getElementById("swagger-ui");

      try {
        const canvas = await html2canvas(htmlContent, options);
        const imgData = canvas.toDataURL('image/jpeg');

        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const totalHeight = imgHeight;

        let currentPosition = 0;
        let currentPage = 1;

        while (currentPosition < totalHeight) {
          if (currentPage > 1) {
            pdf.addPage();
          }

          const startY = -(currentPage - 1) * pdf.internal.pageSize.getHeight();
          const remainingHeight = totalHeight - currentPosition;
          const heightToAdd = Math.min(remainingHeight, pdf.internal.pageSize.getHeight());

          pdf.addImage(imgData, 'JPEG', 0, startY, imgWidth, imgHeight, null, 'FAST');
          currentPosition += heightToAdd;
          currentPage++;
        }

        pdf.save("swagger.pdf");
      } catch (error) {
        console.error('Erro ao gerar PDF:', error);
      }

      this.pdfCarregado = true;
    },

  },
};
</script>
