import { Station } from '../../domain/model/station';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteStationRepository {
    (id: number): Promise<Station>
}

const deleteStationRepository = (axios: AxiosInstance): DeleteStationRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`https://stationapihydra.com/station/${id}`)

        const station: Station = response.data
        return station
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteStationRepository