import { FetchScreenCompanyUseCase } from './../../screen/domain/usecase/fetch_screen_company_usecase';
import { ChangeUserProfileImageUseCase } from '@/module/user/domain/usecase/change_user_profile_image_usecase';
import { FetchUserProfileImageUseCase } from '@/module/user/domain/usecase/fetch_user_profile_image_usecase';
import { FetchNotificationUseCase } from '@/module/notifications/domain/usecase/fetch_notification_usecase';
import { FetchAccountPermissionsUseCase } from '@/core/domain/usecase/fetch_account_permissions_usecase'
import { NotificationPagination } from '@/module/notifications/domain/model/notification_pagination';
import { getAccountGroupID, getAccountName, getAccount, getCompanyID } from '@/core/domain/model/jwtAuthToken';
import HardwareVersionPage from '../../hardwareVersion/view/hardware_version.vue'
import { Notification } from '@/module/notifications/domain/model/notification';
import RulerGroupMark from '../../rulerGroupMark/view/rulerGroupMark.vue'
import NotificationPage from '../../notifications/view/notifications.vue'
import DashboardPage from '../../dashboard/view/dashboard_page.vue'
import NewRulerMark from '../../newRulerMark/view/newRulerMark.vue'
import AccessPlanPage from '../../accessPlan/view/accessPlan.vue'
import { snackbar } from '@/core/controller/snackbar_controller';
import ChecklistPage from '../../checklist/view/checklist.vue'
import CallCenterPage from '../../callCenter/view/callCenter.vue'
import AlertTypePage from '../../alertType/view/alertType.vue'
import RulerGroup from '../../rulerGroup/view/rulerGroup.vue';
import RulerMark from '../../rulerMark/view/rulerMark.vue'
import CompanyPage from '../../company/view/company.vue'
import HarvestPage from '../../harvest/view/harvest.vue'
import SwaggerPage from '../../swagger/view/swagger.vue'
import PumpMark from '../../pumpMark/view/pumpMark.vue'
import ScreenPage from '../../screen/view/screen.vue'
import UserPage from '../../user/view/user_page.vue'
import AlertPage from '../../alert/view/alert.vue'
import RulerPage from '../../ruler/view/ruler.vue'
import StationPage from '../../station/view/station.vue'
import PlacePage from '../../place/view/place.vue'
import PumpPage from '../../pump/view/pump.vue'
import enginePage from '../../engine/view/engine_page.vue'
import pumpMotoPage from '../../pump_moto/view/index.vue'
import DrivePage from '@/module/drive/view/index.vue'
import { baseUrl } from "@/constants";
import { DataOptions } from 'vuetify';
import Vue from 'vue';


class HomeController {
    public context: any;
    public selected: any = null;
    public mini: boolean = true
    public sidebarMenu: boolean = true
    public userMenu: boolean = false
    public fetchAccountPermissionsUseCase: FetchAccountPermissionsUseCase
    public fetchNotificationUseCase: FetchNotificationUseCase
    public fetchUserProfileImageUseCase: FetchUserProfileImageUseCase
    public fetchScreenCompanyUseCase: FetchScreenCompanyUseCase
    public changeUserProfileImageUseCase: ChangeUserProfileImageUseCase
    public dark: boolean = false
    public userName: string = ""
    public loading: boolean = true;
    public termosDialog: boolean = false
    public termosContext: any;
    public profileImage: any;
    public previewImage: any;
    public notificationMenu: boolean = false
    public search: string = ""
    public countNotifications: number = 0

    public notificationPagination: NotificationPagination = {
        total: 0,
        items: []
    }

    public options: DataOptions = {
        page: 1,
        itemsPerPage: 30,
        groupBy: [],
        sortBy: ["createDate"],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: [true]
    }


    public notification: Notification = {
        createDate: "", fromAccountID: 0, fromPumpID: 0, fromRulerID: 0, id: 0, message: "", seen: false, seenDate: "", toAccountID: 0, toCompanyID: 0
    }

    constructor(
        context: any,
        fetchAccountPermissionsUseCase: FetchAccountPermissionsUseCase,
        fetchNotificationUseCase: FetchNotificationUseCase,
        fetchUserProfileImageUseCase: FetchUserProfileImageUseCase,
        changeUserProfileImageUseCase: ChangeUserProfileImageUseCase,
        fetchScreenCompanyUseCase: FetchScreenCompanyUseCase
    ) {
        this.context = context;
        this.fetchAccountPermissionsUseCase = fetchAccountPermissionsUseCase;
        this.fetchNotificationUseCase = fetchNotificationUseCase;
        this.fetchUserProfileImageUseCase = fetchUserProfileImageUseCase;
        this.changeUserProfileImageUseCase = changeUserProfileImageUseCase;
        this.fetchScreenCompanyUseCase = fetchScreenCompanyUseCase
    }

    async navigate(page: number) {
        switch (page) {
            case 0:
                this.selected = DashboardPage
                break
            case 1:
                this.selected = NewRulerMark
                break
            case 2:
                this.selected = PumpMark
                break
            case 3:
                this.selected = UserPage
                break
            case 4:
                this.selected = CompanyPage
                break
            case 5:
                this.selected = RulerPage
                break
            case 25:
                this.selected = StationPage
                break
            case 6:
                this.selected = PlacePage
                break
            case 7:
                this.selected = PumpPage
                break
            case 8:
                this.selected = HardwareVersionPage
                break
            case 9:
                this.selected = HarvestPage
                break
            case 10:
                this.countNotifications = 0
                this.selected = NotificationPage
                break
            case 11:
                this.selected = SwaggerPage
                break
            case 12:
                this.selected = AccessPlanPage
                break
            case 13:
                this.userMenu = !this.userMenu
                break
            case 14:
                this.selected = RulerGroup
                break
            case 15:
                this.selected = RulerGroupMark
                break
            case 16:
                this.selected = RulerMark
                break
            case 17:
                this.selected = ChecklistPage
                break
            case 18:
                this.selected = AlertPage
                break
            case 19:
                this.selected = ScreenPage
                break
            case 20:
                this.selected = AlertTypePage
                break
            case 21:
                this.selected = CallCenterPage
                break
            case 22:
                this.selected = enginePage
                break
            case 23:
                this.selected = pumpMotoPage
                break;
            case 24:
                this.selected = DrivePage
                break;
            default:
                break
        }

        if (this.context.$vuetify.breakpoint.mobile) {
            this.sidebarMenu = false
        }

    }

    async openMenu() {
        this.sidebarMenu = false
        await new Promise((resolver: any) => setTimeout(resolver(), 200))
        this.sidebarMenu = true
    }

    logout() {
        localStorage.removeItem('auth');
        window.router.login()
    }

    async mounted() {
        try {
            this.dark = localStorage.getItem("dark") === "true" ? true : false;

            window.navigation = this;
            const accountGroupID = getAccountGroupID()
            const companyID = getCompanyID()

            const notes = await this.fetchNotificationUseCase(this.options, this.search, "", "")
            const res = notes.items.some(el => el.seen == false)
            for (let item of notes.items) {
                if (item.seen == false) {
                    this.countNotifications += 1
                }
            }
            if (res) {
                this.notificationPagination = await this.fetchNotificationUseCase(this.options, this.search, "", "")
            }

            Vue.prototype.permissions = await this.fetchAccountPermissionsUseCase(
                accountGroupID
            );

            if (companyID != null) {
                Vue.prototype.screens = await this.fetchScreenCompanyUseCase(
                    companyID
                );
            }

            this.userName = getAccountName()
            this.getProfileImage()

            // if (!hadPermission(5, 1)) {
            this.selected = NewRulerMark
            // } else {
            // this.selected = DashboardPage
            // }

            setTimeout(() => {
                this.loading = false
            }, 500)
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    getProfileImage() {
        let user = getAccount()
        this.profileImage = `${baseUrl}/account/${user.id}/profile-image`
    }

    async saveProfileImage(imagem) {
        let user = getAccount()
        try {
            this.changeUserProfileImageUseCase(user.id, imagem);
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    openUserMenu() {
        this.userMenu = !this.userMenu
        this.notificationMenu = false
    }

    openNotificationMenu() {
        this.notificationMenu = !this.notificationMenu
        this.userMenu = false
    }

    termos() {
        this.userMenu = !this.userMenu
        this.termosDialog = true
    }

    toNotifications() {
        this.notificationMenu = false
        this.notificationPagination.items = []
        this.navigate(10)
    }
}

export default HomeController