import { ChangeUserProfileImageRepository } from '../../data/repository/change_profile_image_repository';
import { User } from '../model/user';

export interface ChangeUserProfileImageUseCase {
    (id: number, image: any): Promise<User>
}

const changeUserProfileImageUseCase = (repository: ChangeUserProfileImageRepository): ChangeUserProfileImageUseCase => async (id: number, image: any) => {
    try {
        return await repository(id, image);
    } catch (error) {
        throw error
    }
}

export default changeUserProfileImageUseCase;