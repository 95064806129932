import { CallCenterPagination } from './../../callCenter/domain/model/call_center_pagination';
import { FetchCallCenterUseCase } from './../../callCenter/domain/usecase/fetch_call_center_usecase';
import { AlertTypePagination } from './../../alertType/domain/model/alert_type_pagination';
import { FetchAlertTypeUseCase } from './../../alertType/domain/usecase/fetch_alert_type_usecase';
import { UserPagination } from './../../user/domain/model/user_pagination';
import { defaultModelPagination } from '@/core/domain/model/pagination';
import { defaultPagination } from '@/core/domain/model/pagination';
import { alertDefault, AlertHistory } from '../domain/model/alert';
import { FetchAlertUseCase } from '../domain/usecase/fetch_alert_usecase';
import { CreateAlertUseCase } from '../domain/usecase/create_alert_usecase';
import { ChangeAlertUseCase } from '../domain/usecase/change_alert_usecase';
import { AlertPagination } from '../domain/model/alert_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Alert } from '../domain/model/alert';
import { snackbar } from '@/core/controller/snackbar_controller';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { FetchUsersUseCase } from '@/module/user/domain/usecase/fetch_user_usecase';
import { getAccountID } from '@/core/domain/model/jwtAuthToken';

class AlertController {
  public context: any;
  public dialog: boolean = false;
  public dialogShare: boolean = false;
  public dialogHistory: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public searchAccount: string = ""
  public loading: boolean = false
  public alertPagination: AlertPagination = defaultModelPagination()
  public callCenterPagination: CallCenterPagination = defaultModelPagination()
  public companyPagination: CompanyPagination = defaultModelPagination()
  public accountPagination: UserPagination = defaultModelPagination()
  public options: DataOptions = defaultPagination({})
  public optionsCompany: DataOptions = defaultPagination({})
  public optionsAccount: DataOptions = defaultPagination({})
  public optionsAlertType: DataOptions = defaultPagination({itemsPerPage: -1})
  public optionsCallCenter: DataOptions = defaultPagination({itemsPerPage: -1})
  public option: number = 1
  public alertTypes: AlertTypePagination = defaultModelPagination()

  public alert: Alert = alertDefault()
  public alertHistory: AlertHistory[] = []

  private fetchAlertUseCase: FetchAlertUseCase
  private createAlertUseCase: CreateAlertUseCase
  private changeAlertUseCase: ChangeAlertUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase
  private fetchUserUseCase: FetchUsersUseCase
  private fetchAlertTypeUseCase: FetchAlertTypeUseCase
  private fetchCallCenterUseCase: FetchCallCenterUseCase

  constructor(
    context: any,
    fetchAlertUseCase: FetchAlertUseCase,
    createAlertUseCase: CreateAlertUseCase,
    changeAlertUseCase: ChangeAlertUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase,
    fetchUserUseCase: FetchUsersUseCase,
    fetchAlertTypeUseCase: FetchAlertTypeUseCase,
    fetchCallCenterUseCase: FetchCallCenterUseCase
  ) {
    this.context = context
    this.fetchAlertUseCase = fetchAlertUseCase
    this.createAlertUseCase = createAlertUseCase
    this.changeAlertUseCase = changeAlertUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
    this.fetchUserUseCase = fetchUserUseCase
    this.fetchAlertTypeUseCase = fetchAlertTypeUseCase
    this.fetchCallCenterUseCase = fetchCallCenterUseCase
  }


  async open() {
    this.alertTypes = await this.fetchAlertTypeUseCase(this.optionsAlertType, "")
    this.paginateCompany()
    this.alert = alertDefault()
    this.context?.$refs?.crud?.resetValidation()
  }

  async paginate() {
    this.loading = true
    try {
      this.alertPagination = await this.fetchAlertUseCase(this.options, this.search)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async paginateAccount() {
    if (this.searchAccount != null) {
      try {
        this.accountPagination = await this.fetchUserUseCase(this.optionsAccount, this.searchAccount)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  close() {
    this.context?.$refs?.crud?.resetValidation()
    this.dialog = false
    this.dialogShare = false
    this.dialogHistory = false
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  watchSearchAccount() {
    this.paginateAccount()
  }

  watchOptionsAccount() {
    this.paginateAccount()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.option === 2) {
          this.alert.whoIs = getAccountID() ?? 0
          this.alert.currentStatus = 3
        }

        if (this.alert.id === 0) {
          await this.createAlertUseCase(this.alert)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          this.context?.$refs?.crud?.resetValidation()
          this.alert = alertDefault()
          this.dialog = false
          this.dialogHistory = false
          this.dialogShare = false
          this.option = 0
          return
        }

        await this.changeAlertUseCase(this.alert.id, this.alert)
        this.paginate()
        snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
        this.context?.$refs?.crud?.resetValidation()
        this.alert = alertDefault()
        this.dialog = false
        this.dialogHistory = false
        this.dialogShare = false
        this.option = 0
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } 
    }
  }

  async share(item: Alert, option: number) {
    this.callCenterPagination = await this.fetchCallCenterUseCase(this.optionsCallCenter, "")
    this.option = option
    this.dialogShare = true
    this.alert = { ...item }
    this.alert.currentStatus = 2
    //this.searchCompany = item.companyName
    this.paginateAccount()
  }

  viewHistory(history: AlertHistory[]) {
    this.alertHistory = history
    this.dialogHistory = true
  }
}

export default AlertController