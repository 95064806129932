import { FetchPlaceUseCase } from '@/module/place/domain/usecase/fetch_place_usecase';
import { FetchPumpMotoUseCase } from '../domain/usecase/fetch_remote_control_usecase';
import { DeletePumpMotoUseCase } from '../domain/usecase/delete_remote_control_usecase';
import { CreatePumpMotoUseCase } from '../domain/usecase/create_remote_control_usecase';
import { ChangeRemoteControlUseCase } from '../domain/usecase/change_remote_control_usecase';
import { PumpMotoPagination } from '../domain/model/pump_moto_pagination';
import { Place } from '@/module/place/domain/model/place';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { PumpMoto } from '../domain/model/pump_moto';
import { snackbar } from '@/core/controller/snackbar_controller';
import { PlacePagination } from '@/module/place/domain/model/place_pagination';
import { MarkPumpPagination } from '@/module/pumpMark/domain/model/markPump_pagination';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import * as XLSX from "xlsx";

class PumpMotoController {
  public context: any;
  public status: number = 0
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchPlace: string = ""
  public searchCompany: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public loadingLogs: boolean = false
  public groupBy: string = ""
  public menuGroup: boolean = false
  public dialogTracking: boolean = false
  public dialogPumpTracking: boolean = false
  public dialogRulerTrackingLevel: boolean = false
  public selectedPumpMoto: PumpMoto | null = null
  public selected: Array<MarkPump> = []
  public loadingBtnExcel : boolean = false
  public selectedMarkPump: MarkPump = {
    id: 0,
    accountID: 0,
    amperage: 0,
    capacitors: 0,
    createdDate: "",
    description: "",
    engines: 0,
    higherLevel: 0,
    hourMeter: 0,
    lowerLevel: 0,
    pumpID: 0,
    voltage: 0,
    power: 0
  }
  public selectedPlace: Place = {
    color: "",
    companyID: 0,
    companyName: "",
    id: 0,
    imagePath: "",
    latitude: "",
    longitude: "",
    name: ""
  }
  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus
  public pumpMotoPagination: PumpMotoPagination = {
    total: 0,
    items: []
  }
  public pumpMotoPaginationNoPlace: PumpMotoPagination = {
    total: 0,
    items: []
  }
  public trackingPagination: MarkPumpPagination = {
    total: 0,
    items: []
  }
  public placePagination: PlacePagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 1000,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsPlace: DataOptions = {
    page: 1,
    itemsPerPage: 1000,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsTracking: DataOptions = {
    page: 1,
    itemsPerPage: 1000,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 1000,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public pumpMoto: PumpMoto = {
    id: 0, placeID: 0, name: "", EngineID: 0, EngineName: "", CompanyID: 0, checkbox:false, isButtonDisabled: true, withPump: true ,isFavorite: false, remoteControl: false, energyMonitoring: false, inUseSet: false, Status: 0, CompanyName: "", placeName: ""
  }

  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchPumpMotoUseCase: FetchPumpMotoUseCase
  private deletePumpMotoUseCase: DeletePumpMotoUseCase
  private createPumpMotoUseCase: CreatePumpMotoUseCase
  private changeRemoteControlUseCase: ChangeRemoteControlUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(context: any, fetchPlaceUseCase: FetchPlaceUseCase, fetchPumpMotoUseCase: FetchPumpMotoUseCase, deletePumpMotoUseCase: DeletePumpMotoUseCase, createPumpMotoUseCase: CreatePumpMotoUseCase, changeRemoteControlUseCase: ChangeRemoteControlUseCase, fetchCompanyUseCase: FetchCompanyUseCase) {
    
    this.context = context
    
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchPumpMotoUseCase = fetchPumpMotoUseCase
    this.deletePumpMotoUseCase = deletePumpMotoUseCase
    this.createPumpMotoUseCase = createPumpMotoUseCase
    this.changeRemoteControlUseCase = changeRemoteControlUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  open() {
    this.flagOption = "create"
    //this.pump = { id: 0, placeID: 0, name: "", placeName: "" }
    this.paginatePlace()
    this.paginateCompany()
  }

  close(){

    this.dialog = false
  }
  
  async paginate() {
    this.loading = true;
    try {
      this.placePagination = await this.fetchPlaceUseCase(this.options, this.search);
      this.pumpMotoPaginationNoPlace = await this.fetchPumpMotoUseCase(this.options, this.search, this.status);
      const selectedPlaceID = this.selectedPlace.id;
  
      const filteredItems = this.pumpMotoPaginationNoPlace.items.filter(
        (item) => item.placeID === selectedPlaceID
      );

      const sortedItems = filteredItems.sort((a, b) => a.id - b.id);
  
      this.pumpMotoPagination = {
        total: sortedItems.length,
        items: sortedItems,
      };
      
    } catch (error: any) {
      snackbar.show({ message: error.toString() });
    } finally {
      this.loading = false;
    }
  }
  

  async paginatePlace() {
    if (this.searchPlace != null) {
      try {
        this.placePagination = await this.fetchPlaceUseCase(this.optionsPlace, this.searchPlace)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changePlace(data) {
    this.selectedPlace = data
    this.paginate()

  }



  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changeRemoteControlUseCase(item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }
  watchOptionsPlace() {
    this.paginatePlace()
  }

  watchSearchPlace() {
    this.paginatePlace()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {

    try {
      if (this.flagOption === "create") {
        await this.handleCreate();
      } else if (this.flagOption === "change") {
        await this.handleChange();
      } else if(this.flagOption === "multichange") {
        await this.handleMultiChange();
      }
  
      return true;
    } catch (error:any) {
      console.error("Error:", error);
      snackbar.show({ message: error.toString() });
      return false;
    } finally {
      this.dialog = false;
      
    }
  }
  
  async handleCreate() {
    await this.createPumpMotoUseCase(this.pumpMoto);
    snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 });
    this.paginate();
  }
  
  async handleChange() {
  
    const statusArray = this.pumpMotoPagination.items.map(item => item.Status);
    const clickedItemIndex = this.pumpMotoPagination.items.findIndex(item => item.id === this.pumpMoto.id);

    statusArray[clickedItemIndex] = statusArray[clickedItemIndex] === 1 ? 0 : 1;

    const newData = {
      id: this.pumpMoto.id,
      status: statusArray,
      companyID: this.pumpMoto.CompanyID,
      placeID: this.pumpMoto.placeID,
    };

    try {
      const changePromise = this.changeRemoteControlUseCase(newData);
  
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Sem resposta do equipamento. Tente novamente!"));
        }, 25000);
      });
  
      await Promise.race([changePromise, timeoutPromise]);
      await new Promise(resolve => setTimeout(resolve, 3000));
  
      this.paginate();
    } catch (error: any) {
      throw error;
    }
  }

  async handleMultiChange() {
    const statusArray: any = [];
  
    this.pumpMotoPagination.items.forEach((item) => {
      const newStatus = item.checkbox ? (item.Status === 1 ? 0 : 1) : item.Status;
      statusArray.push(newStatus);
    });
  
    if (statusArray.length === 0) {
      return;
    }
  
    const newData = {
      id: this.pumpMotoPagination.items[0].id,
      status: statusArray,
      companyID: this.pumpMoto.CompanyID,
      placeID: this.pumpMoto.placeID,
    };
  
    try {
      const changePromise = this.changeRemoteControlUseCase(newData);
  
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Sem resposta do equipamento. Tente novamente!"));
        }, 25000);
      });
  
      await Promise.race([changePromise, timeoutPromise]);

      await new Promise(resolve => setTimeout(resolve, 3000));
  
      this.paginate();
    } catch (error: any) {
      throw error;
    }
  }

  
  

  async delete(item: PumpMoto) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deletePumpMotoUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: PumpMoto) {
    this.flagOption = "change"
    this.dialog = true
    this.searchPlace = item.placeName
    this.searchCompany = item.CompanyName    
    this.pumpMoto = { ...item }
    this.paginateCompany()
    this.paginatePlace()
  }

  async multichange(item: PumpMoto) {
    this.flagOption = "multichange"
    this.dialog = true
    this.searchPlace = item.placeName
    this.searchCompany = item.CompanyName    
    this.pumpMoto = { ...item }

    this.paginateCompany()
    this.paginatePlace()
  }



  updateLevel(item: MarkPump) {
    this.selectedMarkPump = {...item}
    this.dialogPumpTracking = true
  }


}

export default PumpMotoController