import { ChangeStationRepository } from './../../data/repository/change_station_repository';
import { Station } from '../model/station';

export interface ChangeStationUseCase {
    (id: number, data: object): Promise<Station>
}

const changeStationUseCase = (repository: ChangeStationRepository): ChangeStationUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeStationUseCase;

