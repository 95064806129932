import { AlertPagination } from '../model/alert_pagination';
import { FetchAlertRepository } from '../../data/repository/fetch_alert_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/alert';

export interface FetchAlertUseCase {
    (pagination: DataOptions, search: string): Promise<AlertPagination>
}

const fetchAlertUseCase = (repository: FetchAlertRepository): FetchAlertUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchAlertUseCase;