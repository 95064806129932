import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

class Maps {
  constructor() {
    Vue.use(VueGoogleMaps, {
      load: {
        key: "AIzaSyD0k3vKKVk3R_MM3U46WJTcMIgY3K9eqRM",
        libraries: "places",
      },
    });

    Vue.component("gmap-info-window", VueGoogleMaps.InfoWindow);
    Vue.component("google-marker", VueGoogleMaps.Marker);
    Vue.component("google-map", VueGoogleMaps.Map);
  }
}

export default new Maps();
