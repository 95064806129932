import { DeleteCallCenterRepository } from '../../data/repository/delete_call_center_repository';
import { CallCenter } from '../model/call_center';

export interface DeleteCallCenterUseCase {
    (id: number): Promise<CallCenter>
}

const deleteCallCenterUseCase = (repository: DeleteCallCenterRepository): DeleteCallCenterUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteCallCenterUseCase;