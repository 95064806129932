import { BombMeasurement } from './../../../pumpMark/domain/model/bomb_measurement';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchCompanyMeasurementRepository {
    (id: number): Promise<Array<BombMeasurement>>
}

const fetchCompanyMeasurementRepository = (axios: AxiosInstance): FetchCompanyMeasurementRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/company/${id}/measurement`)

        const bombMeasurement: Array<BombMeasurement> = response.data
        return bombMeasurement
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchCompanyMeasurementRepository