import { FetchRulerLogsRepository } from './../../data/repository/fetch_logs_repository';
import { LogsPagination } from './../model/logs_pagination';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/logs';

export interface FetchRulerLogUseCase {
    (pagination: DataOptions, search: string): Promise<LogsPagination>
}

const fetchRulerLogsUseCase = (repository: FetchRulerLogsRepository): FetchRulerLogUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.map((s: string) => en[s]).join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchRulerLogsUseCase;