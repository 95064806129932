<template>
  <v-card class="mx-auto" tile>
    <v-card-title>
      Notificações

      <v-spacer></v-spacer>

      <!-- <v-menu
        v-model="controller.calendarMenuIn"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            filled
            v-model="formatDateIn"
            label="De"
            readonly
            persistent-hint
            prepend-inner-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            class="pt-6 pl-2"
            style="max-width: 160px"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            single-line
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="controller.calendarIn"
          class="primary"
          no-title
          @input="controller.calendarMenuIn = false"
          :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
          min="2010-NaN-NaN"
        >
        </v-date-picker>
      </v-menu>

      <v-menu
        v-model="controller.calendarMenuUntil"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            filled
            v-model="formatDateUntil"
            label="Até"
            persistent-hint
            prepend-inner-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            class="pt-6 pl-2"
            style="max-width: 150px"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            single-line
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="controller.calendarUntil"
          class="primary"
          no-title
          @input="controller.calendarMenuUntil = false"
          :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
          min="2010-NaN-NaN"
        ></v-date-picker>
      </v-menu> -->
      <v-autocomplete
        v-model="controller.selectedRuler"
        :items="controller.rulerPagination.items"
        item-text="name"
        item-value="id"
        dense
        filled
        class="pt-6 pl-2"
        label="Selecione a Régua"
        style="max-width: 350px"
        return-object
        single-line
        clearable
      ></v-autocomplete>

      <date-picker
        style="max-width: 150px"
        v-model="controller.calendarIn"
        filled
        dense
        label="Data inicial"
        class="ml-2 mt-6"
        single-line
      ></date-picker>
      <date-picker
        v-model="controller.calendarUntil"
        filled
        dense
        label="Data final"
        style="max-width: 150px"
        class="mx-2 mt-6"
        single-line
      ></date-picker>

      <div class="ml-2">
        <v-btn
          height="38"
          :loading="controller.loadingBtn"
          color="primary"
          class="mt-0"
          @click="controller.filter()"
        >
          <v-icon> mdi-filter </v-icon>
          Filtrar
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-0 background_screen" style="min-height: 88vh">
      <v-timeline dense>
        <v-timeline-item
          color="white"
          v-for="(note, index) in controller.notifications"
          :key="index"
          :icon="note.seen ? 'mdi-bell' : 'mdi-bell-ring'"
          :icon-color="note.seen ? '#B0BEC5' : 'primary'"
          fill-dot
        >
          <v-card>
            <v-row class="my-2">
              <v-col
                cols="3"
                class="d-flex flex-column align-center justify-center"
              >
                <h3 class="py-3">
                  {{ dayjs(note.createDate).format("DD/MM/YYYY") }}
                </h3>
                <h3 class="pb-4 font-weight-light">
                  {{ dayjs(note.createDate).format("HH:mm:ss") }}
                </h3>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="9">
                <div v-if="note.fromAccountName != null" class="d-flex">
                  <h3 class="font-weight-regular">Usuário:</h3>
                  <h2 class="pl-2">{{ note.fromAccountName }}</h2>
                </div>
                <div v-if="note.fromPumpName != null" class="d-flex">
                  <h3 class="font-weight-regular">Bomba:</h3>
                  <h2 class="pl-2">{{ note.fromPumpName }}</h2>
                </div>
                <div v-if="note.fromRulerName != null" class="d-flex">
                  <h3 class="font-weight-regular">Régua:</h3>
                  <h2 class="pl-2">{{ note.fromRulerName }}</h2>
                </div>
                <div class="pt-2 d-flex flex-column">
                  <h3 class="font-weight-regular">Mensagem:</h3>

                  <h3 class="pt-1">
                    {{ note.message }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { notificationController } from "@/di/di";
import dayjs from "dayjs";

export default {
  data: (context) => ({
    controller: notificationController(context),
  }),
  computed: {
    formatDateIn() {
      return dayjs(this.controller.calendarIn).format("DD/MM/YYYY");
    },
    formatDateUntil() {
      return dayjs(this.controller.calendarUntil).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.controller.mounted();
    this.controller.paginate();

    window.onscroll = () => {
      var pageHeight = document.documentElement.offsetHeight,
        scrollPosition = window.scrollY;
      window.pageYOffset;
      document.body.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) || 0);

      var intViewportHeight = window.innerHeight;

      if (pageHeight <= scrollPosition + intViewportHeight) {
        this.controller.options.page++;
        this.controller.paginate();
      }
    };
  },
  beforeDestroy() {
    this.controller.beforeDestroy();
  },
};
</script>