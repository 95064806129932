import { FetchMarkRulerWith30MinutesUseCase } from './../../rulerMark/domain/usecase/fetch_markRuler_with_30_minutes_usecase';
import { HarvestPagination } from '../../harvest/domain/model/harvest_pagination';
import { FetchRulerUseCase } from '@/module/ruler/domain/usecase/fetch_ruler_usecase';
import { ChangeRulerUseCase } from '@/module/ruler/domain/usecase/change_ruler_usecase';
import { FetchMarkRulerUseCase } from '@/module/rulerMark/domain/usecase/fetch_markRuler_usecase';
import { RulerPagination } from '@/module/ruler/domain/model/ruler_pagination';
import { MarkRulerPagination } from '../domain/model/markRuler_pagination'
import { DataOptions } from 'vuetify';
import { Ruler, rulerDefault } from '@/module/ruler/domain/model/ruler';
import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import { snackbar } from '@/core/controller/snackbar_controller';
import * as echarts from 'echarts';
import dayjs from "dayjs";
import waterLevel from '../../../assets/water_level.json'
import { Harvest, harvestDefault } from '@/module/harvest/domain/model/harvest';
import { FetchHarvestUseCase } from '@/module/harvest/domain/usecase/fetch_harvest_usecase';
import { PeriodSelect } from '../domain/model/periodSelect';


class NewRulerMarkController {
  public context: any;
  public contextDrawer: any;
  public contextFormRuler: any;
  public search: string = ""
  public loading: boolean = false
  public loadingBtn: boolean = false
  public dialog: boolean = false
  public compare: boolean = false
  public rightDrawer: boolean = false
  public calendarMenuIn: boolean = false
  public calendarMenuUntil: boolean = false
  public calendarMenuDialogIn: boolean = false
  public calendarMenuDialogUntil: boolean = false
  public calendarIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendarUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public searchCompany: string = ""
  public dateMark: any = []
  public levelMark: any = []
  public marks: any = []
  public marksChart: any = []
  public maxLevel: any = 15

  public typeOfFilter: Array<PeriodSelect> = [
    { id: 0, name: "Dia" },
    { id: 1, name: "Semana" },
    { id: 2, name: "Mês" },
    { id: 3, name: "Safra" },
  ]
  public typeOfFilterSelected: PeriodSelect = { id: 1, name: "Semana" }
  public dayOfFilter: string = dayjs().format("YYYY-MM-DD")

  public startOfWeek: string = dayjs().startOf('week').format("YYYY-MM-DD")
  public endOfWeek: string = dayjs().endOf('week').format("YYYY-MM-DD")

  public startOfMonth: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public endOfMonth: string = dayjs().endOf('month').format("YYYY-MM-DD")

  public monthsOfYear: Array<Object> = [
    { name: 'Janeiro', value: 0 },
    { name: 'Fevereiro', value: 1 },
    { name: 'Março', value: 2 },
    { name: 'Abril', value: 3 },
    { name: 'Maio', value: 4 },
    { name: 'Junho', value: 5 },
    { name: 'Julho', value: 6 },
    { name: 'Agosto', value: 7 },
    { name: 'Setembro', value: 8 },
    { name: 'Outubro', value: 9 },
    { name: 'Novembro', value: 10 },
    { name: 'Dezembro', value: 11 },
  ]

  public selectedMonth: number = dayjs().month()

  public calendar1StIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendar1StUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public calendar2ndIn: string = dayjs().startOf('month').format("YYYY-MM-DD")
  public calendar2ndUntil: string = dayjs().endOf('month').format("YYYY-MM-DD")
  public marks1st: any = []
  public marks2nd: any = []

  public harvestPagination: HarvestPagination = {
    items: [],
    total: 0
  }

  public harvestOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["startDate", "endDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true, true]
  }

  public selectedHarvest: Harvest = harvestDefault()

  public lottieLevelAnimation: object = { animationData: waterLevel }


  // positiveRuler = (v) => {
  //   if (!isNaN(parseFloat(v)) && v > -999999999 && v < 999999999) return true;
  //   return "Preencha o campo!";
  // };


  public rulerPagination: RulerPagination = {
    items: [],
    total: 0
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["isFavorite"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public markRulerPagination: MarkRulerPagination = {
    total: 0,
    items: []
  }
  public markRulerOptions: DataOptions = {
    page: 1,
    itemsPerPage: 48,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }
  public markRulerCompareOptions: DataOptions = {
    page: 1,
    itemsPerPage: -1,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [false],
    multiSort: false,
    mustSort: true,
    sortDesc: [false]
  }

  public ruler: Ruler = rulerDefault()

  public selectedRuler: Ruler = rulerDefault()

  private rulerChart: echarts.ECharts | null = null

  private fetchRulerUseCase: FetchRulerUseCase
  private fetchMarkRulerUseCase: FetchMarkRulerUseCase
  private changeRulerUseCase: ChangeRulerUseCase
  private fetchHarvestUseCaseImpl: FetchHarvestUseCase
  private fetchMarkRulerWith30MinutesUseCase: FetchMarkRulerWith30MinutesUseCase

  constructor(
    context: any,
    fetchRulerUseCase: FetchRulerUseCase,
    fetchMarkRulerUseCase: FetchMarkRulerUseCase,
    changeRulerUseCase: ChangeRulerUseCase,
    fetchHarvestUseCaseImpl: FetchHarvestUseCase,
    fetchMarkRulerWith30MinutesUseCase: FetchMarkRulerWith30MinutesUseCase
  ) {
    this.context = context
    this.fetchRulerUseCase = fetchRulerUseCase
    this.fetchMarkRulerUseCase = fetchMarkRulerUseCase
    this.changeRulerUseCase = changeRulerUseCase
    this.fetchHarvestUseCaseImpl = fetchHarvestUseCaseImpl
    this.fetchMarkRulerWith30MinutesUseCase = fetchMarkRulerWith30MinutesUseCase
  }


  async mounted() {
    this.loading = true
    this.maxLevel = localStorage.getItem('rulerMaxLevel') ? localStorage.getItem('rulerMaxLevel') : 15;
    //localStorage.setItem("mytime", Date.now());

    //this.maxLevel = localStorage.getItem("rulerMaxLevel") : 15;
    try {
      this.rulerPagination = await this.fetchRulerUseCase(this.options, this.search, 1) //actives
      this.harvestPagination = await this.fetchHarvestUseCaseImpl(this.harvestOptions, "")
      this.selectedHarvest = this.harvestPagination.items.filter((harvest) => harvest.isFavorite)[0] || this.harvestPagination.items?.[0]
      //this.selectedHarvest = this.harvestPagination.items?.[0] ?? harvestDefault()
      this.changeHarvest(this.selectedHarvest)
      this.selectedRuler = this.rulerPagination.items[0]
      if (this.selectedRuler != null) {
        this.calendarUntil = dayjs().format("YYYY-MM-DD")
        //this.filter()
        this.markRulerOptions.itemsPerPage = 48
        this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }


    window.addEventListener('resize', () => {
      if (this.rulerChart) {
        this.rulerChart.resize();
      }
    })
  }

  async filter() {
    this.marks = []
    this.marksChart = []
    this.dialog = false
    this.markRulerOptions.page = 1
    if (this.context.$refs.filter.validate()) {
      this.loadingBtn = true
      try {
        this.markRulerOptions.itemsPerPage = this.context.$vuetify.breakpoint.mobile ? 10 : 48
        this.markRulerPagination = await this.fetchMarkRulerWith30MinutesUseCase(this.markRulerOptions, this.selectedRuler.id, this.calendarIn, this.calendarUntil)
        for (let el of this.markRulerPagination.items) {
          this.marks.push(el)
          this.marksChart.push(el)
        }
        this.marksChart
        this.createRulerChart()
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false
      }
    }
  }

  async change() {
    if (this.contextDrawer.$refs.rightDrawer.validate()) {
      localStorage.setItem('rulerMaxLevel', this.maxLevel)
      try {
        await this.changeRulerUseCase(this.selectedRuler.id, this.selectedRuler)
        snackbar.show({ message: "Alterado com sucesso!", color: 'green', timeout: 1000 })
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.rightDrawer = false
        this.filter()
      }
    }
  }



  createRulerChart() {
    if (this.selectedRuler) {
      const higher = this.selectedRuler.higherLevel != null ? this.selectedRuler.higherLevel : 0
      const lower = this.selectedRuler.lowerLevel != null ? this.selectedRuler.lowerLevel : 0

      var chartDom = document.getElementById('ruler_chart');
      if (chartDom != null) {
        this.rulerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
        const marcacao = this.marksChart.map((mark: MarkRuler, i: number) => {
          if (this.typeOfFilterSelected.id == 0) {
            return [{
              symbol: 'none',
              name: '', xAxis: i, yAxis: 0, lineStyle: {
                normal: {
                  type: 'solid',
                  color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
                },

              },
            },
            {
              symbol: 'none',
              name: '', xAxis: i, yAxis: mark.level,
              lineStyle: {
                normal: {
                  type: 'solid',
                  color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
                },

              },
            },]
          } else {
            return [
              {
                symbol: 'none',
                name: '', xAxis: i, yAxis: 0, /* lineStyle: {
                  normal: {
                    type: 'solid',
                    color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
                  },
  
                }, */
              },
              {
                symbol: 'none',
                name: '', xAxis: i, yAxis: mark.level,
                /*                 lineStyle: {
                                  normal: {
                                    type: 'solid',
                                    color: this.context.$vuetify.theme.isDark ? 'orange' : '#0458b6',
                                  },
                  
                                }, */
              },
            ]
          }
        }
        )
        if (!this.context.$vuetify.breakpoint.mobile) {
          marcacao.push({
            type: 'average',
            name: 'Média',
            label: {
              formatter: '{b}: {c}',
              position: 'end',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
              fontWeight: 'bold',
              fontSize: 12,
              fontFamily: 'Helvetica',
            },

          })
        }
        marcacao.push({
          label: {
            formatter: '{b}',
            position: 'insideEndTop',
            color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Helvetica',
          },
          name: `Nível máximo: ${higher}`,
          yAxis: higher,
          type: 'value',
          lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            }
          },
        })
        marcacao.push({
          label: {
            formatter: '{b}',
            position: 'insideEndTop',
            color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Helvetica',
          },
          name: `Nível mínimo: ${lower}`,
          yAxis: lower,
          type: 'value',
          lineStyle: {
            normal: {
              type: 'solid',
              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
            }
          },
        })
        let option = {
          title: {
            text: this.selectedRuler.name,
            left: "center",
            top: "top",

            textStyle: {
              fontSize: 16,
              fontWeight: 'normal',
            },
            padding: [0, 0, 20, 0]
          },
          grid: {
            left: 50,
            top: 20,
            right: !this.context.$vuetify.breakpoint.mobile ? 80 : 40,
            bottom: 25,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: (params: any) => {
              return (
                "Nivel: " + params[0].value +
                '<br/>' +

                "Data: " + params[0].name
              );
            },
          },
          xAxis: {
            type: 'category',
            data: this.marksChart.map((mark: MarkRuler) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
            boundaryGap: true,
            axisLabel: {
              interval: this.marksChart.length - 2
            }
            //axisLabel: { show: false, interval: 0, fontSize: 10, rotate: 90 }
          },
          yAxis: {
            type: 'value',
            //max: this.maxLevel
          },
          dataZoom: [
            {
              show: false,
              realtime: true,
            },
            {
              type: 'inside',
              realtime: true,
            }
          ],
          series: [{
            areaStyle: {},
            data: this.marksChart.map((mark: MarkRuler) => mark.level.toFixed(2)) ?? [],
            type: 'line',
            itemStyle: {
              color: this.selectedRuler.color ?? '#8094f7'
            },
            markLine: {
              //data: this.typeOfFilterSelected.id == 0 ? marcacao : ''
              data:
                marcacao,
            },
            /*             markLine: {
                          data:
                            marcacao,
            
                          {
                            type: 'average',
                            name: 'Média',
                            label: {
                              formatter: '{b}: {c}',
                              position: 'end',
                              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                              fontWeight: 'bold',
                              fontSize: 12,
                              fontFamily: 'Helvetica',
                            },
            
                          },
                          {
                            label: {
                              formatter: '{b}',
                              position: 'insideEndTop',
                              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                              fontWeight: 'bold',
                              fontSize: 16,
                              fontFamily: 'Helvetica',
                            },
                            name: `Nível máximo: ${higher}`,
                            yAxis: higher,
                            type: 'value',
                            lineStyle: {
                              normal: {
                                type: 'solid',
                                color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                              }
                            },
                          },
                          {
                            label: {
                              formatter: '{b}',
                              position: 'insideEndTop',
                              color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                              fontWeight: 'bold',
                              fontSize: 16,
                              fontFamily: 'Helvetica',
                            },
                            name: `Nível mínimo: ${lower}`,
                            yAxis: lower,
                            type: 'value',
                            lineStyle: {
                              normal: {
                                type: 'solid',
                                color: this.context.$vuetify.theme.isDark ? 'orange' : 'black',
                              }
                            },
                          },
            
            
                        }, */
          }]
        };

        option && this.rulerChart.setOption(option);
      }
    }
  }


  async paginateMarks() {
    try {
      this.markRulerPagination = await this.fetchMarkRulerWith30MinutesUseCase(this.markRulerOptions, this.selectedRuler.id, this.calendarIn, this.calendarUntil)
      for (let el of this.markRulerPagination.items) {
        this.marks.push(el)
      }

      //this.createRulerChart()
    } catch (error) {
      console.log(error);
    }
  }
  closeDialog() {
    this.dialog = false
    this.rightDrawer = true
  }
  async changeHarvest(data: Harvest) {
    this.calendarIn = data.startDate
    this.calendarUntil = data.endDate
    this.filterByFilter(this.calendarIn, this.calendarUntil)
  }

  lastDay() {
    this.markRulerOptions.itemsPerPage = 48
    this.dayOfFilter = dayjs(this.dayOfFilter).subtract(1, 'day').format("YYYY-MM-DD")
    //const tomorrow = dayjs(this.dayOfFilter).add(1, 'day').format("YYYY-MM-DD")
    this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
  }
  nextDay() {
    this.markRulerOptions.itemsPerPage = 48
    const today = dayjs().format("YYYY-MM-DD")
    //const tomorrow = dayjs(this.dayOfFilter).add(1, 'day').format("YYYY-MM-DD")
    if (today != this.dayOfFilter) {
      this.dayOfFilter = dayjs(this.dayOfFilter).add(1, 'day').format("YYYY-MM-DD")
      this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
    }
  }
  lastWeek() {
    this.markRulerOptions.itemsPerPage = -1
    this.startOfWeek = dayjs(this.startOfWeek).subtract(1, 'week').format("YYYY-MM-DD")
    this.endOfWeek = dayjs(this.endOfWeek).subtract(1, 'week').format("YYYY-MM-DD")
    this.filterByFilter(this.startOfWeek, this.endOfWeek)
  }
  nextWeek() {
    this.markRulerOptions.itemsPerPage = -1
    const thisStartWeek = dayjs().startOf('week').format("YYYY-MM-DD")
    if (thisStartWeek != this.startOfWeek) {
      this.startOfWeek = dayjs(this.startOfWeek).add(1, 'week').format("YYYY-MM-DD")
      this.endOfWeek = dayjs(this.endOfWeek).add(1, 'week').format("YYYY-MM-DD")
      this.filterByFilter(this.startOfWeek, this.endOfWeek)
    }
  }
  lastMonth() {
    this.markRulerOptions.itemsPerPage = -1
    this.startOfMonth = dayjs(this.startOfMonth).subtract(1, 'month').format("YYYY-MM-DD")
    this.endOfMonth = dayjs(this.startOfMonth).endOf('month').format("YYYY-MM-DD")
    this.filterByFilter(this.startOfMonth, this.endOfMonth)
  }
  nextMonth() {
    this.markRulerOptions.itemsPerPage = -1
    const thisStartMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    if (thisStartMonth != this.startOfMonth) {
      this.startOfMonth = dayjs(this.startOfMonth).add(1, 'month').format("YYYY-MM-DD")
      this.endOfMonth = dayjs(this.startOfMonth).endOf('month').format("YYYY-MM-DD")
      this.filterByFilter(this.startOfMonth, this.endOfMonth)
    }
  }

  async filterByFilter(start, end) {
    this.rulerChart?.clear()
    this.marks = []
    this.marksChart = []
    this.dialog = false
    this.markRulerOptions.page = 1
    this.loadingBtn = true
    try {
      //this.markRulerOptions.itemsPerPage = this.context.$vuetify.breakpoint.mobile ? 10 : 48
      this.markRulerPagination = await this.fetchMarkRulerWith30MinutesUseCase(this.markRulerOptions, this.selectedRuler.id, start, end)
      for (let el of this.markRulerPagination.items) {
        this.marks.push(el)
        this.marksChart.push(el)
      }
      this.marksChart
      this.createRulerChart()
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingBtn = false
    }

  }

  changeRuler(event) {
    if (event.id != undefined) {
      switch (this.typeOfFilterSelected.id) {
        case 0:
          this.markRulerOptions.itemsPerPage = 48
          this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
          break;
        case 1:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfWeek, this.endOfWeek)
          break;
        case 2:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfMonth, this.endOfMonth)
          break;
        case 3:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.calendarIn, this.calendarUntil)
          break;
        default:
          break;
      }
    }
  }

  changePeriod(event) {
    if (event.id != undefined) {
      switch (event.id) {
        case 0:
          this.markRulerOptions.itemsPerPage = 48
          this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
          break;
        case 1:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfWeek, this.endOfWeek)
          break;
        case 2:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.startOfMonth, this.endOfMonth)
          break;
        case 3:
          this.markRulerOptions.itemsPerPage = -1
          this.filterByFilter(this.calendarIn, this.calendarUntil)
          break;
        default:
          break;
      }
    }
  }

  changeMonth(event) {
    this.startOfWeek = dayjs().month(event.value).startOf('month').startOf('week').format("YYYY-MM-DD")
    this.endOfWeek = dayjs().month(event.value).startOf('month').endOf('week').format("YYYY-MM-DD")
    this.markRulerOptions.itemsPerPage = -1
    this.filterByFilter(this.startOfWeek, this.endOfWeek)
  }

  changeDay() {
    this.filterByFilter(this.dayOfFilter, this.dayOfFilter)
  }


  async filterCompare() {
    this.rulerChart?.clear()
    this.marks1st = []
    this.marks2nd = []
    this.compare = false
    if (this.context.$refs.filter.validate()) {
      this.loadingBtn = true
      try {
        this.marks1st = await this.fetchMarkRulerUseCase(this.markRulerCompareOptions, this.selectedRuler.id, this.calendar1StIn, this.calendar1StUntil)
        this.marks2nd = await this.fetchMarkRulerUseCase(this.markRulerCompareOptions, this.selectedRuler.id, this.calendar2ndIn, this.calendar2ndUntil)
        this.createCompareRulerChart()
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false
      }
    }
  }



  async createCompareRulerChart() {
    await new Promise(resolver => setTimeout(resolver, 500))
    var chartDom = document.getElementById('ruler_chart');
    if (chartDom != null) {
      this.rulerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      const marcacao1st = this.marks1st.items.map((mark: MarkRuler, i: number) => [
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: 0, lineStyle: {
            normal: {
              type: 'solid',
              color: '#0458b6',
            },

          },
        },
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: mark.level, lineStyle: {
            normal: {
              type: 'solid',
              color: '#0458b6',
            },

          },
        },
      ])
      const marcacao2nd = this.marks2nd.items.map((mark: MarkRuler, i: number) => [
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: 0, lineStyle: {
            normal: {
              type: 'solid',
              color: '#A05151',
            },

          },
        },
        {
          symbol: 'none',
          name: '', xAxis: i, yAxis: mark.level, lineStyle: {
            normal: {
              type: 'solid',
              color: '#A05151',
            },
          },
        },
      ])
      this.rulerChart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');
      let option = {
        title: {
          text: this.selectedRuler.name,
          left: "left",
          top: "top",
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal',
          },
          padding: [0, 0, 20, 0]
        },
        legend: {
          data: ['Primeiro Período', 'Segundo Período']
        },
        grid: {
          left: 60,
          top: 50,
          right: 50,
          bottom: 25
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
/*           formatter: (params: any) => {
            console.log(params);
            return (
              "Nivel: " + params[0].value +
              '<br/>' +

              "Data: " + params[0].name
            );
          }, */
        },
        xAxis: [{
          type: 'category',
          data: this.marks2nd.items.map((mark: MarkRuler) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
          boundaryGap: true,
          axisLabel: {
            interval: this.marks2nd.items.length - 2
          }
        },
        {
          type: 'category',
          data: this.marks1st.items.map((mark: MarkRuler) => dayjs(mark.createdDate).format('DD/MM/YYYY HH:mm')) ?? [],
          boundaryGap: true,
          axisLabel: {
            interval: this.marks1st.items.length - 2
          }
        },
        ],
        yAxis: {
          type: 'value',
        },
        dataZoom: [
          {
            show: false,
            realtime: true,
          },
          {
            type: 'inside',
            realtime: true,
          }
        ],
        series: [{
          itemStyle: {
            color: '#8094f7'
          },
          /*           markLine: {
                      data: marcacao1st
                    }, */
          name: 'Primeiro Período',
          areaStyle: {},
          data: this.marks1st.items.map((mark: MarkRuler) => mark.level.toFixed(2)) ?? [],
          type: 'line'
        }, {
          itemStyle: {
            color: '#e57575'
          },
          /*           markLine: {
                      data: marcacao2nd
                    }, */
          name: 'Segundo Período',
          areaStyle: {},
          data: this.marks2nd.items.map((mark: MarkRuler) => mark.level.toFixed(2)) ?? [],
          type: 'line'
        }]
      }

      option && this.rulerChart.setOption(option);

      this.rulerChart.resize()
    }
  }

}

export default NewRulerMarkController