import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Station } from '../../domain/model/station';

export interface ChangeStationRepository {
  (id: number, data: object): Promise<Station>
}

const changeStationRepository = (axios: AxiosInstance): ChangeStationRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`https://stationapihydra.com/station/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeStationRepository

