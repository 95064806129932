import { MarkRuler } from '@/module/rulerMark/domain/model/markRuler';
import { MarkRulerPagination } from './../../../rulerMark/domain/model/markRuler_pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface UpdateRulerTrackingLevelRepository {
    (rulerTracking: MarkRuler): Promise<MarkRulerPagination>
}

const updateRulerTrackingLevelRepository = (axios: AxiosInstance): UpdateRulerTrackingLevelRepository => async (rulerTracking: MarkRuler) => {
    try {
        const response: AxiosResponse = await axios.put(`/ruler-tracking/${rulerTracking.id}`,
            rulerTracking
        )

        const rulerPagination: MarkRulerPagination = response.data
        return rulerPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default updateRulerTrackingLevelRepository