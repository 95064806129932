<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.pumpPagination.total"
      :items="controller.pumpPagination.items"
      :loading="controller.loading"
      :group-by="controller.groupBy"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status === 1 ? "Ativo" : "Inativo" }}
      </template>

      <template v-slot:[`item.isFavorite`]="{ item }">
        <v-btn
          icon
          @click="controller.changeFav(item)"
        >
          <v-icon :color="item.isFavorite ? '#FFC107' : 'grey'">mdi-star</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.logs(item)"
            class="px-2"
          >
            <v-icon
              small
              color="orange"
            >mdi-database</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(3, 3)"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hadPermission(3, 4)"
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Bombas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            @change="controller.paginate()"
            class="px-2"
            style="max-width: 200px"
            filled
            dense
            single-line
            color="primary"
            hide-details
            v-model="controller.status"
            :items="controller.visibilityStatus"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
                v-if="hadPermission(3, 2)"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Bombas
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-select
                    filled
                    dense
                    color="primary"
                    label="Status"
                    v-model="controller.pump.status"
                    :items="controller.itemStatus"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-select>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Identificação da Bomba"
                    v-model="controller.pump.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-combobox v-model="controller.pump.engineName" :items="availableEngines" dense filled label="Identificação do Motor" item-text="name" />
                  <!-- <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Identificação do Motor"
                    v-model="controller.pump.EngineName"
                  >
                  </v-text-field> -->


                  <v-autocomplete
                    filled
                    dense
                    color="primary"
                    label="Local"
                    v-model="controller.pump.placeID"
                    :search-input.sync="controller.searchPlace"
                    :items="controller.placePagination.items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-if="isAdmin()"
                    filled
                    dense
                    color="primary"
                    label="Empresa"
                    v-model="controller.pump.companyID"
                    :search-input.sync="controller.searchCompany"
                    :items="controller.companyPagination.items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-autocomplete>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-menu
            v-model="controller.menuGroup"
            :close-on-click="false"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-x
          >
            <!--             <template v-slot:activator="{ on, attrs }">
              <v-icon class="mt-0 ml-4" text v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-card scrollable>
              <v-toolbar flat dense>
                <v-toolbar-title>Organizar tabela</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="px-0">
                <v-autocomplete
                  dense
                  class="mt-8 px-3"
                  :items="controller.columns"
                  item-text="text"
                  item-value="value"
                  label="Agrupar por"
                  v-model="controller.groupBy"
                  clearable
                  no-data-text="Sem dados"
                ></v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  small
                  color="red"
                  outlined
                  @click="controller.menuGroup = false"
                  block
                >
                  <v-icon small left>mdi-close</v-icon>Fechar
                </v-btn>
              </v-card-actions>
            </v-card> -->
          </v-menu>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog
      persistent
      v-model="controller.dialogTracking"
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-data-table
            class="elevation-0 ma-0 pa-0"
            dense
            height="75vh"
            fixed-header
            :headers="controller.columnsTracking"
            :options.sync="controller.optionsTracking"
            :server-items-length="controller.trackingPagination.total"
            :items="controller.trackingPagination.items"
            show-select
            v-model="controller.selected"
            :footer-props="{
              'items-per-page-options': [40, 80],
            }"
            :loading="controller.loadingLogs"
          >
            <template v-slot:[`item.createdDate`]="{ item }">
              {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm:ss") }}
            </template>
            <template v-slot:[`item.capacitors`]="{ item }">
              <div class="d-flex justify-center">
                <v-checkbox
                  readonly
                  :input-value="item.capacitors > 0 ? true : false"
                  hide-details
                  class="shrink mr-2 mt-0"
                  dense
                >
                </v-checkbox>
              </div>
            </template>
            <template v-slot:[`item.engines`]="{ item }">
              <div class="d-flex justify-center">
                <v-checkbox
                  readonly
                  :input-value="item.engines > 0 ? true : false"
                  hide-details
                  class="shrink mr-2 mt-0"
                  dense
                >
                </v-checkbox>
              </div>
            </template>
            <template v-slot:[`item.isOn`]="{ item }">
              <div class="d-flex justify-center">
                <v-checkbox
                  readonly
                  :input-value="item.isOn == null ? true : item.isOn"
                  hide-details
                  class="shrink mr-2 mt-0"
                  dense
                >
                </v-checkbox>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-btn
                  v-if="hadPermission(3, 3)"
                  icon
                  @click="controller.updateLevel(item)"
                  class="px-2"
                >
                  <v-icon
                    small
                    color="blue"
                  >mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Medições da bomba:
                  {{ controller.selectedPump.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-text-field
                  class="px-2"
                  style="max-width: 250px"
                  filled
                  color="primary"
                  hide-details
                  dense
                  placeholder="Pesquisar..."
                  v-model="controller.searchTracking"
                ></v-text-field> -->
                <v-btn
                  v-if="hadPermission(3, 4)"
                  v-show="controller.selected.length > 0"
                  @click="controller.deleteSelected()"
                  color="red"
                  class="mx-2"
                  style="color: white"
                >
                  <v-icon>mdi-delete</v-icon>Excluir Selecionados
                </v-btn>
                <v-btn
                  :loading="controller.loadingBtnExcel"
                  color="green"
                  class="white--text"
                  @click="controller.exportExcel()"
                >
                  <v-icon left> mdi-file-excel </v-icon>
                  Excel
                </v-btn>
                <v-btn
                  @click="controller.reloadLogs()"
                  icon
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-icon @click="controller.dialogTracking = false">mdi-close</v-icon>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      width="350"
      persistent
      v-model="controller.dialogPumpTracking"
    >
      <v-card>
        <v-card-title> Alterar Medições </v-card-title>
        <v-card-text>
          <v-text-field
            type="number"
            filled
            label="Voltagem"
            v-model.number="controller.selectedMarkPump.voltage"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Amperagem"
            v-model.number="controller.selectedMarkPump.amperage"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Horimetro"
            v-model.number="controller.selectedMarkPump.hourMeter"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Nível Superior"
            v-model.number="controller.selectedMarkPump.higherLevel"
          ></v-text-field>
          <v-text-field
            type="number"
            filled
            label="Nível Inferior"
            v-model.number="controller.selectedMarkPump.lowerLevel"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn
            @click="controller.dialogPumpTracking = false"
            outlined
            color="red"
          >
            <v-icon left>mdi-delete</v-icon>cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="controller.confirmUpdate()"
            color="primary"
          >
            <v-icon left>mdi-content-save</v-icon>alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const defaultParams = {
  sort: 2,
  descending: false,
  page: 1,
  itemsPerPage: 40,
  search: '',
  status: 0
}
import { axosInstance } from "@/di/axios_instance_di";
import { pumpController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: pumpController(context),
    engines: [],
    pumpMoto: []
  }),
  computed: {
    availableEngines() {
      return this.engines.filter(engine => !engine.in_use).map(engine => engine.name)
    }
    
  },
  methods: {
    async fetchEngines(params) {
      try {
        const { data } = await axosInstance.get('/engine', {
          params
        })
        this.engines = data.items
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPumpMoto(params) {
      try {
        const { data } = await axosInstance.get('/pump-moto', {
          params
        })
        this.pumpMoto = data.items
      } catch (error) {
        console.log(error)
      }
    },
    async updateEngineName() {
      const pumpId = this.controller.pump.id;

      const pumpMotoWithId = this.pumpMoto.find(pump => pump.pumpID === pumpId);

      if (pumpMotoWithId) {
        const engineId = pumpMotoWithId.EngineID;

        if (engineId) {
          const engine = this.engines.find(engine => engine.id === engineId);

          if (engine) {
            // Update engineName in controller.pumpMoto
            this.controller.pump.engineName = engine.name;
          }
        }
      }
          
    },
    



  },
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.optionsPlace"() {
      this.controller.watchOptionsPlace();
    },
    "controller.searchPlace": _.debounce(function () {
      this.controller.watchSearchPlace();
    }, 500),
    "controller.searchTracking": _.debounce(function () {
      this.controller.watchSearchTracking();
    }, 500),
    "controller.optionsTracking"() {
      this.controller.watchOptionsTracking();
    },
    "controller.searchCompany": _.debounce(function () {
      this.controller.watchSearchCompany();
    }, 500),
    "controller.optionsCompany"() {
      this.controller.watchOptionsCompany();
    },
    'controller.pump.id': function (newVal) {
      // Atualizar engineName quando o id selecionado mudar
      this.updateEngineName();
    },
    
  },
  async mounted() {

    await this.fetchEngines(defaultParams)
    await this.fetchPumpMoto(defaultParams)
    
  }
};
</script>