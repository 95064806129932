import { UpdateStationDataRepository } from '../../data/repository/update_station_data_repository';
import { MarkStationPagination } from '../../../stationMark/domain/model/markStation_pagination';
import { MarkStation } from '@/module/stationMark/domain/model/markStation';

export interface UpdateStationDataUseCase {
    (stationTracking: MarkStation): Promise<MarkStationPagination>
}

const updateStationDataUseCase = (repository: UpdateStationDataRepository): UpdateStationDataUseCase => async (stationTracking: MarkStation) => {
    try {
        return await repository(stationTracking);
    } catch (error) {
        throw error
    }
}

export default updateStationDataUseCase;