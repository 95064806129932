import dayjs from "dayjs"

export interface ChecklistItem {
  id: number
  createdDate: string
  name: string
  companyID: number
  checklistID: number
}

export interface Checklist {
  id: number
  createdDate: string
  name: string
  companyID: number
  companyName: string
  items: ChecklistItem[]

}

export const checklistDefault = (): Checklist => {
  return {
    id: 0,
    createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
    name: "",
    companyID: 0,
    companyName: "",
    items: []
  }
}


const en: any = {
  id: 1,
  createdDate: 2,
  name: 3,
  companyID: 4,
  companyName: 5
}
export { en };


