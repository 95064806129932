import { HardwareVersion } from '../../domain/model/hardware_version';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteHardwareVersionRepository {
    (id: number): Promise<HardwareVersion>
}

const deleteHardwareVersionRepository = (axios: AxiosInstance): DeleteHardwareVersionRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/hardware-version/${id}`)

        const company: HardwareVersion = response.data
        return company
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteHardwareVersionRepository