import { DeletePumpRepository } from '../../data/repository/delete_pump_repository';
import { Pump } from '../model/pump';

export interface DeletePumpUseCase {
    (id: number): Promise<Pump>
}

const deletePumpUseCase = (repository: DeletePumpRepository): DeletePumpUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deletePumpUseCase;