import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import { DeleteMarkPumpRepository } from '../../data/repository/delete_pump_mark_repository';

export interface DeleteMarkPumpUseCase {
    (id: number): Promise<MarkPump>
}

const deletePumpMarkUseCase = (repository: DeleteMarkPumpRepository): DeleteMarkPumpUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deletePumpMarkUseCase;