import { AlertTypePagination } from './../../alertType/domain/model/alert_type_pagination';
import { UserPagination } from './../../user/domain/model/user_pagination';
import { FetchAlertTypeUseCase } from './../../alertType/domain/usecase/fetch_alert_type_usecase';
import { FetchUsersUseCase } from './../../user/domain/usecase/fetch_user_usecase';
import { defaultModelPagination } from '@/core/domain/model/pagination';
import { defaultPagination } from '@/core/domain/model/pagination';
import { callCenterDefault, CallCenterAccount, CallCenterAlertType } from '../domain/model/call_center';
import { FetchCallCenterUseCase } from '../domain/usecase/fetch_call_center_usecase';
import { DeleteCallCenterUseCase } from '../domain/usecase/delete_call_center_usecase';
import { CreateCallCenterUseCase } from '../domain/usecase/create_call_center_usecase';
import { ChangeCallCenterUseCase } from '../domain/usecase/change_call_center_usecase';
import { CallCenterPagination } from '../domain/model/call_center_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { CallCenter } from '../domain/model/call_center';
import { snackbar } from '@/core/controller/snackbar_controller';
import { GetCompanyByIDUseCase } from '@/module/dashboard/domain/usecase/get_company_by_id_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';

class CallCenterController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public loading: boolean = false
  public callCenterPagination: CallCenterPagination = defaultModelPagination()
  public companyPagination: CompanyPagination = defaultModelPagination()
  public userPagination: UserPagination = defaultModelPagination()
  public alertTypePagination: AlertTypePagination = defaultModelPagination()
  public options: DataOptions = defaultPagination({})
  public optionsCompany: DataOptions = defaultPagination({})
  public optionsUser: DataOptions = defaultPagination({itemsPerPage: -1})
  public optionsAlertType: DataOptions = defaultPagination({itemsPerPage: -1})
  public taskDescription: string = ""

  public callCenter: CallCenter = callCenterDefault()

  private fetchCallCenterUseCase: FetchCallCenterUseCase
  private deleteCallCenterUseCase: DeleteCallCenterUseCase
  private createCallCenterUseCase: CreateCallCenterUseCase
  private changeCallCenterUseCase: ChangeCallCenterUseCase
  private getCompanyByIDUseCase: GetCompanyByIDUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase
  private fetchAlertTypeUseCase: FetchAlertTypeUseCase
  private fetchUsersUseCase: FetchUsersUseCase

  constructor(
    context: any,
    fetchCallCenterUseCase: FetchCallCenterUseCase,
    deleteCallCenterUseCase: DeleteCallCenterUseCase,
    createCallCenterUseCase: CreateCallCenterUseCase,
    changeCallCenterUseCase: ChangeCallCenterUseCase,
    getCompanyByIDUseCase: GetCompanyByIDUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase,
    fetchAlertTypeUseCase: FetchAlertTypeUseCase,
    fetchUsersUseCase: FetchUsersUseCase
  ) {
    this.context = context
    this.fetchCallCenterUseCase = fetchCallCenterUseCase
    this.deleteCallCenterUseCase = deleteCallCenterUseCase
    this.createCallCenterUseCase = createCallCenterUseCase
    this.changeCallCenterUseCase = changeCallCenterUseCase
    this.getCompanyByIDUseCase = getCompanyByIDUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
    this.fetchAlertTypeUseCase = fetchAlertTypeUseCase
    this.fetchUsersUseCase = fetchUsersUseCase
  }


  async open() {
    this.userPagination = await this.fetchUsersUseCase(this.optionsUser, "")
    this.alertTypePagination = await this.fetchAlertTypeUseCase(this.optionsAlertType, "")
    this.paginateCompany()
    this.callCenter = callCenterDefault()
    this.taskDescription = ""
    this.context.$refs.crud.resetValidation()
  }

  async paginate() {
    this.loading = true
    try {
      this.callCenterPagination = await this.fetchCallCenterUseCase(this.options, this.search)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changeCallCenterUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.callCenter.id === 0) {
          await this.createCallCenterUseCase(this.callCenter)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          return
        }

        await this.changeCallCenterUseCase(this.callCenter.id, this.callCenter)
        this.paginate()
        snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
        this.context.$refs.crud.resetValidation()
        this.callCenter = callCenterDefault()
        this.taskDescription = ""
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
      }
    }
  }

  async delete(item: CallCenter) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteCallCenterUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: CallCenter) {
    this.userPagination = await this.fetchUsersUseCase(this.optionsUser, "")
    this.alertTypePagination = await this.fetchAlertTypeUseCase(this.optionsAlertType, "")
    this.dialog = true
    this.callCenter = { ...item }
    this.searchCompany = item.companyName
    this.paginateCompany()
  }
}

export default CallCenterController