import { RulerGroupPagination } from '../../domain/model/rulerGroup_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchRulerGroupRepository {
    (pagination: Pagination): Promise<RulerGroupPagination>
}

const fetchRulerGroupRepository = (axios: AxiosInstance): FetchRulerGroupRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/group', {
            params: pagination
        })

        const rulerGroupPagination: RulerGroupPagination = response.data
        return rulerGroupPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchRulerGroupRepository