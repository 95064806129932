
<template>
  <div>
    <v-data-table class="elevation-2 ma-2" dense height="85vh" fixed-header :headers="headers" :items="controller.enginePagination.items"
      :loading="controller.loading" :server-items-length="controller.enginePagination.total" :search="search" :footer-props="{
        'items-per-page-options': [40, 80],
      }">

      <template v-slot:[`item.power`]="{ item }">
        <p>{{ item.power }}</p>
      </template>

      <template v-slot:[`item.nominal_voltage`]="{ item }">
        <p>{{ item.nominal_voltage || '--' }}</p>
      </template>

      <template v-slot:[`item.nominal_current`]="{ item }">
        <p>{{ item.nominal_current || '--' }}</p>
      </template>

      <template v-slot:[`item.startingMethodString`]="{ item }">
        <p>{{ item.startingMethodString || '--' }}</p>
      </template>

      <template v-slot:[`item.in_use`]="{ item }">
        <v-icon v-if="item.in_use" color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:[`item.in_use_set`]="{ item }">
        <v-icon v-if="item.in_use_set" color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-col cols="3">
            <!-- <v-btn icon class="px-2">
              <v-icon small color="orange">mdi-database</v-icon>
            </v-btn> -->
          </v-col>
          <v-col cols="3">
            <v-btn v-if="hadPermission(3, 3)" @click="() => {
              modals.edit = true 
              selectedEngine = item
            }" icon class="px-2">
            <v-icon small color="blue">mdi-pencil</v-icon>
          </v-btn>  
          </v-col>
          <v-col cols="3">
            <v-btn v-if="hadPermission(3, 4)" @click="controller.delete(item)" icon class="px-2">
            <v-icon small color="red">mdi-delete</v-icon>
          </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Motores</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="modals.create" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark height="40" v-bind="attrs" v-on="on" v-if="hadPermission(3, 2)">
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Adicionar novo Motor
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">

                <v-form ref="crud" lazy-validation>
                  <v-text-field v-model="controller.engine.name" filled dense color="primary" label="Nome" />
                  <v-text-field v-model="controller.engine.brand" filled dense color="primary" label="Marca" />
                  <v-text-field v-model="controller.engine.model" filled dense color="primary" label="Modelo" />
                  <v-text-field v-model="controller.engine.nominal_voltage" filled dense color="primary"
                    label="Voltagem" />
                  <v-autocomplete filled dense color="primary" label="Local" item-text="name" item-value="id"
                  :items="places"
                  v-model="controller.engine.place_id">
                  </v-autocomplete>
                  <v-autocomplete filled dense color="primary" label="Empresa"
                    :items="companies"
                    v-model="controller.engine.company_id" item-text="name" item-value="id">
                  </v-autocomplete>
                 
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn outlined @click="modals.create = false" color="red">
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn color="primary" @click="handleEngineRegister">
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-menu :close-on-click="false" :close-on-content-click="false" :nudge-width="300" offset-x>
          </v-menu>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog>
      <v-card>
        <v-card-text class="pa-0">
          <v-data-table class="elevation-0 ma-0 pa-0" dense height="75vh" fixed-header show-select :footer-props="{
            'items-per-page-options': [40, 80],
          }">
            <template v-slot:[`item.createdDate`]="{ item }">
            </template>
            <template v-slot:[`item.capacitors`]="{ item }">
              <div class="d-flex justify-center">
                <v-checkbox readonly hide-details class="shrink mr-2 mt-0" dense>
                </v-checkbox>
              </div>
            </template>
            <template v-slot:[`item.engines`]="{ item }">
              <div class="d-flex justify-center">
                <v-checkbox readonly hide-details class="shrink mr-2 mt-0" dense>
                </v-checkbox>
              </div>
            </template>
            <template v-slot:[`item.isOn`]="{ item }">
              <div class="d-flex justify-center">
                <v-checkbox readonly hide-details class="shrink mr-2 mt-0" dense>
                </v-checkbox>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-btn icon class="px-2">
                  <v-icon small color="blue">mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Medições da bomba:
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn v-if="hadPermission(3, 4)" color="red" class="mx-2" style="color: white">
                  <v-icon>mdi-delete</v-icon>Excluir Selecionados
                </v-btn>
                <v-btn color="green" class="white--text">
                  <v-icon left> mdi-file-excel </v-icon>
                  Excel
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-icon>mdi-close</v-icon>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.edit" persistent width="600">
      <v-card>
        <v-card-title> Alterar Medições </v-card-title>
        <v-card-text>
          <v-text-field filled v-model="selectedEngine.name"  label="Nome"/>
          <v-text-field filled v-model="selectedEngine.brand"  label="Marca"/>
          <v-text-field filled v-model="selectedEngine.model"  label="Modelo"/>
          <v-text-field filled type="number" v-model.number="selectedEngine.power"  label="Potencia"/>
          <v-text-field filled type="number" v-model.number="selectedEngine.nominalVoltage"  label="Voltagem"/>
          <v-text-field filled type="number" v-model.number="selectedEngine.nominalCurrent" label="Corrente"/>
          <v-text-field filled v-model="selectedEngine.startingMethodString" label="Metodo de inicialização"/>
          <v-text-field filled type="number" v-model.number="selectedEngine.efficienncy" label="Eficiencia"/>
          <v-text-field filled type="number" v-model.number="selectedEngine.nominalRotation" label="Rotação"/>
          
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn @click="modals.edit = false" outlined color="red">
            <v-icon left>mdi-delete</v-icon>cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="updateEngine" color="primary">
            <v-icon left>mdi-content-save</v-icon>alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.delete" persistent width="600" min-heigth="600" >
      
      <v-card class="pa-4">
        <v-card-title>Deletar Motor</v-card-title>
        <v-card-text>
          Realmente deseja deletar o motor:
          {{ selectedEngine.name }} ?
        </v-card-text>

        <v-card-actions class="">
          <v-row  align="center">
            <v-col cols="3">
              <v-btn color="red" outlined @click="modals.delete=false">Cancelar</v-btn>
            </v-col>
            <v-col  cols="3">
              <v-btn @click="confirmDeletion(selectedEngine)" outlined color="primary">confirmar</v-btn>
            </v-col>
            
            
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
const defaultParams = {
  sort: 2,
  descending: false,
  page: 1,
  itemsPerPage: 40,
  search: '',
  status: 0
}
import { axosInstance } from "@/di/axios_instance_di";
import { engineController } from "@/di/di";
import _ from "lodash";
export default {
  data: (context) => ({
    modals: {
      edit: false,
      delete: false,
      deleteConfirm: false,
      create: false
    },
    controller: engineController(context),
    engines: [],
    pumps: [],
    selectedEngine: {},
    companies: [],
    places: [],
    search: '',
    pagination: {
      total: null
    },
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Levante',
        align: 'start',
        sortable: true,
        value: 'place_id',
      },
      {
        text: 'Companhia',
        align: 'start',
        sortable: true,
        value: 'company_id',
      },
      {
        text: 'Marca',
        align: 'start',
        sortable: true,
        value: 'brand',
      },
      {
        text: 'Modelo',
        align: 'start',
        sortable: true,
        value: 'model',
      },
      {
        text: 'Potência',
        align: 'start',
        sortable: true,
        value: 'power',
      },
      {
        text: 'Tensão Nominal',
        align: 'start',
        sortable: true,
        value: 'nominalVoltage',
      },
      {
        text: 'Corrente Nominal',
        align: 'start',
        sortable: true,
        value: 'nominalCurrent',
      },
      {
        text: 'Starting Method',
        align: 'start',
        sortable: true,
        value: 'startingMethodString',
      },
      {
        text: 'Em uso',
        align: 'start',
        sortable: true,
        value: 'in_use',
      },
      {
        text: 'Em uso (Conjunto)',
        align: 'start',
        sortable: true,
        value: 'in_use_set',
      },
      {
        text: 'Ações',
        align: 'start',
        sortable: true,
        value: 'actions',
      },
    ]
  }),

  computed: {
    computedPlace() {
      return (id) => this.places.find(place => place.id === id)
    },
    computedCompany() {
      return (id) => this.companies.find(company => company.id === id)
    }
  },
  async mounted() {
    this.controller.paginate();
    await this.fetchEngines(defaultParams)
    await this.fetchPlaces(defaultParams)
    await this.fetchCompanies(defaultParams)
    await this.fetchPump(defaultParams)

  },
  methods: {
    async confirmDeletion(engine) {
      await axosInstance.delete(`/engine/${this.selectedEngine.id}`)
      await this.fetchEngines(defaultParams)
      this.modals.delete = false

    },
    async handleEngineDeletion(engine) {
      this.selectedEngine = engine
      this.modals.delete = true
      
    },
    async handleEngineRegister() {
      await this.controller.createEngineUseCase({...this.controller.engine, EngineName: this.controller.engine.name})
      await this.fetchEngines(defaultParams)
      this.modals.create= false
    },

    async updateEngine() {
      await axosInstance.put(`/engine/${this.selectedEngine.id}`, this.selectedEngine)
      await this.fetchEngines(defaultParams)
      this.modals.edit = false
    },

    async fetchEngines(params) {
      try {
        const { data } = await axosInstance.get('/engine', {
          params
        })
        this.engines = data.items
   
        this.pagination.total = data.total
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPump(params) {
      try {
        const { data } = await axosInstance.get('/pump', {
          params
        })
        this.pumps = data.items
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCompanies(params) {
      try {
      const { data } = await axosInstance.get('/company', {
          params
        })
        this.companies = data.items
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPlaces(params) {
      try {
      const { data } = await axosInstance.get('/place', {
          params
        })
        this.places = data.items
        
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>

<style scoped>
.card {
  display: flex;
  font-display: column;
}
</style>