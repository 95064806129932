import { MarkRulerPagination } from '../../domain/model/markRuler_pagination';
import { PaginationDate } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchMarkRulerWith1HourRepository {
    (pagination: PaginationDate, id: number): Promise<MarkRulerPagination>
}

const fetchMarkRulerWith1HourRepository = (axios: AxiosInstance): FetchMarkRulerWith1HourRepository => async (pagination: PaginationDate, id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/ruler/${id}/tracking/1-hour`, {
            params: pagination,
        })

        const rulerPagination: MarkRulerPagination = response.data
        return rulerPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchMarkRulerWith1HourRepository