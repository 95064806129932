import { CreateHarvestRepository } from '../../data/repository/create_harvest_repository';
import { Harvest } from '../model/harvest';

export interface CreateHarvestUseCase {
    (data: Harvest): Promise<Harvest>
}

const createHarvestUseCase = (repository: CreateHarvestRepository): CreateHarvestUseCase => async (data: Harvest) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createHarvestUseCase;

