import { UpdatePumpTrackingLevelRepository } from '../../data/repository/update_pump_tracking_level_repository';
import { MarkPump } from '@/module/pumpMark/domain/model/markPump';
import { MarkPumpPagination } from '@/module/pumpMark/domain/model/markPump_pagination';

export interface UpdatePumpTrackingLevelUseCase {
    (pumpTracking: MarkPump): Promise<MarkPumpPagination>
}

const updatePumpTrackingLevelUseCase = (repository: UpdatePumpTrackingLevelRepository): UpdatePumpTrackingLevelUseCase => async (pumpTracking: MarkPump) => {
    try {
        //pumpTracking.level = parseFloat(pumpTracking.level.toString())
        return await repository(pumpTracking);
    } catch (error) {
        throw error
    }
}

export default updatePumpTrackingLevelUseCase;


