import { StationPagination } from '../../domain/model/station_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchStationRepository {
    (pagination: Pagination): Promise<StationPagination>
}

const fetchStationRepository = (axios: AxiosInstance): FetchStationRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('https://stationapihydra.com/station', {
            params: pagination
        })
        const stationPagination: StationPagination = {items: response.data, total: response.data.length}
        return stationPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchStationRepository