import { ChangeCallCenterRepository } from '../../data/repository/change_call_center_repository';
import { CallCenter } from '../model/call_center';

export interface ChangeCallCenterUseCase {
    (id: number, data: object): Promise<CallCenter>
}

const changeCallCenterUseCase = (repository: ChangeCallCenterRepository): ChangeCallCenterUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeCallCenterUseCase;

