import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Harvest } from '../../domain/model/harvest';

export interface CreateHarvestRepository {
  (data: Harvest): Promise<Harvest>
}

const createHarvestRepository = (axios: AxiosInstance): CreateHarvestRepository => async (data: Harvest) => {
  try {
    const response: AxiosResponse = await axios.post(`/harvest`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createHarvestRepository

