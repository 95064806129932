export interface Station {
    id: number;
    name: string;
    latitude: string;
    longitude: string;
    barometer: boolean;
    temperature: boolean;
    humidity: boolean;
    wind_velocity: boolean;
    wind_direction: boolean;
    pluviometer: boolean;
    pluviometer_model: string;
    pluviometer_mmpp: number;
    wind_direction_coefficient: number;
    company_id: number;
    company_name: string;
    created_at: Date;
    updated_at: Date;
  }
  
  export const stationDefault = () => {
    const station: Station = {
      id: 0,
      name: "",
      latitude: "",
      longitude: "",
      barometer: false,
      temperature: false,
      humidity: false,
      wind_direction: false,
      wind_velocity: false,
      pluviometer: false,
      pluviometer_model: "",
      pluviometer_mmpp: 0,
      wind_direction_coefficient: 0,
      company_id: 0,
      company_name: "",
      created_at: new Date(),
      updated_at: new Date()
    }
    return station
  }
  
  const en: any = {
    id: 1,
    name: 2,
    latitude: 3,
    longitude: 4,
    temperature: 5,
    humidity: 6,
    wind_direction: 7,
    wind_velocity: 8,
    pluviometer: 9,
    barometer: 10,
    company_id: 11,
    company_name: 12,
    created_at: 13,
    updated_at: 14,
  }
  export { en };
  
  