import { FetchPlaceUseCase } from '@/module/place/domain/usecase/fetch_place_usecase';
import { FetchEngineUseCase } from '../domain/usecase/fetch_engine_usecase';
import { DeleteEngineUseCase } from '../domain/usecase/delete_engine_usecase';
import { CreateEngineUseCase } from '../domain/usecase/create_engine_usecase';
import { ChangeEngineUseCase } from '../domain/usecase/change_engine_usecase';
import { EnginePagination } from '../domain/model/engine_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Engine } from '../domain/model/engine';
import { snackbar } from '@/core/controller/snackbar_controller';
import { PlacePagination } from '@/module/place/domain/model/place_pagination';

import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import * as XLSX from "xlsx";

class EngineController {
  public context: any;
  public status: number = 0
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchPlace: string  = ""
  public searchCompany: string  = ""
  public flagOption: string = ""
  public loading: boolean = false
  public loadingLogs: boolean = false
  public groupBy: string = ""
  public menuGroup: boolean = false
  public selectedEngine: Engine | null = null
  
  public loadingBtnExcel : boolean = false
  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus
  public enginePagination: EnginePagination = {
    total: 0,
    items: []
  }
  public placePagination: PlacePagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsPlace: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsTracking: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public engine: Engine = {
    name: "", place_id: 0, company_id: 0
  }

  private fetchPlaceUseCase: FetchPlaceUseCase
  private fetchEngineUseCase: FetchEngineUseCase
  private deleteEngineUseCase: DeleteEngineUseCase
  private createEngineUseCase: CreateEngineUseCase
  private changeEngineUseCase: ChangeEngineUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(context: any, fetchPlaceUseCase: FetchPlaceUseCase, fetchEngineUseCase: FetchEngineUseCase, deleteEngineUseCase: DeleteEngineUseCase, createEngineUseCase: CreateEngineUseCase, changeEngineUseCase: ChangeEngineUseCase, fetchCompanyUseCase: FetchCompanyUseCase) {
    this.context = context
    this.fetchPlaceUseCase = fetchPlaceUseCase
    this.fetchEngineUseCase = fetchEngineUseCase
    this.deleteEngineUseCase = deleteEngineUseCase
    this.createEngineUseCase = createEngineUseCase
    this.changeEngineUseCase = changeEngineUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  open() {
    this.flagOption = "create"
    
    this.paginatePlace()
    this.paginateCompany()
  }

  close(){
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.enginePagination = await this.fetchEngineUseCase(this.options, this.search, this.status)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  async paginatePlace() {
    if (this.searchPlace != null) {
      try {
        this.placePagination = await this.fetchPlaceUseCase(this.optionsPlace, this.searchPlace)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  // async paginateTracking() {
  //   this.loadingLogs = true
  //   try {
  //     if (this.selectedPump) {
  //       this.trackingPagination = await this.fetchPumpTrackingByIDUseCase(this.selectedPump.id, this.optionsTracking, "")
  //     }
  //   } catch (error: any) {
  //     snackbar.show({ message: error.toString() })
  //   } finally {
  //     this.loadingLogs = false
  //   }
  // }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async changeFav(item) {
    try {
      item.isFavorite = !item.isFavorite
      await this.changeEngineUseCase(item.id, item)
      this.paginate()
      snackbar.show({ message: "Alterado favorito com sucesso!", color: 'blue', timeout: 1000 })
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }
  watchOptionsPlace() {
    this.paginatePlace()
  }

  watchSearchPlace() {
    this.paginatePlace()
  }

  // watchSearchTracking() {
  //   this.paginateTracking()
  // }

  // watchOptionsTracking() {
  //   this.paginateTracking()
  // }
  watchSearchCompany() {
    this.paginateCompany()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createEngineUseCase(this.engine)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeEngineUseCase(this.engine.id, this.engine)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Engine) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteEngineUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }


  async change(item: Engine) {
    this.flagOption = "change"
    this.dialog = true
    this.searchPlace = item.place_id
    this.searchCompany = item.company_id
    this.engine = { ...item }
    this.paginateCompany()
    this.paginatePlace()
  }

   async logs(item: Engine) {
    try {      
      this.selectedEngine = item
      // this.paginateTracking()
      // this.dialogTracking = true

    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  } 

  async reloadLogs(){
    // try {
    //   this.paginateTracking()
    // } catch (error: any) {
    //   snackbar.show({ message: error.toString() })
    // }
  }

  // updateLevel(item: MarkPump) {
  //   this.selectedMarkPump = {...item}
  //   this.dialogPumpTracking = true
  // }

  async confirmUpdate() {
    // try {
    //   await this.updatePumpTrackingLevelUseCase(this.selectedMarkPump)
    //   this.dialogPumpTracking = false
    //   this.paginateTracking()
    //   snackbar.show({ message: "Alterado com sucesso!!", color: "green", timeout: 1000})
    // } catch (error: any) {
    //   snackbar.show({ message: error.toString() })
    // }
  }

  // async deleteSelected() {
  //   try {
  //     const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000})
  //     if (confirm) {
  //       if (this.selected.length > 0) {
  //         for (let el of this.selected) {
  //           await this.deletePumpMarkUseCase(el.id)
  //         }
  //         snackbar.show({ message: "Item(s) excluídos com sucesso!", color: "green", timeout: 1000 })
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }finally{
  //     this.selected = []
  //     this.paginateTracking()
  //   }
  // }


  // async exportExcel() {
  //   try {
  //     const heading = [['ID', 'ID BOMBA', 'VOLTAGEM', 'AMPEREAGEM', 'HORIMETRO', 'NIVEL MAXIMO', 'NIVEL MINIMO', 'CAPACITORES', 'MOTORES', 'DESCRIÇÃO', 'DATA CRIAÇÃO', 'ID USUARIO', 'NOME USUARIO', 'LIGADA']];
  //     this.loadingBtnExcel = true
  //     const paginate = {...this.optionsTracking}
  //     paginate.itemsPerPage = -1
  //     const data =  await this.fetchPumpTrackingByIDUseCase(this.selectedPump!.id, paginate, "")

  //     const wb = XLSX.utils.book_new();
  //     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  //     XLSX.utils.sheet_add_aoa(ws, heading);

  //     XLSX.utils.sheet_add_json(ws, data.items, { origin: 'A2', skipHeader: true })
  //     XLSX.utils.book_append_sheet(wb, ws, 'bomba');

  //     XLSX.writeFile(wb, `bomba.xlsx`);
  //   } catch (error: any) {
  //     snackbar.show({ message: error.toString() })
  //   } finally {
  //     this.loadingBtnExcel = false
  //   }
  // }

}

export default EngineController