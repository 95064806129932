import { DeleteRulerGroupUseCase } from '../domain/usecase/delete_rulerGroup_usecase';
import { CreateRulerGroupUseCase } from '../domain/usecase/create_rulerGroup_usecase';
import { ChangeRulerGroupUseCase } from '../domain/usecase/change_rulerGroup_usecase';
import { FetchRulerGroupUseCase } from '../domain/usecase/fetch_rulerGroup_usecase';
import { RulerGroupPagination } from '../domain/model/rulerGroup_pagination';
import { snackbar } from '@/core/controller/snackbar_controller';
import { headers } from '../const/headers';
import { DataOptions } from 'vuetify';
import { itemStatus, visibilityStatus } from '@/core/domain/model/pagination';
import { RulerFromGroup, RulerGroup, rulerGroupDefault } from '../domain/model/rulerGroup';
import { RulerPagination } from '@/module/ruler/domain/model/ruler_pagination';
import { Ruler, rulerDefault } from '@/module/ruler/domain/model/ruler';
import { FetchRulerUseCase } from '@/module/ruler/domain/usecase/fetch_ruler_usecase';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';

class RulerGroupController {
  public context: any;
  public status: number = 0
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchRuler: string = ""
  public searchCompany: string = ""
  public loading: boolean = false

  public visibilityStatus = visibilityStatus
  public itemStatus = itemStatus

  public rulerGroupPagination: RulerGroupPagination = {
    total: 0,
    items: []
  }
  public rulerPagination: RulerPagination = {
    total: 0,
    items: []
  }
  public companyPagination: CompanyPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsRuler: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }
  public optionsCompany: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public rulerGroup: RulerGroup = rulerGroupDefault()
  public ruler: Ruler = rulerDefault()

  private fetchRulerGroupUseCase: FetchRulerGroupUseCase
  private deleteRulerGroupUseCase: DeleteRulerGroupUseCase
  private createRulerGroupUseCase: CreateRulerGroupUseCase
  private changeRulerGroupUseCase: ChangeRulerGroupUseCase
  private fetchRulerUseCase: FetchRulerUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase

  constructor(
    context: any,
    fetchRulerGroupUseCase: FetchRulerGroupUseCase,
    deleteRulerGroupUseCase: DeleteRulerGroupUseCase,
    createRulerGroupUseCase: CreateRulerGroupUseCase,
    changeRulerGroupUseCase: ChangeRulerGroupUseCase,
    fetchRulerUseCase: FetchRulerUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase,
  ) {
    this.context = context
    this.fetchRulerGroupUseCase = fetchRulerGroupUseCase
    this.deleteRulerGroupUseCase = deleteRulerGroupUseCase
    this.createRulerGroupUseCase = createRulerGroupUseCase
    this.changeRulerGroupUseCase = changeRulerGroupUseCase
    this.fetchRulerUseCase = fetchRulerUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
  }

  open() {
    this.rulerGroup = rulerGroupDefault()
    this.paginateRuler()
    this.paginateCompany()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.rulerGroupPagination = await this.fetchRulerGroupUseCase(this.options, this.search, this.status)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error) {
        console.log(error);
      }
    }
  }

  async paginateRuler() {
    if (this.searchRuler != null) {
      try {
        this.rulerPagination = await this.fetchRulerUseCase(this.optionsRuler, this.searchRuler, this.status)
      } catch (error) {
        console.log(error);
      }
    }
  }


  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchOptionsRuler() {
    this.paginate()
  }

  watchSearchRuler() {
    this.paginate()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }


  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.rulerGroup.id != 0) {
          await this.changeRulerGroupUseCase(this.rulerGroup.id, this.rulerGroup)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })

        } else {
          await this.createRulerGroupUseCase(this.rulerGroup)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: RulerGroup) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteRulerGroupUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: RulerGroup) {
    await this.paginateCompany()
    await this.paginateRuler()
    this.dialog = true
    this.rulerGroup = { ...item }
    const data = { ...item }
    const rulersFromGroup: Array<RulerFromGroup> = []
    if (data.items.length > 0) {
      for (let el of data.items) {
        const ruler: RulerFromGroup = {
          rulerID: el.id,
          id: el.id,
          name: el.name,
          itemOrder: 0
        }
        rulersFromGroup.push(ruler)
      }
    }
    this.rulerGroup.items = rulersFromGroup
    this.reOrder()
  }


  addRuler(item) {
    if (this.rulerGroup.items.find(el => el.rulerID == item.id)) {
      snackbar.show({ message: "Está regua já está na lista!", color: "primary", timeout: 1000 })
    } else {
      const ruler = {
        rulerID: item.id,
        id: item.id,
        name: item.name,
        itemOrder: this.rulerGroup.items.length + 1
      }
      this.rulerGroup.items.push(ruler)
    }
  }

  removeRuler(item) {
    const index = this.rulerGroup.items.indexOf(item);
    if (index > -1) {
      this.rulerGroup.items.splice(index, 1);
      this.reOrder()
    }
  }

  reOrder() {
    this.rulerGroup.items.map(
      el => {
        const index = this.rulerGroup.items.indexOf(el);
        el.itemOrder = index + 1
      }
    )
  }

  changeCompanyRulers(data) {
    this.rulerGroup.companyID = data
  }

}

export default RulerGroupController