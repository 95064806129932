import { FetchPlaceMeasurementRepository } from '../../data/repository/fetch_placeMeasurement_repository';
import { BombMeasurement } from './../model/bomb_measurement';

export interface FetchPlaceMeasurementUseCase {
    (id: number, startDate:string, endDate: string): Promise<Array<BombMeasurement>>
}

const fetchPlaceMeasurementUseCase = (repository: FetchPlaceMeasurementRepository): FetchPlaceMeasurementUseCase => async (id: number, startDate:string, endDate: string) => {
    try {
        return await repository(id, startDate, endDate);
    } catch (error) {
        throw error
    }
}

export default fetchPlaceMeasurementUseCase;