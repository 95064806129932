import { FetchUserProfileImageRepository } from '../../data/repository/fetch_profile_image_repository';
import { User } from '../model/user';

export interface FetchUserProfileImageUseCase {
    (id: number): Promise<User>
}

const fetchUserProfileImageUseCase = (repository: FetchUserProfileImageRepository): FetchUserProfileImageUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default fetchUserProfileImageUseCase;